import { datadogLogs } from '@datadog/browser-logs';
// Standalone runtime for Regenerator-compiled generator and async functions
import 'regenerator-runtime/runtime';
// Important import related to HMR. It have to be at the
// very top of the application source code.
// TODO: remove or uncomment after testing (react-router)
// import 'react-hot-loader/patch';
// css imports
import './fonts/fonts.css';
import 'react-pivottable/pivottable.css';
import 'react-phone-number-input/style.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// Special AppContainer to make HMR work
// TODO: remove or uncomment after testing (react-router)
// import { AppContainer } from 'react-hot-loader';
// React and ReactDOM instances
import React from 'react';
import { createRoot } from 'react-dom/client';
// root
import AppRouter from './src/router';
// helpers
import { isStaging, isLocalhost, isProduction, isDevelopment } from './src/helpers/env.js';
import { initWindowAmousLoadDivisionMap, clearWindowAmousBranchConfigsByInterval } from './src/helpers';
//////////////////////////////////////////////////


const getDatadogEnv = () => {
  if (isProduction) return 'AmousProdFront';

  if (isStaging) return 'AmousStageFront';

  if (isDevelopment) return 'AmousDevFront';
};

datadogLogs.init({
  clientToken: 'pub3d5b1086a22665aa43c6eea80e1045d0',
  site: 'datadoghq.com',
  forwardErrorsToLogs: false,
  sessionSampleRate: 100,
  env: getDatadogEnv(),
  service: 'amous-ui',
});

initWindowAmousLoadDivisionMap();
clearWindowAmousBranchConfigsByInterval();

// From this place our app start loading;
// In development mode HMR activated using react-hot-loader
// const render = (Component: Object) => {
//   ReactDOM.render(
//     <div id='app-container'>
//       <Component />
//       {/* TODO: remove or uncomment after testing (react-router) */}
//       {/* {isLocalhost ? (
//         <AppContainer>
//           <Component />
//         </AppContainer>
//       ) : (
//         <Component />
//       )} */}
//     </div>,
//     document.getElementById('amous-frontend-app'));
// };

const root = createRoot(document.getElementById('amous-frontend-app'));

root.render(
  <AppRouter />,
);

// render(AppRouter);
