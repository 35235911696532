import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const store = R.path(['fleetProfile', 'driver']);

const selectDriverGeneralDetails = createSelector(
  R.path(['fleetProfile', 'driver', 'generalDetails']),
  (generalDetails: Object) => {
    if (R.isNil(generalDetails)) return;

    const { assignedDivision, domicileTerminal, unemploymentReason } = generalDetails;

    return {
      ...generalDetails,
      [GC.FIELD_ASSIGNED_DIVISION]: G.getDropdownOptionGuidFromObject(assignedDivision),
      [GC.FIELD_DOMICILE_TERMINAL]: G.getDropdownOptionGuidFromObject(domicileTerminal),
      [GC.FIELD_UNEMPLOYMENT_REASON]: R.propOr('', GC.FIELD_DROPDOWN_OPTION_GUID, unemploymentReason),
    };
  },
);

const selectUserGuid = createSelector(
  R.path(['fleetProfile', 'driver', 'generalDetails', 'userGuid']),
  (userGuid: string) => R.join('', R.split('', R.or(userGuid, ''))),
);

const makeSelectGeneralTabEntities = () => createSelector(
  store,
  (state: Object) => {
    const payToLocation = R.path(['generalDetails', GC.SYSTEM_OBJECT_PAY_TO_LOCATION], state);

    return {
      ...R.pick(
        [
          'contacts',
          'references',
          'driverHours',
          'integrationList',
          'safetyViolation',
          'lastKnownLocation',
        ],
        state,
      ),
      [GC.SYSTEM_OBJECT_PAY_TO_LOCATION]: G.ifElse(G.isNilOrEmpty(payToLocation), [], R.of(Array, payToLocation)),
    };
  },
);

const makeSelectLicenseDocumentsTabEntities = () => createSelector(
  store,
  (state: Object) => {
    const { documents } = state;

    const documentsFilter = R.path(['formGroupFilters', 'documentsFilter'], state);

    const filteredDocuments = R.compose(
      R.filter((item: Object) => R.or(
        G.isNilOrEmpty(documentsFilter),
        R.pathEq(documentsFilter, [GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], item),
      )),
      R.pathOr([], ['documents']),
    )(state);

    return {
      ...R.pick(['license', 'citizenship', 'certifications'], state),
      documents: G.ifElse(G.isNotNil(documents), filteredDocuments, documents),
    };
  },
);

const makeSelectDriverPaidByTrip = () => createSelector(
  store,
  R.path(['generalDetails', GC.FIELD_DRIVER_PAID_BY_TRIP]),
);

const makeSelectRatingEngineTabEntities = () => createSelector(
  store,
  R.pick(['accessorialList', 'compensationList']),
);

const makeSelectPayrollTabEntities = () => createSelector(
  store,
  (state: Object) => {
    const { payrollCompensation } = state;

    return {
      ...R.pick(
        ['deductionSettings', 'payrollAccessorialList', 'monthlyPayrollDeductionList'],
        state,
      ),
      payrollCompensation: G.ifElse(
        G.isNotNilAndNotEmpty(payrollCompensation),
        R.assocPath([0, 'expanded'], true, payrollCompensation),
        payrollCompensation,
      ),
    };
  },
);

const makeSelectDriverDailyLogTabEntities = () => createSelector(
  store,
  R.pick(['dailyHoursList']),
);

const makeSelectAdvancePaymentTabEntities = () => createSelector(
  store,
  R.pick(['advancePaymentList']),
);

const makeSelectDriverExpenseTabEntities = () => createSelector(
  store,
  R.pick(['driverExpenseList']),
);

const makeSelectDriverInfoForAdvancePayment = () => createSelector(
  store,
  R.compose(
    R.pick([
      'userGuid',
      GC.FIELD_GUID,
      GC.FIELD_LAST_NAME,
      GC.FIELD_FIRST_NAME,
      GC.FIELD_USER_LOGIN_ID,
    ]),
    R.pathOr({}, ['generalDetails']),
  ),
);

const makeSelectUnavailablePeriodsTabEntities = () => createSelector(
  store,
  R.pick(['unavailablePeriodList']),
);

const makeSelectUnavailablePeriodDatesFilter = () => createSelector(
  store,
  R.path(['formGroupFilters', 'unavailablePeriodDatesFilter']),
);

const makeSelectUnavailablePeriodReasonFilter = () => createSelector(
  store,
  R.path(['formGroupFilters', 'unavailablePeriodReasonFilter']),
);

const makeSelectDriverExpenseTypes = () => createSelector(
  store,
  R.path(['driverExpenseTypes']),
);

export {
  // common
  selectUserGuid,
  selectDriverGeneralDetails,
  // driver
  makeSelectDriverPaidByTrip,
  makeSelectGeneralTabEntities,
  makeSelectPayrollTabEntities,
  makeSelectRatingEngineTabEntities,
  makeSelectDriverExpenseTabEntities,
  makeSelectDriverDailyLogTabEntities,
  makeSelectAdvancePaymentTabEntities,
  makeSelectDriverInfoForAdvancePayment,
  makeSelectLicenseDocumentsTabEntities,
  makeSelectUnavailablePeriodsTabEntities,
  // filters
  makeSelectUnavailablePeriodDatesFilter,
  makeSelectUnavailablePeriodReasonFilter,
  // expenses
  makeSelectDriverExpenseTypes,
};
