import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { ENUMS } from '../../constants/enums';
// features
import {
  socketAvailableDriversNoteReceived,
  socketAvailableDriversStatusReceived,
  socketAvailableDriversCommentsReceived,
  socketAvailableDriversLocationReceived,
  socketAvailableDriversReservationReceived,
} from '../sockets-v2/actions';
// feature available-driver
import * as A from './actions';
//////////////////////////////////////////////////

const commonStateProps = {
  totalCount: 0,
  itemList: null,
  loading: false,
};

const initialState = {
  ...commonStateProps,
  truckTypes: [],
  searchRadius: '',
  locationValue: '',
  colorFilter: null,
  pageVisited: false,
  driverInitials: '',
  unassignedLoads: [],
  searchLocation: null,
  teamFilterValue: false,
  showUnassignedLoads: false,
  searchDate: G.getCurrentDay(),
  activeList: 'availableDrivers',
  uiReportFields: G.getUiReportFieldsFromLocalStorage('availableDrivers'),
  allDrivers: {
    ...commonStateProps,
    pagination: {
      limit: 20,
      offset: 0,
    },
  },
};

const setInitialState = () => (
  initialState
);

const setListLoading = (state: Object, data: boolean) => (
  P.$set('loading', data, state)
);

const getItemListSuccess = (state: Object, { driverList }: Object) => {
  const list = R.indexBy(R.prop(GC.FIELD_GUID), driverList);

  return P.$all(
    P.$set('itemList', list),
    P.$set('pageVisited', true),
    P.$set('totalCount', R.length(driverList)),
    state,
  );
};

const setSearchDate = (state: Object, data: string) => (
  P.$set('searchDate', data, state)
);

const setTruckTypes = (state: Object, data: Array) => (
  P.$set('truckTypes', data, state)
);

const setSearchRadius = (state: Object, data: number) => (
  P.$set('searchRadius', data, state)
);

const setLocationValue = (state: Object, data: string) => (
  P.$set('locationValue', data, state)
);

const setDriverInitials = (state: Object, data: string) => (
  P.$set('driverInitials', data, state)
);

const setSearchLocation = (state: Object, data: any) => (
  P.$set('searchLocation', data, state)
);

const setActiveList = (state: Object, data: string) => (
  P.$set('activeList', data, state)
);

const setColorFilter = (state: Object, data: string) => P.$set('colorFilter', data, state);

const toggleTeamFilterValue = (state: Object) => P.$toggle('teamFilterValue', state);

const toggleUnassignedLoads = (state: Object) => P.$toggle('showUnassignedLoads', state);

const setUiReportFields = (state: Object, data: Array) => P.$set('uiReportFields', data, state);

const getUnassignedLoadsSuccess = (state: Object, data: Object) => P.$set('unassignedLoads', data, state);

const assignDriverOnAvailableDriverSuccess = (state: Object, { telGuid }: Object) => {
  const { unassignedLoads } = state;

  const newUnassignedLoads = R.reject(R.propEq(telGuid, GC.FIELD_GUID), unassignedLoads);

  return P.$set('unassignedLoads', newUnassignedLoads, state);
};

const updateAvailableDriversColorSuccess = (state: Object, { guid, color }: Object) => {
  const driverPath = `itemList.${guid}`;
  const driverFromAllDriversListPath = `allDrivers.${driverPath}`;

  const driver = P.$get(driverPath, state);
  const driverFromAllDriversList = P.$get(driverFromAllDriversListPath, state);

  if (G.isAllNilOrEmpty([driver, driverFromAllDriversList])) return state;

  if (G.isNilOrEmpty(driver)) {
    return P.$set(`${driverFromAllDriversListPath}.${GC.FIELD_AVAILABLE_DRIVERS_COLOR}`, color, state);
  }

  if (G.isNilOrEmpty(driverFromAllDriversList)) {
    return P.$set(`${driverPath}.${GC.FIELD_AVAILABLE_DRIVERS_COLOR}`, color, state);
  }

  return P.$all(
    P.$set(`${driverPath}.${GC.FIELD_AVAILABLE_DRIVERS_COLOR}`, color),
    P.$set(`${driverFromAllDriversListPath}.${GC.FIELD_AVAILABLE_DRIVERS_COLOR}`, color),
    state,
  );
};

// all drivers
const getAllDriversListSuccess = (state: Object, data: Object) => {
  const { allDrivers } = state;

  const { itemList, totalCount, pagination } = allDrivers;

  const { limit, offset } = pagination;

  const newOffset = R.add(offset, limit);

  const newList = R.mergeRight(itemList, R.indexBy(R.prop(GC.FIELD_GUID), data));

  return P.$all(
    P.$set('allDrivers.itemList', newList),
    P.$set('allDrivers.pagination.limit', 10),
    P.$set(
      'allDrivers.pagination.offset',
      G.ifElse(
        R.gt(totalCount, newOffset),
        newOffset,
        totalCount,
      ),
    ),
    state,
  );
};

const setAllDriversListLoading = (state: Object, data: boolean) => (
  P.$set('allDrivers.loading', data, state)
);

const resetAllDriversListAndPagination = (state: Object) => (
  P.$all(
    P.$set('allDrivers.totalCount', 0),
    P.$set('allDrivers.itemList', null),
    P.$set('allDrivers.pagination', R.path(['allDrivers', 'pagination'], initialState)),
    state,
  )
);

const getDriverAvailabilityTotalCountSuccess = (state: Object, data: number) => (
  P.$set('allDrivers.totalCount', data, state)
);

// sockets
const getDriverPathsAndCommonInfo = (state: Object, driverGuid: string) => {
  const driverPath = `itemList.${driverGuid}`;
  const driverFromAllDriversListPath = `allDrivers.${driverPath}`;

  const driver = P.$get(driverPath, state);
  const driverFromAllDriversList = P.$get(driverFromAllDriversListPath, state);

  const isDriverMissing = G.isNilOrEmpty(driver);
  const isDriverFromAllDriversListMissing = G.isNilOrEmpty(driverFromAllDriversList);
  const isDriversDataMissing = R.and(isDriverMissing, isDriverFromAllDriversListMissing);

  return {
    driver,
    driverPath,
    isDriverMissing,
    isDriversDataMissing,
    driverFromAllDriversList,
    driverFromAllDriversListPath,
    isDriverFromAllDriversListMissing,
  };
};

const handleWSAvailableDriversReservationReceived = (state: Object, { data, driverGuid }: Object) => {
  const {
    driver,
    driverPath,
    isDriverMissing,
    isDriversDataMissing,
    driverFromAllDriversList,
    driverFromAllDriversListPath,
    isDriverFromAllDriversListMissing,
  } = getDriverPathsAndCommonInfo(state, driverGuid);

  if (isDriversDataMissing) return state;

  const { reserved, reservedBy, reservationEndDate } = data;

  const newDriverFromAllDriversList = isDriverFromAllDriversListMissing
    ? {}
    : {
      ...driverFromAllDriversList,
      reserved,
      reservedBy,
      reservationEndDate,
    };

  if (isDriverMissing) return P.$set(driverFromAllDriversListPath, newDriverFromAllDriversList, state);

  const newDriver = {
    ...driver,
    reserved,
    reservedBy,
    reservationEndDate,
  };

  if (isDriverFromAllDriversListMissing) return P.$set(driverPath, newDriver, state);

  return P.$all(
    P.$set(driverPath, newDriver),
    P.$set(driverFromAllDriversListPath, newDriverFromAllDriversList),
    state,
  );
};

const handleWSAvailableDriversNoteReceived = (state: Object, { data, driverGuid }: Object) => {
  const {
    driverPath,
    isDriverMissing,
    isDriversDataMissing,
    driverFromAllDriversListPath,
    isDriverFromAllDriversListMissing,
  } = getDriverPathsAndCommonInfo(state, driverGuid);

  if (isDriversDataMissing) return state;

  if (isDriverMissing) {
    return P.$set(`${driverFromAllDriversListPath}.${GC.FIELD_NOTE}`, data, state);
  }

  if (isDriverFromAllDriversListMissing) {
    return P.$set(`${driverPath}.${GC.FIELD_NOTE}`, data, state);
  }

  return P.$all(
    P.$set(`${driverPath}.${GC.FIELD_NOTE}`, data),
    P.$set(`${driverFromAllDriversListPath}.${GC.FIELD_NOTE}`, data),
    state,
  );
};

const handleWSAvailableDriversCommentsReceived = (state: Object, { driverGuid, data: { comments } }: Object) => {
  const {
    driverPath,
    isDriverMissing,
    isDriversDataMissing,
    driverFromAllDriversListPath,
    isDriverFromAllDriversListMissing,
  } = getDriverPathsAndCommonInfo(state, driverGuid);

  if (isDriversDataMissing) return state;

  if (isDriverMissing) {
    return P.$set(`${driverFromAllDriversListPath}.${GC.FIELD_COMMENTS}`, comments, state);
  }

  if (isDriverFromAllDriversListMissing) {
    return P.$set(`${driverPath}.${GC.FIELD_COMMENTS}`, comments, state);
  }

  return P.$all(
    P.$set(`${driverPath}.${GC.FIELD_COMMENTS}`, comments),
    P.$set(`${driverFromAllDriversListPath}.${GC.FIELD_COMMENTS}`, comments),
    state,
  );
};

const handleWSAvailableDriversLocationReceived = (state: Object, { data, driverGuid }: Object) => {
  const {
    driver,
    driverPath,
    isDriverMissing,
    isDriversDataMissing,
    driverFromAllDriversListPath,
    isDriverFromAllDriversListMissing,
  } = getDriverPathsAndCommonInfo(state, driverGuid);

  if (isDriversDataMissing) return state;

  if (isDriverMissing) {
    return P.$set(`${driverFromAllDriversListPath}.${GC.FIELD_LOCATION}`, data, state);
  }

  const { searchLocation } = state;

  let distance = R.path([GC.FIELD_DISTANCE], driver);

  if (R.and(G.isNotNilAndNotEmpty(searchLocation), G.isNilOrEmpty(R.path([GC.FIELD_TEL], driver)))) {
    distance = G.mathRoundNumber(G.getDistanceBetweenTwoPoints(searchLocation, data));
  }

  if (isDriverFromAllDriversListMissing) {
    return P.$all(
      P.$set(`${driverPath}.${GC.FIELD_LOCATION}`, data),
      P.$set(`${driverPath}.${GC.FIELD_DISTANCE}`, distance),
      state,
    );
  }

  return P.$all(
    P.$set(`${driverPath}.${GC.FIELD_LOCATION}`, data),
    P.$set(`${driverPath}.${GC.FIELD_DISTANCE}`, distance),
    P.$set(`${driverFromAllDriversListPath}.${GC.FIELD_LOCATION}`, data),
    state,
  );
};

const handleWSAvailableDriversStatusReceived = (state: Object, { data, driverGuid }: Object) => {
  const {
    driver,
    driverPath,
    isDriverMissing,
    isDriversDataMissing,
    driverFromAllDriversList,
    driverFromAllDriversListPath,
    isDriverFromAllDriversListMissing,
  } = getDriverPathsAndCommonInfo(state, driverGuid);

  if (isDriversDataMissing) return state;

  const { searchLocation } = state;

  const { status, statusSource, availableAtDate, availableAtLocation } = data;

  if (G.isNotNilAndNotEmpty(R.path([GC.FIELD_TEL], R.or(driver, driverFromAllDriversList)))) {
    if (isDriverMissing) {
      return P.$set(`${driverFromAllDriversListPath}.${GC.FIELD_STATUS}`, status, state);
    }

    if (isDriverFromAllDriversListMissing) {
      return P.$set(`${driverPath}.${GC.FIELD_STATUS}`, status, state);
    }

    return P.$all(
      P.$set(`${driverPath}.${GC.FIELD_STATUS}`, status),
      P.$set(`${driverFromAllDriversListPath}.${GC.FIELD_STATUS}`, status),
      state,
    );
  }

  const newDriverFromAllDriversList = isDriverFromAllDriversListMissing
    ? {}
    : {
      ...driverFromAllDriversList,
      status,
      statusSource,
      [GC.FIELD_FUTURE_AVAILABILITY_DATE]: availableAtDate,
      [GC.FIELD_FUTURE_AVAILABILITY_LOCATION]: availableAtLocation,
    };

  if (isDriverMissing) return P.$set(driverFromAllDriversListPath, newDriverFromAllDriversList, state);

  let distance = R.path([GC.FIELD_DISTANCE], driver);

  if (G.isNotNilAndNotEmpty(searchLocation)) {
    if (R.equals(status, ENUMS.ENUM_AVAILABLE_IN_FUTURE)) {
      distance = G.mathRoundNumber(G.getDistanceBetweenTwoPoints(searchLocation, availableAtLocation));
    }

    const { futureAvailabilityLocation } = driver;

    if (R.and(R.equals(status, ENUMS.ENUM_UNAVAILABLE), G.isNotNilAndNotEmpty(futureAvailabilityLocation))) {
      const { location } = driver;

      distance = G.mathRoundNumber(G.getDistanceBetweenTwoPoints(searchLocation, location));
    }
  }

  const newDriver = {
    ...driver,
    status,
    distance,
    statusSource,
    [GC.FIELD_FUTURE_AVAILABILITY_DATE]: availableAtDate,
    [GC.FIELD_FUTURE_AVAILABILITY_LOCATION]: availableAtLocation,
  };

  if (isDriverFromAllDriversListMissing) return P.$set(driverPath, newDriver, state);

  return P.$all(
    P.$set(driverPath, newDriver),
    P.$set(driverFromAllDriversListPath, newDriverFromAllDriversList),
    state,
  );
};

export default createReducer({
  [A.setSearchDate]: setSearchDate,
  [A.setTruckTypes]: setTruckTypes,
  [A.setActiveList]: setActiveList,
  [A.setListLoading]: setListLoading,
  [A.setColorFilter]: setColorFilter,
  [A.setSearchRadius]: setSearchRadius,
  [A.setInitialState]: setInitialState,
  [A.setLocationValue]: setLocationValue,
  [A.setUiReportFields]: setUiReportFields,
  [A.setDriverInitials]: setDriverInitials,
  [A.setSearchLocation]: setSearchLocation,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.toggleTeamFilterValue]: toggleTeamFilterValue,
  [A.toggleUnassignedLoads]: toggleUnassignedLoads,
  [A.getUnassignedLoadsSuccess]: getUnassignedLoadsSuccess,
  [A.updateAvailableDriversColorSuccess]: updateAvailableDriversColorSuccess,
  [A.assignDriverOnAvailableDriverSuccess]: assignDriverOnAvailableDriverSuccess,
  // all drivers
  [A.setAllDriversListLoading]: setAllDriversListLoading,
  [A.getAllDriversListSuccess]: getAllDriversListSuccess,
  [A.resetAllDriversListAndPagination]: resetAllDriversListAndPagination,
  [A.getDriverAvailabilityTotalCountSuccess]: getDriverAvailabilityTotalCountSuccess,
  // sockets
  [socketAvailableDriversNoteReceived]: handleWSAvailableDriversNoteReceived,
  [socketAvailableDriversStatusReceived]: handleWSAvailableDriversStatusReceived,
  [socketAvailableDriversCommentsReceived]: handleWSAvailableDriversCommentsReceived,
  [socketAvailableDriversLocationReceived]: handleWSAvailableDriversLocationReceived,
  [socketAvailableDriversReservationReceived]: handleWSAvailableDriversReservationReceived,
}, initialState);
