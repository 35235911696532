import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature new-do
import { makeLocation, getDataFromStops } from './helpers';
import { locationFieldsToPick } from './settings/fields-settings';
//////////////////////////////////////////////////

const selectNewDOStore = (state: object) => state.newDO;
const selectBranchStore = (state: object) => state.branch;
const selectNewDOBranchConfigs = (state: Object) => state.newDO.branchConfigs;

const makeSelectFullDOStore = () => createSelector(
  selectNewDOStore,
  (store: Object) => ({ ...store }),
);

const makeSelectPageType = () => createSelector(
  selectNewDOStore,
  ({ pageType }: Object) => pageType,
);

const makeSelectStops = () => createSelector(
  selectNewDOStore,
  ({ stops }: Object) => stops,
);

const makeSelectLoadType = () => createSelector(
  selectNewDOStore,
  ({ loadType }: Object) => loadType,
);

const makeSelectStopsData = () => createSelector(
  selectNewDOStore,
  ({ stops }: Object) => getDataFromStops(stops),
);

const makeSelectRate = () => createSelector(
  selectNewDOStore,
  ({ rate }: Object) => rate,
);

const makeSelectStyling = () => createSelector(
  selectNewDOStore,
  ({ styling }: Object) => styling,
);

const makeSelectNumberOfLoads = () => createSelector(
  selectNewDOStore,
  ({ numberOfLoads }: Object) => numberOfLoads,
);

const makeSelectBranchList = () => createSelector(
  selectNewDOStore,
  ({ branchList }: Object) => branchList,
);

const makeSelectBranchInfo = () => createSelector(
  selectNewDOStore,
  ({ branchInfo }: Object) => branchInfo,
);

const makeSelectBranchGuid = () => createSelector(
  selectNewDOStore,
  ({ branchInfo }: Object) => R.path([GC.FIELD_VALUE], branchInfo),
);

const makeSelectDivisionGuid = () => createSelector(
  selectNewDOStore,
  ({ divisionGuid }: Object) => divisionGuid,
);

const makeSelectBranchConfigs = () => createSelector(
  selectNewDOStore,
  ({ branchConfigs }: Object) => branchConfigs,
);

const makeSelectBranchRefTypes = () => createSelector(
  selectNewDOStore,
  ({ branchRefTypes }: Object) => branchRefTypes,
);

const makeSelectPrimaryReference = () => createSelector(
  selectNewDOStore,
  ({ primaryReference }: Object) => primaryReference,
);

const makeSelectCustomerLoadNumber = () => createSelector(
  selectNewDOStore,
  ({ customerLoadNumber }: Object) => customerLoadNumber,
);

const makeSelectLeftActiveTad = () => createSelector(
  selectNewDOStore,
  ({ leftActiveTad }: Object) => leftActiveTad,
);

const makeSelectRightActiveTad = () => createSelector(
  selectNewDOStore,
  ({ rightActiveTad }: Object) => rightActiveTad,
);

const makeSelectReferenceFormData = () => createSelector(
  selectNewDOStore,
  ({ referenceFormData }: Object) => referenceFormData,
);

const makeSelectBillTo = () => createSelector(
  selectNewDOStore,
  ({ billTo }: Object) => billTo,
);

const makeSelectValidationErrors = () => createSelector(
  selectNewDOStore,
  ({ validationErrors }: Object) => validationErrors,
);

const makeSelectValidationWarnings = () => createSelector(
  selectNewDOStore,
  ({ validationWarnings }: Object) => validationWarnings,
);

const makeSelectEdiFile = () => createSelector(
  selectNewDOStore,
  ({ ediFile }: Object) => ediFile,
);

const makeSelectRatePreviewFiles = () => createSelector(
  selectNewDOStore,
  ({ ratePreviewFiles }: Object) => ratePreviewFiles,
);

const makeSelectEdiData = () => createSelector(
  selectNewDOStore,
  ({ ediData }: Object) => ediData,
);

const makeSelectTemplateData = () => createSelector(
  selectNewDOStore,
  ({ templateData }: Object) => templateData,
);

const makeSelectTelCreationMode = () => createSelector(
  selectNewDOStore,
  ({ telCreationMode }: Object) => telCreationMode,
);

const makeSelectRouteTelConfigs = () => createSelector(
  selectNewDOBranchConfigs,
  (configs: Object) => G.getRouteTelConfigsForCreateClo(configs),
);

export const createPrimaryReferenceDisplayValue = (sequence: Object, configPrefix: string, divisionPrefix: string) => {
  const arrayOfValues = [divisionPrefix, configPrefix, sequence.prefix, sequence.approxValue, sequence.suffix];
  return G.createStringFromArray(arrayOfValues, '');
};

const makeSelectPrimaryRefSequenceValue = () => createSelector(
  selectNewDOStore,
  selectBranchStore,
  (doStore: Object, branchStore: Object) => {
    const { branchConfigs, divisionGuid, primaryRefSequence } = doStore;
    const { branchesConfigs } = branchStore;
    const autogenerated = G.getConfigValueFromStore(
      GC.CLO_PRIMARY_REFERENCE_AUTOGENERATED,
      branchConfigs,
    );
    if (R.and(G.isTrue(autogenerated), G.isNotNil(primaryRefSequence))) {
      const configPrefix = G.getConfigValueFromStore(
        GC.CLO_PRIMARY_REFERENCE_PREFIX,
        branchConfigs,
      );
      const configsForDivisionPrefix = G.ifElse(
        G.isNotNilAndNotEmpty(divisionGuid),
        R.pathOr({}, [divisionGuid], branchesConfigs),
        branchConfigs,
      );
      const divisionPrefix = G.getConfigValueFromStore(
        GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX,
        configsForDivisionPrefix,
      );
      return createPrimaryReferenceDisplayValue(primaryRefSequence, configPrefix, divisionPrefix);
    }
    return '';
  },
);

const makeSelectPrimaryRefTypeGuid = () => createSelector(
  selectNewDOBranchConfigs,
  (configs: Object) => G.getConfigValueFromStore(
    GC.CLO_PRIMARY_REFERENCE_TYPE,
    configs,
  ),
);

const makeSelectPrimaryRefTypeName = () => createSelector(
  selectNewDOStore,
  (doStore: Object) => {
    const { branchConfigs, branchRefTypes } = doStore;
    const primaryRefTypeGuid = G.getConfigValueFromStore(
      GC.CLO_PRIMARY_REFERENCE_TYPE,
      branchConfigs,
    );

    return R.compose(
      R.path([GC.FIELD_NAME]),
      R.find(R.propEq(primaryRefTypeGuid, GC.FIELD_GUID)),
      R.pathOr([], [GC.LOAD_TYPE_CLO]),
    )(branchRefTypes);
  },
);

const makeSelectCustomerRefTypeName = () => createSelector(
  selectNewDOStore,
  (doStore: Object) => {
    const { branchConfigs, branchRefTypes } = doStore;
    const primaryRefTypeGuid = G.getConfigValueFromStore(
      GC.CLO_GENERAL_CUSTOMER_REFERENCE_TYPE,
      branchConfigs,
    );

    return R.compose(
      R.path([GC.FIELD_NAME]),
      R.find(R.propEq(primaryRefTypeGuid, GC.FIELD_GUID)),
      R.pathOr([], [GC.LOAD_TYPE_CLO]),
    )(branchRefTypes);
  },
);

const makeSelectCustomerReferenceValue = () => createSelector(
  selectNewDOStore,
  ({ customerReferenceValue }: Object) => customerReferenceValue,
);

const getTotalWeight = (stopsData: Object) => {
  const { pickedItems, pickedUpContainers } = stopsData;

  if (G.isNotNilAndNotEmpty(pickedItems)) {
    return G.renameKeys({
      [GC.FIELD_ITEM_WEIGHT]: GC.FIELD_TOTAL_TRIP_WEIGHT,
      [GC.FIELD_ITEM_WEIGHT_TYPE]: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    }, G.calcItemsTotalWeightWithoutQty(pickedItems));
  }

  if (G.isNotNilAndNotEmpty(pickedUpContainers)) {
    return G.renameKeys({
      [GC.FIELD_WEIGHT_UOM]: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
      [GC.FIELD_FULL_CONTAINER_WEIGHT]: GC.FIELD_TOTAL_TRIP_WEIGHT,
    }, G.calcContainersTotalWeightWithoutQty(pickedUpContainers));
  }

  return {
    [GC.FIELD_TOTAL_TRIP_WEIGHT]: 0,
    [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: '',
  };
};

const makeSelectLoadTotals = () => createSelector(
  selectNewDOStore,
  ({ stops }: Object) => {
    const stopsData = getDataFromStops(stops);

    const { sortedStops, pickedItems } = stopsData;

    const totalWeight = getTotalWeight(stopsData);
    const totalQuantity = G.calculateTotalQuantity(pickedItems);

    const totalQuantities = {
      [R.propOr('', GC.FIELD_ITEM_PACKAGE_TYPE, totalQuantity)]: G.mathRoundNumber(
        R.prop(GC.FIELD_ITEM_QUANTITY, totalQuantity),
      ),
    };

    const totalVolume = G.calculateTotalVolumeWithoutQty(pickedItems);
    const totalDistance = G.getLoadTotalDistance({ [GC.FIELD_LOAD_STOPS]: sortedStops }, GC.FIELD_DISTANCE_CLO);

    return {
      totalWeight,
      totalVolume,
      totalDistance,
      [GC.FIELD_TOTAL_QUANTITIES]: totalQuantities,
    };
  },
);

const makeSelectMapLocations = () => createSelector(
  selectNewDOStore,
  ({ stops }: Object) => {
    const { sortedStops } = getDataFromStops(stops);
    const mappedStops = R.map((stop: Object) => {
      const { id, order, formData, eventType } = stop;
      const location = makeLocation(R.pick(locationFieldsToPick, formData));
      const contact = R.pick(GC.GROUPED_FIELDS.EVENT_CONTACT_ARR, location);
      const hasNotLatLng = R.or(
        G.isNilOrEmpty(R.pathOr(null, [GC.FIELD_LATITUDE], location)),
        G.isNilOrEmpty(R.pathOr(null, [GC.FIELD_LONGITUDE], location)),
      );
      if (hasNotLatLng) return null;
      return {
        stop,
        contact,
        location,
        guid: id,
        title: `${G.toTitleCase(eventType)} ${order}`,
        latLng: { lat: R.prop(GC.FIELD_LATITUDE, location), lng: R.prop(GC.FIELD_LONGITUDE, location) },
      };
    }, sortedStops);
    return R.compose(R.values(), R.filter((stop: Object) => G.isNotNil(stop)))(mappedStops);
  },
);

const makeSelectDivision = () => createSelector(
  selectNewDOStore,
  selectBranchStore,
  (doStore: Object, branchStore: Object) => {
    const { divisionGuid } = doStore;
    const { treeBranches } = branchStore;

    if (R.or(G.isNilOrEmpty(divisionGuid), G.isNilOrEmpty(treeBranches))) return null;

    return G.getBranchFromTreeByGuid(divisionGuid, treeBranches);
  },
);

const makeSelectIntegrationCarriers = () => createSelector(
  selectNewDOStore,
  ({ rateBackup }: Object) => ({
    [GC.FIELD_ORIGIN_CARRIER_NAME]: G.getPropFromObject(GC.FIELD_ORIGIN_CARRIER_NAME, rateBackup),
    [GC.FIELD_DESTINATION_CARRIER_NAME]: G.getPropFromObject(GC.FIELD_DESTINATION_CARRIER_NAME, rateBackup),
    [GC.FIELD_TRANSPORTING_CARRIER_NAME]: R.or(
      R.path([GC.FIELD_TRANSPORTING_CARRIER_NAME], rateBackup),
      R.path([GC.FIELD_TRANSPORTING_CARRIER], rateBackup)),
  }),
);

const makeSelectSourceType = () => createSelector(
  selectNewDOStore,
  R.pathOr(GC.CREATE_DO_SOURCE_TYPE_MANUAL, [GC.FIELD_SOURCE_TYPE]),
);

const makeSelectSaveAndDuplicateQty = () => createSelector(
  selectNewDOStore,
  ({ saveAndDuplicateQty }: Object) => saveAndDuplicateQty,
);

const makeSelectHotOrder = () => createSelector(
  selectNewDOStore,
  ({ hotOrder }: Object) => hotOrder,
);

const makeSelectCarrierRate = () => createSelector(
  selectNewDOStore,
  ({ telCarrierRate }: Object) => telCarrierRate,
);

const makeSelectDriverRate = () => createSelector(
  selectNewDOStore,
  ({ telDriverRate }: Object) => telDriverRate,
);

const makeSelectOrderType = () => createSelector(
  selectNewDOStore,
  ({ orderType }: Object) => orderType,
);

const makeSelectRouteTemplateGuid = () => createSelector(
  selectNewDOStore,
  ({ routeTemplateGuid }: Object) => routeTemplateGuid,
);

const makeSelectTransportationModeGrouping = () => createSelector(
  selectNewDOStore,
  ({ transportationModeGroupingList }: Object) => transportationModeGroupingList,
);

const makeSelectStopPointsStrings = () => createSelector(
  selectNewDOStore,
  ({ stopPointsStrings }: Object) => stopPointsStrings,
);

const makeSelectAiRecognize = () => createSelector(
  selectNewDOStore,
  ({ aiRecognize }: Object) => aiRecognize,
);

export {
  makeSelectRate,
  makeSelectStops,
  selectNewDOStore,
  makeSelectBillTo,
  makeSelectEdiFile,
  makeSelectEdiData,
  makeSelectStyling,
  makeSelectHotOrder,
  makeSelectDivision,
  makeSelectLoadType,
  makeSelectPageType,
  makeSelectStopsData,
  makeSelectOrderType,
  makeSelectSourceType,
  makeSelectLoadTotals,
  makeSelectBranchList,
  makeSelectBranchInfo,
  makeSelectBranchGuid,
  makeSelectDriverRate,
  makeSelectAiRecognize,
  makeSelectCarrierRate,
  makeSelectFullDOStore,
  makeSelectDivisionGuid,
  makeSelectTemplateData,
  makeSelectMapLocations,
  makeSelectNumberOfLoads,
  makeSelectLeftActiveTad,
  makeSelectBranchConfigs,
  makeSelectRightActiveTad,
  makeSelectBranchRefTypes,
  makeSelectTelCreationMode,
  makeSelectRouteTelConfigs,
  makeSelectRatePreviewFiles,
  makeSelectValidationErrors,
  makeSelectPrimaryReference,
  makeSelectStopPointsStrings,
  makeSelectRouteTemplateGuid,
  makeSelectReferenceFormData,
  makeSelectCustomerLoadNumber,
  makeSelectPrimaryRefTypeGuid,
  makeSelectPrimaryRefTypeName,
  makeSelectValidationWarnings,
  makeSelectIntegrationCarriers,
  makeSelectCustomerRefTypeName,
  makeSelectSaveAndDuplicateQty,
  makeSelectCustomerReferenceValue,
  makeSelectPrimaryRefSequenceValue,
  makeSelectTransportationModeGrouping,
};
