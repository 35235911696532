import { createAction } from 'redux-act';
//////////////////////////////////////////////////

// Socket
export const socketConnectRequest = createAction('socketConnectRequest');
export const tryToReconnectSocket = createAction('tryToReconnectSocket');
export const socketDisconnectRequest = createAction('socketDisconnectRequest');
export const socketConnectionSuccess = createAction('socketConnectionSuccess');

export const socketPostMessage = createAction('socketPostMessage');

// LOAD BOARD MESSAGES
export const socketLBStateReceived = createAction('socketLBStateReceived');
export const socketLBResultReceived = createAction('socketLBResultReceived');
export const socketFilterStateReceived = createAction('socketFilterStateReceived');
export const socketLBConfigStateReceived = createAction('socketLBConfigStateReceived');
export const socketPostedShipmentStateReceived = createAction('socketPostedShipmentStateReceived');
export const socketBookedShipmentStateReceived = createAction('socketBookedShipmentStateReceived');

// TEL
export const socketTelRateReceived = createAction('socketTelRateReceived');
export const socketTelDocumentReceived = createAction('socketTelDocumentReceived');
export const socketTelLoadStatusReceived = createAction('socketTelLoadStatusReceived');
export const socketTelRateStatusReceived = createAction('socketTelRateStatusReceived');
export const socketTelEventStatusReceived = createAction('socketTelEventStatusReceived');
export const socketCloStatusToTelReceived = createAction('socketCloStatusToTelReceived');
export const socketTelChatMessageReceived = createAction('socketTelChatMessageReceived');
export const socketTelStatusMessageReceived = createAction('socketTelStatusMessageReceived');
export const socketTelCostAllocationsReceived = createAction('socketTelCostAllocationsReceived');

// DRIVER CARDS
export const socketDriverCardsTelReceived = createAction('socketDriverCardsTelReceived');
export const socketDriverCardsTelConnected = createAction('socketDriverCardsTelConnected');

// AVAILABLE DRIVERS
export const socketAvailableDriversNoteReceived = createAction('socketAvailableDriversNoteReceived');
export const socketAvailableDriversStatusReceived = createAction('socketAvailableDriversStatusReceived');
export const socketAvailableDriversCommentsReceived = createAction('socketAvailableDriversCommentsReceived');
export const socketAvailableDriversLocationReceived = createAction('socketAvailableDriversLocationReceived');
export const socketAvailableDriversReservationReceived = createAction('socketAvailableDriversReservationReceived');

// CARRIER PORTAL
export const socketCarrierPortalReceived = createAction('socketCarrierPortalReceived');
export const socketCarrierPortalConnectRequest = createAction('socketCarrierPortalConnectRequest');

// DOCUMENT GENERATION
export const socketUserDocumentGeneratedReceivedSuccess = createAction('socketUserDocumentGeneratedReceivedSuccess');

// STATISTIC
export const saveImportListSuccess = createAction('saveImportListSuccess');

// NOTIFICATION
export const socketTelWarningReceived = createAction('socketTelWarningReceived');
export const updateNotificationsStore = createAction('updateNotificationsStore');
