import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectScopeByName } from '../reference/selectors';
import { makeSelectCurrentBranch, makeSelectCurrentBranchGuid } from '../branch/selectors';
import {
  makeReqStops,
  getDataFromStops,
  makeReqReferences,
  getLoadStopsGeodata,
  mapStopsWithDistances,
  makeReqEquipmentAndServices,
  getDivisionGuidByBranchConfigs,
  prependCurrentBranchToBranchList,
} from '../new-do/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// sagas
import { visitPageSaga } from '../../sagas';
// feature new-do-quote
import * as A from './actions';
import { isPageCreateQuote, isPageUpdateQuote } from './helpers';
import { PAGE_TYPE_CREATE_QUOTE, PAGE_TYPE_UPDATE_QUOTE } from './constants';
import {
  makeSelectDivision,
  makeSelectBranchInfo,
  makeSelectFullDOQuoteStore,
} from './selectors';
//////////////////////////////////////////////////

function* getBranchStylingRequest({ payload }: Object) {
  try {
    const endpoint = endpointsMap.getStylingForBranchEndpoint(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchStylingSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchStylingRequest fail', false);
    }
  } catch (error) {
    yield call(G.handleException, error, 'getBranchStylingRequest exception');
  }
}

function* getBranchListSaga() {
  try {
    const branch = yield select(makeSelectCurrentBranch());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const endpoint = endpointsMap.getBranchAllChildrenWithShared(branchGuid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isAllTrue(
        R.equals(R.length(data), 1),
        R.eqProps(GC.FIELD_GUID, R.head(data), branch),
      )) {
        const guid = G.getGuidFromObject(branch);

        yield put(A.getBranchListSuccess(data));

        yield put(A.cleanStore({
          [GC.FIELD_GUID]: guid,
          [GC.FIELD_BRANCH_NAME]: R.prop(GC.FIELD_BRANCH_NAME, branch),
        }));

        yield put(A.getBranchDataRequest(guid));
      } else {
        const actionData = prependCurrentBranchToBranchList(data, branch);

        yield put(A.getBranchListSuccess(actionData));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getBranchListSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getBranchListSaga exception');
  }
}

function* getBranchDataRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = payload;

    yield put(A.getBranchConfigsRequest(branchGuid));
    yield put(A.getBranchStylingRequest(branchGuid));
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleGetBranchDataSaga exception');
  }
}

function* getBranchListRequest() {
  try {
    const branch = yield select(makeSelectCurrentBranch());

    const branchGuid = G.getGuidFromObject(branch);

    const endpoint = endpointsMap.getBranchAllChildrenWithShared(branchGuid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const actionData = prependCurrentBranchToBranchList(data, branch);

      yield put(A.getBranchListSuccess(actionData));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getBranchListRequest exception');
  }
}

const CONFIGS_ARR = [
  // templates
  GC.TEMPLATES_LOCATION_TYPE,
  // general
  GC.GENERAL_SERVICES,
  GC.GENERAL_EQUIPMENTS,
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
  // clo general
  GC.CLO_GENERAL_PAYMENT_TERMS,
  GC.CLO_GENERAL_TIME_INTERVAL,
  GC.CLO_GENERAL_DROP_INTERVAL,
  GC.CLO_GENERAL_EVENTS_INTERVAL,
  GC.CLO_GENERAL_PICKUP_INTERVAL,
  GC.CLO_GENERAL_DROP_EARLY_TIME,
  GC.CLO_GENERAL_PICKUP_EARLY_TIME,
  GC.CLO_GENERAL_RATE_SERVICE_TYPE,
  GC.CLO_GENERAL_RATE_TRANSPORTATION_MODE,
  // clo default
  GC.CLO_DEFAULT_DROP_LOCATION_TYPE,
  GC.CLO_DEFAULT_PICKUP_LOCATION_TYPE,
  // clo ui
  GC.CLO_UI_ADDITIONAL_ITEM_FIELDS,
  GC.CLO_UI_SINGLE_APPOINTMENT_TIME,
  GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME,
  // clo item
  GC.CLO_ITEM_DEFAULT_TYPE,
  GC.CLO_ITEM_DEFAULT_COUNTRY,
  GC.CLO_ITEM_REQUIRED_FIELDS,
  GC.CLO_ITEM_DEFAULT_WEIGHT_UOM,
  GC.CLO_ITEM_USE_DIFFERENT_TYPES,
  GC.CLO_ITEM_DEFAULT_PACKAGE_TYPE,
  GC.CLO_ITEM_DEFAULT_TEMPERATURE_UOM,
  // clo quote
  GC.CLO_QUOTE_EXPIRATION_DAYS,
  GC.CLO_QUOTE_SHOW_TRIP_PRICING,
  GC.CLO_QUOTE_HIDE_ADDRESS_FIELDS,
  GC.CLO_QUOTE_ITEM_REQUIRED_FIELDS,
  GC.CLO_QUOTE_QUOTE_NUMBER_SEQUENCE,
  GC.CLO_QUOTE_ITEM_ADDITIONAL_ITEM_FIELDS,
];

function* getBranchConfigsRequest({ payload }: Object) {
  try {
    const branchGuid = payload;
    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);
      yield put(A.getBranchConfigsSuccess({ branchGuid, branchConfigs: mappedConfigs }));
      yield put(A.getAllAvBranchRefTypesRequest({
        branchGuid,
        scopeName: GC.REF_SCOPE_NAME_CLO,
      }));
      const branchInfo = yield select(makeSelectBranchInfo());
      const divisionGuid = getDivisionGuidByBranchConfigs(mappedConfigs, branchInfo);

      if (G.isNotNilAndNotEmpty(divisionGuid)) {
        yield put(A.setDivisionGuid(divisionGuid));
      }

      const quoteNumberSequenceGuid = G.getConfigValueFromStore(
        GC.CLO_QUOTE_QUOTE_NUMBER_SEQUENCE, mappedConfigs,
      );

      if (G.isNotNilAndNotEmpty(quoteNumberSequenceGuid)) {
        yield put(A.getQuoteNumberSequenceRequest(quoteNumberSequenceGuid));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getBranchConfigsRequest exception');
  }
}

function* getAllAvBranchRefTypesRequest({ payload }: Object) {
  try {
    const { scopeName, branchGuid } = payload;
    const scope = yield select(makeSelectScopeByName, scopeName);
    const scopeGuid = G.getOrElse(scope, GC.FIELD_GUID, '');
    const options = { params: { scopeGuid, [GC.FIELD_BRANCH_GUID]: branchGuid } };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.availableReferenceTypesForScope,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAllAvBranchRefTypesSuccess({ data, scopeName }));
    } else {
      yield call(G.handleFailResponse, res, 'getAllAvBranchRefTypesRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAllAvBranchRefTypesRequest exception');
  }
}

function* recalculateLoadDistancesRequest({ payload }: Object) {
  try {
    const { distance, reorderedStops } = payload;
    const store = yield select(makeSelectFullDOQuoteStore());
    const { stops, loadType, branchInfo } = store;
    const branchGuid = R.prop(GC.FIELD_VALUE, branchInfo);
    const { sortedStops } = getDataFromStops(R.or(reorderedStops, stops));
    const stopPoints = getLoadStopsGeodata(sortedStops);

    if (G.isNotNilAndNotEmpty(stopPoints)) {
      const options = {
        data: {
          stopPoints,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
        },
      };

      const res = yield call(sendRequest, 'post', endpointsMap.distanceCalculation, options);

      const { data, status } = res;

      if (R.isNil(R.prop(['stopResults'], data))) return yield put(closeLoader());

      if (G.isResponseSuccess(status)) {
        const mappedStops = mapStopsWithDistances(loadType, sortedStops, data);
        yield put(A.recalculateLoadDistancesSuccess({ distance, mappedStops }));
      }
    }
  } catch (error) {
    yield call(G.handleException, error, 'recalculateLoadDistancesRequest exception');
  }
}

function* applyOrderRateUpliftRequest({ payload }: Object) {
  try {
    const store = yield select(makeSelectFullDOQuoteStore());
    const { rate, branchInfo, telDriverRate, telCarrierRate } = store;
    const branchGuid = R.prop(GC.FIELD_VALUE, branchInfo);
    const telRateToUse = R.or(telDriverRate, telCarrierRate);
    const rateType = G.ifElse(
      G.isNotNilAndNotEmpty(telCarrierRate),
      GC.RATE_TYPE_CARRIER_RATE,
      GC.RATE_TYPE_FLEET_RATE,
    );
    const options = {
      data: {
        ...payload,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
        cloRateCurrency: G.getCurrencyFromRate(rate),
        telRateCurrency: G.getCurrencyFromRate(telRateToUse),
        telRateCharges: G.omitEmptyChargesFromCharges(G.getTelRateCharges(telRateToUse, { rateType, concatDriverCharges: true })),
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.cloRateSyncOnCreateEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setCustomerRateChargesToStore(data));
    }
  } catch (error) {
    yield call(G.handleException, error, 'applyOrderRateUpliftRequest exception');
  }
}

function* getOrderQuoteDataRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'get', endpointsMap.orderQuoteGetByGuid(payload));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getBranchConfigsWithOrderQuoteDataRequest(data));
    } else {
      yield call(G.handleFailResponse, res, 'getOrderQuoteDataRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getOrderQuoteDataRequest exception');
  }
}

function* getBranchConfigsWithOrderQuoteDataRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = G.getPropFromObject(GC.BRANCH_GUID, payload);
    yield put(A.getBranchStylingRequest(branchGuid));
    yield put(A.getAllAvBranchRefTypesRequest({
      branchGuid,
      scopeName: GC.REF_SCOPE_NAME_CLO,
    }));
    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);
      yield put(A.getBranchConfigsWithOrderQuoteDataSuccess({
        branchGuid,
        orderQuoteData: payload,
        branchConfigs: mappedConfigs,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigsWithOrderQuoteDataRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getBranchConfigsWithOrderQuoteDataRequest exception');
  }
}

function* getQuoteNumberSequenceRequest({ payload }: Object) {
  try {
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.getSequenceEndpoint(payload),
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getQuoteNumberSequenceSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getQuoteNumberSequenceRequest fail', false);
    }
  } catch (error) {
    yield call(G.handleException, error, 'getQuoteNumberSequenceRequest exception');
  }
}

const makeOrderQuoteNumber = (store: Object, pageType: string) => {
  const { quoteNumber, quoteNumberSequence } = store;

  if (isPageUpdateQuote(pageType)) return quoteNumber;

  const approxValue = G.createSequenceApproxValue(quoteNumberSequence);

  if (R.equals(quoteNumber, approxValue)) return null;

  return quoteNumber;
};

function* sendDataToApiRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const division = yield select(makeSelectDivision());
    const store = yield select(makeSelectFullDOQuoteStore());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const {
      stops,
      pageType,
      rateBackup,
      branchInfo,
      telDriverRate,
      branchConfigs,
      expirationDate,
      branchRefTypes,
      telCarrierRate,
      orderQuoteData,
      referenceFormData,
    } = store;

    const references = makeReqReferences(store);

    const { pickedItems } = getDataFromStops(stops);

    const branchGuid = R.prop(GC.FIELD_VALUE, branchInfo);
    const systemFields = G.pickObjectSystemFields(orderQuoteData);
    const orderQuoteNumber = makeOrderQuoteNumber(store, pageType);
    const method = G.ifElse(isPageCreateQuote(pageType), 'post', 'put');
    const divisionGuid = R.pathOr(currentBranchGuid, [GC.FIELD_GUID], division);
    const specialInstructions = R.prop(GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS, referenceFormData);

    const { services, equipment } = makeReqEquipmentAndServices(branchConfigs, rateBackup);

    const rate = G.omitEmptyChargesFromData(R.or(rateBackup, null), GC.FIELD_CHARGES);

    let orderQuote = {
      ...R.or(systemFields, {}),
      rate,
      services,
      equipment,
      telCarrierRate,
      orderQuoteNumber,
      specialInstructions,
      telFleetRate: telDriverRate,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
      [GC.FIELD_LOAD_REFERENCES]: references,
      [GC.FIELD_LOAD_DIVISION_GUID]: divisionGuid,
      [GC.FIELD_LOAD_STOPS]: makeReqStops(stops, branchRefTypes),
      [GC.FIELD_LOAD_ITEMS]: G.mapArrayObjectEmptyStringFieldsToNull(pickedItems),
      expirationDate: G.createLocalDateTimeFromInstanceOrISOString(expirationDate, GC.DEFAULT_DATE_FORMAT),
    };

    if (G.isTrue(R.path(['rateRequested'], payload))) {
      orderQuote = R.assoc('rateRequested', true, R.dissoc('rate', orderQuote));
    }

    let reqData = {
      ...R.or(payload, {}),
      orderQuote,
    };

    if (R.equals(method, 'put')) reqData = orderQuote;

    const res = yield call(sendRequest, method, endpointsMap.orderQuote, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.goToRoute, GC.ROUTE_PATH_ORDER_QUOTES);
    } else {
      if (G.isPathNotNilAndNotEmpty(['errors'], data)) {
        yield put(A.setValidationErrors(data.errors));
      }

      yield call(G.handleFailResponse, res, 'sendDataToApiRequest fail', true);
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'sendDataToApiRequest exception');
  }
}

export function* visitEditPageSaga(payload: Object) {
  try {
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_NEW_DO_QUOTE_PAGE);
    yield put(A.cleanStore({ pageType: PAGE_TYPE_UPDATE_QUOTE }));
    yield put(A.getBranchListRequest());
    yield put(A.getOrderQuoteDataRequest(G.getGuidFromObject(payload)));
  } catch (error) {
    yield call(G.handleException, error, 'newDOQuoteWatcherSaga -> visitEditPageSaga exception');
  }
}

function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const editPage = R.path(['editPage'], payload);

    if (G.isTrue(editPage)) return yield call(visitEditPageSaga, payload);

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_NEW_DO_QUOTE_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield put(A.cleanStore({ pageType: PAGE_TYPE_CREATE_QUOTE }));
    yield call(getBranchListSaga);
    yield put(closeLoader());
    break;
  }
}

function* newDOQuoteWatcherSaga() {
  yield takeLatest(GC.VISIT_NEW_DO_QUOTE_PAGE, handleVisitPageSaga);
  yield takeLatest(A.getBranchListRequest, getBranchListRequest);
  yield takeLatest(A.getBranchDataRequest, getBranchDataRequest);
  yield takeLatest(A.sendDataToApiRequest, sendDataToApiRequest);
  yield takeLatest(A.getBranchStylingRequest, getBranchStylingRequest);
  yield takeLatest(A.getBranchConfigsRequest, getBranchConfigsRequest);
  yield takeLatest(A.applyOrderRateUpliftRequest, applyOrderRateUpliftRequest);
  yield takeLatest(A.getQuoteNumberSequenceRequest, getQuoteNumberSequenceRequest);
  yield takeLatest(A.getAllAvBranchRefTypesRequest, getAllAvBranchRefTypesRequest);
  yield takeLatest(A.recalculateLoadDistancesRequest, recalculateLoadDistancesRequest);
  // edit quote
  yield takeLatest(A.getOrderQuoteDataRequest, getOrderQuoteDataRequest);
  yield takeLatest(A.getBranchConfigsWithOrderQuoteDataRequest, getBranchConfigsWithOrderQuoteDataRequest);
}

export default newDOQuoteWatcherSaga;
