import React from 'react';
import * as R from 'ramda';
import { useField } from 'formik';
import MaskedInput from 'react-text-mask';
import styled, { css } from 'styled-components';
// components
import { Switcher } from '../../components/switcher';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Toggle } from '../../forms/restyled';
import { Label, ToggleWrapper } from '../../forms/ui';
import { Input, Error, Textarea, InputSelect, SelectWrapper } from '../../forms/formik/fieldset2/ui';
// ui
import { Box, Flex, StyledLink, ReactSelect, RelativeFlex, MainActionButton } from '../../ui';
//////////////////////////////////////////////////

const hoverStyles = css`
  transition: border-color 0.4s;

  &:hover {
    border-color: ${G.getTheme('colors.light.darksGrey')};
  }
`;

const ConfigGroupLeftSectionWrapper = ({ children }: Object) => (
  <Flex
    px={15}
    width={320}
    flexGrow={0}
    fontSize={15}
    bg='lightGrey'
    flexShrink={0}
    fontWeight={600}
    color='light.black'
  >
    {children}
  </Flex>
);

const ConfigGroupRightSectionWrapper = ({ children, ...props }: Object) => (
  <Flex {...props} bg='white' flexWrap='wrap'>
    {children}
  </Flex>
);

const ConfigGroupRightSectionFieldGroupWrapper = ({ children }: Object) => (
  <Flex
    py={10}
    bg='white'
    flexWrap='wrap'
    css={css`
      @media (max-width: 1600px) {
        & > div {
          margin-bottom: 5px;
        }
      }
    `}
  >
    {children}
  </Flex>
);

const ConfigGroupFieldWrapper = ({ children, ...props }: Object) => (
  <RelativeFlex
    {...props}
    mr={15}
    bg='white'
    flexWrap='wrap'
  >
    {children}
  </RelativeFlex>
);

const Button = ({ children, ...props }: Object) => (
  <MainActionButton
    {...props}
    p='8px 5px'
    m='0 5px 10px 0'
    borderColor='light.middleGrey'
  >
    {children}
  </MainActionButton>
);

const PreviewWrapper = styled(Box)`
  & code {
    font-family: monospace;

    & * {
      font-family: inherit;
    }
  }
`;

const renderInputField = ({
  field,
  width,
  disabled,
  placeholder,
}: Object) => {
  const { error, touched } = field;

  const hasError = R.and(error, touched);

  return (
    <ConfigGroupFieldWrapper>
      <Input
        {...field}
        type='text'
        css={hoverStyles}
        hasError={hasError}
        width={width || 232}
        disabled={disabled || false}
        placeholder={placeholder || ''}
      />
      {hasError && <Error top='37px'>{field.error}</Error>}
    </ConfigGroupFieldWrapper>
  );
};

const renderTextareaField = ({
  field,
  width,
  height,
  disabled,
  placeholder,
}: Object) => (
  <ConfigGroupFieldWrapper>
    <Textarea
      {...field}
      type='text'
      width={width || '500'}
      height={height || '300'}
      disabled={disabled || false}
      placeholder={placeholder || ''}
      css={css`
        resize: auto;

        ${hoverStyles}
      `}
    />
  </ConfigGroupFieldWrapper>
);

const renderSelectGroup = ({
  field,
  width,
  options,
  disabled,
}: Object) => {
  const { error, touched } = field;

  const hasError = R.and(error, touched);

  return (
    <ConfigGroupFieldWrapper>
      <SelectWrapper>
        <InputSelect
          {...field}
          css={hoverStyles}
          borderRadius='4px'
          hasError={hasError}
          width={width || 232}
          disabled={disabled || false}
        >
          <option />
          {
            options.map((option: string, index: number) => (
              <option
                key={index}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            ))
          }
        </InputSelect>
      </SelectWrapper>
      {R.and(hasError) && <Error top='37px'>{field.error}</Error>}
    </ConfigGroupFieldWrapper>
  );
};

const renderToggleField = ({
  field,
  margin,
  checked,
  disabled,
  // name,
  // input,
}: Object) => (
  <ConfigGroupFieldWrapper>
    <ToggleWrapper m={margin}>
      <Toggle
        {...field}
        icons={false}
        value={Boolean(field.value)}
        disabled={disabled || false}
        checked={Boolean(G.ifElse(G.notEquals(checked, undefined), checked, field.value))}
      />
    </ToggleWrapper>
  </ConfigGroupFieldWrapper>
);

const getSwitcherOptions = () => [
  {
    width: 40,
    value: true,
    name: G.getWindowLocale('titles:yes', 'Yes'),
  },
  {
    width: 40,
    value: false,
    name: G.getWindowLocale('titles:no', 'No'),
  },
  {
    width: 60,
    value: 'inherited',
    name: G.getWindowLocale('titles:inherited', 'Inherited'),
  },
];

const getSwitcherIndex = (value: any) => {
  if (G.isTrue(value)) return 0;

  if (G.isFalse(value)) return 1;

  return 2;
};

const renderSwitcher = ({ field, margin }: Object) => (
  <ConfigGroupFieldWrapper>
    <ToggleWrapper m={margin}>
      <Switcher
        version={3}
        itemZIndex='1 !important'
        options={getSwitcherOptions()}
        switchElementZIndex='unset !important'
        selectedOptionIndex={getSwitcherIndex(field.value)}
        onSwitch={(newValue: Object) => field.setValue(newValue)}
      />
    </ToggleWrapper>
  </ConfigGroupFieldWrapper>
);

const MultiselectFieldComponent = (props: Object) => {
  const {
    field,
    label,
    width,
    options,
    fieldClass,
    labelMargin,
    labelPosition,
    labelFontSize,
    menuZIndex = 40,
    closeMenuOnSelect,
  } = props;

  const { name, value, error, touched, setValue } = field;

  const handleChange = (newValue: Array) => {
    const value = newValue.map((item: Object) => item.value);

    setValue(value);
  };

  return (
    <Box
      width={width || 232}
      m='5px 15px 0px 0px'
    >
      {
        R.equals(labelPosition, 'left') &&
        <Label css={fieldClass} fontSize={labelFontSize} m={labelMargin}>{label}</Label>
      }
      <ReactSelect
        name={name}
        height={36}
        value={value}
        isMulti={true}
        options={options}
        onChange={handleChange}
        closeMenuOnSelect={closeMenuOnSelect}
        additionalStyles={{
          menu: (baseStyles: Object) => ({
            ...baseStyles,
            zIndex: menuZIndex,
          }),
        }}
      />
      {
        R.and(touched, error) && <Error>{error}</Error>
      }
    </Box>
  );
};

const EmailsComponent = ({ p, emails }: Object) => (
  <Flex flexWrap='wrap'>
    {
      emails.map((email: string, index: number) => (
        <StyledLink
          key={index}
          target='_blank'
          p={R.or(p, '5px')}
          wordBreak='break-all'
          href={`mailto:${email}`}
          color={G.getTheme('colors.light.blue')}
        >
          {email}{R.and(R.lt(R.inc(index), R.length(emails)), ',')}
        </StyledLink>
      ))
    }
  </Flex>
);

const ReactSelectComponent = ({ value, options, placeholder, handleChange, useMenuPortalTarget }: Object) => (
  <Box p='5px 10px' width={250}>
    <ReactSelect
      height={32}
      value={value}
      isClearable={true}
      placeholder={placeholder}
      options={R.or(options, [])}
      useMenuPortalTarget={useMenuPortalTarget}
      onChange={(option: Object) => handleChange(R.pathOr('', ['value'], option))}
    />
  </Box>
);

const renderMaskedTimeInput = ({ field, input, width }: Object) => {
  const value = G.setCorrectTimeInitialValue(G.getValueFromObject(field));

  const { mask, pipe, placeholder } = R.path([GC.DATE_TIME_FORMAT_US, 'time'], GC.dateTimeFormatMap);

  return (
    <MaskedInput
      {...field}
      pipe={pipe}
      mask={mask}
      value={value}
      render={(ref: Object, props: Object) => (
        <Input {...props} ref={ref} width={R.or(width, 232)} placeholder={placeholder} />
      )}
    />
  );
};

const renderFormFieldComponent = (type: string) => {
  switch (type) {
    case ('input'):
      return renderInputField;
    case ('textarea'):
      return renderTextareaField;
    case ('toggle'):
      return renderToggleField;
    case ('switcher'):
      return renderSwitcher;
    case ('select'):
      return renderSelectGroup;
    case ('time'):
      return renderMaskedTimeInput;
    default:
      return renderInputField;
  }
};

const FieldComponent = (props: Object) => {
  const { type, component } = props;

  const [input, meta, helpers] = useField(props);

  const field = { ...meta, ...input, ...helpers };

  const map = {
    list: component,
    input: renderInputField,
    switcher: renderSwitcher,
    toggle: renderToggleField,
    select: renderSelectGroup,
    customComponent: component,
    time: renderMaskedTimeInput,
    textarea: renderTextareaField,
  };

  return R.propOr(renderInputField, type, map)({ ...props, field });
};

export {
  Button,
  PreviewWrapper,
  FieldComponent,
  EmailsComponent,
  ReactSelectComponent,
  ConfigGroupFieldWrapper,
  renderFormFieldComponent,
  MultiselectFieldComponent,
  ConfigGroupLeftSectionWrapper,
  ConfigGroupRightSectionWrapper,
  ConfigGroupRightSectionFieldGroupWrapper,
};
