import React from 'react';
import * as R from 'ramda';
import { css } from 'styled-components';
// components
import { Map } from '../../../../components/map';
import { TextComponent } from '../../../../components/text';
import { Directions } from '../../../../components/map/components/directions';
import { StopInfo, StopMarker } from '../../../../components/map/components/stop-components';
import { MarkerWithInfo, FleetMarkerWithInfo } from '../../../../components/map/components/marker-with-info';
// features
import { withRerenderMap } from '../../../fleet-map/hocs';
import { renderFleetMapIcon, getLatLngFromFleets } from '../../../fleet-map/helpers';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withBooleanStatus } from '../../../../hocs';
// icons
import { onMap, renderDownArrowIcon } from '../../../../svgs';
// ui
import {
  Box,
  Text,
  Flex,
  RelativeBox,
  AbsoluteWrapper,
} from '../../../../ui';
// feature rate
import { animation } from './ui';
import {
  getFleetIcon,
  joinTrailers,
  filterTrucks,
  filterTrailers,
  makeAssignmentInfo,
  takeArrayByOddOrEven,
} from './helpers';
//////////////////////////////////////////////////

const renderCurrentLocation = (fleet: Object, condition: boolean) => {
  if (condition) {
    return (
      <Box mt='5px'>
        <Flex alignItems='center'>
          <Box mr='5px' width='16px'>{onMap(G.getTheme('icons.iconColorGrey'))}</Box>
          <div>{G.concatLocationFields(fleet.currentLocation)}</div>
        </Flex>
        <div>{R.path(['currentLocation', 'lastModifiedDate'], fleet)}</div>
      </Box>
    );
  }
};

const uoms = {
  [GC.UOM_MILE]: GC.UOM_MILE_LABEL,
  [GC.UOM_KILOMETER]: GC.UOM_KILOMETER_LABEL,
};

const renderEmptyDistance = (fleet: Object, condition: boolean) => {
  if (condition) {
    return (
      <Text mt='5px' fontSize='10px'>
        {`${
          G.getWindowLocale('titles:empty', 'Empty')
        }: ${
          fleet.displayDistanceToStart
        }${
          uoms[fleet.displayDistanceToStartUOM]
        }`}
      </Text>
    );
  }
};

const getTripCost = (fleet: Object, condition: boolean) => {
  if (condition) {
    return (
      <Text mt='5px' fontSize='10px'>
        {G.getWindowLocale('titles:driver-trip-cost', 'Driver Trip Cost')}: ${fleet.total}
      </Text>
    );
  }
};

const CommonText = (props: Object) => (
  <TextComponent
    {...props}
    fontSize='10px'
    display='block'
    withEllipsis={true}
  >
    {props.title}
  </TextComponent>
);

const InfoLengthContainer = ({ length }: Object) => (
  <AbsoluteWrapper top='0px' left='30px'>
    <Flex
      width={20}
      height={20}
      borderRadius='50%'
      justifyContent='center'
      color={G.getTheme('colors.white')}
      bg={G.getTheme('colors.dark.blue')}
      border={`2px solid ${G.getTheme('colors.boxShadowLightGrey')}`}
    >
      {length}
    </Flex>
  </AbsoluteWrapper>
);

const AssignmentSection = ({ assignment }: Object) => (
  <div>
    <Flex mt='5px'>
      {
        G.isNotNil(assignment.truck)
        && (
          <CommonText
            width='75px'
            title={`${
              G.getWindowLocale('titles:truck', 'Truck')
            }: ${
              R.path(['truck', GC.FIELD_TRUCK_UNIT_ID], assignment)
            }`}
          />
        )
      }
      {
        G.isNotNil(assignment.trailers)
        && (
          <CommonText
            width='150px'
            title={`${
              G.getWindowLocale('titles:trailers', 'Trailers')
            }: ${
              joinTrailers(assignment.trailers)
            }`}
          />
        )
      }
    </Flex>
    {
      G.isNotNil(assignment.teamDriver)
      && (
        <CommonText
          mt='5px'
          width='100%'
          title={`${
            G.getWindowLocale('titles:2-nd-driver', '2-nd Driver')
          }: ${
            R.path(['teamDriver', GC.FIELD_FIRST_NAME], assignment)
          } ${
            R.path(['teamDriver', GC.FIELD_LAST_NAME], assignment)
          }`}
        />
      )
    }
  </div>
);

const ContentHeader = ({ fleet, fleetType }: Object) => (
  <Flex alignItems='flex-start' justifyContent='space-between' >
    <Flex alignItems='center'>
      {getFleetIcon(fleetType)}
      <CommonText
        pl='5px'
        width='75px'
        fontWeight='bold'
        title={`${fleet.firstName} ${fleet.lastName}`}
      />
    </Flex>
    {
      R.and(G.isNotNil(fleet.teamTotal), G.isNotNilAndNotEmpty(R.path(['assignment', 'secondaryDriverGuid'], fleet)))
      && (
        <Box
          p='2px'
          fontSize='10px'
          borderRadius='3px'
          bg={G.getTheme('colors.light.middleGrey')}
        >
          {G.getWindowLocale('titles:team-trip-cost', 'Team Trip Cost')}: ${fleet.teamTotal}
        </Box>
      )
    }
  </Flex>
);

const DriverContentItemInfo = withBooleanStatus(false)((props: Object) => {
  const { lnl, fleet, fleetType, initialFleets, booleanStatus } = props;

  const assignmentInfo = makeAssignmentInfo(initialFleets, fleet);
  const lnlNotCurrentLocation = R.and(G.isNotNil(fleet.currentLocation), G.notEquals(fleet.currentLocation, lnl));
  const cursorPointer = R.or(G.isNotNilAndNotEmpty(assignmentInfo), lnlNotCurrentLocation);

  const toggleBooleanStatus = () => {
    if (cursorPointer) {
      props.handleToggleBooleanStatus();
    }
  };

  const selected = R.equals(props.selectedDriver, fleet.guid);

  return (
    <Box
      mb='5px'
      p='5px 5px 0'
      width='250px'
      borderRadius='3px'
      height='max-content'
      onClick={toggleBooleanStatus}
      border={R.or(selected, '1px solid')}
      cursor={R.and(cursorPointer, 'pointer')}
      boxShadow={R.and(selected, '0 0 5px 2px #1f73a1')}
      borderColor={G.getTheme('map.infoBoxBorderColor')}
    >
      <ContentHeader fleet={fleet} fleetType={fleetType} />
      {getTripCost(fleet, G.isNotNil(fleet.total))}
      {renderEmptyDistance(fleet, G.isNotNil(fleet.displayDistanceToStart))}
      {
        booleanStatus
        && <AssignmentSection assignment={assignmentInfo} />
      }
      {renderCurrentLocation(fleet, R.and(lnlNotCurrentLocation, booleanStatus))}
      <Flex mt='5px' display='flex' alignItems='center'>
        {
          R.and(lnlNotCurrentLocation, booleanStatus)
          && <Box mr='5px' width='16px'>{renderDownArrowIcon()}</Box>
        }
        <div>{G.concatLocationFields(lnl)}</div>
      </Flex>
      <Box mb='5px'>{R.or(lnl.lastModifiedDate, lnl.date)}</Box>
    </Box>
  );
});

const ContentItemInfo = (props: Object) => {
  const { lnl, fleet, fleetType, initialFleets, selectedDriver } = props;

  if (R.equals(fleetType, 'drivers')) {
    return (
      <DriverContentItemInfo
        lnl={lnl}
        fleet={fleet}
        fleetType={fleetType}
        initialFleets={initialFleets}
        selectedDriver={selectedDriver}
      />
    );
  }

  return (
    <Box
      p='5px'
      mb='5px'
      width='250px'
      borderRadius='3px'
      border='1px solid'
      borderColor={G.getTheme('map.infoBoxBorderColor')}
    >
      <Flex mb='5px' alignItems='center'>
        {getFleetIcon(fleetType)}
        <Text pl='5px' fontWeight='bold'>{fleet.unitId}</Text>
      </Flex>
      <div>{G.concatLocationFields(lnl)}</div>
      <div>{lnl.lastModifiedDate}</div>
    </Box>
  );
};

const FleetsContent = (props: Object) => {
  const {
    lnl,
    fleets,
    fleetType,
    initialFleets,
    selectedDriver,
  } = props;

  return (
    <Box
      p='5px'
      width='auto'
      display='flex'
      overflow='auto'
      maxWidth='520px'
      maxHeight='400px'
    >
      <Flex pr='5px' flexDirection='column'>
        {
          takeArrayByOddOrEven(props.fleets, true).map((fleet: Object, index: number) => (
            <ContentItemInfo
              lnl={lnl}
              key={index}
              fleet={fleet}
              fleets={fleets}
              fleetType={fleetType}
              initialFleets={initialFleets}
              selectedDriver={selectedDriver}
            />
          ))
        }
      </Flex>
      <Flex flexDirection='column'>
        {
          takeArrayByOddOrEven(fleets, false).map((fleet: Object, index: number) => (
            <ContentItemInfo
              lnl={lnl}
              key={index}
              fleet={fleet}
              fleets={fleets}
              fleetType={fleetType}
              initialFleets={initialFleets}
              selectedDriver={selectedDriver}
            />
          ))
        }
      </Flex>
    </Box>
  );
};

const MultiContent = (props: Object) => {
  const {
    fleets,
    initialFleets,
    selectedDriver,
  } = props;

  return (
    <div>
      {
        fleets.map(
          (item: Object, index: number) => {
            const fleet = R.prop('fleetObj', item);
            const lnl = R.prop('lnl', item);
            return (
              <ContentItemInfo
                lnl={lnl}
                key={index}
                fleet={fleet}
                fleets={fleets}
                fleetType={item.fleetType}
                initialFleets={initialFleets}
                selectedDriver={selectedDriver}
              />
            );
          })
      }
    </div>
  );
};

const MultiFleetContent = (props: Object) => {
  const { drivers, trucks, trailers, initialFleets, selectedDriver } = props;

  const allFleets = drivers.map((item: Object) => R.assoc('fleetType', 'drivers', item)).concat(
    trucks.map((item: Object) => R.assoc('fleetType', 'trucks', item)),
    trailers.map((item: Object) => R.assoc('fleetType', 'trailers', item)),
  );

  return (
    <Box
      p='5px'
      width='auto'
      display='flex'
      overflow='auto'
      maxWidth='520px'
      maxHeight='400px'
    >
      <Flex pr='5px' flexDirection='column'>
        <MultiContent
          initialFleets={initialFleets}
          selectedDriver={selectedDriver}
          fleets={takeArrayByOddOrEven(allFleets, true)}
        />
      </Flex>
      <Flex flexDirection='column'>
        <MultiContent
          initialFleets={initialFleets}
          selectedDriver={selectedDriver}
          fleets={takeArrayByOddOrEven(allFleets, false)}
        />
      </Flex>
    </Box>
  );
};

const mapFleetsToContent = (
  entities: Array,
  fleetType: string,
  initialFleets: Object,
  selectedDriver: string,
) => entities.map((entity: Array) => {
  const item = R.reduce((acc: Object, item: Object) => {
    const itemWithFleets = R.assoc('fleets', R.append(item.fleetObj, acc.fleets), item);

    return R.omit('fleetObj', itemWithFleets);
  }, { shortid: G.genShortId(), fleets: [] }, entity);

  const length = R.length(item.fleets);
  const selected = R.any(R.pathEq(selectedDriver, ['fleetObj', GC.FIELD_GUID]), entity);

  return {
    guid: item.shortid,
    latLng: item.latLng,
    infoContent: (
      <FleetsContent
        lnl={item.lnl}
        fleets={item.fleets}
        fleetType={fleetType}
        initialFleets={initialFleets}
        selectedDriver={selectedDriver}
      />
    ),
    markerContent: (
      <RelativeBox
        p='5px'
        borderRadius='50%'
        css={selected ? css` animation: ${animation} 2s infinite;` : null}
      >
        {renderFleetMapIcon(fleetType)}
        <InfoLengthContainer length={length} />
      </RelativeBox>
    ),
  };
});

const mapMultiFleetsToContent = (
  entities: Array,
  initialFleets: Object,
  selectedDriver: string,
) => entities.map((entity: Object) => {
  const { drivers, trucks, trailers } = entity;

  const notUsedTrailers = filterTrailers(trailers, drivers);
  const notUsedTrucks = filterTrucks(trucks, drivers);
  const driversLength = R.length(drivers);
  const trucksLength = R.length(notUsedTrucks);
  const trailersLength = R.length(notUsedTrailers);

  let latLng = null;

  if (R.gt(driversLength, 0)) {
    latLng = getLatLngFromFleets(drivers);
  } else if (R.gt(trucksLength, 0)) {
    latLng = getLatLngFromFleets(notUsedTrucks);
  } else if (R.gt(trailersLength, 0)) {
    latLng = getLatLngFromFleets(notUsedTrailers);
  }

  const length = R.sum([driversLength, trucksLength, trailersLength]);
  const selected = R.any(R.pathEq(selectedDriver, ['fleetObj', 'guid']), drivers);

  return {
    guid: G.genShortId(),
    latLng,
    infoContent: (
      <MultiFleetContent
        drivers={drivers}
        trucks={notUsedTrucks}
        trailers={notUsedTrailers}
        initialFleets={initialFleets}
        selectedDriver={selectedDriver}
      />
    ),
    markerContent: (
      <RelativeBox
        p='5px'
        borderRadius='50%'
        css={selected ? css` animation: ${animation} 2s infinite;` : null}
      >
        {renderFleetMapIcon('all')}
        <InfoLengthContainer length={length} />
      </RelativeBox>
    ),
  };
});

const groupFleets = (name: string, fleets: Array, groupedObj: Object = {}) => {
  const grouped = R.clone(groupedObj);

  fleets.forEach((item: Object) => {
    const emptyItem = {
      drivers: [],
      trucks: [],
      trailers: [],
    };

    const lat = R.path(['latLng', 'lat'], item);
    const lng = R.path(['latLng', 'lng'], item);
    const latLngStr = `${lat}${lng}`;

    if (R.has(latLngStr, grouped)) {
      grouped[latLngStr][name].push(item);
    } else {
      grouped[latLngStr] = R.clone(emptyItem);
      grouped[latLngStr][name].push(item);
    }
  });

  return grouped;
};

const getLocations = (props: Object) => {
  const {
    fleets,
    trucks,
    drivers,
    trailers,
    fleetType,
    initialFleets,
    selectedDriver,
  } = props;

  if (R.and(R.equals(fleetType, 'all'), R.isNil(fleets))) {
    const grouped = groupFleets(
      'trailers',
      R.flatten(trailers),
      groupFleets(
        'trucks',
        R.flatten(trucks),
        groupFleets(
          'drivers',
          R.flatten(drivers),
        ),
      ),
    );

    return mapMultiFleetsToContent(
      R.values(grouped),
      initialFleets,
      selectedDriver,
    );
  }

  return mapFleetsToContent(
    fleets,
    fleetType,
    initialFleets,
    selectedDriver,
  );
};

const getLocationsWithContent = (events: Array) => (
  events.map((event: Object) => ({
    ...event,
    infoContent: <StopInfo {...event} />,
    markerContent: <StopMarker {...event} />,
  }))
);

const renderMapComponent = (props: Object) => {
  const { mappedEventsForMap } = props;

  return (
    <Map height='100%' defaultZoom={3} trafficLayer={true}>
      <FleetMarkerWithInfo
        p='0'
        maxWidth='max-content'
        infoBorderColor='transparent'
        locations={getLocations(props)}
        closeButtonColor={G.getTheme('map.infoBoxBorderColor')}
      />
      <MarkerWithInfo locations={G.makeLocationsWithTransform(getLocationsWithContent(mappedEventsForMap))} />
      {
        mappedEventsForMap &&
        <Directions locations={mappedEventsForMap.map((location: Object) => location.latLng)} />
      }
    </Map>
  );
};

const FleetMapComponent = withRerenderMap((props: Object) => (
  <Flex height='100%' width='100%'>
    {props.isActiveMap && renderMapComponent(props)}
  </Flex>
));

export default FleetMapComponent;
