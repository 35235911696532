import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// feature fleet-map
import * as Actions from './actions';
//////////////////////////////////////////////////

const initialState = {
  fleets: {
    trucks: [],
    drivers: [],
    trailers: [],
    fleetsLoaded: {
      trucks: false,
      drivers: false,
      trailers: false,
    },
    geoFencingLocation: null,
  },
  configs: null,
  idleTimeFilter: [],
  enterpriseFilter: [],
  trailerUnitIdFilter: null,
  trailerLoadedFilter: false,
};

const getBranchConfigsByNamesSuccess = (state: Object, configs: Object) => P.$set(
  'configs', configs, state,
);

const setFleetsToStore = (state: Object, { fleetType, data }: Object) => (
  P.$all(
    P.$set(`fleets.${fleetType}`, data),
    P.$set(`fleets.fleetsLoaded.${fleetType}`, true),
    state,
  )
);

const setGeoFencingLocationToStore = (state: Object, { results }: Object) =>
  P.$set('geoFencingLocation', results, state);

const setFleetsLoadedToStore = (state: Object, fleetsLoaded: Object) =>
  P.$set('fleets.fleetsLoaded', fleetsLoaded, state);

const setEnterpriseFilter = (state: Object, enterpriseFilter: any) =>
  P.$set('enterpriseFilter', enterpriseFilter, state);

const setIdleTimeFilter = (state: Object, idleTimeFilter: any) =>
  P.$set('idleTimeFilter', idleTimeFilter, state);

const setTrailerLoadedFilter = (state: Object, trailerLoadedFilter: any) =>
  P.$set('trailerLoadedFilter', trailerLoadedFilter, state);

const setTrailerUnitIdFilter = (state: Object, trailerUnitIdFilter: any) =>
  P.$set('trailerUnitIdFilter', trailerUnitIdFilter, state);

const setGeoFencingLocationTypeFilter = (state: Object, typeFilter: any) =>
  P.$set('geoFencingTypeFilter', typeFilter, state);

const cleanFleetMapStore = () => (
  initialState
);

export default createReducer({
  [Actions.setFleetsToStore]: setFleetsToStore,
  [Actions.setIdleTimeFilter]: setIdleTimeFilter,
  [Actions.cleanFleetMapStore]: cleanFleetMapStore,
  [Actions.setEnterpriseFilter]: setEnterpriseFilter,
  [Actions.setFleetsLoadedToStore]: setFleetsLoadedToStore,
  [Actions.setTrailerLoadedFilter]: setTrailerLoadedFilter,
  [Actions.setTrailerUnitIdFilter]: setTrailerUnitIdFilter,
  [Actions.setGeoFencingLocationToStore]: setGeoFencingLocationToStore,
  [Actions.getBranchConfigsByNamesSuccess]: getBranchConfigsByNamesSuccess,
  [Actions.setGeoFencingLocationTypeFilter]: setGeoFencingLocationTypeFilter,
}, initialState);
