import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectStore = ({ expandedContainer }: Object) => expandedContainer;

const makeSelectExpandedContainerOptions = () => createSelector(
  selectStore,
  (options: Object) => ({ ...options }),
);

const makeSelectExpandedContainerOpened = () => createSelector(
  selectStore,
  ({ opened }: Object) => opened,
);

export {
  makeSelectExpandedContainerOpened,
  makeSelectExpandedContainerOptions,
};
