import * as R from 'ramda';
import React, { useCallback } from 'react';
// components
import FileViewer from '../file-viewer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

const DocumentView = ({
  fileType,
  filePath,
}: Object) => {
  const handleError = useCallback((e: Object) => console.log(e, 'FILE PREVIEW ERROR'), []);

  return (
    <Box height='100%'>
      { R.equals(fileType, GC.EXTENSION_PDF) && (
        <iframe type={fileType} src={filePath} width='100%' height='100%' />
      )}
      { G.notEquals(fileType, GC.EXTENSION_PDF) && (
        <FileViewer
          width='100%'
          fileType={fileType}
          filePath={filePath}
          onError={handleError}
        />
      )}
    </Box>
  );
};

export default DocumentView;
