import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Checkbox } from '../../../forms';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

export const MarkComponent = (props: Object) => (
  <Flex
    p='18px 15px'
    borderBottom='1px solid'
    justifyContent='space-between'
    borderColor={G.getTheme('colors.light.middleGrey')}
  >
    <Flex color={G.getTheme('colors.light.blue')}>
      <Box
        mr={15}
        cursor='pointer'
        onClick={() => props.actionRead(true)}
      >
        {G.getWindowLocale('actions:mark-all-as-read', 'Mark all as read')}
      </Box>
      <Box
        mr={15}
        cursor='pointer'
        onClick={() => props.actionRead(false)}
      >
        {G.getWindowLocale('actions:mark-as-unread', 'Mark as unread')}
      </Box>
      <Box
        mr={15}
        cursor='pointer'
        onClick={props.handleDeleteSelectedNotifications}
      >
        {G.getWindowLocale('actions:delete-selected', 'Delete Selected')}
      </Box>
      <Box
        cursor='pointer'
        onClick={props.handleDeleteAllNotifications}
      >
        {G.getWindowLocale('actions:delete-all', 'Delete All')}
      </Box>
    </Flex>
    <Checkbox
      mr='12px'
      type='checkbox'
      checked={Boolean(props.check)}
      onClick={() => props.selectAll('all')} />
  </Flex>
);

export default MarkComponent;
