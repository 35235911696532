import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// hocs
import { withConnectModalActions } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
// component edit-view
import EditViewForm from './edit-view-form';
//////////////////////////////////////////////////

const enhance = compose(
  withConnectModalActions,
  withHandlers({
    handleOpenEditView: (props: Object) => () => {
      const { openModal } = props;

      const component = (
        <EditViewForm
          {...R.pick([
            'reportName',
            'closeModal',
            'uiReportFields',
            'columnSettings',
            'setUiReportFields',
            'defaultReportFields',
            'disableFieldHandler',
          ], props)}
        />
      );

      const modal = {
        p: '0px',
        component,
        options: {
          width: 500,
          top: '0px',
          right: '0px',
          movable: false,
          height: '100vh',
          componentWrapperHeight: '100%',
          title: G.getWindowLocale('titles:fields', 'Fields'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const darkBlueColor = G.getTheme('colors.darkBlue');

export const EditView = enhance((props: Object) => {
  const { title, boxStyles, titleStyles, handleOpenEditView } = props;

  return (
    <Flex
      ml={20}
      gap='5px'
      cursor='pointer'
      onClick={handleOpenEditView}
      {...G.spreadUiStyles(boxStyles)}
    >
      <Flex>{I.gear(darkBlueColor)}</Flex>
      <Box fontSize={12} color={darkBlueColor} {...G.spreadUiStyles(titleStyles)}>
        {R.or(title, G.getWindowLocale('titles:edit-view', 'Edit View'))}
      </Box>
    </Flex>
  );
});
