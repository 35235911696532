import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { SearchMatch } from '../../../components/search-match';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import { makeSelectAuthorities } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs/with-fixed-popover';
// forms
import { Checkbox } from '../../../forms';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  BoxHovered,
  IconWrapper } from '../../../ui';
// feature branch
import BranchActions from './element-actions';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');
const lightBlueColor = G.getTheme('colors.light.blue');
const lightBlackColor = G.getTheme('colors.light.black');
const darkGreyColor = G.getTheme('colors.light.darkGrey');
const lightGreyColor = G.getTheme('colors.light.lightGrey');
const mainLightColor = G.getTheme('colors.light.mainLight');

const enhance = compose(
  withFixedPopover,
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Event, ent: Object, callback: Function) => {
      const {
        openFixedPopup,
        closeFixedPopup,
        handleShareWithDivisions,
      } = props;

      G.stopPropagation(e);

      openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <BranchActions
            parent={ent}
            prefix='tree'
            callback={callback}
            closeFixedPopup={closeFixedPopup}
            handleShareWithDivisions={handleShareWithDivisions}
          />
        ),
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const currentBranchElement = document.getElementById(R.prop(GC.FIELD_GUID, this.props.currentBranch));
      if (G.isNotNil(currentBranchElement)) currentBranchElement.scrollIntoView();
    },
  }),
);

const renderIcon = (branch: Object, color: string) => (
  G.ifElse(
    G.isTrue(branch.isOpened),
    I.folderOpened,
    I.folderClosed,
  )(color)
);

const BranchTreeItem = ({
  isRoot,
  cursor,
  toggle,
  branch,
  padding,
  allowPin,
  pinnedList,
  searchText,
  authorities,
  allowSelect,
  handleClick,
  allowActions,
  singleSelect,
  currentBranch,
  withoutCurrent,
  withoutCheckbox,
  handlePinBranch,
  handleSelectBranch,
  handleClickEditIcon,
  handleSelectAllChildren,
  handleUnselectAllChildren,
}: Object) => {
  const permission = R.includes(PC.BRANCH_WRITE, authorities);
  const isCurrent = R.eqProps(GC.FIELD_GUID, currentBranch, branch);
  const pinned = G.notEquals(pinnedList.indexOf(branch.guid), -1);
  const currentBranchGuid = G.getGuidFromObject(currentBranch);
  const color = G.ifElse(isCurrent, blueColor, lightBlackColor);
  const pinIconColor = G.ifElse(pinned, blueColor, darkGreyColor);

  return (
    <Box id={R.prop(GC.FIELD_GUID, branch)}>
      <BoxHovered
        cursor={cursor}
        borderLeft='3px solid'
        hoverBackgroundColor={lightGreyColor}
        hoverBorderLeft={`3px solid ${G.ifElse(R.not(isCurrent), color)}`}
        borderColor={G.ifElse(isCurrent, color, mainLightColor)}
        onClick={() => {
          if (withoutCheckbox) return;
          handleClick(branch.guid);
        }}
      >
        <Flex
          height={30}
          pl={padding}
          color={color}
          position='relative'
        >
          {
            R.and(G.isFalse(isRoot), G.isNotNilAndNotEmpty(branch.children)) &&
            <Flex
              mr='6px'
              px='6px'
              height='100%'
              position='absolute'
              left={R.subtract(padding, 25)}
              onClick={(e: Event) => {
                G.stopPropagation(e);

                toggle(branch.guid);
              }}
            >
              {renderIcon(branch, color, toggle)}
            </Flex>
          }
          <Flex mr='8px' fontWeight={G.ifElse(isCurrent, 700)}>
            <Box
              mr='4px'
              color={darkGreyColor}
              title={R.prop(GC.BRANCH_TYPE, branch)}
            >
              {R.pathOr('', [R.prop(GC.BRANCH_TYPE, branch)], GC.branchTypeDisplayMap)}
            </Box>
            {
              G.isNotNil(R.prop(GC.FIELD_BRANCH_SUBTYPE, branch)) &&
              <Box
                mr='4px'
                color={darkGreyColor}
                title={R.path([GC.FIELD_BRANCH_SUBTYPE, GC.FIELD_DISPLAYED_VALUE], branch)}
              >
                {`(${R.path([GC.FIELD_BRANCH_SUBTYPE, GC.FIELD_STORED_VALUE], branch)})`}
              </Box>
            }
            <SearchMatch
              searchText={searchText}
              maxWidth={R.subtract(320, padding)}
              value={R.prop(GC.FIELD_BRANCH_NAME, branch)}
            />
          </Flex>
          {
            R.any(G.isTrue, [allowPin, allowSelect, allowActions, singleSelect]) &&
            <Flex mr='8px' ml='auto' height='100%'>
              {
                singleSelect &&
                <Box
                  p='3px 10px'
                  fontSise={12}
                  fontWeight={700}
                  borderRadius='4px'
                  bg={lightBlueColor}
                  color={mainLightColor}
                  className='hover-visible'
                >
                  {G.getWindowLocale('actions:select', 'Select')}
                </Box>
              }
              {
                R.and(allowSelect, G.isNotNilAndNotEmpty(branch.children)) &&
                <Flex
                  mr='8px'
                  height='100%'
                  cursor='pointer'
                  color={lightBlueColor}
                  className='hover-visible'
                  onClick={(e: Event) => {
                    G.stopPropagation(e);

                    handleUnselectAllChildren(G.getGuidFromObject(branch));
                  }}
                >
                  {G.getWindowLocale('actions:unselect-all-children', 'Unselect All Children')}
                </Flex>
              }
              {
                R.and(allowSelect, G.isNotNilAndNotEmpty(branch.children)) &&
                <Flex
                  mr='8px'
                  height='100%'
                  cursor='pointer'
                  color={lightBlueColor}
                  className='hover-visible'
                  onClick={(e: Event) => {
                    G.stopPropagation(e);

                    handleSelectAllChildren(G.getGuidFromObject(branch));
                  }}
                >
                  {G.getWindowLocale('actions:select-all-children', 'Select All Children')}
                </Flex>
              }
              {
                R.and(G.isFalse(isRoot), allowPin) &&
                <AuthWrapper zIndex='unset' has={[PC.BRANCH_WRITE]}>
                  <Flex
                    px='6px'
                    height='100%'
                    className={G.ifElse(pinned, '', 'hover-visible')}
                    onClick={(e: Event) => {
                      G.stopPropagation(e);
                      handlePinBranch(branch.guid, pinned);
                    }}
                  >
                    {I.pin(pinIconColor)}
                  </Flex>
                </AuthWrapper>
              }
              {
                R.and(allowActions, permission) &&
                <Flex pl='6px' height='100%'>
                  <IconWrapper
                    p='0 8px'
                    cursor='pointer'
                    onClick={(e: Object) => handleClickEditIcon(e, branch)}
                  >
                    {I.threeDots(null, 4, 13)}
                  </IconWrapper>
                </Flex>
              }
              {
                R.and(allowSelect, R.not(withoutCheckbox)) &&
                <Flex>
                  <Checkbox
                    type='checkbox'
                    onClick={G.stopPropagation}
                    checked={Boolean(branch.selected)}
                    onChange={() => handleSelectBranch(G.getGuidFromObject(branch))}
                  />
                </Flex>
              }
            </Flex>
          }
        </Flex>
      </BoxHovered>
      {
        R.and(G.isNotNilAndNotEmpty(branch.children), G.isTrue(branch.isOpened)) &&
        branch.children.map((child: Object, index: number) => (
          <BranchTreeItem
            isRoot={false}
            branch={child}
            cursor={cursor}
            toggle={toggle}
            allowPin={allowPin}
            pinnedList={pinnedList}
            searchText={searchText}
            authorities={authorities}
            allowSelect={allowSelect}
            handleClick={handleClick}
            singleSelect={singleSelect}
            allowActions={allowActions}
            padding={R.add(padding, 24)}
            currentBranch={currentBranch}
            handlePinBranch={handlePinBranch}
            handleSelectBranch={handleSelectBranch}
            handleClickEditIcon={handleClickEditIcon}
            handleSelectAllChildren={handleSelectAllChildren}
            handleUnselectAllChildren={handleUnselectAllChildren}
            key={`branch-tree-item-${R.prop(GC.FIELD_BRANCH_NAME, child)}-${index}`}
            withoutCheckbox={withoutCurrent ? R.equals(currentBranchGuid, G.getGuidFromObject(child)) : false}
          />
        ))
      }
    </Box>
  );
};

const BranchTreeComponent = enhance((props: Object) => (
  <Box
    width='100%'
    height={400}
    overflow='auto'
    border='1px solid'
    bg={mainLightColor}
    borderColor={G.getTheme('colors.light.grey')}
  >
    <BranchTreeItem
      padding={8}
      isRoot={true}
      branch={props.tree}
      cursor={props.cursor}
      allowPin={props.allowPin}
      toggle={props.toggleBranch}
      pinnedList={props.pinnedList}
      searchText={props.searchText}
      authorities={props.authorities}
      allowSelect={props.allowSelect}
      singleSelect={props.singleSelect}
      allowActions={props.allowActions}
      currentBranch={props.currentBranch}
      withoutCheckbox={props.rootDisabled}
      withoutCurrent={props.withoutCurrent}
      handlePinBranch={props.handlePinBranch}
      handleClick={props.handleBranchNameClick}
      handleSelectBranch={props.handleSelectBranch}
      handleClickEditIcon={props.handleClickEditIcon}
      handleSelectAllChildren={props.handleSelectAllChildren}
      handleUnselectAllChildren={props.handleUnselectAllChildren}
    />
  </Box>
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  authorities: makeSelectAuthorities(state),
});

export default connect(mapStateToProps)(BranchTreeComponent);
