import * as R from 'ramda';
import React, { useState } from 'react';
// components
import { TextComponent } from '../text';
import TextMoreLess from './text-more-less';
// helpers constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const initialStyles = {
  fontSize: 12,
  width: '100%',
  maxHeight: 300,
  textAlign: 'justify',
  wordBreak: 'break-all',
};

const ShowLessMoreComponent = ({ collapsed, setCollapsed, showLessMoreText }: Object) => (
  <TextComponent ml='5px' color={G.getTheme('colors.navyBlue')} onClick={() => setCollapsed(R.not(collapsed))}>
    ...{showLessMoreText}
  </TextComponent>
);

export const TextCollapse = (props: Object) => {
  const { text, rootProps, lessHeight } = props;

  const [collapsed, setCollapsed] = useState(true);

  return (
    <TextMoreLess
      text={text}
      collapsed={collapsed}
      lessHeight={lessHeight}
      onClick={() => setCollapsed(R.not(collapsed))}
      rootProps={{ style: R.mergeRight(initialStyles, rootProps) }}
      showLessElement={
        <ShowLessMoreComponent
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          showLessMoreText={G.getWindowLocale('titles:show-less', 'Show Less')}
        />
      }
      showMoreElement={
        <ShowLessMoreComponent
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          showLessMoreText={G.getWindowLocale('titles:show-more', 'Show More')}
        />
      }
    />
  );
};
