import * as R from 'ramda';
import { put, call, take, fork, delay, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-profile
import * as A from '../actions';
import { makeSelectVinLookup, makeSelectGeneralDetails } from '../selectors';
//////////////////////////////////////////////////

function* getTrailerGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.getCurrentTrailerEndpoint(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getGeneralDetailsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getTrailerGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getTrailerGeneralDetailsRequest exception');
  }
}


function* syncTrailerByVINRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: {
        [GC.FIELD_TRAILER_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.syncTrailer, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getGeneralDetailsSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'syncTrailerByVINRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'syncTrailerByVINRequest exception');
  }
}

function* updateTrailerGeneralDetailsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const vinLookup = yield select(makeSelectVinLookup());
    const initialValues = yield select(makeSelectGeneralDetails());

    const { values, options, handleClose, saveAndClose } = payload;

    const { guid, serialNumber } = values;

    if (R.and(vinLookup, R.eqProps(GC.FIELD_SERIAL_NUMBER, values, initialValues))) {
      yield put(closeLoader());
      yield put(A.setVinLookup(false));

      if (G.isNilOrEmpty(serialNumber)) {
        return yield call(G.showToastrMessage, 'error', 'messages:serial-number-is-required');
      }

      return yield call(syncTrailerByVINRequest, { payload: guid });
    }

    const res = yield call(sendRequest, 'put', endpointsMap.trailer, { data: values });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (vinLookup) {
        yield delay(1000);

        yield put(closeLoader());
        yield put(A.setVinLookup(false));

        yield call(syncTrailerByVINRequest, { payload: guid });

        if (R.pathEq(true, ['options', 'shouldCloseModal'], payload)) {
          yield put(closeModal());
        }

        return;
      }

      if (R.pathEq(true, ['options', 'shouldCloseModal'], payload)) {
        yield put(closeModal());
      }

      if (saveAndClose) {
        yield put(closeLoader());

        G.callFunction(handleClose);

        return false;
      }

      yield put(A.getGeneralDetailsSuccess(data));
    } else {
      G.callFunctionWithArgs(options.setSubmitting, false);

      yield call(G.handleFailResponse, res, 'updateTrailerGeneralDetailsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateTrailerGeneralDetailsRequest exception');
  }
}

// visit page
function* visitFleetTrailerProfilePage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_FLEET_PROFILE_TRAILER_PAGE);

    yield put(A.setInitialState('trailer'));

    yield fork(getTrailerGeneralDetailsRequest, { payload: G.getGuidFromObject(payload) });

    const generalDetails = yield take(A.getGeneralDetailsSuccess);

    const shared = R.path(['payload', GC.FIELD_SHARED], generalDetails);
    const branchGuid = R.path(['payload', GC.BRANCH_GUID], generalDetails);

    if (R.not(shared)) {
      yield put(A.getFleetProfileConfigsRequest({ [GC.BRANCH_GUID]: branchGuid, group: GC.TRAILER_CONFIG_GROUP }));
    }

    break;
  }
}

export function* fleetProfileTrailerWatcherSaga() {
  // visit pages
  yield takeLatest(GC.VISIT_FLEET_PROFILE_TRAILER_PAGE, visitFleetTrailerProfilePage);
  // general
  yield takeLatest(A.getTrailerGeneralDetailsRequest, getTrailerGeneralDetailsRequest);
  yield takeLatest(A.updateTrailerGeneralDetailsRequest, updateTrailerGeneralDetailsRequest);
}

export default fleetProfileTrailerWatcherSaga;
