import React from 'react';
import { Form } from 'formik';
import { Link } from 'react-router';
import styled from 'styled-components';
import { space, width, fontSize } from 'styled-system';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

export const AmousBg = 'https://amous-front-dev.s3.amazonaws.com/white-background-new.jpg';

export const StyledLinkTo = styled(Link)` 
  ${space}
  ${width}
  ${fontSize}
  
  font-size: 12px;
  margin-top: 15px;
  text-decoration: underline;

  color: ${({ theme }: Object) => theme.colors.black};

  &:focus {
    outline: none;
  }
`;

StyledLinkTo.defaultProps = {
  fontSize: 14,
};

export const StyledFormTitle = ({ children, textAlign, fontSizeTitle = 36 }: Object) => (
  <Box
    mb={15}
    color='black'
    fontWeight='900'
    fontFamily='Urbanist'
    textAlign={textAlign}
    fontSize={fontSizeTitle}
  >
    {children}
  </Box>
);

export const StyledFormSubtitle = ({ children, textAlign, displaySubtitle }: Object) => (
  <Box
    mb={21}
    fontSize={14}
    textAlign={textAlign}
    color='greyMatterhorn'
    display={displaySubtitle}
  >
    {children}
  </Box>
);
