import * as R from 'ramda';
import React, { Fragment, useCallback } from 'react';
// components
import { AddDocument2 } from '../../../../components/activities';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, Iframe, MainActionButton } from '../../../../ui';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature fleet
import GoToConfiguration from './go-to-configuration';
//////////////////////////////////////////////////

export const prepareAndSetMaintenanceDocs = ({
  doc,
  files,
  aiUpload,
  maintenanceDocs,
  setMaintenanceDocs,
  defaultDocumentType,
}: Object) => {
  if (R.and(G.isNilOrEmpty(files), G.isNilOrEmpty(doc))) {
    return setMaintenanceDocs(null);
  }

  if (G.isTrue(aiUpload)) {
    return setMaintenanceDocs(R.prepend(doc, maintenanceDocs));
  }

  const filesWithProps = files.map((file: Object) => ({
    file,
    guid: G.generateGuid(),
    preview: G.createObjectURL(file),
    documentType: defaultDocumentType,
  }));

  const newDocs = R.concat(R.or(maintenanceDocs, []), filesWithProps);

  return setMaintenanceDocs(newDocs);
};

const AiUpload = (props: Object) => {
  const {
    values,
    setValues,
    isEditMode,
    openLoader,
    entityType,
    closeLoader,
    aiUploadDoc,
    setAiUploadDoc,
    maintenanceDocs,
    primaryObjectGuid,
    aiRecognizeRequest,
    setMaintenanceDocs,
    aiUploadDefaultDocumentType,
  } = props;

  const aiUploadGuid = G.getGuidFromObject(aiUploadDoc);
  const filePreview = R.path(['preview'], aiUploadDoc);
  const type = R.path(['file', 'type'], aiUploadDoc);

  const addDocument = useCallback(async (file: Object) => {
    openLoader();

    const reqFormData = G.makeDataForDocument({ file, primaryObjectGuid, documentType: aiUploadDefaultDocumentType });

    const endpoint = R.equals(entityType, 'trailer') ?
      endpointsMap.trailerEquipmentServiceDocument :
      endpointsMap.truckEquipmentServiceDocument;

    const res = await sendRequest('post', endpoint, { data: reqFormData });

    const { status } = res;

    if (G.isResponseFail(status)) {
      G.handleFailResponseSimple(res, 'AiUpload -> addDocument fail');
    }

    closeLoader();
  }, [entityType, primaryObjectGuid, aiUploadDefaultDocumentType]);

  return (
    <Fragment>
      {
        G.isNilOrEmpty(aiUploadDefaultDocumentType) && <GoToConfiguration entityType={entityType} />
      }
      {
        G.isNilOrEmpty(filePreview) && G.isNotNilAndNotEmpty(aiUploadDefaultDocumentType) &&
        <Flex justifyContent='center' flexDirection='column'>
          <AddDocument2
            m='0px 15px'
            onDropHandler={(files: Array) => {
              const file = R.head(files || []);

              const callback = (data: Object) => {
                const invoice = G.getPropFromObject('invoice', data);

                const currentInvoice = G.getPropFromObject('invoice', values);

                let newInvoice = G.mergeWithoutNullUndefinedOrEmptyString(currentInvoice, invoice);

                const invoiceDate = G.getPropFromObject(GC.FIELD_INVOICE_DATE, newInvoice);

                if (G.isValidDate(invoiceDate)) {
                  newInvoice = R.assoc(
                    GC.FIELD_INVOICE_DATE,
                    G.convertInstanceToDefaultDateFormat(invoiceDate),
                    newInvoice,
                  );
                }

                const newValues = R.assoc('invoice', newInvoice, values);

                setValues(newValues);

                if (G.isTrue(isEditMode)) addDocument(file);
              };

              aiRecognizeRequest(file, callback);

              const doc = {
                file,
                guid: G.generateGuid(),
                preview: G.createObjectURL(file),
                documentType: aiUploadDefaultDocumentType,
              };

              setAiUploadDoc(doc);

              prepareAndSetMaintenanceDocs({
                doc,
                aiUpload: true,
                maintenanceDocs,
                setMaintenanceDocs,
                aiUploadDefaultDocumentType,
              });
            }}
          />
        </Flex>
      }
      {
        filePreview &&
        <Flex
          pl={20}
          fontSize={14}
          justifyContent='space-between'
        >
          <MainActionButton
            type='button'
            onClick={() => {
              setAiUploadDoc(null);

              const newDocs = R.reject(R.propEq(aiUploadGuid, GC.FIELD_GUID), maintenanceDocs);

              setMaintenanceDocs(newDocs);
            }}
          >
            {G.getWindowLocale('actions:remove-file', 'Remove File')}
          </MainActionButton>
        </Flex>
      }
      { filePreview &&
        <Box p={15} height='calc(100% - 100px)'>
          <Iframe type={type} src={filePreview} />
        </Box>
      }
    </Fragment>
  );
};

export default AiUpload;
