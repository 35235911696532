import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  Text,
  StyledLink,
  IconWrapper,
  } from '../../../ui';
// feature load-board
import * as H from '../helpers';
import { Email } from './table-cells';
import { getUOMs } from '../settings/options';
//////////////////////////////////////////////////

const greyColor = G.getTheme('colors.titleGrey');
const blueColor = G.getTheme('colors.dark.blue');
const textColor = G.getTheme('colors.dark.mainDark');

const LocationInfo = ({
  date,
  title,
  location,
}: Object) => {
  const locationDate = date && G.convertDateTimeToConfigFormat(date);

  return (
    <Flex flexDirection='column' alignItems='flex-start' gap='6px'>
      <Box color={greyColor}>{`${title}:`}</Box>
      <Flex gap={6}>
        <IconWrapper>
          {I.locationMarker(textColor, 12, 12)}
        </IconWrapper>
        <Text>{H.getLocationDisplayValue(location)}</Text>
      </Flex>
      {G.isNotNilAndNotEmpty(locationDate) && <Box ml={20}>{locationDate}</Box>}
    </Flex>
  );
};

const Comments = ({ comments }: Object) => (
  <Flex
    mt='8px'
    gap='4px'
    width='100%'
    fontSize={12}
    flexDirection='column'
    alignItems='flex-start'
  >
    <Box color={greyColor} fontWeight='bold'>{G.getWindowLocale('titles:comments', 'Comments')}:</Box>
    <Flex
      gap='3px'
      maxHeight={70}
      overflowY='auto'
      overflowX='hidden'
      flexDirection='column'
      alignItems='flex-start'
    >
      {comments.map((option: string, i: number) => (
        <Box
          key={i}
          pl={10}
          width={480}
          title={option}
          maxWidth='100%'
          whiteSpace='normal'
          wordBreak='break-word'
        >
          {`${R.inc(i)}. ${option}`}
        </Box>
      ))}
    </Flex>
  </Flex>
);

const TripInfo = (props: Object) => {
  const {
    data,
    searchFilters,
    rate: {
      rate,
      rateCurrency,
    },
    distanceInfo: {
      distance,
      distanceUom,
      originDeadhead,
      originDeadheadUom,
    },
  } = props;

  const filterGuid = R.prop('searchFilterGuid', data);
  const shipment = R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT, data);
  const searchFilterOrigin = R.path([filterGuid, GC.FIELD_ORIGIN], searchFilters);

  const {
    origin,
    contact,
    comments,
    pickupDate,
    destination,
    creditScore,
    referenceId,
    deliveryDate,
    startLocation,
    brokerMcNumbers,
  } = shipment;

  const email = R.prop(GC.FIELD_LOAD_BOARD_EMAIL, contact);
  const phone = R.prop(GC.FIELD_LOAD_BOARD_PHONE, contact);
  const phoneExt = R.prop(GC.FIELD_LOAD_BOARD_PHONE_EXT, contact);
  const contactName = R.propOr('-', GC.FIELD_CONTACT_NAME, contact);
  const equipmentLocation = R.or(startLocation, searchFilterOrigin);
  const score = R.prop(GC.FIELD_LOAD_BOARD_CREDIT_SCORE_SCORE, creditScore);
  const phoneExtToUse = G.ifElse(G.isNotNilAndNotEmpty(phoneExt), `,,${phoneExt}`, '');
  const rateSymbol = G.getCurrencySymbol(rateCurrency);
  const totalDistance = distance ? R.add(distance, R.or(originDeadhead, 0)) : null;

  const rateCpm = R.and(G.isNotNilAndNotEmpty(rate), G.isNotNil(totalDistance)) ?
    G.toFixed(R.divide(rate, totalDistance)) :
    '-';

  const startLocationText = originDeadhead ?
    ` (${G.getWindowLocale('titles:origin-deadhead', 'DH-O')} ${originDeadhead} ${getUOMs(originDeadheadUom)})` :
    '';

  const originText = distance ?
    ` (${G.getWindowLocale('titles:trip', 'Trip')} ${distance} ${getUOMs(distanceUom)})` :
    '';

  const summaryText = `${R.or(originDeadhead, 0)} + ${R.or(distance, 0)} = ${R.or(totalDistance, 0)} ${
    getUOMs(distanceUom)}; ${rateSymbol} ${G.toFixed(rate)} (${rateSymbol} ${rateCpm})`;

  return (
    <React.Fragment>
      <Flex
        gap='20px'
        fontSize={12}
        color={textColor}
        alignItems='flex-start'
        data-testid='testid-TripInfoComponent'
      >
        <Box width={250}>
          <Box fontSize={14} fontWeight={600} pb='16px'>
            {G.getWindowLocale('titles:mc-number', 'MC Number')}: {R.pathOr('-', [0], brokerMcNumbers)}
          </Box>
          <Flex flexDirection='column' alignItems='flex-start' gap={8}>
            <Box>
              {G.getWindowLocale('titles:load-number', 'Load Number')}: {R.or(referenceId, '-')}
            </Box>
            {
              G.isNotNilAndNotEmpty(score) &&
              <Box>
                {G.getWindowLocale('titles:credit-score', 'CS')}: {score}
              </Box>
            }
            <TextComponent
              color={textColor}
              title={contactName}
              withEllipsis={true}
              display='inline-block'
              maxWidth='calc(100% - 14px)'
            >
              {G.getWindowLocale('titles:contact')}: {contactName}
            </TextComponent>
            {
              G.isNotNilAndNotEmpty(email) &&
              <Box maxWidth='calc(100% - 14px)'>
                <Email data={shipment} width='100%' />
              </Box>
            }
            {
              G.isNotNilAndNotEmpty(phone) &&
              <StyledLink
                mr={20}
                fontSize={11}
                display='flex'
                color={blueColor}
                wordBreak='break-all'
                href={G.getCallUrl(null, `${phone}${phoneExtToUse}`)}
              >
                <Box mr='4px'>{I.phone(blueColor, 10, 10)}</Box>
                {phone} {G.isNotNilAndNotEmpty(phoneExt) && `(${phoneExt})`}
              </StyledLink>
            }
          </Flex>
        </Box>
        <Flex width={250} flexDirection='column' alignItems='flex-start' gap={12}>
          { G.isNotNilAndNotEmpty(equipmentLocation) && (
            <LocationInfo
              location={equipmentLocation}
              title={`${G.getWindowLocale('titles:start-location', 'Start Location')}${startLocationText}`}
            />
          )}
          <LocationInfo
            date={pickupDate}
            location={origin}
            title={`${G.getWindowLocale('titles:origin', 'Origin')}${originText}`}
          />
          <LocationInfo
            date={deliveryDate}
            location={destination}
            title={G.getWindowLocale('titles:destination', 'Destination')}
          />
          <Box fontWeight='bold'>{summaryText}</Box>
        </Flex>
      </Flex>
      {
        G.isNotNilAndNotEmpty(comments) &&
        <Comments comments={comments} />
      }
    </React.Fragment>
  );
};

export default TripInfo;
