import styled from 'styled-components';
import RDatePicker from 'react-datepicker';
import {
  top,
  space,
  width,
  height,
  fontSize,
  borderRadius,
} from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex, AbsoluteBox } from '../../ui';
//////////////////////////////////////////////////

// TODO: add all ui with props and move related to formik

export const renderBorderColor = ({ hasError, additionalColor }: Object) => {
  if (hasError) {
    return G.getTheme('forms.inputs.borderColorErr');
  }

  if (G.isNotNilAndNotEmpty(additionalColor)) {
    return additionalColor;
  }

  return G.getTheme('colors.dark.grey');
};

export const HeaderFieldWrapper = styled.div`
  width: 160px;
  margin-right: 15px;

  & div.Select-control {
    border-radius: 4px;
    border-color: ${() => G.getTheme('colors.dark.grey')};
  }
`;

export const TabBox = styled(Box)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const LabelBox = styled(AbsoluteBox)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  &.required::after {
    content: '*';
    color: ${() => G.getTheme('colors.light.mainRed')};
  }
`;

export const LabelBoxEllipsed = styled(LabelBox)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  border-radius: unset;
  text-overflow: ellipsis;
  width: calc(100% - 20px);
`;

export const Error = styled.span`
  ${top}
  ${width}

  left: 5px;
  font-size: 11px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;

  z-index: ${({ zIndex }: Object) => zIndex || 'unset'};
  color: ${() => G.getTheme('forms.inputs.errorTextColor')};
`;

Error.defaultProps = {
  width: 'max-content',
  top: 'calc(100% + 1px)',
};

export const Input = styled.input`
  ${space}
  ${width}
  ${height}
  ${fontSize}
  ${borderRadius}

  cursor: text;
  outline: none;

  line-height: ${({ lineHeight }: Object) => lineHeight || '36px'};
  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }
`;

Input.defaultProps = {
  pl: 15,
  pr: 15,
  height: 36,
  borderRadius: 4,
};

export const Textarea = styled.textarea`
  ${space}
  ${width}
  ${height}
  ${fontSize}
  ${borderRadius}

  cursor: text;
  resize: none;
  outline: none;

  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

Textarea.defaultProps = {
  height: 80,
  borderRadius: 4,
};

export const SelectWrapper = styled.div`
  ${width}

  &:after {
    width: 4px;
    height: 4px;
    content: '';
    z-index: 10;
    position: absolute;
    border: solid black;
    pointer-events: none;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg) translate(0, -60%);
    top: ${({ afterTop }: Object) => afterTop || '17px'};
    right: ${({ afterRight }: Object) => afterRight || '10px'};
  }
`;

export const InputSelect = styled.select`
  ${space}
  ${width}
  ${borderRadius}

  height: 36px;
  outline: none;
  padding: 0 15px;
  appearance: none;
  line-height: 36px;
  position: relative;

  background-color: ${() => G.getTheme('forms.inputs.bgColor')};
  border: 1px solid ${(props: Object) => renderBorderColor(props)};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

InputSelect.defaultProps = {
  borderRadius: 4,
};

export const Checkbox = styled.input`
  ${space}

  position: relative;

  &:focus {
    outline: none;
  }

  &:before {
    border: 1px solid ${() => G.getTheme('forms.checkboxes2.borderColor')};
    left: 0;
    top: 50%;
    content: '';
    width: 17px;
    height: 17px;
    border-radius: 3px;
    position: absolute;
    transform: translate(0, -50%);
    background-color: ${() => G.getTheme('forms.checkboxes2.bgColor')};
  }

  &:checked:before {
    border: 1px solid ${() => G.getTheme('forms.checkboxes2.borderColor')};
    content: '';
    width: 17px;
    height: 17px;
    border-radius: 3px;
    position: absolute;
    background-color: ${() => G.getTheme('forms.checkboxes2.bgColor')};
  }

  &:checked:after {
    border: solid ${() => G.getTheme('forms.checkboxes2.textColor')};
    top: 50%;
    left: 1px;
    width: 3px;
    height: 6px;
    content: '';
    position: absolute;
    border-width: 0px 3px 3px 0;
    transform: rotate(45deg) translate(0, -90%);
  }
`;

export const CountrySelectWrapper = styled.div`
  ${space}
  ${width}

  position: relative;

  & > select {
    z-index: 0;
    width: 100%;
    height: 36px;
    outline: none;
    appearance: none;
    line-height: 36px;
    padding-left: 15px;
    border-radius: 4px;
    position: relative;
    padding-right: 25px;

    background-color: ${() => G.getTheme('forms.inputs.bgColor')};
    border: 1px solid ${(props: Object) => renderBorderColor(props)};

    &:focus {
      box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
    }
  }

  &:after {
    top: 17px;
    width: 4px;
    content: '';
    right: 10px;
    height: 4px;
    position: absolute;
    border: solid black;
    pointer-events: none;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg) translate(0, -60%);
  }
`;

export const DatePicker = styled(RDatePicker)`
  ${space}
  ${width}

  height: 36px;
  cursor: text;
  outline: none;
  line-height: 36px;
  border-radius: 4px;
  padding-left: 15px;

  background-color: ${() => G.getTheme('inputs.bgColor')};
  border: 1px solid ${(props: Object) => renderBorderColor(props)};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

export const CalendarWrapper = styled.div`
  ${space}
  ${width}

  & .react-datepicker-popper[data-placement^="top"] {
    margin-left: 10px;
  }

  & .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 0;
  }

  & .react-datepicker__triangle {
    display: none;
  }

  & .react-datepicker__navigation--previous {
    border-right-color: ${() => G.getTheme('colors.light.mainRed')};
  }

  & .react-datepicker__navigation--next {
    border-left-color: ${() => G.getTheme('colors.light.mainRed')};
  }

  & .react-datepicker__day--selected {
    background-color: ${() => G.getTheme('colors.light.mainRed')};

    &:hover {
      background-color: ${() => G.getTheme('colors.light.mainRed')};
    }
  }

  & .react-datepicker {
    box-shadow: 0 0 1px 1px ${() => G.getTheme('colors.light.mainRed')};
  }

  & .react-datepicker__header--time {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
      padding: 0;
      height: 183px !important;

      & li.react-datepicker__time-list-item {
        padding: 5px 6px;
      }

      & li.react-datepicker__time-list-item--selected {
        background-color: ${() => G.getTheme('colors.light.mainRed')};

      &:hover {
        background-color: ${() => G.getTheme('colors.light.mainRed')};
        }
      }
    }

  & .react-datepicker__close-icon {
    right: 15px;
  }
  & .react-datepicker-popper {
    z-index: 1000;
  }
`;

export const CalendarIconWrapper = styled(CalendarWrapper)`
  & .react-datepicker-popper[data-placement^="bottom-start"] {
    transform: translate3d(0px, 38px, 0px) !important;
  }
  & .react-datepicker-popper[data-placement^="top-start"] {
    transform: translate3d(-10px, -101%, 0px) !important;
  }
`;

export const PhoneNumberInputWrapper = styled.div`
  ${space}
  ${width}

  & .PhoneInput > input {
    height: 36px;
    outline: none;
    width: calc(100% - 30px);

    border: ${({ phoneInputBorder = 'none' }: Object) => phoneInputBorder};
    padding-left: ${({ phoneInputPaddingLeft }: Object) => phoneInputPaddingLeft};
    border-radius: ${({ phoneInputBorderRadius }: Object) => phoneInputBorderRadius};
    border-bottom: ${({ phoneInputBorder }: Object) => G.ifElse(G.isNilOrEmpty(phoneInputBorder), '1px solid')};
    border-color: ${(props: Object) => renderBorderColor(props)};

    &:focus {
      border-color: rgba(206, 40, 40, 0.5);
    }
  }
`;

export const RouteTelFieldsWrapper = styled.section`
  & div {
    width: 100%;
  }
  & div select::after {
    right: 15px;
    top: 23px;
  }
`;

export const TelFieldsWrapper = styled.section`
  & > div:last-child > div > select::after {
    top: 23px;
    right: 15px;
  }
`;

export const MuiIconWrapper = styled(Flex)`
  & .MuiIconButton-root {
    padding: 1px;
    margin-left: -13px;
  }
`;
