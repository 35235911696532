import * as R from 'ramda';
import { connect } from 'react-redux';
import { CSS } from '@dnd-kit/utilities';
import { createStructuredSelector } from 'reselect';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { useSortable, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
// features
import PC from '../../../permission/role-permission';
import { makeSelectStyling } from '../../../styling/selectors';
import { makeSelectPinnedMenuItems } from '../../../profile/selectors';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { Flex } from '../../../../ui';
// feature navbar-menu
import NavbarButton from './navbar-button';
import { makeSelectSidebar } from '../../selectors';
import SidebarMenuList from '../../configs/menus-map';
import { removePinnedItemFromList, reorderPinnedItems } from '../../actions';
import { Divider, DeleteButton, PinnedScrollbar, PinnedItemUI, GhostDivider } from './ui';
//////////////////////////////////////////////////

const findItemInListDeep = (key: string, list: Array, nested: string) => {
  let found = null;

  const search = (list: Array) => (
    R.find((item: Object) => {
      if (R.equals(item.key, key)) {
        found = item;

        return true;
      } else if (G.isNotNilAndNotEmpty(G.getPropFromObject(nested, item))) {
        return search(G.getPropFromObject(nested, item));
      }

      return false;
    })(list)
  );

  search(list);

  return found;
};

const getParentItemMenu = (menuList: Array, key: string) =>
  R.find((item: MenuItem) => {
    if (R.equals(R.path(['key'], item), key)) {
      return true;
    } else if (item.submenu) {
      return G.isNotNilAndNotEmpty(getParentItemMenu(item.submenu, key));
    }

    return false;
  })(menuList);

const NavbarItemComponent = ({
  item,
  isHidden,
  editPinned,
  iconsColor,
  isDragging,
  isHighlighted,
  currentMenuItem,
  handleClickNavItem,
  handleRemoveNavItem,
} : Object) => {
  const { key, title, action } = item;

  const parentItem = getParentItemMenu(SidebarMenuList, key);

  const {
    listeners,
    transform,
    attributes,
    setNodeRef,
    transition,
  } = useSortable({
    id: key,
    disabled: R.not(editPinned),
    animateLayoutChanges: () => false,
  });

  const style = {
    transition,
    opacity: isHidden ? 0.1 : 1,
    zIndex: isDragging ? 99999 : 'unset',
    transform: CSS.Translate.toString(transform),
  };

  const dragHandleProps = {
    ...attributes,
    ...listeners,
  };

  return (
    <PinnedItemUI
      {...dragHandleProps}
      style={style}
      ref={setNodeRef}
      isHighlighted={isHighlighted}
    >
      <GhostDivider isHighlighted={isHighlighted} />
      <NavbarButton
        iconsColor={iconsColor}
        text={G.getWindowLocale(key, title)}
        onClick={() => handleClickNavItem(action)}
        active={R.equals(currentMenuItem, action)}
        cursor={G.ifElse(editPinned, 'grab', 'pointer')}
        icon={R.pathOr(() => {}, [parentItem.iconName], I)}
      />
      {
        editPinned &&
        <DeleteButton
          onPointerDown={(e: Event) => e.stopPropagation()}
          onClick={(e: Event) => handleRemoveNavItem(e, item)}
        >
          {I.trash(G.getTheme('colors.light.darkRed'))}
        </DeleteButton>
      }
    </PinnedItemUI>
  );
};

const DraggableList = ({
  navItems,
  iconsColor,
  editPinned,
  currentMenuItem,
  handleClickNavItem,
  reorderPinnedItems,
  handleRemoveNavItem,
}: Object) => {
  const [draggingItemKey, setDraggingItemKey] = useState(null);
  const [draggableItems, setDraggableItems] = useState(navItems);
  const [overItemKey, setOverItemKey] = useState(null);

  useEffect(() => {
    setDraggableItems(navItems);
  }, [navItems]);

  const onDragStart = useCallback(({ active }: Event) => {
    setDraggingItemKey(active.id);
  }, []);

  const onDragOver = useCallback(({ over }: Event) => {
    const overId = R.prop('id', over);

    setOverItemKey(overId);
  }, []);

  const onDragEnd = useCallback(({ active, over }: Event) => {
    setOverItemKey(null);
    setDraggingItemKey(null);

    const activeId = R.prop('id', active);
    const overId = R.prop('id', over);

    if (R.or(R.not(over), R.equals(activeId, overId))) return;

    const sourceIndex = R.findIndex(R.propEq(activeId, 'key'), draggableItems);
    const destinationIndex = R.findIndex(R.propEq(overId, 'key'), draggableItems);

    if (R.equals(sourceIndex, destinationIndex)) return;

    const reorderedItems = G.reorderList(draggableItems, sourceIndex, destinationIndex);

    setDraggableItems(reorderedItems);

    reorderPinnedItems(reorderedItems);
  }, [draggableItems, reorderPinnedItems]);

  const draggingItem = R.find(R.propEq(draggingItemKey, 'key'), draggableItems);

  return (
    <DndContext
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragStart={onDragStart}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={draggableItems} strategy={verticalListSortingStrategy}>
        <Flex flexDirection='column'>
          {R.map((item: Object) => {
            const { key } = item;

            const isHidden = R.equals(key, draggingItemKey);
            const isHighlighted = R.equals(key, overItemKey);

            return (
              <NavbarItemComponent
                key={key}
                item={item}
                isDragging={false}
                isHidden={isHidden}
                editPinned={editPinned}
                iconsColor={iconsColor}
                isHighlighted={isHighlighted}
                currentMenuItem={currentMenuItem}
                handleClickNavItem={handleClickNavItem}
                handleRemoveNavItem={handleRemoveNavItem}
              />
            );
          }, draggableItems)}
        </Flex>
      </SortableContext>
      <DragOverlay>
        {draggingItemKey ? (
          <NavbarItemComponent
            isHidden={false}
            isDragging={true}
            item={draggingItem}
            isHighlighted={false}
            editPinned={editPinned}
            iconsColor={iconsColor}
            currentMenuItem={currentMenuItem}
            handleClickNavItem={handleClickNavItem}
            handleRemoveNavItem={handleRemoveNavItem}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

const getNavItems = (sidebar: Array, pinnedItems: Array) => R.reject(
  R.isNil,
  R.map(
    (item: string) => findItemInListDeep(item, sidebar, 'submenu'),
    pinnedItems),
);

const PinnedItems = ({
  styling,
  sidebar,
  currentMenuItem,
  pinnedItemsList,
  reorderPinnedItems,
  carrierPinnedItems,
  handleClickNavItem,
  removePinnedItemFromList,
}: Object) => {
  const [editPinned, setEditPinned] = useState(false);

  const iconsColor = R.pathOr(G.getTheme('colors.light.mainLight'), ['textColor'], styling);

  const isCarrier = G.isCurrentUserTypeCarrier();

  const pinnedItems = G.ifElse(isCarrier, carrierPinnedItems, pinnedItemsList);

  const navItems = useMemo(() => getNavItems(sidebar, pinnedItems), [sidebar, pinnedItems]);

  const adjustHeight = R.and(
    R.not(G.isCurrentBranchTypeCustomer()),
    G.hasAmousCurrentUserPermissions([PC.EXTERNAL_LOAD_BOARD_EXECUTE]),
  );

  const maxHeightDiff = G.ifElse(isCarrier, 190, G.ifElse(adjustHeight, 515, 405));

  const handleRemoveNavItem = useCallback((event: Event, item: Object) => {
    G.stopPropagation(event);
    removePinnedItemFromList(item.key);
  });

  const toggleEditPinned = useCallback(() => setEditPinned((prev: boolean) => R.not(prev)));

  return (
    <Flex
      flex={1}
      mb='10px'
      flexDirection='column'
    >
      <Flex
        flex={1}
        width='100%'
        flexDirection='column'
      >
        {R.not(isCarrier) && <Divider />}
        <PinnedScrollbar
          gap={8}
          width='100%'
          overflowY='auto'
          flexDirection='column'
          justifyContent='start'
          maxHeight={`calc(100dvh - ${maxHeightDiff}px)`}
        >
          <DraggableList
            navItems={navItems}
            iconsColor={iconsColor}
            editPinned={editPinned}
            currentMenuItem={currentMenuItem}
            handleClickNavItem={handleClickNavItem}
            reorderPinnedItems={reorderPinnedItems}
            handleRemoveNavItem={handleRemoveNavItem}
          />
        </PinnedScrollbar>
      </Flex>
      <Flex
        width='100%'
        flexDirection='column'
      >
        <Divider />
        {
          R.not(isCarrier) &&
          <NavbarButton
            icon={I.pencil}
            iconsColor={iconsColor}
            editPinned={editPinned}
            onClick={toggleEditPinned}
            text={G.getWindowLocale('action:edit', 'Edit Panel')}
          />
        }
      </Flex>
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  styling: makeSelectStyling(state),
  sidebar: makeSelectSidebar(state),
  pinnedItemsList: makeSelectPinnedMenuItems(state),
});

export default connect(mapStateToProps, {
  reorderPinnedItems,
  removePinnedItemFromList,
})(PinnedItems);
