import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// components
import { openModal } from '../../components/modal/actions';
// features
import { sendLogOutRequest } from '../auth/actions';
import { receivedSwitchBranchSuccess } from '../branch/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//
import * as A from './actions';
import { paramsByType } from './settings/filter-props';
//////////////////////////////////////////////////

const handleCleanStateActionsLogic = createLogic({
  type: [
    sendLogOutRequest,
    receivedSwitchBranchSuccess,
  ],
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(A.cleanReportStateAction());
    done();
  },
});

const validateOperation = (report: Object) => {
  let operationAbsent = false;
  report.searchCriteria.forEach((criteria: Object) => {
    const condition = G.ifElse(
      R.equals(criteria.dataType, 'date'),
      R.and(G.isNilOrEmpty(criteria.timeUnit), G.isNilOrEmpty(criteria.operation)),
      G.isNilOrEmpty(criteria.operation),
    );
    if (condition) operationAbsent = true;
  });
  return operationAbsent;
};

const reportValidate = ({ getState, action }: Object, allow: void, reject: void) => {
  const state = getState();
  const { newReport } = action.payload;
  if (state.router.location.pathname.includes('/list')) return allow(action);
  const operationAbsent = validateOperation(newReport);
  if (operationAbsent) {
    G.showToastrMessageSimple(
      'info',
      G.getWindowLocale(
        'messages:select-operation-for-filtrer',
        'Please select operations for all selected filter fields',
      ),
    );
    return reject();
  }
  const invalidFilterValue = [];
  newReport.searchCriteria.forEach((criteria: Object) => {
    if (R.equals(criteria.dataType, 'string')) return;
    if (R.equals(criteria.dataType, GC.FIELD_REFERENCE)) return;
    const field = R.find(
      R.propEq(criteria.propertyName, 'value'),
      paramsByType[newReport.type],
    );
    const checkOnNumber = R.and(
      R.equals(criteria.dataType, 'number'),
      G.isNotNilAndNotEmpty(criteria.numberValue),
    );
    if (checkOnNumber) {
      const isNumber = /^\d+$/;
      if (G.isFalse(isNumber.test(criteria.numberValue))) {
        invalidFilterValue.push(`${field.name}
        ${G.getWindowLocale('validation:should-be-numeric', 'Should be a numeric value')}`);
      }
      return;
    }
    if (R.equals(criteria.dataType, 'date')) {
      const timeUnitCondition = R.and(
        G.isNotNilAndNotEmpty(criteria.timeUnit),
        R.or(
          G.isNotNilAndNotEmpty(criteria.last),
          G.isNotNilAndNotEmpty(criteria.next),
        ),
      );
      if (timeUnitCondition) {
        const isNumber = new RegExp(/^\d+$/);
        const isValid = R.or(
          R.and(G.isNotNilAndNotEmpty(criteria.last), isNumber.test(criteria.last)),
          R.and(G.isNotNilAndNotEmpty(criteria.next), isNumber.test(criteria.next)),
        );
        if (R.not(isValid)) {
          invalidFilterValue.push(`${field.name}
          ${G.getWindowLocale('validation:should-be-numeric', 'Should be a numeric value')}`);
        }
        return;
      }
      const rangeCondition = R.and(
        G.isNotNilAndNotEmpty(criteria.operation),
        R.and(
          G.isNotNilAndNotEmpty(criteria.from),
          G.isNotNilAndNotEmpty(criteria.to),
        ),
      );

      if (rangeCondition && G.isBeforeDate(criteria.to, criteria.from)) {
        invalidFilterValue.push(`${field.name}
        ${G.getWindowLocale('validation:invalid-date-range', 'Invalid date range')}`);
      }
    }
  });
  if (G.isNotEmpty(invalidFilterValue)) {
    const message = invalidFilterValue.reduce((msg: string, error: string, i: number) => (
      `${msg}${G.ifElse(R.equals(i, 0), '', ', ')}${error}`
    ), '');
    G.showToastrMessageSimple('info', message);
    return reject();
  }
  allow(action);
};

const handleSetUsedReportRequest = createLogic({
  type: A.checkReportValid,
  validate: reportValidate,
  process({ action }: Object, dispatch: void, done: void) {
    const { payload } = action;
    if (G.stringContains(payload.newReport.guid, 'draft')) {
      dispatch(A.setUsedReport(payload.newReport));

      G.historyGoBack();
    } else {
      dispatch(openModal(payload.modal));
    }
    done();
  },
});

const handleSetEditDataReportRequest = createLogic({
  type: A.openEditReportModal,
  process({ action }: Object, dispatch: void, done: void) {
    const { payload } = action;
    dispatch(payload.modal(R.head(payload.usedReport)));
    done();
  },
});

export default [
  handleSetUsedReportRequest,
  handleCleanStateActionsLogic,
  handleSetEditDataReportRequest,
];
