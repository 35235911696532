import { createAction } from 'redux-act';
// fleet-profile
export * from './truck';
export * from './driver';
export * from './vendor';
export * from './trailer';
//////////////////////////////////////////////////

// common
export const setVinLookup = createAction('setVinLookup');
export const setInitialState = createAction('setInitialState');
export const getItemListSuccess = createAction('getItemListSuccess');
export const getItemListRequest = createAction('getItemListRequest');
export const removeEntityRequest = createAction('removeEntityRequest');
export const removeEntitySuccess = createAction('removeEntitySuccess');
export const toggleFormGroupTable = createAction('toggleFormGroupTable');
export const getGeneralDetailsRequest = createAction('getGeneralDetailsRequest');
export const getGeneralDetailsSuccess = createAction('getGeneralDetailsSuccess');
export const toggleFormGroupTableDetails = createAction('toggleFormGroupTableDetails');
export const createOrUpdateEntityRequest = createAction('createOrUpdateEntityRequest');
export const createOrUpdateEntitySuccess = createAction('createOrUpdateEntitySuccess');
export const retrieveLatestLocationRequest = createAction('retrieveLatestLocationRequest');
export const updateEntityFromFleetMapSuccess = createAction('updateEntityFromFleetMapSuccess');
// users
export const getGeneralUserListSuccess = createAction('getGeneralUserListSuccess');
export const getFleetDispatchingGroupSuccess = createAction('getFleetDispatchingGroupSuccess');
// configs
export const getBranchConfigsSuccess = createAction('getBranchConfigsSuccess');
export const getFleetProfileConfigsRequest = createAction('getFleetProfileConfigsRequest');
export const getFleetProfileConfigsSuccess = createAction('getFleetProfileConfigsSuccess');
// vendor
export const getFleetVendorListSuccess = createAction('getFleetVendorListSuccess');
// working division
export const changeAssignedToDivisionRequest = createAction('changeAssignedToDivisionRequest');
export const changeAssignedToDivisionSuccess = createAction('changeAssignedToDivisionSuccess');
// assign vendor
export const assignOrUnAssignVendorRequest = createAction('assignOrUnAssignVendorRequest');
export const assignOrUnAssignVendorSuccess = createAction('assignOrUnAssignVendorSuccess');
// accessorial configs
export const getAccessorialConfigListRequest = createAction('getAccessorialConfigListRequest');
export const getAccessorialConfigListSuccess = createAction('getAccessorialConfigListSuccess');
// filters
export const setDocumentsFilter = createAction('setDocumentsFilter');
// report
export const setReports = createAction('setReports');
export const setUsedReport = createAction('setUsedReport');
export const setPagination = createAction('setPagination');
export const setListLoading = createAction('setListLoading');
export const setReportPending = createAction('setReportPending');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const resetListAndPagination = createAction('resetListAndPagination');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const setTableTitleSortValues = createAction('setTableTitleSortValues');
export const getReportItemListRequest = createAction('getReportItemListRequest');
export const getReportItemListSuccess = createAction('getReportItemListSuccess');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const getAvailableReportsRequest = createAction('getAvailableReportsRequest');
