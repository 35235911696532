import * as R from 'ramda';
// forms
import { districtOptions } from '../../forms/forms/accessorial-form/field-settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { ENUMS } from '../../constants/enums';
import {
  idleTimeTypeOptions,
  priorityTypeOptions,
  workOrderStatusOptions,
  getFreightClassOptions,
  ITEM_TEMPERATURE_OPTIONS,
  DEFAULT_WEIGHT_UOM_OPTIONS,
  TEMPERATURE_SENSOR_OPTIONS,
  DEFAULT_DISTANCE_UOM_OPTIONS,
  ITEM_TEMPERATURE_TYPE_OPTIONS,
  fleetServiceIssueStatusOptions,
  getOrderQuoteStatusTypeOptions,
  DEFAULT_TIME_RATE_UNIT_OPTIONS,
  DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
  DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
  DEFAULT_LINE_HAUL_RATE_UNIT_OPTIONS,
  driverOnboardingIntegrationTypeOptions,
  DEFAULT_CUSTOMER_RATE_RATE_UNIT_OPTIONS,
  DEFAULT_TAXABLE_TOTAL_RATE_UNIT_OPTIONS,
  DEFAULT_DEADHEAD_DISTANCE_RATE_UNIT_OPTIONS,
  vendorPayrollAccessorialPayForUnitTypeOptions,
} from '../../helpers/options';
//////////////////////////////////////////////////

export const PIE = 'PIE';
export const BAR = 'BAR';
export const LINE = 'LINE';
export const PIE3D = 'PIE3D';
export const BAR3D = 'BAR3D';

export const TOTAL = 'TOTAL';
export const DRIVER = 'DRIVER';
export const STATUS = 'STATUS';
export const NO_GROUP = 'NO_GROUP';
export const BRANCH = GC.BRANCH_UPPERCASE;
export const FIRST_DATE_DAY = 'FIRST_DATE_DAY';

export const TEL_COUNT = 'TEL_COUNT';
export const CLO_COUNT = 'CLO_COUNT';
export const TEL_MARGIN = 'TEL_MARGIN';
export const CLO_MARGIN = 'CLO_MARGIN';
export const TEL_SPEND = 'TEL_TOTAL_SPEND';
export const CLO_INCOME = 'CLO_TOTAL_INCOME';
export const EVENT_ON_TIME_LATE = 'EVENT_ON_TIME_LATE';

const REPORT_FILTER_DATE_OPERATION_DAYS = 'DAYS';
const REPORT_FILTER_DATE_OPERATION_HOURS = 'HOURS';
const REPORT_FILTER_DATE_OPERATION_WEEKS = 'WEEKS';
const REPORT_FILTER_DATE_OPERATION_MONTHS = 'MONTHS';

export const REPORT_FILTER_DATE_LAST_NEXT_OPERATIONS = [
  REPORT_FILTER_DATE_OPERATION_DAYS,
  REPORT_FILTER_DATE_OPERATION_HOURS,
  REPORT_FILTER_DATE_OPERATION_WEEKS,
  REPORT_FILTER_DATE_OPERATION_MONTHS,
];

export const reportConditions = () => ({
  string: [
    {
      value: 'equal',
      name: G.getWindowLocale('titles:equal', 'Equal'),
    },
    {
      value: 'range',
      name: G.getWindowLocale('titles:range', 'Range'),
    },
    {
      value: 'begin',
      name: G.getWindowLocale('titles:begin', 'Begin'),
    },
    {
      value: 'end',
      name: G.getWindowLocale('titles:end', 'End'),
    },
    {
      value: 'contain',
      name: G.getWindowLocale('titles:contain', 'Contain'),
    },
    {
      value: 'not_equal',
      name: G.getWindowLocale('titles:not-equal', 'Not Equal'),
    },
    {
      value: 'not_begin',
      name: G.getWindowLocale('titles:not-begin', 'Not Begin'),
    },
    {
      value: 'not_end',
      name: G.getWindowLocale('titles:not-end', 'Not End'),
    },
    {
      value: 'not_contain',
      name: G.getWindowLocale('titles:not-contain', 'Not Contain'),
    },
    {
      name: 'In',
      value: 'in',
    },
    {
      name: 'Not In',
      value: 'not_in',
    },
  ],
  'string:array': [
    {
      value: 'contain',
      name: G.getWindowLocale('titles:contain', 'Contain'),
    },
  ],
  number: [
    {
      value: 'equal',
      name: G.getWindowLocale('titles:equal', 'Equal'),
    },
    {
      value: 'range',
      name: G.getWindowLocale('titles:range', 'Range'),
    },
    {
      value: 'more',
      name: G.getWindowLocale('titles:more', 'More'),
    },
    {
      value: 'less',
      name: G.getWindowLocale('titles:less', 'Less'),
    },
    {
      value: 'not_equal',
      name: G.getWindowLocale('titles:not-equal', 'Not Equal'),
    },
    {
      value: 'not_more',
      name: G.getWindowLocale('titles:not-more', 'Not More'),
    },
    {
      value: 'not_less',
      name: G.getWindowLocale('titles:not-less', 'Not Less'),
    },
  ],
  boolean: [
    {
      value: 'equal',
      name: G.getWindowLocale('titles:equal', 'Equal'),
    },
    {
      value: 'not_equal',
      name: G.getWindowLocale('titles:not-equal', 'Not Equal'),
    },
  ],
  selectMultiple: [
    {
      name: 'In',
      value: 'in',
    },
    {
      name: 'Not In',
      value: 'not_in',
    },
  ],
  reference: [
    {
      value: 'equal',
      name: G.getWindowLocale('titles:equal', 'Equal'),
    },
    {
      value: 'begin',
      name: G.getWindowLocale('titles:begin', 'Begin'),
    },
    {
      value: 'end',
      name: G.getWindowLocale('titles:end', 'End'),
    },
    {
      value: 'contain',
      name: G.getWindowLocale('titles:contain', 'Contain'),
    },
    {
      value: 'not_equal',
      name: G.getWindowLocale('titles:not-equal', 'Not Equal'),
    },
    {
      value: 'not_begin',
      name: G.getWindowLocale('titles:not-begin', 'Not Begin'),
    },
    {
      value: 'not_end',
      name: G.getWindowLocale('titles:not-end', 'Not End'),
    },
    {
      value: 'not_contain',
      name: G.getWindowLocale('titles:not-contain', 'Not Contain'),
    },
  ],
  date: [
    {
      value: 'HOURS',
      name: G.getWindowLocale('titles:hours', 'Hours'),
    },
    {
      value: 'DAYS',
      name: G.getWindowLocale('titles:days', 'Days'),
    },
    {
      value: 'WEEKS',
      name: G.getWindowLocale('titles:weeks', 'Weeks'),
    },
    {
      value: 'MONTHS',
      name: G.getWindowLocale('titles:months', 'Months'),
    },
    {
      value: 'date-range',
      name: G.getWindowLocale('titles:date-range', 'Date Range'),
    },
  ],
  default: [],
  'string:select': [
    {
      value: 'equal',
      name: G.getWindowLocale('titles:equal', 'Equal'),
    },
    {
      value: 'not_equal',
      name: G.getWindowLocale('titles:not-equal', 'Not Equal'),
    },
  ],
});

// NOTE: temporary for addition fields on new report
export const newReportConditions = () => ({
  string: [
    {
      value: 'equal',
      name: G.getWindowLocale('titles:equal', 'Equal'),
    },
    {
      value: 'begin',
      name: G.getWindowLocale('titles:begin', 'Begin'),
    },
    {
      value: 'end',
      name: G.getWindowLocale('titles:end', 'End'),
    },
    {
      value: 'contain',
      name: G.getWindowLocale('titles:contain', 'Contain'),
    },
    {
      value: 'not_equal',
      name: G.getWindowLocale('titles:not-equal', 'Not Equal'),
    },
    {
      value: 'not_begin',
      name: G.getWindowLocale('titles:not-begin', 'Not Begin'),
    },
    {
      value: 'not_end',
      name: G.getWindowLocale('titles:not-end', 'Not End'),
    },
    {
      value: 'not_contain',
      name: G.getWindowLocale('titles:not-contain', 'Not Contain'),
    },
    {
      name: 'In',
      value: 'in',
    },
    {
      name: 'Not In',
      value: 'not_in',
    },
    {
      value: 'range',
      name: G.getWindowLocale('titles:range', 'Range'),
    },
  ],
  'string:array': [
    {
      value: 'contain',
      name: G.getWindowLocale('titles:contain', 'Contain'),
    },
  ],
  number: [
    {
      value: 'equal',
      name: G.getWindowLocale('titles:equal', 'Equal'),
    },
    {
      value: 'more',
      name: G.getWindowLocale('titles:more', 'More'),
    },
    {
      value: 'less',
      name: G.getWindowLocale('titles:less', 'Less'),
    },
    {
      value: 'not_equal',
      name: G.getWindowLocale('titles:not-equal', 'Not Equal'),
    },
    {
      value: 'not_more',
      name: G.getWindowLocale('titles:not-more', 'Not More'),
    },
    {
      value: 'not_less',
      name: G.getWindowLocale('titles:not-less', 'Not Less'),
    },
    {
      value: 'range',
      name: G.getWindowLocale('titles:range', 'Range'),
    },
  ],
  'string:select': [
    {
      value: 'equal',
      name: G.getWindowLocale('titles:equal', 'Equal'),
    },
    {
      value: 'not_equal',
      name: G.getWindowLocale('titles:not-equal', 'Not Equal'),
    },
  ],
  boolean: [
    {
      value: 'equal',
      name: G.getWindowLocale('titles:equal', 'Equal'),
    },
    {
      value: 'not_equal',
      name: G.getWindowLocale('titles:not-equal', 'Not Equal'),
    },
  ],
  selectMultiple: [
    {
      name: 'In',
      value: 'in',
    },
    {
      name: 'Not In',
      value: 'not_in',
    },
  ],
  reference: [
    {
      value: 'equal',
      name: G.getWindowLocale('titles:equal', 'Equal'),
    },
    {
      value: 'begin',
      name: G.getWindowLocale('titles:begin', 'Begin'),
    },
    {
      value: 'end',
      name: G.getWindowLocale('titles:end', 'End'),
    },
    {
      value: 'contain',
      name: G.getWindowLocale('titles:contain', 'Contain'),
    },
    {
      value: 'not_equal',
      name: G.getWindowLocale('titles:not-equal', 'Not Equal'),
    },
    {
      value: 'not_begin',
      name: G.getWindowLocale('titles:not-begin', 'Not Begin'),
    },
    {
      value: 'not_end',
      name: G.getWindowLocale('titles:not-end', 'Not End'),
    },
    {
      value: 'not_contain',
      name: G.getWindowLocale('titles:not-contain', 'Not Contain'),
    },
  ],
  date: [
    {
      value: 'HOURS',
      name: G.getWindowLocale('titles:hours', 'Hours'),
    },
    {
      value: 'DAYS',
      name: G.getWindowLocale('titles:days', 'Days'),
    },
    {
      value: 'WEEKS',
      name: G.getWindowLocale('titles:weeks', 'Weeks'),
    },
    {
      value: 'MONTHS',
      name: G.getWindowLocale('titles:months', 'Months'),
    },
    {
      value: 'date-range',
      name: G.getWindowLocale('titles:date-range', 'Date Range'),
    },
  ],
  default: [],
});

export const reportBooleanValues = () => ([
  {
    value: '',
    disabled: 'disabled',
    name: G.getWindowLocale('titles:choose-value', 'Choose a value'),
  },
  {
    value: true,
    name: G.getWindowLocale('titles:yes', 'Yes'),
  },
  {
    value: false,
    name: G.getWindowLocale('titles:no', 'No'),
  },
]);

export const getEventTypeOptions = () => ([
  {
    value: GC.EVENT_TYPE_DROP,
    label: G.getWindowLocale('titles:drop', 'Drop'),
  },
  {
    value: GC.EVENT_TYPE_PICKUP,
    label: G.getWindowLocale('titles:pickup', 'Pickup'),
  },
]);

export const getStopStatusOptions = () => ([
  {
    value: GC.STOP_STATUS_TYPE_PENDING,
    label: G.getWindowLocale('titles:pending', 'Pending'),
  },
  {
    value: GC.STOP_STATUS_TYPE_ARRIVED,
    label: G.getWindowLocale('titles:arrived', 'Arrived'),
  },
  {
    value: GC.STOP_STATUS_TYPE_COMPLETED,
    label: G.getWindowLocale('titles:completed', 'Completed'),
  },
  {
    value: GC.STOP_STATUS_TYPE_CHECKED_IN,
    label: G.getWindowLocale('titles:checked-in', 'Checked In'),
  },
]);

export const getLoadStatusOptions = () => ([
  {
    value: GC.LOAD_STATUS_DELIVERED,
    label: G.getWindowLocale('titles:load-status-delivered', 'Delivered'),
  },
  {
    value: GC.LOAD_STATUS_IN_TRANSIT,
    label: G.getWindowLocale('titles:load-status-in-transit', 'In Transit'),
  },
  {
    value: GC.LOAD_STATUS_PLANNED,
    label: G.getWindowLocale('titles:load-status-planned', 'Planned'),
  },
  {
    value: GC.LOAD_STATUS_RATED,
    label: G.getWindowLocale('titles:load-status-rated', 'Rated'),
  },
  {
    value: GC.LOAD_STATUS_BOOKED_STATUS,
    label: G.getWindowLocale('titles:load-status-booked', 'Booked'),
  },
  {
    value: GC.LOAD_STATUS_DISPATCH_REJECTED,
    label: G.getWindowLocale('titles:dispatch-rejected', 'Dispatch Rejected'),
  },
  {
    value: GC.LOAD_STATUS_UNSCHEDULED,
    label: G.getWindowLocale('titles:load-status-unscheduled', 'Unscheduled'),
  },
  {
    value: GC.LOAD_STATUS_DISPATCHED,
    label: G.getWindowLocale('titles:dispatched', 'Dispatched'),
  },
  {
    value: GC.LOAD_STATUS_DISPATCH_EXPIRED,
    label: G.getWindowLocale('titles:dispatch-expired', 'Dispatch Expired'),
  },
  {
    value: GC.LOAD_STATUS_QUOTE,
    label: G.getWindowLocale('titles:quote', 'Quote'),
  },
  {
    value: GC.LOAD_STATUS_QUOTED,
    label: G.getWindowLocale('titles:load-status-quoted', 'Quoted'),
  },
  {
    value: GC.LOAD_STATUS_CANCELED,
    label: G.getWindowLocale('titles:canceled', 'Canceled'),
  },
]);

export const getOrderStatusOptions = () => ([
  {
    value: GC.LOAD_STATUS_DELIVERED,
    label: G.getWindowLocale('titles:load-status-delivered', 'Delivered'),
  },
  {
    value: GC.LOAD_STATUS_IN_TRANSIT,
    label: G.getWindowLocale('titles:load-status-in-transit', 'In Transit'),
  },
  {
    value: GC.LOAD_STATUS_PLANNED,
    label: G.getWindowLocale('titles:load-status-planned', 'Planned'),
  },
  {
    value: GC.LOAD_STATUS_BOOKED_STATUS,
    label: G.getWindowLocale('titles:load-status-booked', 'Booked'),
  },
  {
    value: GC.LOAD_STATUS_UNSCHEDULED,
    label: G.getWindowLocale('titles:load-status-unscheduled', 'Unscheduled'),
  },
  {
    value: GC.LOAD_STATUS_QUOTE,
    label: G.getWindowLocale('titles:quote', 'Quote'),
  },
  {
    value: GC.LOAD_STATUS_CANCELED,
    label: G.getWindowLocale('titles:canceled', 'Canceled'),
  },
]);

export const getTripStatusOptions = () => ([
  {
    value: GC.LOAD_STATUS_DELIVERED,
    label: G.getWindowLocale('titles:load-status-delivered', 'Delivered'),
  },
  {
    value: GC.LOAD_STATUS_IN_TRANSIT,
    label: G.getWindowLocale('titles:load-status-in-transit', 'In Transit'),
  },
  {
    value: GC.LOAD_STATUS_PLANNED,
    label: G.getWindowLocale('titles:load-status-planned', 'Planned'),
  },
  {
    value: GC.LOAD_STATUS_RATED,
    label: G.getWindowLocale('titles:load-status-rated', 'Rated'),
  },
  {
    value: GC.LOAD_STATUS_BOOKED_STATUS,
    label: G.getWindowLocale('titles:load-status-booked', 'Booked'),
  },
  {
    value: GC.LOAD_STATUS_DISPATCH_REJECTED,
    label: G.getWindowLocale('titles:dispatch-rejected', 'Dispatch Rejected'),
  },
  {
    value: GC.LOAD_STATUS_DISPATCHED,
    label: G.getWindowLocale('titles:dispatched', 'Dispatched'),
  },
  {
    value: GC.LOAD_STATUS_DISPATCH_EXPIRED,
    label: G.getWindowLocale('titles:dispatch-expired', 'Dispatch Expired'),
  },
  {
    value: GC.LOAD_STATUS_CANCELED,
    label: G.getWindowLocale('titles:canceled', 'Canceled'),
  },
]);

export const getTelRateOptions = () => ([
  {
    value: GC.RATE_STATUS_DRAFT,
    label: G.getWindowLocale('titles:draft', 'Draft'),
  },
  {
    value: GC.RATE_STATUS_QUOTED,
    label: G.getWindowLocale('titles:quoted', 'Quoted'),
  },
  {
    value: GC.RATE_STATUS_PENDING,
    label: G.getWindowLocale('titles:pending', 'Pending'),
  },
  {
    value: GC.RATE_STATUS_EXPIRED,
    label: G.getWindowLocale('titles:expired', 'Expired'),
  },
  {
    value: GC.RATE_STATUS_DECLINED,
    label: G.getWindowLocale('titles:declined', 'Declined'),
  },
  {
    value: GC.RATE_STATUS_ACCEPTED,
    label: G.getWindowLocale('titles:accepted', 'Accepted'),
  },
  {
    value: GC.RATE_STATUS_CANCELED,
    label: G.getWindowLocale('titles:canceled', 'Canceled'),
  },
]);

export const getDriverTypeOptions = () => ([
  {
    value: GC.DRIVER_TYPE_EMPLOYEE,
    label: G.getWindowLocale('titles:employee', 'Employee'),
  },
  {
    value: GC.DRIVER_TYPE_CONTRACTOR,
    label: G.getWindowLocale('titles:contractor', 'Contractor'),
  },
  {
    value: GC.DRIVER_TYPE_OWNER_OPERATOR,
    label: G.getWindowLocale('titles:owner-operator', 'Owner Operator'),
  },
]);

export const getTemperatureUomOptions = () => R.tail(ITEM_TEMPERATURE_OPTIONS);

export const getTemperatureTypeOptions = () => R.tail(ITEM_TEMPERATURE_TYPE_OPTIONS);

export const getTemperatureSensorOptions = () => R.tail(TEMPERATURE_SENSOR_OPTIONS);

export const getRouteGroupByOptions = () => ([
  {
    value: DRIVER,
    label: G.getWindowLocale('titles:driver', 'Driver'),
  },
  {
    value: STATUS,
    label: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    value: BRANCH,
    label: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    value: NO_GROUP,
    label: G.getWindowLocale('titles:no-group', 'No Group'),
  },
  {
    value: FIRST_DATE_DAY,
    label: G.getWindowLocale('titles:date-day', 'Date Day'),
  },
]);

export const getChartTypeOptions = () => ([
  {
    value: PIE,
    label: G.getWindowLocale('titles:pie', 'Pie'),
  },
  {
    value: BAR,
    label: G.getWindowLocale('titles:bar', 'Bar'),
  },
  {
    value: LINE,
    label: G.getWindowLocale('titles:line', 'Line'),
  },
  {
    value: PIE3D,
    label: G.getWindowLocale('titles:pie-3d', 'Pie 3D'),
  },
  {
    value: TOTAL,
    label: G.getWindowLocale('titles:total', 'Total'),
  },
  {
    value: BAR3D,
    label: G.getWindowLocale('titles:bar-3d', 'Bar 3D'),
  },
]);

export const getChartDataTypeOptions = () => ([
  {
    value: TEL_COUNT,
    label: G.getWindowLocale('titles:tel-count', 'TEL Count'),
  },
  {
    value: CLO_COUNT,
    label: G.getWindowLocale('titles:clo-count', 'CLO Count'),
  },
  {
    value: TEL_MARGIN,
    label: G.getWindowLocale('titles:tel-margin', 'TEL Margin'),
  },
  {
    value: CLO_MARGIN,
    label: G.getWindowLocale('titles:clo-margin', 'CLO Margin'),
  },
  {
    value: TEL_SPEND,
    label: G.getWindowLocale('titles:tel-total-spend', 'TEL Total Spend'),
  },
  {
    value: CLO_INCOME,
    label: G.getWindowLocale('titles:clo-total-income', 'CLO Total Income'),
  },
  {
    value: EVENT_ON_TIME_LATE,
    label: G.getWindowLocale('titles:event-on-time-late', 'Event On Time Late'),
  },
]);

const getChargeTypeOptions = () => [
  {
    value: GC.CHARGE_TYPE_MAIN,
    label: G.getWindowLocale('titles:line-haul', 'Line Haul'),
  },
  {
    value: GC.CHARGE_TYPE_DISCOUNT,
    label: G.getWindowLocale('titles:discount', 'Discount'),
  },
  {
    value: GC.CHARGE_TYPE_FUEL,
    label: G.getWindowLocale('titles:fuel', 'Fuel'),
  },
  {
    value: GC.CHARGE_TYPE_ADDITIONAL,
    label: G.getWindowLocale('titles:additional', 'Additional'),
  },
];

const getRateTypeOptions = () => [
  {
    value: GC.CHARGE_RATE_TYPE_FLAT,
    label: G.getWindowLocale('titles:flat', 'Flat'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_DISTANCE,
    label: G.getWindowLocale('titles:distance', 'Distance'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_WEIGHT,
    label: G.getWindowLocale('titles:weight', 'Weight'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_VOLUME,
    label: G.getWindowLocale('titles:volume', 'Volume'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_QUANTITY,
    label: G.getWindowLocale('titles:quantity', 'Quantity'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_TIME,
    label: G.getWindowLocale('titles:time', 'Time'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_CUSTOMER_RATE,
    label: G.getWindowLocale('titles:customer-rate', 'Customer Rate'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL,
    label: G.getWindowLocale('titles:customer-line-haul', 'Customer Line Haul'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_DISCOUNT,
    label: G.getWindowLocale('titles:discount', 'Discount'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_DEADHEAD_DISTANCE,
    label: G.getWindowLocale('titles:deadhead', 'Deadhead'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_LINE_HAUL,
    label: G.getWindowLocale('titles:line-haul', 'Line Haul'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_STOP,
    label: G.getWindowLocale('titles:stop', 'Stop'),
  },
  {
    value: GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL,
    label: G.getWindowLocale('titles:taxable-total', 'Taxable Total'),
  },
];

const getRateUnitOptions = () => [
  ...DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
  ...R.tail(DEFAULT_TIME_RATE_UNIT_OPTIONS),
  ...DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  ...R.tail(DEFAULT_WEIGHT_RATE_UNIT_OPTIONS),
  ...R.tail(DEFAULT_DISTANCE_RATE_UNIT_OPTIONS),
  ...R.tail(DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS),
  ...R.tail(DEFAULT_LINE_HAUL_RATE_UNIT_OPTIONS),
  ...R.tail(DEFAULT_TAXABLE_TOTAL_RATE_UNIT_OPTIONS),
  ...R.tail(DEFAULT_CUSTOMER_RATE_RATE_UNIT_OPTIONS),
  ...R.tail(DEFAULT_DEADHEAD_DISTANCE_RATE_UNIT_OPTIONS),
];

const getChargeRateTypeOptions = () => [
  {
    value: GC.RATE_CHARGE_TYPE_CARRIER,
    label: G.getWindowLocale('titles:carrier', 'Carrier'),
  },
  {
    value: GC.RATE_CHARGE_TYPE_FLEET_VENDOR,
    label: G.getWindowLocale('titles:vendor', 'Vendor'),
  },
  {
    value: GC.RATE_CHARGE_TYPE_PRIMARY_DRIVER,
    label: G.getWindowLocale('titles:primary-driver', 'Primary Driver'),
  },
  {
    value: GC.RATE_CHARGE_TYPE_SECONDARY_DRIVER,
    label: G.getWindowLocale('titles:team-driver', 'Team Driver'),
  },
];

const getInvoiceEdiStatusOptions = () => [
  {
    value: GC.INVOICE_EDI_STATUS_ERROR,
    label: G.getEnumLocale(GC.INVOICE_EDI_STATUS_ERROR),
  },
  {
    value: GC.INVOICE_EDI_STATUS_NO_MATCH,
    label: G.getEnumLocale(GC.INVOICE_EDI_STATUS_NO_MATCH),
  },
  {
    value: GC.INVOICE_EDI_STATUS_PROCESSED,
    label: G.getEnumLocale(GC.INVOICE_EDI_STATUS_PROCESSED),
  },
  {
    value: GC.INVOICE_EDI_STATUS_TRANSACTION_UNASSIGNED,
    label: G.getEnumLocale(GC.INVOICE_EDI_STATUS_TRANSACTION_UNASSIGNED),
  },
];

const getEdiTransactionTypeOptions = () => [
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_INVOICE,
    label: G.getEnumLocale(GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_INVOICE),
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_STATUS_MESSAGE,
    label: G.getEnumLocale(GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_STATUS_MESSAGE),
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ACKNOWLEDGEMENT_RESPONSE,
    label: G.getEnumLocale(GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ACKNOWLEDGEMENT_RESPONSE),
  },
];

const getItemTypeOptions = () => [
  { value: GC.ITEM_TYPE_GENERAL, label: G.getWindowLocale('titles:general', 'General') },
  { value: GC.ITEM_TYPE_VEHICLE, label: G.getWindowLocale('titles:vehicle', 'Vehicle') },
];

const getRateSourceOptions = () => [
  {
    value: GC.TEL_SOURCE_TYPE_MANUALLY,
    label: G.getEnumLocale(GC.TEL_SOURCE_TYPE_MANUALLY),
  },
  {
    value: GC.TEL_SOURCE_TYPE_LOAD_BOARD,
    label: G.getEnumLocale(GC.TEL_SOURCE_TYPE_LOAD_BOARD),
  },
  {
    value: GC.TEL_SOURCE_TYPE_EMAIL_QUOTE,
    label: G.getEnumLocale(GC.TEL_SOURCE_TYPE_EMAIL_QUOTE),
  },
  {
    value: GC.TEL_SOURCE_TYPE_LOAD_BOARD_QUOTE,
    label: G.getEnumLocale(GC.TEL_SOURCE_TYPE_LOAD_BOARD_QUOTE),
  },
];

const getDimensionsUomOptions = () => [
  { label: G.getWindowLocale('titles:feet', 'Feet'), value: GC.UOM_FOOT },
  { label: G.getWindowLocale('titles:inches', 'Inches'), value: GC.UOM_INCH },
  { label: G.getWindowLocale('titles:meters', 'Meters'), value: GC.UOM_METER },
  { label: G.getWindowLocale('titles:centimeters', 'Centimeters'), value: GC.UOM_CENTIMETER },
];

const getOdometerUomOptions = () => [
  { value: GC.UOM_MILE, label: G.getWindowLocale('titles:mile', 'Mile') },
  { value: GC.UOM_METER, label: G.getWindowLocale('titles:meter', 'Meter') },
  { value: GC.UOM_KILOMETER, label: G.getWindowLocale('titles:kilometer', 'Kilometer') },
];

const getLoadBoardShipmentsStatusOptions = () => [
  {
    value: GC.LOAD_BOARD_SHIPMENT_STATUS_POSTED,
    label: G.getEnumLocale(GC.LOAD_BOARD_SHIPMENT_STATUS_POSTED),
  },
  {
    value: GC.LOAD_BOARD_SHIPMENT_STATUS_REMOVED,
    label: G.getEnumLocale(GC.LOAD_BOARD_SHIPMENT_STATUS_REMOVED),
  },
  {
    value: GC.LOAD_BOARD_SHIPMENT_STATUS_COVERED,
    label: G.getEnumLocale(GC.LOAD_BOARD_SHIPMENT_STATUS_COVERED),
  },
];

const getLoadBoardShipmentsSourceOptions = () => [
  {
    value: GC.EXTERNAL_LOAD_BOARD_DAT,
    label: G.getEnumLocale(GC.EXTERNAL_LOAD_BOARD_DAT),
  },
  {
    value: GC.EXTERNAL_LOAD_BOARD_TRUCK_STOP,
    label: G.getEnumLocale(GC.EXTERNAL_LOAD_BOARD_TRUCK_STOP),
  },
  {
    value: GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS,
    label: G.getEnumLocale(GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS),
  },
  {
    value: GC.EXTERNAL_LOAD_BOARD_PARADE,
    label: G.getEnumLocale(GC.EXTERNAL_LOAD_BOARD_PARADE),
  },
  {
    value: GC.EXTERNAL_LOAD_BOARD_NEWTRUL,
    label: G.getEnumLocale(GC.EXTERNAL_LOAD_BOARD_NEWTRUL),
  },
];

const getIntervalUomOptions = () => [
  { value: GC.UOM_DAYS, label: G.getUomLocale(GC.UOM_DAYS) },
  { value: GC.UOM_HOURS, label: G.getUomLocale(GC.UOM_HOURS) },
  { value: GC.UOM_MILES, label: G.getUomLocale(GC.UOM_MILES) },
  { value: GC.UOM_KILOMETERS, label: G.getUomLocale(GC.UOM_KILOMETERS) },
];

const getPerformedTypeOptions = () => [
  { value: GC.UOM_HOURS, label: G.getUomLocale(GC.UOM_HOURS) },
  { value: GC.UOM_MILES, label: G.getUomLocale(GC.UOM_MILES) },
  { value: GC.UOM_KILOMETERS, label: G.getUomLocale(GC.UOM_KILOMETERS) },
];

const getResponseStatusOptions = () => [
  {
    value: GC.STATUS_SUCCESS,
    label: G.getWindowLocale('titles:success', 'Success'),
  },
  {
    value: GC.STATUS_PARTIALLY_SUCCESS,
    label: G.getEnumLocale(GC.STATUS_PARTIALLY_SUCCESS),
  },
  {
    value: GC.STATUS_FAILED,
    label: G.getWindowLocale('titles:failed', 'Failed'),
  },
  {
    value: GC.STATUS_PENDING,
    label: G.getWindowLocale('enum:pending', 'Pending'),
  },
  {
    value: GC.STATUS_IN_PROGRESS,
    label: G.getWindowLocale('titles:in-progress', 'In Progress'),
  },
  {
    value: GC.STATUS_PARTIAL_SUCCESS,
    label: G.getWindowLocale('titles:partial-success', 'Partial Success'),
  },
  {
    value: GC.STATUS_ERROR,
    label: G.getWindowLocale('titles:error', 'Error'),
  },
];

const getImportTypeOptions = () => [
  {
    value: 'DRIVER',
    label: G.getWindowLocale('titles:driver', 'Driver'),
  },
  {
    value: 'TRUCK',
    label: G.getWindowLocale('titles:truck', 'Truck'),
  },
  {
    value: 'TRAILER',
    label: G.getWindowLocale('titles:trailer', 'Trailer'),
  },
  {
    value: 'TOLL_CHARGE',
    label: G.getWindowLocale('titles:toll-charge', 'Toll Charge'),
  },
  {
    value: 'FUEL_CARD_TRANSACTION',
    label: G.getWindowLocale('titles:fuel-transactions', 'Fuel Transactions'),
  },
  {
    value: 'LOCATION',
    label: G.getWindowLocale('titles:location', 'Location'),
  },
  {
    value: 'CONTACT_BOOK',
    label: G.getWindowLocale('titles:contact', 'Contact'),
  },
  {
    value: 'ENTERPRISE',
    label: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    value: 'CARRIER',
    label: G.getWindowLocale('titles:carrier', 'Carrier'),
  },
  {
    value: 'CARRIER_INVOICE',
    label: G.getWindowLocale('titles:carrier-invoice', 'Carrier Invoice'),
  },
  {
    value: 'CARRIER_INSURANCE',
    label: G.getWindowLocale('titles:carrier-insurance', 'Carrier Insurance'),
  },
  {
    value: 'CARRIER_PAY_TO_LOCATION',
    label: G.getWindowLocale('titles:carrier-pay-to-location', 'Carrier Pay To Location'),
  },
  {
    value: 'GEOFENCING_ZONE',
    label: G.getWindowLocale('titles:geo-fencing-zone', 'Geo Fencing Zone'),
  },
  {
    value: 'CLO',
    label: G.getWindowLocale('titles:clo', 'CLO'),
  },
  {
    value: 'CARRIER_RATE_PRICE',
    label: G.getWindowLocale('titles:carrier-rate-price', 'Carrier Rate Price'),
  },
];

const getWeightUomOptions = () => [
  { label: 'Pounds', value: GC.UOM_POUND },
  { label: 'Kilograms', value: GC.UOM_KILOGRAM },
  { label: 'Short Tons', value: GC.UOM_TON_US },
  { label: 'Metric Tons', value: GC.UOM_METRIC_TON },
];

const getItemTemperatureTypeOptions = () => [
  { label: G.getWindowLocale('titles:hot', 'Hot'), value: GC.ITEM_TEMPERATURE_TYPE_HOT },
  { label: G.getWindowLocale('titles:dry', 'Dry'), value: GC.ITEM_TEMPERATURE_TYPE_DRY },
  { label: G.getWindowLocale('titles:frozen', 'Frozen'), value: GC.ITEM_TEMPERATURE_TYPE_FROZEN },
  { label: G.getWindowLocale('titles:chilled', 'Chilled'), value: GC.ITEM_TEMPERATURE_TYPE_CHILLED },
];

const getItemVolumeOptions = () => [
  { label: 'Cubic Foot', value: GC.UOM_CUBIC_FT },
  { label: 'Cubic Metre', value: GC.UOM_CUBIC_M },
  { label: 'Gallon Liquid', value: GC.UOM_GALLON_LIQUID },
];

const getCurrencyTypeOptions2 = () => R.tail(GC.CURRENCY_OPTIONS_2);

const getServiceVendorTypeOptions = () => [
  {
    value: GC.SERVICE_VENDOR_TYPE_GENERAL,
    label: G.getEnumLocale(GC.SERVICE_VENDOR_TYPE_GENERAL),
  },
  {
    value: GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE,
    label: G.getEnumLocale(GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE),
  },
  {
    value: GC.SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE,
    label: G.getEnumLocale(GC.SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE),
  },
];

export const getUserRoleTypeOptions = () => [
  {
    value: GC.USER_ROLE_TYPE_DRIVER,
    label: G.getEnumLocale(GC.USER_ROLE_TYPE_DRIVER),
  },
  {
    value: GC.USER_ROLE_TYPE_GENERAL,
    label: G.getEnumLocale(GC.USER_ROLE_TYPE_GENERAL),
  },
  {
    value: GC.USER_ROLE_TYPE_CARRIER,
    label: G.getEnumLocale(GC.USER_ROLE_TYPE_CARRIER),
  },
  {
    value: GC.USER_ROLE_TYPE_CUSTOMER,
    label: G.getEnumLocale(GC.USER_ROLE_TYPE_CUSTOMER),
  },
];

export const getBranchTypeOptions = () => [
  {
    value: GC.BRANCH_TYPE_ENUM_CUSTOMER,
    label: G.getEnumLocale(GC.BRANCH_TYPE_ENUM_CUSTOMER),
  },
  {
    value: GC.BRANCH_TYPE_ENUM_DIVISION,
    label: G.getEnumLocale(GC.BRANCH_TYPE_ENUM_DIVISION),
  },
  {
    value: GC.BRANCH_TYPE_ENUM_MASTER,
    label: G.getEnumLocale(GC.BRANCH_TYPE_ENUM_MASTER),
  },
];

export const getLoadSubStatusOptions = () => [
  {
    value: ENUMS.ENUM_ARRIVED_AT_PICKUP,
    label: G.getEnumLocale(ENUMS.ENUM_ARRIVED_AT_PICKUP),
  },
];

export const getInspectionStatusOptions = () => [
  {
    value: GC.STATUS_IN_PROGRESS,
    label: G.getWindowLocale('titles:in-progress', 'In Progress'),
  },
  {
    value: GC.STATUS_COMPLETED,
    label: G.getWindowLocale('titles:completed', 'Completed'),
  },
  {
    value: GC.STATUS_INACTIVE,
    label: G.getWindowLocale('titles:inactive', 'Inactive'),
  },
];

export const getInspectionSourceOptions = () => [
  {
    value: GC.SOURCE_TYPE_MOBILE_APP,
    label: G.getWindowLocale('titles:mobile-app', 'Mobile App'),
  },
  {
    value: GC.SOURCE_TYPE_CARRIER_MOBILE_APP,
    label: G.getWindowLocale('titles:carrier-mobile-app', 'Carrier Mobile App'),
  },
  {
    value: GC.SOURCE_TYPE_WEB_APP,
    label: G.getWindowLocale('titles:web-app', 'Web App'),
  },
];

export const getTruckingMethodOptions = () => [
  {
    value: GC.TRUCKING_METHOD_DROP,
    label: G.getWindowLocale('titles:drop', 'Drop'),
  },
  {
    value: GC.TRUCKING_METHOD_HOOK,
    label: G.getWindowLocale('titles:hook', 'Hook'),
  },
  {
    value: GC.TRUCKING_METHOD_LIFE_LOAD,
    label: G.getWindowLocale('titles:life-load', 'Life Load'),
  },
  {
    value: GC.TRUCKING_METHOD_LIFE_UNLOAD,
    label: G.getWindowLocale('titles:life-unload', 'life-unload'),
  },
];

export const getInspectionTypeOptions = () => [
  {
    value: ENUMS.ENUM_INSPECTION_TYPE_GENERAL,
    label: G.getWindowLocale('titles:inspection-type-general', 'General'),
  },
  {
    value: ENUMS.ENUM_INSPECTION_TYPE_PRE_TRIP,
    label: G.getWindowLocale('titles:inspection-type-pre-trip', 'Pre-Trip'),
  },
  {
    value: ENUMS.ENUM_INSPECTION_TYPE_POST_TRIP,
    label: G.getWindowLocale('titles:inspection-type-post-trip', 'Post-Trip'),
  },
];

// NOTE: used on report filters options
// TODO: need better approach to transform
export const fieldNameToTransform = {
  status: (reportType: string) => {
    if (R.equals(reportType, GC.INVOICE_EDI_REPORT)) {
      return getInvoiceEdiStatusOptions();
    } else if (R.equals(reportType, GC.FLEET_SERVICE_ISSUE_REPORT)) {
      return fleetServiceIssueStatusOptions;
    } else if (R.includes('FLEET', reportType)) {
      return getResponseStatusOptions();
    } else if (R.includes(reportType, [GC.IMPORT_REPORT_REPORT, GC.EXCEL_RATING_RESULT_REPORT])) {
      return getResponseStatusOptions();
    } else if (R.equals(reportType, GC.CLO_EVENT_REPORT)) {
      return getStopStatusOptions();
    } else if (R.equals(reportType, GC.ORDER_QUOTE_REPORT)) {
      return getOrderQuoteStatusTypeOptions();
    } else if (R.equals(reportType, GC.INSPECTION_REPORT)) {
      return getInspectionStatusOptions();
    } else if (R.equals(reportType, GC.WORK_ORDER_REPORT)) {
      return workOrderStatusOptions;
    }

    return getLoadStatusOptions();
  },
  chartType: () => getChartTypeOptions(),
  telRateStatus: () => getTelRateOptions(),
  driverType: () => getDriverTypeOptions(),
  dataType: () => getChartDataTypeOptions(),
  groupByType: () => getRouteGroupByOptions(),
  chargeType: () => getChargeRateTypeOptions(),
  [GC.FIELD_RATE_UNIT]: () => getRateUnitOptions(),
  [GC.FIELD_RATE_TYPE]: () => getRateTypeOptions(),
  [GC.FIELD_TRUCKING_METHOD]: getTripStatusOptions(),
  [GC.FIELD_CURRENCY]: () => getCurrencyTypeOptions2(),
  [GC.FIELD_USER_TYPE]: () => getUserRoleTypeOptions(),
  [GC.FIELD_SOURCE]: () => getInspectionSourceOptions(),
  [GC.FIELD_PRIORITY]: () => R.tail(priorityTypeOptions),
  [GC.FIELD_SUB_STATUS]: () => getLoadSubStatusOptions(),
  [GC.GRC.WORK_ORDER_STATUS]: () => workOrderStatusOptions,
  [GC.FIELD_BRANCH_BRANCH_TYPE]: () => getBranchTypeOptions(),
  [GC.GRC.FLEET_SERVICE_ISSUES_PRIORITY]: () => priorityTypeOptions,
  [GC.GRC.ITEMS_TEMPERATURE_TYPE]: () => getTemperatureTypeOptions(),
  [GC.GRC.ITEMS_TEMPERATURE_SENSOR]: () => getTemperatureSensorOptions(),
  [GC.GRC.FLEET_SERVICE_ISSUES_STATUS]: () => fleetServiceIssueStatusOptions,
  [GC.GRC.EVENTS_ITEMS_TEMPERATURE_SENSOR]: () => getTemperatureSensorOptions(),
  [GC.GRC.FLEET_ASSIGNMENT_SEC_DRIVER_DRIVER_TYPE]: () => getDriverTypeOptions(),
  [GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_DRIVER_TYPE]: () => getDriverTypeOptions(),
  [GC.GRC.RATE_MODE_DROPDOWN_OPTION_GUID]: () => G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    G.getAmousConfigFromWindow(),
    GC.GENERAL_MODE_TRANSPORTATION,
  ),
  [GC.GRC.SERVICES_DROPDOWN_OPTION_GUID]: () => G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    G.getAmousConfigFromWindow(),
    GC.GENERAL_SERVICES,
  ),
  [GC.FIELD_CONTAINER_TYPE_GUID]: () => G.mapNameGuidToLabelValue(G.getAmousContainerTypeListFromWindow()),
  [GC.FIELD_TYPE]: (reportType: string) => {
    if (R.includes(reportType, [GC.CLO_RATE_CHARGE_REPORT, GC.TEL_RATE_CHARGE_REPORT])) {
      return getChargeTypeOptions();
    } else if (R.equals(reportType, GC.IMPORT_REPORT_REPORT)) {
      return getImportTypeOptions();
    } else if (R.equals(reportType, GC.SERVICE_VENDOR_REPORT)) {
      return getServiceVendorTypeOptions();
    } else if (R.equals(reportType, GC.INSPECTION_REPORT)) {
      return getInspectionTypeOptions();
    }

    return [];
  },
  [GC.FIELD_IDLE_TIME]: () => idleTimeTypeOptions,
  [GC.GRC.CLO_STATUS]: () => getLoadStatusOptions(),
  [GC.GRC.TEL_STATUS]: () => getLoadStatusOptions(),
  [GC.FIELD_EVENT_TYPE]: () => getEventTypeOptions(),
  [GC.GRC.CLOS_STATUS]: () => getLoadStatusOptions(),
  [GC.GRC.TELS_STATUS]: () => getLoadStatusOptions(),
  [GC.GRC.RATE_SOURCE]: () => getRateSourceOptions(),
  [GC.FIELD_PACKAGE_TYPE]: () => getRateUnitOptions(),
  [GC.GRC.ITEMS_ITEM_TYPE]: () => getItemTypeOptions(),
  [GC.FIELD_DRIVER_TYPE]: () => getDriverTypeOptions(),
  [GC.FIELD_INTERVAL_UOM]: () => getIntervalUomOptions(),
  [GC.FIELD_ODOMETER_UOM]: () => getOdometerUomOptions(),
  [GC.FIELD_ITEM_WEIGHT_TYPE]: () => getWeightUomOptions(),
  [GC.FIELD_ITEM_VOLUME_UOM]: () => getItemVolumeOptions(),
  [GC.FIELD_WHEELBASE_UOM]: () => getDimensionsUomOptions(),
  [GC.GRC.ITEMS_ODOMETER_UOM]: () => getOdometerUomOptions(),
  [GC.FIELD_PERFORMED_TYPE]: () => getPerformedTypeOptions(),
  [GC.GRC.EVENTS_ITEMS_ITEM_TYPE]: () => getItemTypeOptions(),
  [GC.GRC.ITEMS_WHEELBASE_UOM]: () => getDimensionsUomOptions(),
  [GC.FIELD_ITEM_FREIGHT_CLASS]: () => getFreightClassOptions(),
  [GC.FIELD_ACCESSORIAL_DISTRICT]: () => R.tail(districtOptions),
  [GC.FIELD_WEIGHT_UOM]: () => R.tail(DEFAULT_WEIGHT_UOM_OPTIONS),
  [GC.FIELD_ITEM_DIMENSIONS_UOM]: () => getDimensionsUomOptions(),
  [GC.GRC.CLOS_ITEMS_FREIGHT_CLASS]: () => getFreightClassOptions(),
  [GC.GRC.EVENTS_ITEMS_ODOMETER_UOM]: () => getOdometerUomOptions(),
  [GC.FIELD_ITEM_TEMPERATURE_UOM]: () => getTemperatureUomOptions(),
  [GC.FIELD_ITEM_DECLARED_CURRENCY]: () => getCurrencyTypeOptions2(),
  [GC.GRC.EVENTS_ITEMS_WHEELBASE_UOM]: () => getDimensionsUomOptions(),
  [GC.GRC.INVOICE_INVOICE_CURRENCY]: () => R.tail(GC.CURRENCY_OPTIONS),
  [GC.FIELD_EDI_TRANSACTION_TYPE]: () => getEdiTransactionTypeOptions(),
  [GC.FIELD_ITEM_TEMPERATURE_TYPE]: () => getItemTemperatureTypeOptions(),
  [GC.FIELD_ITEM_HAZMAT_FLASHPOINT_UOM]: () => getTemperatureTypeOptions(),
  [GC.GRC.EVENTS_ITEMS_ITEM_FREIGHT_CLASS]: () => getFreightClassOptions(),
  [GC.FIELD_INTEGRATION_TYPE]: () => driverOnboardingIntegrationTypeOptions,
  [GC.GRC.EVENTS_ITEMS_ITEM_DIMENSIONS_UOM]: () => getDimensionsUomOptions(),
  [GC.GRC.ITEMS_ITEM_HAZMAT_FLASHPOINT_UOM]: () => getTemperatureUomOptions(),
  [GC.FIELD_PAY_FOR_UNIT]: () => vendorPayrollAccessorialPayForUnitTypeOptions,
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM]: () => DEFAULT_DISTANCE_UOM_OPTIONS,
  [GC.GRC.LOAD_BOARD_SHIPMENTS_STATUS]: () => getLoadBoardShipmentsStatusOptions(),
  [GC.GRC.LOAD_BOARD_SHIPMENTS_SOURCE]: () => getLoadBoardShipmentsSourceOptions(),
  [GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID]: () => R.or(G.getItemFromWindow('accessorialConfigOptions'), []),
};
