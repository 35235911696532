import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dispatch-planner-events
import { getRouteClosOptions, getCurrentRouteCloEventGuids } from './helpers';
//////////////////////////////////////////////////

const selectBuilderStore = (state: Object) => state.dispatchPlannerEvents;
const selectCurrentBranchConfigs = (state: Object) => state.dispatchPlannerEvents.currentBranchConfigs;
const selectCloEventList = (state: Object) => state.dispatchPlannerEvents.cloEventList;

const makeSelectBuilderStore = () => createSelector(
  selectBuilderStore,
  (state: Object) => ({ ...state }),
);

const makeSelectCurrentRoute = () => createSelector(
  selectBuilderStore,
  ({ currentRoute }: Object) => currentRoute,
);

const makeSelectOpenedFromPage = () => createSelector(
  selectBuilderStore,
  ({ openedFromPage }: Object) => openedFromPage,
);

const makeSelectBranchGuidForRequest = () => createSelector(
  selectBuilderStore,
  ({ branchGuidForRequest }: Object) => branchGuidForRequest,
);

const makeSelectCurrentBranchConfigs = () => createSelector(
  selectBuilderStore,
  ({ currentBranchConfigs }: Object) => currentBranchConfigs,
);

const makeSelectCloEventReport = () => createSelector(
  selectBuilderStore,
  ({ cloEventReport }: Object) => cloEventReport,
);

const makeSelectTelReport = () => createSelector(
  selectBuilderStore,
  ({ telReport }: Object) => telReport,
);

const makeSelectInboundTelReport = () => createSelector(
  selectBuilderStore,
  ({ inboundTelReport }: Object) => inboundTelReport,
);

const makeSelectOutboundTelReport = () => createSelector(
  selectBuilderStore,
  ({ outboundTelReport }: Object) => outboundTelReport,
);

const makeSelectCloEventList = () => createSelector(
  selectBuilderStore,
  ({ cloEventList }: Object) => cloEventList,
);

const makeSelectTelList = () => createSelector(
  selectBuilderStore,
  ({ telList }: Object) => telList,
);

const makeSelectInboundTelList = () => createSelector(
  selectBuilderStore,
  ({ inboundTelList }: Object) => inboundTelList,
);

const makeSelectOutboundTelList = () => createSelector(
  selectBuilderStore,
  ({ outboundTelList }: Object) => outboundTelList,
);

const makeSelectCloEventListLoading = () => createSelector(
  selectBuilderStore,
  ({ cloEventListLoading }: Object) => cloEventListLoading,
);

const makeSelectTelListLoading = () => createSelector(
  selectBuilderStore,
  ({ telListLoading }: Object) => telListLoading,
);

const makeSelectInboundTelListLoading = () => createSelector(
  selectBuilderStore,
  ({ inboundTelListLoading }: Object) => inboundTelListLoading,
);

const makeSelectOutboundTelListLoading = () => createSelector(
  selectBuilderStore,
  ({ outboundTelListLoading }: Object) => outboundTelListLoading,
);

const makeSelectCloEventPagination = () => createSelector(
  selectBuilderStore,
  ({ cloEventPagination }: Object) => cloEventPagination,
);

const makeSelectTelPagination = () => createSelector(
  selectBuilderStore,
  ({ telPagination }: Object) => telPagination,
);

const makeSelectInboundTelPagination = () => createSelector(
  selectBuilderStore,
  ({ inboundTelPagination }: Object) => inboundTelPagination,
);

const makeSelectOutboundTelPagination = () => createSelector(
  selectBuilderStore,
  ({ outboundTelPagination }: Object) => outboundTelPagination,
);

const makeSelectCloEventTotal = () => createSelector(
  selectBuilderStore,
  ({ cloEventTotal }: Object) => cloEventTotal,
);

const makeSelectTelTotal = () => createSelector(
  selectBuilderStore,
  ({ telTotal }: Object) => telTotal,
);

const makeSelectInboundTelTotal = () => createSelector(
  selectBuilderStore,
  ({ inboundTelTotal }: Object) => inboundTelTotal,
);

const makeSelectOutboundTelTotal = () => createSelector(
  selectBuilderStore,
  ({ outboundTelTotal }: Object) => outboundTelTotal,
);

const makeSelectFullScreenView = () => createSelector(
  selectBuilderStore,
  ({ fullScreenView }: Object) => fullScreenView,
);

const makeSelectCloEventFilterParams = () => createSelector(
  selectBuilderStore,
  ({ cloEventFilterParams }: Object) => cloEventFilterParams,
);

const makeSelectTelFilterParams = () => createSelector(
  selectBuilderStore,
  ({ telFilterParams }: Object) => telFilterParams,
);

const makeSelectAvailableCloEventReports = () => createSelector(
  selectBuilderStore,
  ({ availableCloEventReports }: Object) => availableCloEventReports,
);

const makeSelectAvailableTelReports = () => createSelector(
  selectBuilderStore,
  ({ availableTelReports }: Object) => availableTelReports,
);

const makeSelectRouteTelConfigs = () => createSelector(
  selectCurrentBranchConfigs,
  (configs: Object) => G.getRouteTelConfigsForCreateClo(configs),
);

const makeSelectPrimaryReferenceAutogeneratedConfig = () => createSelector(
  selectCurrentBranchConfigs,
  (configs: Object) => G.getConfigValueFromStore(
    GC.TEL_PRIMARY_REFERENCE_AUTOGENERATED,
    configs,
  ),
);

const makeSelectDefaultUomFields = () => createSelector(
  selectCurrentBranchConfigs,
  (configStore: Object) => {
    let defaultValue = {};
    const uomSystem = G.getConfigValueFromStore(
      GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
      configStore,
    );
    if (R.and(G.isNotNil(uomSystem), G.isNotEmpty(uomSystem))) {
      defaultValue = GC.defaultSystemUoms[uomSystem];
    }
    return defaultValue;
  },
);

const makeSelectOptionsForSelect = () => createSelector(
  selectCurrentBranchConfigs,
  (configs: Object) => {
    const serviceType = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      configs,
      GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
      true,
    );
    const transportationMode = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      configs,
      GC.GENERAL_MODE_TRANSPORTATION,
      true,
    );
    return {
      serviceType,
      transportationMode,
    };
  },
);

const makeSelectCloEventByGuid = (guid: string) => createSelector(
  selectCloEventList,
  (cloEventList: Array=[]) => R.find(R.propEq(guid, GC.FIELD_GUID), cloEventList),
);

const makeSelectTelByGuid = (guid: string) => createSelector(
  selectBuilderStore,
  ({ telList }: Object) => R.find(R.propEq(guid, GC.FIELD_GUID), R.or(telList, [])),
);

const makeSelectItemsForDrop = () => createSelector(
  selectBuilderStore,
  ({ currentRouteItems }: Object) => R.filter(
    (item: Object) => R.and(
      R.propEq(false, 'selected', item),
      R.not(G.isLoadTypeClo(item)),
    ),
    R.values(currentRouteItems),
  ),
);

const makeSelectItemsForPickup = () => createSelector(
  selectBuilderStore,
  ({ currentRouteItems }: Object) => R.filter(
    (item: Object) => R.and(
      R.propEq(true, 'selected', item),
      R.not(G.isLoadTypeClo(item)),
    ),
    R.values(currentRouteItems),
  ),
);

const makeSelectCurrentRouteItems = () => createSelector(
  selectBuilderStore,
  ({ currentRouteItems }: Object) => currentRouteItems,
);

const makeSelectCurrentRouteContainers = () => createSelector(
  selectBuilderStore,
  ({ currentRouteContainers }: Object) => currentRouteContainers,
);

const makeSelectEventsInfo = () => createSelector(
  selectBuilderStore,
  ({ currentRoute }: Object) => {
    const tels = R.filter(
      (tel: Object) => G.isNotNilAndNotEmpty(tel[GC.FIELD_LOAD_STOPS]),
      R.sortBy(
        R.prop('order'),
        R.values(currentRoute.tels),
      ),
    );
    if (G.isNilOrEmpty(tels)) {
      return {
        firstEvent: null,
        lastEvent: null,
        total: 0,
      };
    }
    return {
      firstEvent: R.head(R.sortBy(
        R.prop('telEventIndex'),
        R.values(R.head(tels).events),
      )),
      lastEvent: R.last(R.sortBy(
        R.prop('telEventIndex'),
        R.values(R.last(tels).events),
      )),
      total: R.reduce(
        (arr: Array = [], tel: Object) => R.concat(arr, R.values(tel[GC.FIELD_LOAD_STOPS])),
        [],
        tels,
      ).length,
    };
  },
);

const makeSelectRouteErrors = () => createSelector(
  selectBuilderStore,
  ({ routeErrors }: Object) => routeErrors,
);

const locationFieldsToPick = [
  GC.FIELD_TEMPLATE_ID,
  GC.FIELD_LOCATION_NAME,
  GC.FIELD_CITY,
  GC.FIELD_STATE,
  GC.FIELD_ADDRESS_1,
  GC.FIELD_ZIP,
];

const makeSelectExistedTerminalOptions = () => createSelector(
  selectBuilderStore,
  ({ currentRoute }: Object) => R.compose(
    R.map((event: Object) => ({
      label: G.concatLocationFields(R.prop('location', event), locationFieldsToPick),
      value: R.prop('stopId', event),
    })),
    R.uniqBy(R.prop('stopId')),
    R.reduce((acc: Array, item: Array) => R.concat(acc, item), []),
    R.map((tel: Object) => R.compose(
      R.uniqBy(R.prop('stopId')),
      R.filter(R.propEq(GC.STOP_TYPE_TERMINAL, 'stopType')),
      R.values(),
      R.prop(GC.FIELD_LOAD_STOPS),
    )(tel)),
    R.values(),
    R.prop('tels'),
  )(currentRoute),
);

const makeSelectSelectedTels = () => createSelector(
  selectBuilderStore,
  ({ selectedTels }: Object) => selectedTels,
);

const makeSelectCrossDockLocationList = () => createSelector(
  selectBuilderStore,
  ({ crossDockLocationList }: Object) => crossDockLocationList,
);

const makeSelectInboundTerminals = () => createSelector(
  selectBuilderStore,
  ({ inboundTerminals }: Object) => inboundTerminals,
);

const makeSelectOutboundTerminals = () => createSelector(
  selectBuilderStore,
  ({ outboundTerminals }: Object) => outboundTerminals,
);

const makeSelectPlannerMode = () => createSelector(
  selectBuilderStore,
  ({ plannerMode }: Object) => plannerMode,
);

const makeSelectHidePlanned = () => createSelector(
  selectBuilderStore,
  ({ hidePlanned }: Object) => hidePlanned,
);

const makeSelectGlobalFilterValue = () => createSelector(
  selectBuilderStore,
  ({ globalFilterValue }: Object) => globalFilterValue,
);

const makeSelectGlobalFilterValueET = () => createSelector(
  selectBuilderStore,
  ({ globalFilterValueET }: Object) => globalFilterValueET,
);

const makeSelectGlobalFilterValueIT = () => createSelector(
  selectBuilderStore,
  ({ globalFilterValueIT }: Object) => globalFilterValueIT,
);

const makeSelectGlobalFilterValueOT = () => createSelector(
  selectBuilderStore,
  ({ globalFilterValueOT }: Object) => globalFilterValueOT,
);

const makeSelectUnassignedCloEvents = () => createSelector(
  selectBuilderStore,
  ({ unassignedCloEvents }: Object) => unassignedCloEvents,
);

const makeSelectDeletedEventGuids = () => createSelector(
  selectBuilderStore,
  ({ deletedEventGuids }: Object) => deletedEventGuids,
);

const makeSelectInitPlannerData = () => createSelector(
  selectBuilderStore,
  ({ initPlannerData }: Object) => initPlannerData,
);

const makeSelectInitPlannerDataFromTemplate = () => createSelector(
  selectBuilderStore,
  ({ initPlannerDataFromTemplate }: Object) => initPlannerDataFromTemplate,
);

const makeSelectEditedClos = () => createSelector(
  selectBuilderStore,
  ({ editedClos }: Object) => editedClos,
);

const makeSelectEditedCloGuids = () => createSelector(
  selectBuilderStore,
  ({ editedClos }: Object) => R.keys(editedClos),
);

const makeSelectFilterProps = () => createSelector(
  selectBuilderStore,
  ({ filterProps }: Object) => filterProps,
);

const makeSelectCloEventsTableTitleSortValues = () => createSelector(
  selectBuilderStore,
  ({ cloEventsTitleSortValues }: Object) => cloEventsTitleSortValues,
);

const makeSelectTelTableTitleSortValues = () => createSelector(
  selectBuilderStore,
  ({ telTitleSortValues }: Object) => telTitleSortValues,
);

const makeSelectInboundTableTitleSortValues = () => createSelector(
  selectBuilderStore,
  ({ inboundTitleSortValues }: Object) => inboundTitleSortValues,
);

const makeSelectOutboundTableTitleSortValues = () => createSelector(
  selectBuilderStore,
  ({ outboundTitleSortValues }: Object) => outboundTitleSortValues,
);

const makeSelectCloEventsTableTitleFilters = () => createSelector(
  selectBuilderStore,
  ({ cloEventsTableTitleFilters }: Object) => cloEventsTableTitleFilters,
);

const makeSelectTelTableTitleFilters = () => createSelector(
  selectBuilderStore,
  ({ telTableTitleFilters }: Object) => telTableTitleFilters,
);

const makeSelectInboundTableTitleFilters = () => createSelector(
  selectBuilderStore,
  ({ inboundTableTitleFilters }: Object) => inboundTableTitleFilters,
);

const makeSelectOutboundTableTitleFilters = () => createSelector(
  selectBuilderStore,
  ({ outboundTableTitleFilters }: Object) => outboundTableTitleFilters,
);

const makeSelectCurrentRouteInitialState = () => createSelector(
  selectBuilderStore,
  ({ currentRouteInitialState }: Object) => currentRouteInitialState,
);

const makeSelectTelsByCloGuidList = () => createSelector(
  selectBuilderStore,
  ({ telsByCloGuidList }: Object) => telsByCloGuidList,
);

const makeSelectCurrentRouteCloOptions = () => createSelector(
  selectBuilderStore,
  ({ currentRoute, currentRouteItems }: Object) => getRouteClosOptions(currentRoute, currentRouteItems),
);

const makeSelectCloRelatedTelListLoading = () => createSelector(
  selectBuilderStore,
  ({ cloRelatedTelListLoading }: Object) => cloRelatedTelListLoading,
);

const makeSelectRouteTemplates = () => createSelector(
  selectBuilderStore,
  ({ routeTemplates }: Object) => routeTemplates,
);

// clo event list for map
const makeSelectCloEventListForMap = () => createSelector(
  selectBuilderStore,
  ({ currentRoute, cloEventListForMap }: Object) => R.map((stop: Object) => {
    const lat = G.getPropFromObject(GC.GRC.LOCATION_LATITUDE, stop);
    const lng = G.getPropFromObject(GC.GRC.LOCATION_LONGITUDE, stop);
    const latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };

    const currentRouteCloEventGuids = getCurrentRouteCloEventGuids(currentRoute);
    const selected = R.includes(G.getGuidFromObject(stop), currentRouteCloEventGuids);

    return R.mergeRight(stop, { latLng, selected }, stop);
  }, cloEventListForMap),
);

const makeSelectCloEventListForMapLoading = () => createSelector(
  selectBuilderStore,
  ({ cloEventListForMapLoading }: Object) => cloEventListForMapLoading,
);

const makeSelectCloEventListForMapTotal = () => createSelector(
  selectBuilderStore,
  ({ cloEventListForMap }: Object) => R.length(cloEventListForMap),
);

const makeSelectStopsInfoList = () => createSelector(
  selectBuilderStore,
  ({ stopsInfoList }: Object) => stopsInfoList,
);

export {
  makeSelectTelList,
  makeSelectTelTotal,
  makeSelectTelReport,
  makeSelectTelByGuid,
  makeSelectEventsInfo,
  makeSelectEditedClos,
  makeSelectFilterProps,
  makeSelectRouteErrors,
  makeSelectPlannerMode,
  makeSelectHidePlanned,
  makeSelectBuilderStore,
  makeSelectCurrentRoute,
  makeSelectItemsForDrop,
  makeSelectSelectedTels,
  makeSelectCloEventList,
  makeSelectCloEventTotal,
  makeSelectTelPagination,
  makeSelectStopsInfoList,
  makeSelectOpenedFromPage,
  makeSelectCloEventReport,
  makeSelectCloEventByGuid,
  makeSelectItemsForPickup,
  makeSelectTelListLoading,
  makeSelectFullScreenView,
  makeSelectInboundTelList,
  makeSelectEditedCloGuids,
  makeSelectRouteTemplates,
  makeSelectRouteTelConfigs,
  makeSelectTelFilterParams,
  makeSelectOutboundTelList,
  makeSelectInboundTelTotal,
  makeSelectInitPlannerData,
  makeSelectDefaultUomFields,
  makeSelectOptionsForSelect,
  makeSelectInboundTelReport,
  makeSelectOutboundTelTotal,
  makeSelectInboundTerminals,
  makeSelectCurrentRouteItems,
  makeSelectOutboundTelReport,
  makeSelectOutboundTerminals,
  makeSelectGlobalFilterValue,
  makeSelectDeletedEventGuids,
  makeSelectTelsByCloGuidList,
  makeSelectCloEventPagination,
  makeSelectAvailableTelReports,
  makeSelectCloEventListLoading,
  makeSelectUnassignedCloEvents,
  makeSelectGlobalFilterValueET,
  makeSelectGlobalFilterValueIT,
  makeSelectGlobalFilterValueOT,
  makeSelectCloEventFilterParams,
  makeSelectBranchGuidForRequest,
  makeSelectCurrentBranchConfigs,
  makeSelectInboundTelPagination,
  makeSelectTelTableTitleFilters,
  makeSelectInboundTelListLoading,
  makeSelectCrossDockLocationList,
  makeSelectOutboundTelPagination,
  makeSelectExistedTerminalOptions,
  makeSelectOutboundTelListLoading,
  makeSelectCurrentRouteCloOptions,
  makeSelectCurrentRouteContainers,
  makeSelectTelTableTitleSortValues,
  makeSelectAvailableCloEventReports,
  makeSelectInboundTableTitleFilters,
  makeSelectCurrentRouteInitialState,
  makeSelectCloRelatedTelListLoading,
  makeSelectOutboundTableTitleFilters,
  makeSelectCloEventsTableTitleFilters,
  makeSelectInitPlannerDataFromTemplate,
  makeSelectInboundTableTitleSortValues,
  makeSelectOutboundTableTitleSortValues,
  makeSelectCloEventsTableTitleSortValues,
  makeSelectPrimaryReferenceAutogeneratedConfig,
  // clo event list for map
  makeSelectCloEventListForMap,
  makeSelectCloEventListForMapTotal,
  makeSelectCloEventListForMapLoading,
};
