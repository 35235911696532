
import * as R from 'ramda';
//////////////////////////////////////////////////

const apiDomain = process.env.API_DOMAIN;

const WebSocketURIsMap = {
  localDevelopment: 'wss://j4iowslwl9.execute-api.us-east-1.amazonaws.com/LATEST',
  localhost: 'wss://j4iowslwl9.execute-api.us-east-1.amazonaws.com/LATEST',
  development: 'wss://j4iowslwl9.execute-api.us-east-1.amazonaws.com/LATEST',
  testing: 'wss://ws.testamoustms.com',
  staging: 'wss://ws.testamoustms.com',
  production: 'wss://ws.amoustms.com',
};

const webSocketUri = R.prop(apiDomain, WebSocketURIsMap);

const messageType = {
  message: 'MESSAGE',
  subscribe: 'SUBSCRIBE',
  unsubscribe: 'UNSUBSCRIBE',
};

const socketTypes = {
  generic: 'GENERIC',
  statistic: 'STATISTIC',
  loadBoard: 'LOAD_BOARD',
  notification: 'NOTIFICATION',
};

const destinations = {
  [socketTypes.generic]: /^\/(generic)/,
  [socketTypes.statistic]: /^\/(statistic)\//,
  [socketTypes.loadBoard]: /^\/(load-board)\//,
  [socketTypes.notification]: /^\/(notification)\//,
};

const loadBoardMessageType = {
  lbLogin: 'login',
  lbResult: 'load',
  lbConfig: 'load-board-config',
  committed: 'committed-shipment',
  posted: 'posted-shipment/state',
  filterState: 'search-filter/state',
};

const statisticMessageType = {
  import: 'import',
};

export const SOCKET_DATA_TYPE_IFTA = 'IFTA';
export const SOCKET_DATA_TYPE_RATE = 'RATE';
export const SOCKET_DATA_TYPE = 'socketDataType';
export const SOCKET_DATA_TYPE_DOCUMENT = 'DOCUMENT';
export const SOCKET_DATA_TYPE_LOAD_STATUS = 'LOAD_STATUS';
export const SOCKET_DATA_TYPE_RATE_STATUS = 'RATE_STATUS';
export const SOCKET_DATA_TYPE_FRONT_ERROR = 'FRONT_ERROR';
export const SOCKET_DATA_TYPE_CHAT_MESSAGE = 'CHAT_MESSAGE';
export const SOCKET_DATA_TYPE_EVENT_STATUS = 'EVENT_STATUS';
export const SOCKET_DATA_TYPE_GENERAL_ERROR = 'GENERAL_ERROR';
export const SOCKET_DATA_TYPE_STATUS_MESSAGE = 'STATUS_MESSAGE';
export const SOCKET_DATA_TYPE_COST_ALLOCATION = 'COST_ALLOCATION';
export const SOCKET_DATA_TYPE_DRIVER_CARDS_TEL = 'DRIVER_CARDS_TEL';
export const SOCKET_DATA_TYPE_MACROPOINT_ERROR = 'MACROPOINT_ERROR';
export const SOCKET_DATA_TYPE_CLO_CREATE_ERROR = 'CLO_CREATE_ERROR';
export const SOCKET_DATA_TYPE_TEL_STATUS_TO_CLO = 'TEL_STATUS_TO_CLO';
export const SOCKET_DATA_TYPE_CLO_STATUS_TO_TEL = 'CLO_STATUS_TO_TEL';
export const SOCKET_DATA_TYPE_EDI_EXPORT_RESULT = 'EDI_EXPORT_RESULT';
export const SOCKET_DATA_TYPE_EXPORT_TO_ACCOUNTING = 'EXPORT_TO_ACCOUNTING';
export const SOCKET_DATA_TYPE_GENERATED_DOCUMENT_URL = 'GENERATED_DOCUMENT_URL';
export const SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_NOTE = 'AVAILABLE_DRIVERS_NOTE';
export const SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_STATUS = 'AVAILABLE_DRIVERS_STATUS';
export const SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_COMMENTS = 'AVAILABLE_DRIVERS_COMMENTS';
export const SOCKET_DATA_TYPE_MASTER_INVOICE_EXPORT_ERROR = 'MASTER_INVOICE_EXPORT_ERROR';
export const SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_RESERVATION = 'AVAILABLE_DRIVERS_RESERVATION';
export const SOCKET_DATA_TYPE_SUCCESSFULLY_GENERATED_DOCUMENT = 'SUCCESSFULLY_GENERATED_DOCUMENT';
export const SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_LAST_KNOWN_LOCATION = 'AVAILABLE_DRIVERS_LAST_KNOWN_LOCATION';
export const SOCKET_DATA_TYPE_CARRIER_LOAD_BOARD_SYNCHRONIZATION_ERROR = 'CARRIER_LOAD_BOARD_SYNCHRONIZATION_ERROR';

const genericMessageType = {
  // tel
  loadStatus: SOCKET_DATA_TYPE_LOAD_STATUS,
  rateStatus: SOCKET_DATA_TYPE_RATE_STATUS,
  chatMessage: SOCKET_DATA_TYPE_CHAT_MESSAGE,
  eventStatus: SOCKET_DATA_TYPE_EVENT_STATUS,
  statusMessage: SOCKET_DATA_TYPE_STATUS_MESSAGE,
  document: SOCKET_DATA_TYPE_DOCUMENT,
  costAllocation: SOCKET_DATA_TYPE_COST_ALLOCATION,
  rate: SOCKET_DATA_TYPE_RATE,
  cloStatusToTel: SOCKET_DATA_TYPE_CLO_STATUS_TO_TEL,
  // driver cards
  driverCardsTel: SOCKET_DATA_TYPE_DRIVER_CARDS_TEL,
  // availableDrivers
  availableDriversNote: SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_NOTE,
  availableDriversStatus: SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_STATUS,
  availableDriversComments: SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_COMMENTS,
  availableDriversReservation: SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_RESERVATION,
  availableDriversLastKnownLocation: SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_LAST_KNOWN_LOCATION,
  // document generation
  generatedDocumentUrl: SOCKET_DATA_TYPE_GENERATED_DOCUMENT_URL,
  successfullyGeneratedDocument: SOCKET_DATA_TYPE_SUCCESSFULLY_GENERATED_DOCUMENT,
  // ifta
  ifta: SOCKET_DATA_TYPE_IFTA,
  //
  ediExportResult: SOCKET_DATA_TYPE_EDI_EXPORT_RESULT,
  exportToAccounting: SOCKET_DATA_TYPE_EXPORT_TO_ACCOUNTING,
  // errors
  frontError: SOCKET_DATA_TYPE_FRONT_ERROR,
  generalError: SOCKET_DATA_TYPE_GENERAL_ERROR,
  cloCreateError: SOCKET_DATA_TYPE_CLO_CREATE_ERROR,
  macroPointError: SOCKET_DATA_TYPE_MACROPOINT_ERROR,
  masterInvoiceExportError: SOCKET_DATA_TYPE_MASTER_INVOICE_EXPORT_ERROR,
  carrierLoadBoardSyncError: SOCKET_DATA_TYPE_CARRIER_LOAD_BOARD_SYNCHRONIZATION_ERROR,
};

export const SOCKET_OPEN = 'OPEN';
export const SOCKET_CLOSE = 'CLOSE';
export const SOCKET_ERROR = 'ERROR';
export const SOCKET_MESSAGE = 'MESSAGE';

export const MaxSocketReconnectTries = 6;
export const SocketReconnectDelay = 30000;

export {
  messageType,
  socketTypes,
  destinations,
  webSocketUri,
  genericMessageType,
  loadBoardMessageType,
  statisticMessageType,
};
