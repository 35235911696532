import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { LocalLoader } from '../../../components/local-loader';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { asyncGetConfigsByNames } from '../../../helpers/api-async';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, ReactSelect } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// features
import allEventsFieldsList from '../../configurations/settings/all-event-fields';
import { makeSelectAllAvailableReferenceTypesByScopeName } from '../../reference/selectors';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.light.blue');
const warningColor = G.getTheme('colors.warning');
const mainLightColor = G.getTheme('colors.light.mainLight');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const TitleText = () => (
  <Flex p={6} gap={5} backgroundColor={blueColor} fontWeight={600} color={mainLightColor}>
    {G.getWindowLocale('actions:export-exl-with-stops', 'Generate Excel With Stops')}
  </Flex>
);

const FieldText = () => (
  <Box fontWeight={600} color={greyMatterhornColor}>
    {G.getWindowLocale(
      'titles:export-event-fields',
      'Fields to export',
    )}
  </Box>
);

const WarningText = () => (
  <Flex gap={5} fontWeight={600} color={greyMatterhornColor}>
    {I.symbol('⚠', warningColor, 18, 18)}
    {G.getWindowLocale(
      'messages:export-event-fields-empty-new',
      'To generate Excel with stops you must fill up the field list.',
    )}
  </Flex>
);

const ConfigLink = ({ goToConfiguration }: Object) => (
  <Box
    cursor='pointer'
    color={blueColor}
    onClick={goToConfiguration}
  >
    {G.getWindowLocale(
      'messages:export-event-fields-config',
      'Go to configuration to set default fields.',
    )}
  </Box>
);

const EventFieldsComponent = ({ handleCloseModal, handleGenerateExcel }: props) => {
  const [loading, setLoading] = useState(true);
  const [eventFields, setEventFields] = useState(null);

  const configurationTabUri = `${routesMap.configUI}?tab=${GC.TAB_UI_LOAD}`;
  const isConfigAllowed = G.hasAmousCurrentUserPermissions([PC.CONFIGURATIONS_WRITE]);

  useEffect(() => {
    const getConfigs = async () => {
      const branchGuid = G.getAmousCurrentBranchGuidFromWindow();

      const { configs } = await asyncGetConfigsByNames({ branchGuid, names: [GC.UI_LOAD_GENERATE_EXCEL_WITH_EVENTS] });

      const eventFields = R.pathOr(null, [GC.UI_LOAD_GENERATE_EXCEL_WITH_EVENTS, 'value'], configs);

      setEventFields(eventFields);
      setLoading(false);
    };

    getConfigs();
  }, []);

  const allEventsFields = useMemo(() => {
    if (G.isNilOrEmpty(eventFields)) return null;

    return G.mapIndexed((name: Object, index: number) => ({
      name,
      sequence: R.inc(index),
      displayName: R.compose(
        R.prop('label'),
        R.find(R.propEq(name, 'value')),
      )(allEventsFieldsList),
    }), eventFields);
  }, [eventFields]);

  const goToConfiguration = useCallback(() => G.goToRoute(configurationTabUri), []);

  const handleFieldsChange = useCallback((options: Array) => {
    const newValue = R.pluck('value', options);

    setEventFields(newValue);
  }, []);

  const handleGenerateSubmit = useCallback(() => {
    handleGenerateExcel(true, allEventsFields);

    handleCloseModal();
  }, [allEventsFields, handleCloseModal, handleGenerateExcel]);

  return (
    <LocalLoader localLoaderOpen={loading}>
      <Flex gap={20} flexDirection='column' alignItems='flex-start'>
        <FieldText />
        <Box width={410}>
          <ReactSelect
            height={32}
            isMulti={true}
            isClearable={true}
            value={eventFields}
            useMenuPortalTarget={true}
            options={allEventsFieldsList}
            onChange={handleFieldsChange}
            placeholder={G.getWindowLocale('titles:export-event-fields', 'Fields to export')}
          />
        </Box>
        {
          R.and(
            isConfigAllowed,
            G.isNotNilAndNotEmpty(eventFields),
          ) && <ConfigLink goToConfiguration={goToConfiguration} />
        }
        {
          G.isNilOrEmpty(eventFields) && <WarningText />
        }
        <FormFooter2
          submitAction={handleGenerateSubmit}
          submitDisabled={G.isNilOrEmpty(eventFields)}
          boxStyles={{ justifyContent: 'space-between' }}
          submitBtnText={G.getWindowLocale('actions:generate', 'Generate')}
        />
      </Flex>
    </LocalLoader>
  );
};

const useExportExcel = (props: Object, filterProps: Array) => {
  const { selectedReport, exportReportDataRequest } = props;

  const dispatch = useDispatch();

  const allAvailableRefTypesByScopeName = useSelector(makeSelectAllAvailableReferenceTypesByScopeName());

  const filterBy = useMemo(() => {
    const references = G.getRefListByReportType(
      allAvailableRefTypesByScopeName,
      R.path([GC.FIELD_TYPE], selectedReport),
    );

    return G.setFilterByParams(references, filterProps);
  }, [filterProps, selectedReport, allAvailableRefTypesByScopeName]);

  const handleGenerateExcel = useCallback((showAllEvents: boolean, allEventsFields: Array) => {
    const fields = R.pathOr({}, ['fields'], selectedReport);
    const report = R.assoc('fields', G.addDisplayNameToReportFields(fields, filterBy), selectedReport);

    exportReportDataRequest({
      ...report,
      showAllEvents,
      allEventsFields,
      fileType: 'xlsx',
    });
  }, [filterBy, selectedReport, exportReportDataRequest]);

  const handleCloseModal = useCallback(() => dispatch(closeModal()), []);

  const handleEventsExportAction = useCallback(() => {
    const modal = {
      p: '15px',
      TitleComponent: TitleText,
      component: (
        <EventFieldsComponent handleGenerateExcel={handleGenerateExcel} handleCloseModal={handleCloseModal} />
      ),
      options: {
        width: 440,
        movable: false,
        withCloseIcon: true,
      },
    };

    dispatch(openModal(modal));
  }, [handleGenerateExcel]);

  const exportFileTypes = useMemo(() => (
    [
      {
        label: 'xls',
        action: () => handleGenerateExcel(false),
        text: G.getWindowLocale('actions:export-exl', 'Generate Excel'),
      },
      {
        label: 'xls',
        action: () => handleEventsExportAction(),
        text: G.getWindowLocale('actions:export-exl-with-stops', 'Generate Excel With Stops'),
      },
    ]
  ), [handleGenerateExcel, handleEventsExportAction]);

  return exportFileTypes;
};

export default useExportExcel;
