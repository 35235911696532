import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
import {
  DEFAULT_ROUTE_TYPE_OPTIONS,
  CLO_ROUTE_DEFAULT_ORGANIZE_BY,
  TEL_ROUTE_DEFAULT_ORGANIZE_BY,
  getUiOrderEntryConfigEnumOptions,
  getDriverCardsDefaultGroupByEnumOptions,
  getDriverCardsDefaultGroupFilterEnumOptions,
  getDriverCardsDefaultDriverFilterEnumOptions,
} from '../../../helpers/options';
// feature configurations
import allEventFields from './all-event-fields';
import { MultiselectFieldComponent } from '../ui';
import { hiddenMenuItemOptions } from '../constants';
import DispatchPanelConfig from '../config-pages/ui/components/dispatch-panel-config';
//////////////////////////////////////////////////

const getHiddenMenuItemOptions = () => R.map((option: Object) => ({
  ...option,
  label: G.getWindowLocale(...option.label),
}), hiddenMenuItemOptions);

const hiddenTabOptions = [
  {
    value: 'tabs:dispatch-edi',
    label: G.getWindowLocale('titles:edi', 'EDI'),
  },
  {
    value: 'tabs:dispatch-carrier-edi',
    label: G.getWindowLocale('titles:carrier-edi', 'Carrier EDI'),
  },
  {
    value: 'tabs:dispatch-order-api',
    label: G.getWindowLocale('titles:oder-api', 'Order API'),
  },
  {
    value: 'tabs:dispatch-order-quotes',
    label: G.getWindowLocale('titles:quotes', 'Quotes'),
  },
  {
    value: 'tabs:dispatch-order-external-quotes',
    label: G.getWindowLocale('titles:external-quotes', 'External Quotes'),
  },
];

const UI_GENERAL_SETTINGS = [
  {
    title: 'titles:general',
    fields: {
      [GC.UI_ROUTE_LIST_CLO_SECONDARY_REFERENCE_TYPE]: {
        type: 'select',
        options: 'cloReferenceTypes',
        name: 'titles:clo-secondary-ref-type',
        nameForAttribute: GC.UI_ROUTE_LIST_CLO_SECONDARY_REFERENCE_TYPE,
      },
      [GC.UI_ROUTE_LIST_TEL_SECONDARY_REFERENCE_TYPE]: {
        type: 'select',
        options: 'telReferenceTypes',
        name: 'titles:tel-secondary-ref-type',
        nameForAttribute: GC.UI_ROUTE_LIST_TEL_SECONDARY_REFERENCE_TYPE,
      },
      // TODO: remove after customers using
      // [GC.UI_USE_MENU_VERSION_2]: {
      //   type: 'switcher',
      //   name: 'titles:use-menu-v2',
      //   nameForAttribute: GC.UI_USE_MENU_VERSION_2,
      // },
      [GC.UI_USE_NEW_FLEET_PROFILE]: {
        type: 'switcher',
        name: 'titles:use-new-fleet-profile',
        nameForAttribute: GC.UI_USE_NEW_FLEET_PROFILE,
      },
      [GC.UI_USE_NEW_CARRIER_PROFILE]: {
        type: 'switcher',
        name: 'titles:use-new-carrier-profile',
        nameForAttribute: GC.UI_USE_NEW_CARRIER_PROFILE,
      },
      [GC.UI_HIDDEN_MENU_ITEMS]: {
        zIndex: 20,
        type: 'list',
        name: 'titles:hidden-menu-items',
        options: getHiddenMenuItemOptions(),
        component: MultiselectFieldComponent,
        nameForAttribute: GC.UI_HIDDEN_MENU_ITEMS,
        shouldGetInheritedValueFromConfigOptions: true,
      },
      [GC.UI_HIDDEN_TABS]: {
        zIndex: 19,
        type: 'list',
        options: hiddenTabOptions,
        name: 'titles:hidden-tabs',
        nameForAttribute: GC.UI_HIDDEN_TABS,
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
      },
      [GC.UI_USE_MUI_CALENDAR]: {
        type: 'switcher',
        hideForNotSuperAdmin: true,
        name: 'titles:use-mui-calendar',
        nameForAttribute: GC.UI_USE_MUI_CALENDAR,
      },
      [GC.UI_REPLACE_DOLLAR_SYMBOL_WITH_US]: {
        type: 'switcher',
        name: 'titles:use-$-symbol-as-US$',
        nameForAttribute: GC.UI_REPLACE_DOLLAR_SYMBOL_WITH_US,
      },
      [GC.UI_CALENDAR_TIME_INTERVAL]: {
        type: 'input',
        name: 'titles:calendar-time-interval',
        nameForAttribute: GC.UI_CALENDAR_TIME_INTERVAL,
        validation: G.yupIntegerNotRequiredLessOrEqual(0, 30),
        // TODO: remove or uncomment after testing
        // validate: [G.isEmptyOrInteger, G.curriedIsEmptyOrMinMaxNumber(0, 30)],
      },
      [GC.UI_ORDER_TRIP_DEFAULT_NOTES_CARD]: {
        type: 'switcher',
        name: 'titles:order-trip-default-notes-card',
        nameForAttribute: GC.UI_ORDER_TRIP_DEFAULT_NOTES_CARD,
      },
      [GC.UI_SHOW_FAST_LOAD_INDICATOR]: {
        type: 'switcher',
        name: 'titles:show-fast-load-indicator',
        nameForAttribute: GC.UI_SHOW_FAST_LOAD_INDICATOR,
      },
      [GC.UI_LOAD_PLANNER_HIDE_INBOUND_OUTBOUND_TABS]: {
        type: 'switcher',
        name: 'titles:load-planner-hide-inbound-outbound-tabs',
        nameForAttribute: GC.UI_LOAD_PLANNER_HIDE_INBOUND_OUTBOUND_TABS,
      },
      [GC.UI_TRACKING_USE_GOOGLE_MAP]: {
        type: 'switcher',
        name: 'titles:tracking-use-google-map',
        nameForAttribute: GC.UI_TRACKING_USE_GOOGLE_MAP,
      },
    },
  },
  {
    title: 'titles:customer-portal',
    fields: {
      [GC.UI_CUSTOMER_PORTAL_USE_GOOGLE_MAP]: {
        type: 'switcher',
        name: 'titles:use-google-map',
        nameForAttribute: GC.UI_CUSTOMER_PORTAL_USE_GOOGLE_MAP,
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:chart-collection-type',
    configName: GC.UI_CHART_COLLECTIONS_TYPE,
  },
];

const UI_LOAD_SETTINGS = [{
  title: 'titles:load',
  fields: {
    [GC.UI_LOAD_GENERATE_EXCEL_WITH_EVENTS]: {
      zIndex: 18,
      type: 'list',
      options: allEventFields,
      component: MultiselectFieldComponent,
      name: 'titles:generate-excel-with-event-fields',
      nameForAttribute: GC.UI_LOAD_GENERATE_EXCEL_WITH_EVENTS,
    },
  },
}];

const UI_ORDER_SETTINGS = [{
  title: 'titles:order',
  fields: {
    [GC.UI_ORDER_ORDER_ENTRY]: {
      type: 'select',
      name: 'titles:order-entry',
      nameForAttribute: GC.UI_ORDER_ORDER_ENTRY,
      options: getUiOrderEntryConfigEnumOptions(),
    },
  },
}];

const UI_TEL_SETTINGS = [
  {
    title: 'titles:carrier-rate',
    fields: {
      [GC.UI_TEL_CARRIER_RATE_SHOW_LANE_HISTORY]: {
        type: 'switcher',
        name: 'titles:show-lane-history',
        nameForAttribute: GC.UI_TEL_CARRIER_RATE_SHOW_LANE_HISTORY,
      },
    },
  },
];

const UI_DISPATCH_BOARD = [
  {
    title: 'titles:dispatch-board',
    fields: {
      [GC.UI_ROUTE_LIST_GENERAL_DEFAULT_LOAD]: {
        type: 'select',
        name: 'titles:route-default-list',
        options: DEFAULT_ROUTE_TYPE_OPTIONS,
        nameForAttribute: GC.UI_ROUTE_LIST_GENERAL_DEFAULT_LOAD,
      },
      [GC.UI_ROUTE_LIST_CLO_DEFAULT_ORGANIZE_BY]: {
        type: 'select',
        name: 'titles:clo-default-group-by',
        options: CLO_ROUTE_DEFAULT_ORGANIZE_BY,
        nameForAttribute: GC.UI_ROUTE_LIST_CLO_DEFAULT_ORGANIZE_BY,
      },
      [GC.UI_ROUTE_LIST_TEL_DEFAULT_ORGANIZE_BY]: {
        type: 'select',
        name: 'titles:tel-default-group-by',
        options: TEL_ROUTE_DEFAULT_ORGANIZE_BY,
        nameForAttribute: GC.UI_ROUTE_LIST_TEL_DEFAULT_ORGANIZE_BY,
      },
      [GC.UI_ROUTE_LIST_SHOW_TRANSPORTATION_MODE]: {
        type: 'switcher',
        name: 'titles:show-transportation-mode',
        nameForAttribute: GC.UI_ROUTE_LIST_SHOW_TRANSPORTATION_MODE,
      },
      [GC.UI_OPEN_LOAD_IN_SIDEBAR]: {
        type: 'switcher',
        name: 'titles:open-load-in-sidebar',
        nameForAttribute: GC.UI_OPEN_LOAD_IN_SIDEBAR,
      },
      [GC.UI_SHOW_LOCATION_INSTEAD_LATE_DATE]: {
        type: 'switcher',
        name: 'titles:show-location-instead-late-date',
        nameForAttribute: GC.UI_SHOW_LOCATION_INSTEAD_LATE_DATE,
      },
      [GC.UI_LOADBOARD_HIDE_TRIP_STOPS]: {
        type: 'switcher',
        name: 'titles:hide-trip-stops',
        nameForAttribute: GC.UI_LOADBOARD_HIDE_TRIP_STOPS,
      },
    },
  },
  {
    hideInherited: true,
    configName: GC.LOAD_TYPE_CLO,
    configType: 'customComponent',
    title: 'titles:dispatch-board-action-panel-clo',
    component: (props: Object) => <DispatchPanelConfig {...props} loadType={GC.LOAD_TYPE_CLO} />,
  },
  {
    hideInherited: true,
    configName: GC.LOAD_TYPE_TEL,
    configType: 'customComponent',
    title: 'titles:dispatch-board-action-panel-tel',
    component: (props: Object) => <DispatchPanelConfig {...props} loadType={GC.LOAD_TYPE_TEL} />,
  },
];

const UI_DRIVER_CARDS_SETTINGS = [{
  title: 'titles:driver-cards',
  fields: {
    [GC.UI_DRIVER_CARDS_CARD_VIEW]: {
      type: 'select',
      name: 'titles:card-view',
      nameForAttribute: GC.UI_DRIVER_CARDS_CARD_VIEW,
      options: [
        { label: G.getWindowLocale('titles:load', 'Load'), value: 'LOAD' },
        { label: G.getWindowLocale('titles:note', 'Note'), value: 'NOTE' },
        { label: G.getWindowLocale('titles:hybrid', 'Hybrid'), value: 'HYBRID' },
      ],
    },
    [GC.UI_DRIVER_CARDS_CARD_HEIGHT]: {
      type: 'select',
      name: 'titles:card-height',
      nameForAttribute: GC.UI_DRIVER_CARDS_CARD_HEIGHT,
      options: [
        { label: G.getWindowLocale('titles:full', 'Full'), value: ENUMS.ENUM_FULL },
        { label: G.getWindowLocale('titles:small', 'Small'), value: ENUMS.ENUM_SMALL },
      ],
    },
    [GC.UI_DRIVER_CARDS_SHOW_TEAM_DRIVER]: {
      type: 'switcher',
      name: 'titles:show-team-driver',
      nameForAttribute: GC.UI_DRIVER_CARDS_SHOW_TEAM_DRIVER,
    },
    [GC.UI_DRIVER_CARDS_24H_VIEW]: {
      type: 'switcher',
      name: 'titles:cards-24h-view',
      nameForAttribute: GC.UI_DRIVER_CARDS_24H_VIEW,
    },
    [GC.UI_DRIVER_CARDS_DEFAULT_GROUP_BY]: {
      type: 'select',
      name: 'titles:default-group-by',
      options: getDriverCardsDefaultGroupByEnumOptions(),
      nameForAttribute: GC.UI_DRIVER_CARDS_DEFAULT_GROUP_BY,
    },
    [GC.UI_DRIVER_CARDS_DEFAULT_DAY_FILTER]: {
      type: 'select',
      options: [
        { label: '1', value: 1 },
        { label: '3', value: 3 },
        { label: '7', value: 7 },
      ],
      name: 'titles:default-day-filter',
      nameForAttribute: GC.UI_DRIVER_CARDS_DEFAULT_DAY_FILTER,
    },
    [GC.UI_DRIVER_CARDS_DEFAULT_GROUP_FILTER]: {
      type: 'select',
      name: 'titles:default-group-filter',
      options: getDriverCardsDefaultGroupFilterEnumOptions(),
      nameForAttribute: GC.UI_DRIVER_CARDS_DEFAULT_GROUP_FILTER,
    },
    [GC.UI_DRIVER_CARDS_DEFAULT_DRIVER_FILTER]: {
      type: 'select',
      name: 'titles:default-driver-filter',
      options: getDriverCardsDefaultDriverFilterEnumOptions(),
      nameForAttribute: GC.UI_DRIVER_CARDS_DEFAULT_DRIVER_FILTER,
    },
    [GC.UI_DRIVER_CARDS_ZOOM]: {
      type: 'select',
      options: [
        { label: '100%', value: 1 },
        { label: '200%', value: 2 },
        { label: '300%', value: 3 },
      ],
      name: 'titles:zoom',
      nameForAttribute: GC.UI_DRIVER_CARDS_ZOOM,
    },
    // TODO: not used now
    // [GC.UI_DRIVER_CARDS_WARNING_PERIOD]: {
    //   type: 'input',
    //   name: 'titles:empty-warning-period-hours',
    //   nameForAttribute: GC.UI_DRIVER_CARDS_WARNING_PERIOD,
    //   validate: [G.isEmptyOrInteger, G.curriedIsEmptyOrMinMaxNumber(6, 48)],
    // },
    // TODO: check how to show UI
    // [GC.UI_DRIVER_CARDS_FUTURE_EMPTY_WARNING_PERIOD]: {
    //   type: 'input',
    //   name: 'titles:future-empty-warning-period-hours',
    //   nameForAttribute: GC.UI_DRIVER_CARDS_FUTURE_EMPTY_WARNING_PERIOD,
    //   validate: [G.isEmptyOrInteger, G.curriedIsEmptyOrMinMaxNumber(6, 48)],
    // },
    [GC.UI_DRIVER_CARDS_DEFAULT_SEARCH_RADIUS]: {
      type: 'input',
      name: 'titles:search-radius',
      validation: G.yupIntegerNotRequiredLessOrEqual(0, 5000),
      nameForAttribute: GC.UI_DRIVER_CARDS_DEFAULT_SEARCH_RADIUS,
      // validate: [G.isEmptyOrInteger, G.curriedIsEmptyOrMinMaxNumber(0, 5000)],
    },
    // TODO: not used now
    // [GC.UI_DRIVER_CARDS_DEFAULT_MIN_EMPTY_HOURS]: {
    //   type: 'input',
    //   name: 'titles:minimum-empty-hours',
    //   nameForAttribute: GC.UI_DRIVER_CARDS_DEFAULT_MIN_EMPTY_HOURS,
    //   validate: [G.isEmptyOrInteger, G.curriedIsEmptyOrMinMaxNumber(0, 500)],
    // },
  },
}];

const UI_AVAILABLE_DRIVERS_SETTINGS = [{
  title: 'titles:available-drivers',
  fields: {
    [GC.UI_AVAILABLE_DRIVERS_DEFAULT_SEARCH_LOCATION]: {
      type: 'input',
      name: 'titles:search-location-template-id',
      nameForAttribute: GC.UI_AVAILABLE_DRIVERS_DEFAULT_SEARCH_LOCATION,
    },
    [GC.UI_AVAILABLE_DRIVERS_DEFAULT_SEARCH_RADIUS]: {
      type: 'input',
      name: 'titles:search-radius',
      validation: G.yupIntegerNotRequiredLessOrEqual(0, 5000),
      nameForAttribute: GC.UI_AVAILABLE_DRIVERS_DEFAULT_SEARCH_RADIUS,
      // validate: [G.isEmptyOrInteger, G.curriedIsEmptyOrMinMaxNumber(0, 5000)],
    },
    [GC.UI_AVAILABLE_DRIVERS_DEFAULT_TRUCK_TYPES]: {
      type: 'list',
      options: 'truckTypes',
      name: 'titles:truck-types',
      component: MultiselectFieldComponent,
      shouldGetInheritedValueFromConfigOptions: true,
      nameForAttribute: GC.UI_AVAILABLE_DRIVERS_DEFAULT_TRUCK_TYPES,
    },
  },
}];

const UI_LOAD_BOARD_SETTINGS = [{
  title: 'titles:external-load-boards',
  fields: {
    [GC.UI_LOADBOARD_AUTODIALMODAL]: {
      type: 'switcher',
      name: 'titles:autodialing-modal',
      nameForAttribute: GC.UI_LOADBOARD_AUTODIALMODAL,
    },
    [GC.UI_LOADBOARD_AUTODIALACTION]: {
      type: 'switcher',
      name: 'titles:autodialing-action',
      nameForAttribute: GC.UI_LOADBOARD_AUTODIALACTION,
    },
    // NOTE: commented on Alex's request
    // [GC.UI_LOADBOARD_AUTODIALAPPNAME]: {
    //   type: 'select',
    //   name: 'titles:autodialing-app',
    //   nameForAttribute: GC.UI_LOADBOARD_AUTODIALAPPNAME,
    //   options: DEFAULT_LOADBOARD_DIALING_APP_OPTIONS,
    // },
  },
}];

const ConfigUIGroup = {
  UI_GENERAL_SETTINGS,
  UI_LOAD_SETTINGS,
  UI_ORDER_SETTINGS,
  UI_TEL_SETTINGS,
  UI_DISPATCH_BOARD,
  UI_DRIVER_CARDS_SETTINGS,
  UI_AVAILABLE_DRIVERS_SETTINGS,
  UI_LOAD_BOARD_SETTINGS,
};

export {
  ConfigUIGroup,
};
