import * as R from 'ramda';
import { css } from 'styled-components';
import React, { useEffect } from 'react';
import 'leaflet-draw/dist/leaflet.draw.css';
import { useMap, TileLayer } from 'react-leaflet';
import { MapContainer } from 'react-leaflet/MapContainer';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const SetView = (props: Object) => {
  const { bounds, center } = props;

  const map = useMap();

  const mapZoom = map.getZoom();
  const zoom = G.isNilOrEmpty(bounds) ? mapZoom : map.getBoundsZoom(bounds);

  useEffect(() => {
    map.setView(center, zoom);
  }, [zoom, center]);

  return null;
};

const MapWrapper = ({
  bounds,
  center,
  children,
  mapCss = [],
  width = '100%',
  height = 'auto',
}: Object) => (
  <Box width='100%'>
    <MapContainer
      zoom={4}
      maxZoom={18}
      center={center}
      bounds={bounds}
      css={css`
        z-index: 1;
        width: ${width};
        height: ${height};

        ${mapCss}
      `}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {children}
      <SetView center={center} bounds={bounds} />
    </MapContainer>
  </Box>
);

export default MapWrapper;
