import React from 'react';
import * as R from 'ramda';
// features
import { masterInvoiceConfigDefaultGroupByOptions } from '../../master-invoice/constants';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  daySelectOptions,
  PAYMENT_TERM_OPTIONS,
  MAIL_SENDING_REPLY_TO_OPTIONS,
  getQBAccountMappingScopeOptions,
  factoringIntegrationTypeOptions,
  getSageAccountMappingScopeOptions,
} from '../../../helpers/options';
// feature configs
import ConnectToQBComponent from '../components/connect-to-qb';
import { ReactSelectComponent, MultiselectFieldComponent } from '../ui';
import { invoiceStatusesFields } from '../config-pages/invoice/settings';
import {
  scopeOptions as qbIffAssessorialScopeOptions,
} from '../config-pages/invoice/components/qb-iif-assessorial-mapping-form';
import { getInvoiceStatusOptionsByScope } from '../config-pages/invoice/components/invoice-status-rule';
import {
  qbAccountTypeEnumsMap,
  qbCustomerAccountTypeEnumsMap,
  qbCustomerAccountTypeEnumsMap2,
} from '../constants';
//////////////////////////////////////////////////

const INVOICE_GENERAL = [
  {
    title: 'titles:general',
    fields: {
      [GC.INVOICE_TEL_SEPARATE_PAYMENT_BY_CURRENCY]: {
        type: 'switcher',
        name: 'titles:separate-trip-invoices-by-currency',
        nameForAttribute: GC.INVOICE_TEL_SEPARATE_PAYMENT_BY_CURRENCY,
      },
    },
  },
  {
    configType: 'custom',
    title: 'titles:default-invoice-status-rule',
    configName: GC.CUSTOM_INVOICE_STATUS_RULE_CONFIG,
    customConfigName: GC.CUSTOM_INVOICE_STATUS_RULE_CONFIG,
    additionalTitleComponent: ({ invoiceStatusRuleFilter, setInvoiceStatusRuleFilter }: Object) =>
      <ReactSelectComponent
        value={invoiceStatusRuleFilter}
        handleChange={setInvoiceStatusRuleFilter}
        options={getQBAccountMappingScopeOptions()}
        placeholder={G.getWindowLocale('titles:filter-by-scope', 'Filter By Scope')}
      />,
    fields: [
      {
        name: 'titles:priority',
        nameForAttribute: GC.FIELD_PRIORITY,
      },
      {
        name: 'titles:scope',
        nameForAttribute: GC.FIELD_SCOPE,
        customLogic: (_: any, { scope }: Object) => G.toTitleCase(scope),
      },
      {
        name: 'titles:invoice-status',
        nameForAttribute: GC.FIELD_INVOICE_STATUS_GUID,
        customLogic: (_: any, { scope, invoiceStatusGuid }: Object, { configOptions }: Object) => R.compose(
          R.path([GC.FIELD_LABEL]),
          R.find(R.propEq(invoiceStatusGuid, GC.FIELD_VALUE)),
        )(getInvoiceStatusOptionsByScope(scope, configOptions)),
      },
      {
        name: 'titles:conditions',
        nameForAttribute: GC.FIELD_CONDITIONS,
        customLogic: (_: any, entity: Object) => R.compose(
          R.join(', '),
          R.map(G.getEnumLocale),
          R.pathOr([], [GC.FIELD_CONDITIONS]),
        )(entity),
      },
      {
        name: 'titles:rate-mismatch-type',
        nameForAttribute: GC.FIELD_RATE_MISMATCH_TYPE,
        customLogic: (_: any, { rateMismatchType }: Object) => G.getEnumLocale(rateMismatchType),
      },
      {
        name: 'titles:rate-mismatch-value',
        nameForAttribute: GC.FIELD_RATE_MISMATCH_VALUE,
      },
      {
        name: 'titles:clo-status',
        nameForAttribute: GC.FIELD_CLO_STATUSES,
        customLogic: (_: any, entity: Object) => R.compose(
          R.join(', '),
          R.map((status: string) => G.getWindowLocale(G.getPropFromObject(status, GC.statusLocaleMap))),
          R.pathOr([], [GC.FIELD_CLO_STATUSES]),
        )(entity),
      },
      {
        name: 'titles:tel-status',
        nameForAttribute: GC.FIELD_TEL_STATUSES,
        customLogic: (_: any, entity: Object) => R.compose(
          R.join(', '),
          R.map((status: string) => G.getWindowLocale(G.getPropFromObject(status, GC.statusLocaleMap))),
          R.pathOr([], [GC.FIELD_TEL_STATUSES]),
        )(entity),
      },
      {
        name: 'titles:transportation-modes',
        nameForAttribute: GC.FIELD_TRANSPORTATION_MODE_GUIDS,
        customLogic: (_: any, { transportationModeGuids }: Object, props: Object) => R.compose(
          R.join(', '),
          R.filter(G.isNotNilAndNotEmpty),
          R.map((item: string) => R.compose(
            R.path([GC.FIELD_LABEL]),
            R.find(R.propEq(item, GC.FIELD_VALUE)),
            R.pathOr([], ['configOptions', 'transportationModes']),
          )(props)),
        )(transportationModeGuids),
      },
    ],
  },
];

const INVOICE_QUICK_BOOKS = [
  {
    title: 'titles:quick-books-api-connection',
    fields: {
      [GC.INVOICE_QB_AUTHORIZATION]: {
        type: 'customComponent',
        hideInheritedValue: true,
        name: 'titles:quiqkbooks-api',
        component: () => <ConnectToQBComponent />,
        nameForAttribute: GC.INVOICE_QB_AUTHORIZATION,
        infoText: G.getWindowLocale(
          'messages:qb-authorization',
          'Used to connect Amous TMS with your Quickbooks Online account',
        ),
      },
      [GC.INVOICE_QB_CUSTOMER_OVERRIDE_URL]: {
        type: 'input',
        name: 'titles:customer-override-url',
        nameForAttribute: GC.INVOICE_QB_CUSTOMER_OVERRIDE_URL,
        infoText: G.getWindowLocale('messages:qb-override-url', 'Used for Custom API Integration with Quickbooks'),
      },
      [GC.INVOICE_QB_CARRIER_OVERRIDE_URL]: {
        type: 'input',
        name: 'titles:carrier-override-url',
        nameForAttribute: GC.INVOICE_QB_CARRIER_OVERRIDE_URL,
        infoText: G.getWindowLocale('messages:qb-override-url', 'Used for Custom API Integration with Quickbooks'),
      },
      [GC.INVOICE_QB_QB_VENDOR_EXPENSE_PAYMENT_METHOD]: {
        type: 'select',
        name: 'titles:quickbooks-vendor-expense-payment-method',
        nameForAttribute: GC.INVOICE_QB_QB_VENDOR_EXPENSE_PAYMENT_METHOD,
        infoText: G.getWindowLocale(
          'messages:qb-vendor-expense-payment-method',
          'Used to identify how the Vendor is paid within Quickbooks. Available options are: Cash, Check and Credit Card',
        ),
        options: [
          { label: G.getWindowLocale('titles:cash', 'Cash'), value: 'CASH' },
          { label: G.getWindowLocale('titles:check', 'Check'), value: 'CHECK' },
          { label: G.getWindowLocale('titles:credit-card', 'Credit Card'), value: 'CREDIT_CARD' },
        ],
      },
      [GC.INVOICE_QB_QB_VENDOR_TRANSACTION_TYPE]: {
        type: 'select',
        name: 'titles:quickbooks-vendor-transaction-type',
        nameForAttribute: GC.INVOICE_QB_QB_VENDOR_TRANSACTION_TYPE,
        infoText: G.getWindowLocale(
          'messages:qb-qb-vendor-transaction-type',
          'Used to identify the type of transaction that will used in Quickbooks for your Vendor Invoices. Available options are: Purchase and Bill',
        ),
        options: [
          { label: G.getWindowLocale('titles:bill', 'Bill'), value: 'BILL' },
          { label: G.getWindowLocale('titles:purchase', 'Purchase'), value: 'PURCHASE' },
        ],
      },
      [GC.INVOICE_QB_PAYMENT_SYNCHRONIZATION]: {
        type: 'switcher',
        name: 'titles:quickbooks-payment-synchronization',
        nameForAttribute: GC.INVOICE_QB_PAYMENT_SYNCHRONIZATION,
        infoText: G.getWindowLocale(
          'messages:qb-payment-synchronization',
          'If this configuration is enabled, Amous will make a call to QuickBooks every day to retrieve the latest payment information and update the corresponding invoices',
        ),
      },
    },
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:quick-books-desktop-connection',
    configName: GC.CUSTOM_QUICK_BOOKS_DESKTOP_CONNECTION_CONFIG,
    customConfigName: GC.CUSTOM_QUICK_BOOKS_DESKTOP_CONNECTION_CONFIG,
    fields: [
      {
        name: 'titles:user-name',
        nameForAttribute: GC.FIELD_USERNAME_CAMEL_CASE,
      },
    ],
  },
  {
    title: 'titles:customer-quick-books',
    fields: {
      [GC.INVOICE_QB_CUSTOMER_IDENTIFIER]: {
        type: 'select',
        name: 'titles:customer-identifier-api-iif',
        nameForAttribute: GC.INVOICE_QB_CUSTOMER_IDENTIFIER,
        infoText: G.getWindowLocale(
          'messages:qb-customer-identifier',
          'Used for Identifying and Connecting Customer Accounts between Amous TMS and your Quickbooks Account',
        ),
        options: [
          {
            value: 'BILL_TO_NAME',
            label: G.getWindowLocale('titles:bill-to', 'Bill To'),
          },
          {
            value: 'ENTERPRISE_WITH_ACCOUNT',
            label: G.getWindowLocale('titles:branch-with-account', 'Branch With Account'),
          },
          {
            value: 'ACCOUNT',
            label: G.getWindowLocale('titles:account', 'Account'),
          },
        ],
      },
      [GC.INVOICE_QB_CUSTOMER_USE_ORDER_DIVISION_AS_PRIMARY]: {
        type: 'switcher',
        name: 'titles:use-order-division-as-primary',
        nameForAttribute: GC.INVOICE_QB_CUSTOMER_USE_ORDER_DIVISION_AS_PRIMARY,
      },
    },
  },
  {
    title: 'titles:carrier-quick-books',
    fields: {
      [GC.INVOICE_QB_CARRIER_IDENTIFIER]: {
        type: 'select',
        name: 'titles:carrier-identifier-api-iif',
        nameForAttribute: GC.INVOICE_QB_CARRIER_IDENTIFIER,
        infoText: G.getWindowLocale(
          'messages:qb-carrier-identifier',
          'Used for Identifying and Connecting Carriers Accounts between Amous TMS and your Quickbooks Account',
        ),
        options: [
          {
            value: 'MC_NUMBER',
            label: G.getWindowLocale('titles:mc-number', 'MC Number'),
          },
          {
            value: 'NAME',
            label: G.getWindowLocale('titles:carrier-name', 'Carrier Name'),
          },
        ],
      },
      [GC.INVOICE_QB_CARRIER_ADDRESS_IDENTIFIER]: {
        type: 'select',
        name: 'titles:carrier-address-identifier',
        nameForAttribute: GC.INVOICE_QB_CARRIER_ADDRESS_IDENTIFIER,
        options: [
          {
            value: 'REMIT_TO',
            label: G.getWindowLocale('titles:remit-to', 'Remit To'),
          },
          {
            value: 'CARRIER_PROFILE',
            label: G.getWindowLocale('titles:carrier-profile', 'Carrier Profile'),
          },
        ],
      },
      [GC.INVOICE_QB_CARRIER_TRANSACTION_TYPE]: {
        type: 'select',
        name: 'titles:carrier-invoice-transaction-type',
        nameForAttribute: GC.INVOICE_QB_CARRIER_TRANSACTION_TYPE,
        infoText: G.getWindowLocale(
          'messages:qb-carrier-transaction-type',
          'Used to identify the type of transaction that will used in Quickbooks for your Carriers. Available options are: Check, Bill, Credit Card and Purchase Order',
        ),
        options: [
          {
            value: 'CHECK',
            label: G.getWindowLocale('titles:check', 'Check'),
          },
          {
            value: 'BILL',
            label: G.getWindowLocale('titles:bill', 'Bill'),
          },
          {
            value: 'CREDIT_CARD',
            label: G.getWindowLocale('titles:credit-card', 'Credit Card'),
          },
          {
            value: 'PURCHASE_ORDER',
            label: G.getWindowLocale('titles:purchase-order', 'Purchase Order'),
          },
        ],
      },
    },
  },
  {
    title: 'titles:driver-quick-books',
    fields: {
      [GC.INVOICE_QB_DRIVER_EXPENSE_PAYMENT_METHOD]: {
        type: 'select',
        name: 'titles:driver-expense-payment-method',
        nameForAttribute: GC.INVOICE_QB_DRIVER_EXPENSE_PAYMENT_METHOD,
        infoText: G.getWindowLocale(
          'messages:qb-driver-payment-method',
          'Used to identify how the Driver is paid within Quickbooks. Available options are: Cash, Check and Credit Card',
        ),
        options: [
          { label: G.getWindowLocale('titles:cash', 'Cash'), value: 'CASH' },
          { label: G.getWindowLocale('titles:check', 'Check'), value: 'CHECK' },
          { label: G.getWindowLocale('titles:credit-card', 'Credit Card'), value: 'CREDIT_CARD' },
        ],
      },
      [GC.INVOICE_QB_DRIVER_TRANSACTION_TYPE]: {
        type: 'select',
        name: 'titles:driver-invoice-transaction-type',
        nameForAttribute: GC.INVOICE_QB_DRIVER_TRANSACTION_TYPE,
        infoText: G.getWindowLocale(
          'messages:qb-driver-transaction-type',
          'Used to identify the type of transaction that will used in Quickbooks for your Employee Driver Invoices. Available options are: Credit Card and Check',
        ),
        options: [
          {
            value: 'CREDIT_CARD',
            label: G.getWindowLocale('titles:credit-card', 'Credit Card'),
          },
          {
            value: 'CHECK',
            label: G.getWindowLocale('titles:check', 'Check'),
          },
        ],
      },
      [GC.INVOICE_QB_OWNER_OPERATOR_TRANSACTION_TYPE]: {
        type: 'select',
        name: 'titles:owner-operator-transaction-type',
        nameForAttribute: GC.INVOICE_QB_OWNER_OPERATOR_TRANSACTION_TYPE,
        infoText: G.getWindowLocale(
          'messages:qb-owner-operator-transaction-type',
          'Used to identify the type of transaction that will used in Quickbooks for your Owner Operator Invoices. Available options are: Check, Bill, Credit',
        ),
        options: [
          {
            value: 'CHECK',
            label: G.getWindowLocale('titles:check', 'Check'),
          },
          {
            value: 'BILL',
            label: G.getWindowLocale('titles:bill', 'Bill'),
          },
          {
            value: 'CREDIT_CARD',
            label: G.getWindowLocale('titles:credit-card', 'Credit Card'),
          },
          {
            value: 'PURCHASE_ORDER',
            label: G.getWindowLocale('titles:purchase-order', 'Purchase Order'),
          },
        ],
      },
      [GC.INVOICE_QB_CONTRACTOR_TRANSACTION_TYPE]: {
        type: 'select',
        name: 'titles:contractor-transaction-type',
        nameForAttribute: GC.INVOICE_QB_CONTRACTOR_TRANSACTION_TYPE,
        infoText: G.getWindowLocale(
          'messages:qb-contractor-transaction-type',
          'Used to identify the type of transaction that will used in Quickbooks for your Contractor Invoices. Available options are: Check, Bill, Credit',
        ),
        options: [
          {
            value: 'CHECK',
            label: G.getWindowLocale('titles:check', 'Check'),
          },
          {
            value: 'BILL',
            label: G.getWindowLocale('titles:bill', 'Bill'),
          },
          {
            value: 'CREDIT_CARD',
            label: G.getWindowLocale('titles:credit-card', 'Credit Card'),
          },
          {
            value: 'PURCHASE_ORDER',
            label: G.getWindowLocale('titles:purchase-order', 'Purchase Order'),
          },
        ],
      },
    },
  },
  {
    title: 'titles:vendor-quick-books',
    fields: {
      [GC.INVOICE_QB_VENDOR_VENDOR_IDENTIFIER]: {
        type: 'select',
        name: 'titles:vendor-identifier-api-iif',
        nameForAttribute: GC.INVOICE_QB_VENDOR_VENDOR_IDENTIFIER,
        infoText: G.getWindowLocale(
          'messages:qb-vendor-identifier',
          'Used for Identifying and Connecting Vendors Accounts between Amous TMS and your Quickbooks Account',
        ),
        options: [
          {
            value: 'MC_NUMBER',
            label: G.getWindowLocale('titles:mc-number', 'MC Number'),
          },
          {
            value: 'NAME',
            label: G.getWindowLocale('titles:vendor-name', 'Vendor Name'),
          },
        ],
      },
      [GC.INVOICE_QB_VENDOR_TRANSACTION_TYPE]: {
        type: 'select',
        name: 'titles:vendor-invoice-transaction-type',
        nameForAttribute: GC.INVOICE_QB_VENDOR_TRANSACTION_TYPE,
        infoText: G.getWindowLocale(
          'messages:qb-vendor-transaction-type',
          'Used to identify the type of transaction that will used in Quickbooks for your Vendors. Available options are: Check, Bill, Credit Card and Purchase Order',
        ),
        options: [
          {
            value: 'CHECK',
            label: G.getWindowLocale('titles:check', 'Check'),
          },
          {
            value: 'BILL',
            label: G.getWindowLocale('titles:bill', 'Bill'),
          },
          {
            value: 'CREDIT_CARD',
            label: G.getWindowLocale('titles:credit-card', 'Credit Card'),
          },
          {
            value: 'PURCHASE_ORDER',
            label: G.getWindowLocale('titles:purchase-order', 'Purchase Order'),
          },
        ],
      },
    },
  },
  {
    title: 'titles:service-vendor-quick-books',
    fields: {
      [GC.INVOICE_QB_SERVICE_VENDOR_TRANSACTION_TYPE]: {
        type: 'select',
        name: 'titles:service-vendor-invoice-transaction-type',
        nameForAttribute: GC.INVOICE_QB_SERVICE_VENDOR_TRANSACTION_TYPE,
        infoText: G.getWindowLocale(
          'messages:qb-service-vendor-transaction-type',
          'Used to identify the type of transaction that will used in Quickbooks for your Service Vendors. Available options are: Check, Bill, Credit Card and Purchase Order',
        ),
        options: [
          {
            value: 'CHECK',
            label: G.getWindowLocale('titles:check', 'Check'),
          },
          {
            value: 'BILL',
            label: G.getWindowLocale('titles:bill', 'Bill'),
          },
          {
            value: 'CREDIT_CARD',
            label: G.getWindowLocale('titles:credit-card', 'Credit Card'),
          },
          {
            value: 'PURCHASE_ORDER',
            label: G.getWindowLocale('titles:purchase-order', 'Purchase Order'),
          },
        ],
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:quick-books-account',
    configName: GC.INVOICE_QB_QB_ACCOUNT,
  },
  {
    configType: 'custom',
    title: 'titles:quick-books-account-mapping',
    configName: GC.CUSTOM_QUICK_BOOKS_ACCOUNT_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_QUICK_BOOKS_ACCOUNT_MAPPING_CONFIG,
    additionalTitleComponent: ({ qbAccountMappingFilter, setQBAccountMappingFilter }: Object) =>
      <ReactSelectComponent
        useMenuPortalTarget={true}
        value={qbAccountMappingFilter}
        handleChange={setQBAccountMappingFilter}
        options={getQBAccountMappingScopeOptions()}
        placeholder={G.getWindowLocale('titles:filter-by-scope', 'Filter By Scope')}
      />,
    fields: [
      {
        name: 'titles:scope',
        nameForAttribute: GC.FIELD_SCOPE,
        customLogic: (_: any, { scope }: Object) => G.getEnumLocale(scope),
      },
      {
        tableColumnWith: '300px',
        name: 'titles:account-type',
        nameForAttribute: GC.FIELD_ACCOUNT_TYPE,
        customLogic: (_: any, { scope, accountType }: Object) => {
          const isCustomerScope = R.equals(scope, GC.INVOICE_SCOPE_TYPE_CUSTOMER);
          const condition = R.and(
            R.not(isCustomerScope),
            R.includes(accountType, [
              GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT,
              GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL,
              GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS,
            ]),
          );
          const condition2 = R.and(
            isCustomerScope,
            G.notEquals(accountType, GC.QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_RECEIVABLE),
          );
          let mapToUse = qbAccountTypeEnumsMap;

          if (condition) {
            mapToUse = qbCustomerAccountTypeEnumsMap;
          } else if (condition2) {
            mapToUse = qbCustomerAccountTypeEnumsMap2;
          }

          const localeKey = R.pathOr(null, [accountType], mapToUse);

          if (R.isNil(localeKey)) {
            return G.getEnumLocale(accountType);
          }

          return G.getWindowLocale(localeKey, accountType);
        },
      },
      {
        name: 'titles:quick-books-account',
        nameForAttribute: GC.FIELD_ACCOUNT_NAME_GUID,
        customLogic: (_: any, { accountNameGuid }: Object, props: Object) => R.compose(
          R.pathOr('', [GC.FIELD_DISPLAYED_VALUE]),
          R.find((item: Object) => R.equals(accountNameGuid, G.getParentGuidOrGuidFromObject(item))),
          R.pathOr([], ['dropdowns', GC.INVOICE_QB_QB_ACCOUNT, 'options']),
        )(props),
      },
      {
        name: 'titles:accessorial',
        nameForAttribute: GC.FIELD_ACCESSORIAL_CONFIG_GUID,
        customLogic: (_: any, { assessorialConfigGuid }: Object, props: Object) => R.compose(
          R.pathOr('', [GC.FIELD_DISPLAYED_VALUE]),
          R.find(R.propEq(assessorialConfigGuid, GC.FIELD_ORIGINAL_CONFIG_GUID)),
          R.pathOr([], ['configOptions', 'accessorialList']),
        )(props),
      },
    ],
  },
  {
    configType: 'custom',
    title: 'titles:quickbooks-iif-accessorial-mapping',
    configName: GC.CUSTOM_QUICK_BOOKS_IIF_ASSESSORIAL_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_QUICK_BOOKS_IIF_ASSESSORIAL_MAPPING_CONFIG,
    additionalTitleComponent: ({ qbIffAssessorialMappingFilter, setQbIffAssessorialMappingFilter }: Object) =>
      <ReactSelectComponent
        useMenuPortalTarget={true}
        value={qbIffAssessorialMappingFilter}
        options={R.tail(qbIffAssessorialScopeOptions)}
        handleChange={setQbIffAssessorialMappingFilter}
        placeholder={G.getWindowLocale('titles:filter-by-scope', 'Filter By Scope')}
      />,
    fields: [
      {
        name: 'titles:scope',
        nameForAttribute: GC.FIELD_SCOPE,
        customLogic: (_: any, { scope }: Object) => G.getEnumLocale(scope),
      },
      {
        name: 'titles:default-name',
        nameForAttribute: GC.FIELD_DEFAULT_NAME,
        customLogic: (_: any, { defaultName }: Object) => G.getEnumLocale(defaultName),
      },
      {
        name: 'titles:name',
        nameForAttribute: GC.FIELD_NAME,
      },
    ],
  },
];

const sageCarrierVendorMappingOptions = [
  {
    value: 'MC_NUMBER',
    label: G.getWindowLocale('titles:mc-number', 'MC Number'),
  },
  {
    value: 'NAME',
    label: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    value: 'ACCOUNTING_EXTERNAL_ID',
    label: G.getWindowLocale('titles:accounting-external-id', 'Accounting External ID'),
  },
];

const INVOICE_SAGE_INTACCT = [
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:account',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    configName: GC.INVOICE_SAGE_ACCOUNT,
  },
  {
    configType: 'custom',
    title: 'titles:account-mapping',
    configName: GC.CUSTOM_SAGE_ACCOUNT_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_SAGE_ACCOUNT_MAPPING_CONFIG,
    additionalTitleComponent: ({ sageAccountMappingFilter, setSageAccountMappingFilter }: Object) =>
      <ReactSelectComponent
        value={sageAccountMappingFilter}
        handleChange={setSageAccountMappingFilter}
        options={getSageAccountMappingScopeOptions()}
        placeholder={G.getWindowLocale('titles:filter-by-scope', 'Filter By Scope')}
      />,
    fields: [
      {
        name: 'titles:scope',
        nameForAttribute: GC.FIELD_SCOPE,
        customLogic: (_: any, { scope }: Object) => G.getEnumLocale(scope),
      },
      {
        tableColumnWith: '300px',
        name: 'titles:account-type',
        nameForAttribute: GC.FIELD_ACCOUNT_TYPE,
        customLogic: (_: any, { accountType }: Object) => G.getEnumLocale(accountType),
      },
      {
        name: 'titles:sage-intacct-account',
        nameForAttribute: GC.FIELD_ACCOUNT_NAME_GUID,
        customLogic: (_: any, { accountNameGuid }: Object, props: Object) => R.compose(
          R.pathOr('', [GC.FIELD_DISPLAYED_VALUE]),
          R.find((item: Object) => R.equals(accountNameGuid, G.getParentGuidOrGuidFromObject(item))),
          R.pathOr([], ['dropdowns', GC.INVOICE_SAGE_ACCOUNT, 'options']),
        )(props),
      },
      {
        name: 'titles:accessorial',
        nameForAttribute: GC.FIELD_ACCESSORIAL_CONFIG_GUID,
        customLogic: (_: any, { assessorialConfigGuid }: Object, props: Object) => R.compose(
          R.pathOr('', [GC.FIELD_DISPLAYED_VALUE]),
          R.find(R.propEq(assessorialConfigGuid, GC.FIELD_ORIGINAL_CONFIG_GUID)),
          R.pathOr([], ['configOptions', 'accessorialList']),
        )(props),
      },
    ],
  },
  {
    configType: 'custom',
    title: 'titles:customer-id-mapping',
    configName: GC.CUSTOM_CUSTOMER_ID_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_CUSTOMER_ID_MAPPING_CONFIG,
    fields: [
      {
        name: 'titles:customer',
        nameForAttribute: GC.FIELD_CUSTOMER_GUID,
        customLogic: (_: any, { customerGuid }: Object) => {
          const branch = G.getBranchFromWindowByGuid(customerGuid);

          return G.getPropFromObject(GC.FIELD_BRANCH_NAME, branch);
        },
      },
      {
        tableColumnWith: '300px',
        name: 'titles:customer-id',
        nameForAttribute: GC.FIELD_CUSTOMER_ID,
      },
    ],
  },
  {
    configType: 'custom',
    title: 'titles:department-id-mapping',
    configName: GC.CUSTOM_DEPARTMENT_ID_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_DEPARTMENT_ID_MAPPING_CONFIG,
    fields: [
      {
        name: 'titles:transportation-mode',
        nameForAttribute: GC.FIELD_TRANSPORTATION_MODE_GUID,
        customLogic: (_: any, { transportationModeGuid }: Object, { configOptions }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(transportationModeGuid, GC.FIELD_VALUE)),
          R.pathOr([], ['transportationModes']),
        )(configOptions),
      },
      {
        tableColumnWith: '300px',
        name: 'titles:department-id',
        nameForAttribute: GC.FIELD_DEPARTMENT_ID,
      },
    ],
  },
  {
    title: 'titles:customer',
    fields: {
      [GC.INVOICE_SAGE_CUSTOMER_IDENTIFIER]: {
        type: 'select',
        name: 'titles:customer-identifier',
        nameForAttribute: GC.INVOICE_SAGE_CUSTOMER_IDENTIFIER,
        infoText: G.getWindowLocale(
          'messages:sage-customer-identifier',
          'Used for Identifying and Connecting Customer Accounts between Amous TMS and your Sage Account',
        ),
        options: [
          {
            value: 'BILL_TO_NAME',
            label: G.getWindowLocale('titles:bill-to', 'Bill To'),
          },
          {
            value: 'ENTERPRISE_WITH_ACCOUNT',
            label: G.getWindowLocale('titles:branch-with-account', 'Branch With Account'),
          },
          {
            value: 'ACCOUNT',
            label: G.getWindowLocale('titles:account', 'Account'),
          },
        ],
      },
      [GC.INVOICE_SAGE_INVOICE_ITEM_LOCATION]: {
        type: 'input',
        name: 'titles:location-id',
        nameForAttribute: GC.INVOICE_SAGE_INVOICE_ITEM_LOCATION,
      },
      [GC.INVOICE_SAGE_SEND_AS_ORDER]: {
        type: 'switcher',
        name: 'titles:send-as-order',
        nameForAttribute: GC.INVOICE_SAGE_SEND_AS_ORDER,
      },
      [GC.INVOICE_SAGE_ORDER_TRANSACTION]: {
        type: 'input',
        name: 'titles:transaction',
        nameForAttribute: GC.INVOICE_SAGE_ORDER_TRANSACTION,
      },
      [GC.INVOICE_SAGE_INVOICE_ITEM_UNIT]: {
        type: 'input',
        name: 'titles:unit-id',
        nameForAttribute: GC.INVOICE_SAGE_INVOICE_ITEM_UNIT,
      },
    },
  },
  {
    title: 'titles:carrier-vendor',
    fields: {
      [GC.INVOICE_SAGE_CARRIER_IDENTIFIER]: {
        type: 'select',
        name: 'titles:carrier-identifier',
        nameForAttribute: GC.INVOICE_SAGE_CARRIER_IDENTIFIER,
        infoText: G.getWindowLocale(
          'messages:sage-carrier-identifier',
          'Used for Identifying and Connecting Carrier Accounts between Amous TMS and your Sage Account',
        ),
        options: sageCarrierVendorMappingOptions,
      },
      [GC.INVOICE_SAGE_VENDOR_IDENTIFIER]: {
        type: 'select',
        name: 'titles:vendor-identifier',
        nameForAttribute: GC.INVOICE_SAGE_VENDOR_IDENTIFIER,
        infoText: G.getWindowLocale(
          'messages:sage-vendor-identifier',
          'Used for Identifying and Connecting Vendor Accounts between Amous TMS and your Sage Account',
        ),
        options: sageCarrierVendorMappingOptions,
      },
      [GC.INVOICE_SAGE_BILL_ITEM_LOCATION]: {
        type: 'input',
        name: 'titles:location-id',
        nameForAttribute: GC.INVOICE_SAGE_BILL_ITEM_LOCATION,
      },
    },
  },
];

const INVOICE_BUSINESS_CENTRAL = [
  {
    title: 'titles:carrier-vendor',
    fields: {
      [GC.INVOICE_BUSINESS_CENTRAL_CARRIER_IDENTIFIER]: {
        type: 'select',
        name: 'titles:carrier-identifier',
        options: sageCarrierVendorMappingOptions,
        nameForAttribute: GC.INVOICE_BUSINESS_CENTRAL_CARRIER_IDENTIFIER,
        infoText: G.getWindowLocale(
          'messages:business-central-carrier-identifier',
          'Used for Identifying and Connecting Carrier Accounts between Amous TMS and your Business Central Account',
        ),
      },
      [GC.INVOICE_BUSINESS_CENTRAL_VENDOR_IDENTIFIER]: {
        type: 'select',
        name: 'titles:vendor-identifier',
        options: sageCarrierVendorMappingOptions,
        nameForAttribute: GC.INVOICE_BUSINESS_CENTRAL_VENDOR_IDENTIFIER,
        infoText: G.getWindowLocale(
          'messages:business-central-vendor-identifier',
          'Used for Identifying and Connecting Vendor Accounts between Amous TMS and your Business Central Account',
        ),
      },
      [GC.INVOICE_BUSINESS_CENTRAL_SERVICE_VENDOR_IDENTIFIER]: {
        type: 'select',
        name: 'titles:service-vendor-identifier',
        options: sageCarrierVendorMappingOptions,
        nameForAttribute: GC.INVOICE_BUSINESS_CENTRAL_SERVICE_VENDOR_IDENTIFIER,
        infoText: G.getWindowLocale(
          'messages:business-central-vendor-identifier',
          'Used for Identifying and Connecting Service Vendor Accounts between Amous TMS and your Business Central Account',
        ),
      },
    },
  },
];

const validateDefaultInvoiceNetDays = [G.isEmptyOrInteger, G.curriedIsEmptyOrMinMaxNumber(0, 10000)];

const INVOICE_FLEET_INVOICES = [
  {
    title: 'titles:payroll',
    fields: {
      [GC.INVOICE_TEL_PAYROLL_NUMBER_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:number-autogenerated',
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_NUMBER_AUTOGENERATED,
      },
      [GC.INVOICE_TEL_PAYROLL_NUMBER_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:number-sequence',
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_NUMBER_SEQUENCE,
      },
      [GC.INVOICE_TEL_PAYROLL_DEFAULT_INVOICE_STATUSES]: {
        zIndex: 12,
        type: 'list',
        options: 'driverPayrollStatuses',
        component: MultiselectFieldComponent,
        name: 'titles:default-invoice-statuses',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_DEFAULT_INVOICE_STATUSES,
      },
      [GC.INVOICE_TEL_PAYROLL_DEFAULT_STATUS]: {
        type: 'select',
        name: 'titles:default-status',
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_DEFAULT_STATUS,
        options: {
          from: 'props',
          customConfigNameOnProps: 'driverPayrollStatuses',
        },
      },
      [GC.INVOICE_TEL_PAYROLL_INVOICE_FILTER_DATE_RANGE_DAYS]: {
        type: 'input',
        // TODO: remove or uncomment after testing
        // validate: G.isEmptyOrInteger,
        name: 'titles:date-range-days',
        validation: G.yupIntegerNotRequired,
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_INVOICE_FILTER_DATE_RANGE_DAYS,
      },
      [GC.INVOICE_TEL_PAYROLL_DATE_RANGE_DAYS]: {
        type: 'input',
        // validate: G.isEmptyOrInteger,
        validation: G.yupIntegerNotRequired,
        name: 'titles:invoices-date-range-days',
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_DATE_RANGE_DAYS,
      },
      [GC.INVOICE_TEL_PAYROLL_DAY_FROM]: {
        type: 'select',
        name: 'titles:day-from',
        options: daySelectOptions,
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_DAY_FROM,
      },
      [GC.INVOICE_TEL_PAYROLL_DAY_TO]: {
        type: 'select',
        name: 'titles:day-to',
        options: daySelectOptions,
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_DAY_TO,
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_EXPORT]: {
        type: 'switcher',
        name: 'titles:allow-export-driver-invoice',
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_EXPORT,
      },
      [GC.INVOICE_TEL_PAYROLL_ALLOW_CREATE_EMPTY_PAYROLLS]: {
        type: 'switcher',
        name: 'titles:allow-create-empty-payrolls',
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_ALLOW_CREATE_EMPTY_PAYROLLS,
      },
      [GC.INVOICE_TEL_PAYROLL_ADD_PAYMENT_DETAILS_COMMENT]: {
        type: 'switcher',
        name: 'titles:add-payment-details-comment',
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_ADD_PAYMENT_DETAILS_COMMENT,
      },
      [GC.INVOICE_TEL_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION]: {
        type: 'switcher',
        name: 'titles:allow-delete-paid-deduction',
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION,
      },
      [GC.INVOICE_TEL_PAYROLL_MAIL_TEXT]: {
        width: 400,
        type: 'textarea',
        name: 'titles:text',
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_MAIL_TEXT,
      },
      [GC.INVOICE_TEL_PAYROLL_MAIL_SUBJECT]: {
        name: 'titles:subject',
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_MAIL_SUBJECT,
      },
      [GC.INVOICE_TEL_PAYROLL_MAIL_REPLY_TO]: {
        type: 'select',
        name: 'titles:reply-to',
        options: MAIL_SENDING_REPLY_TO_OPTIONS,
        nameForAttribute: GC.INVOICE_TEL_PAYROLL_MAIL_REPLY_TO,
      },
    },
  },
  {
    title: 'titles:vendor-payroll',
    fields: {
      [GC.INVOICE_FLEET_VENDOR_PAYROLL_NUMBER_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:number-autogenerated',
        nameForAttribute: GC.INVOICE_FLEET_VENDOR_PAYROLL_NUMBER_AUTOGENERATED,
      },
      [GC.INVOICE_FLEET_VENDOR_PAYROLL_NUMBER_SEQUENCE]: {
        nameForAttribute: GC.INVOICE_FLEET_VENDOR_PAYROLL_NUMBER_SEQUENCE,
        type: 'select',
        options: 'sequences',
        name: 'titles:number-sequence',
      },
      [GC.INVOICE_FLEET_VENDOR_PAYROLL_DEFAULT_STATUS]: {
        type: 'select',
        name: 'titles:default-status',
        nameForAttribute: GC.INVOICE_FLEET_VENDOR_PAYROLL_DEFAULT_STATUS,
        options: {
          from: 'props',
          customConfigNameOnProps: 'driverPayrollStatuses',
        },
      },
      [GC.INVOICE_FLEET_VENDOR_PAYROLL_ADD_PAYMENT_DETAILS_COMMENT]: {
        type: 'switcher',
        name: 'titles:add-payment-details-comment',
        nameForAttribute: GC.INVOICE_FLEET_VENDOR_PAYROLL_ADD_PAYMENT_DETAILS_COMMENT,
      },
      [GC.INVOICE_FLEET_VENDOR_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION]: {
        type: 'switcher',
        name: 'titles:allow-delete-paid-deduction',
        nameForAttribute: GC.INVOICE_FLEET_VENDOR_PAYROLL_ALLOW_DELETE_PAID_DEDUCTION,
      },
      [GC.INVOICE_FLEET_VENDOR_PAYROLL_MAIL_TEXT]: {
        width: 400,
        type: 'textarea',
        name: 'titles:text',
        nameForAttribute: GC.INVOICE_FLEET_VENDOR_PAYROLL_MAIL_TEXT,
      },
      [GC.INVOICE_FLEET_VENDOR_PAYROLL_MAIL_SUBJECT]: {
        name: 'titles:subject',
        nameForAttribute: GC.INVOICE_FLEET_VENDOR_PAYROLL_MAIL_SUBJECT,
      },
      [GC.INVOICE_FLEET_VENDOR_PAYROLL_MAIL_REPLY_TO]: {
        type: 'select',
        name: 'titles:reply-to',
        options: MAIL_SENDING_REPLY_TO_OPTIONS,
        nameForAttribute: GC.INVOICE_FLEET_VENDOR_PAYROLL_MAIL_REPLY_TO,
      },
    },
  },
  {
    title: 'titles:driver-invoices',
    fields: {
      [GC.INVOICE_TEL_DRIVER_INVOICE_AUTO_CREATE_ON]: {
        type: 'select',
        name: 'titles:auto-create-on',
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_AUTO_CREATE_ON,
        options: [
          {
            value: 'DELIVERY',
            label: G.getWindowLocale('titles:delivery', 'Delivery'),
          },
          {
            value: 'CUSTOMER_INVOICE_CREATE',
            label: G.getWindowLocale('titles:customer-invoice-create', 'Customer Invoice Create'),
          },
        ],
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_AUTO_CREATE_INVOICE_WITH_ZERO_RATE]: {
        type: 'switcher',
        name: 'titles:auto-create-with-zero-rate',
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_AUTO_CREATE_INVOICE_WITH_ZERO_RATE,
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_ADD_DAYS]: {
        type: 'input',
        name: 'titles:add-days',
        // validate: G.isEmptyOrInteger,
        validation: G.yupIntegerNotRequired,
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_ADD_DAYS,
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_DATE]: {
        type: 'select',
        name: 'titles:default-date',
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_DATE,
        options: [
          { label: G.getWindowLocale('titles:creation', 'Creation'), value: 'CREATION_DATE' },
          { label: G.getWindowLocale('titles:delivery', 'Delivery'), value: 'DELIVERY_DATE' },
          { label: G.getWindowLocale('titles:pickup-date', 'Pickup date'), value: 'PICKUP_DATE' },
          { label: G.getWindowLocale('titles:actual-pickup-date', 'Actual Pickup Date'), value: 'ACTUAL_PICKUP_DATE' },
        ],
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_STATUS]: {
        type: 'select',
        options: {
          from: 'props',
          customConfigNameOnProps: 'driverPayrollStatuses',
        },
        name: 'titles:default-status',
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_STATUS,
      },
      [GC.INVOICE_TEL_SECOND_DRIVER_INVOICE_DEFAULT_STATUS]: {
        type: 'select',
        options: {
          from: 'props',
          customConfigNameOnProps: 'driverPayrollStatuses',
        },
        name: 'titles:second-invoice-default-status',
        nameForAttribute: GC.INVOICE_TEL_SECOND_DRIVER_INVOICE_DEFAULT_STATUS,
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_LOCK_STATUSES]: {
        zIndex: 13,
        type: 'list',
        options: 'driverPayrollStatuses',
        component: MultiselectFieldComponent,
        name: 'titles:lock-invoice-statuses',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_LOCK_STATUSES,
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_NET_DAYS]: {
        type: 'input',
        name: 'titles:net-days',
        // validate: validateDefaultInvoiceNetDays,
        validation: G.yupIntegerNotRequiredLessOrEqual(0, 10000),
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_NET_DAYS,
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_USE_TEL_PRIM_REF]: {
        type: 'switcher',
        name: 'titles:use-tel-primary-reference',
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_USE_TEL_PRIM_REF,
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:number-autogenerated',
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_AUTOGENERATED,
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:number-sequence',
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_NUMBER_SEQUENCE,
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_COST_ALLOCATION_SKIPPED_STATUSES]: {
        zIndex: 10,
        type: 'list',
        options: 'driverPayrollStatuses',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:cost-allocation-skipped-statuses',
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_COST_ALLOCATION_SKIPPED_STATUSES,
      },
      [GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_COMPLETED_STATUS]: {
        type: 'select',
        name: 'titles:default-completed-status',
        options: 'completedDriverPayrollStatuses',
        nameForAttribute: GC.INVOICE_TEL_DRIVER_INVOICE_DEFAULT_COMPLETED_STATUS,
      },
    },
  },
  {
    configType: 'custom',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    fields: invoiceStatusesFields,
    configName: GC.INVOICE_DP_STATUS,
    customConfigName: GC.INVOICE_DP_STATUS,
    title: 'titles:driver-payroll-statuses',
  },
  {
    configType: 'custom',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    fields: invoiceStatusesFields,
    title: 'titles:service-invoice-statuses',
    configName: GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS,
    customConfigName: GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS,
  },
];

const INVOICE_CUSTOMER_INVOICES = [
  {
    title: 'titles:customer-master-invoices',
    fields: {
      [GC.INVOICE_CLO_MASTER_INVOICE_NUMBER_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:number-autogenerated',
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_NUMBER_AUTOGENERATED,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_NUMBER_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:number-sequence',
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_NUMBER_SEQUENCE,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_STATUS]: {
        zIndex: 24,
        type: 'list',
        options: 'customerInvoiceStatuses',
        component: MultiselectFieldComponent,
        name: 'titles:default-invoice-statuses',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_STATUS,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_INVOICE_PAYMENT_TERMS]: {
        zIndex: 23,
        type: 'list',
        options: PAYMENT_TERM_OPTIONS,
        component: MultiselectFieldComponent,
        name: 'titles:default-invoice-payment-terms',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_INVOICE_PAYMENT_TERMS,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_DATE_RANGE_DAYS]: {
        type: 'input',
        // validate: G.isEmptyOrInteger,
        name: 'titles:date-range-days',
        validation: G.yupIntegerNotRequired,
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_DATE_RANGE_DAYS,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_INVOICE_FILTER_DATE_RANGE_DAYS]: {
        type: 'input',
        // validate: G.isEmptyOrInteger,
        validation: G.yupIntegerNotRequired,
        name: 'titles:invoices-date-range-days',
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_INVOICE_FILTER_DATE_RANGE_DAYS,
      },
      [GC.INVOICE_CLO_INVOICE_EXPORT]: {
        type: 'switcher',
        name: 'titles:allow-export-customer-invoice',
        nameForAttribute: GC.INVOICE_CLO_INVOICE_EXPORT,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_SEND_EMAILS]: {
        type: 'switcher',
        name: 'titles:send-emails',
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_SEND_EMAILS,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_MAIL_SUBJECT]: {
        type: 'input',
        name: 'titles:mail-subject',
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_MAIL_SUBJECT,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_MAIL_TEXT]: {
        type: 'textarea',
        name: 'titles:mail-text',
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_MAIL_TEXT,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_TEMPLATE]: {
        type: 'select',
        options: 'templates',
        name: 'titles:default-template',
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_TEMPLATE,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_FACTORING_NAME]: {
        type: 'select',
        name: 'titles:default-factoring-name',
        options: factoringIntegrationTypeOptions,
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_FACTORING_NAME,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_REPLY_TO]: {
        type: 'select',
        name: 'titles:reply-to',
        options: MAIL_SENDING_REPLY_TO_OPTIONS,
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_REPLY_TO,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_DOCUMENT_TYPES]: {
        zIndex: 22,
        type: 'list',
        options: 'availableDocumentTypes',
        name: 'titles:default-document-types',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_DOCUMENT_TYPES,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_CARRIER_DOCUMENT_TYPES]: {
        zIndex: 21,
        type: 'list',
        component: MultiselectFieldComponent,
        options: 'availableCarrierDocumentTypes',
        name: 'titles:default-vendor-document-types',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_CARRIER_DOCUMENT_TYPES,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_GROUP_BY]: {
        type: 'select',
        name: 'titles:default-group-by',
        options: masterInvoiceConfigDefaultGroupByOptions,
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_DEFAULT_GROUP_BY,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_GROUP_BY_REFERENCE]: {
        type: 'select',
        options: 'cloReferenceTypes',
        name: 'titles:group-by-reference',
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_GROUP_BY_REFERENCE,
      },
      [GC.INVOICE_CLO_MASTER_INVOICE_ADDITIONAL_REFERENCES]: {
        zIndex: 20,
        type: 'list',
        options: 'cloReferenceTypes',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:create-master-invoice-additional-ref',
        nameForAttribute: GC.INVOICE_CLO_MASTER_INVOICE_ADDITIONAL_REFERENCES,
      },
    },
  },
  {
    title: 'titles:customer-invoices',
    fields: {
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_AUTO_CREATE_ON]: {
        zIndex: 19,
        type: 'list',
        name: 'titles:auto-create-on',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_AUTO_CREATE_ON,
        options: [
          {
            value: 'DELIVERY',
            label: G.getWindowLocale('titles:delivery', 'Delivery'),
          },
          {
            value: 'ORDER_CREATE',
            label: G.getWindowLocale('titles:create-order', 'Create Order'),
          },
          {
            value: 'TEL_CARRIER_INVOICE_CREATE',
            label: G.getWindowLocale('titles:carrier-invoice-create', 'Carrier Invoice Create'),
          },
        ],
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_ADD_DAYS]: {
        type: 'input',
        name: 'titles:add-days',
        // validate: G.isEmptyOrInteger,
        validation: G.yupIntegerNotRequired,
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_ADD_DAYS,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_DATE]: {
        type: 'select',
        name: 'titles:default-date',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_DATE,
        options: [
          { label: G.getWindowLocale('titles:creation', 'Creation'), value: 'CREATION_DATE' },
          { label: G.getWindowLocale('titles:delivery', 'Delivery'), value: 'DELIVERY_DATE' },
        ],
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_STATUS]: {
        type: 'select',
        options: {
          from: 'props',
          customConfigNameOnProps: 'customerInvoiceStatuses',
        },
        name: 'titles:default-status',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_STATUS,
      },
      [GC.INVOICE_CLO_SECOND_CUSTOMER_INVOICE_DEFAULT_STATUS]: {
        type: 'select',
        options: {
          from: 'props',
          customConfigNameOnProps: 'customerInvoiceStatuses',
        },
        name: 'titles:second-invoice-default-status',
        nameForAttribute: GC.INVOICE_CLO_SECOND_CUSTOMER_INVOICE_DEFAULT_STATUS,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_LOCK_STATUSES]: {
        zIndex: 18,
        type: 'list',
        options: 'customerInvoiceStatuses',
        component: MultiselectFieldComponent,
        name: 'titles:lock-invoice-statuses',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_LOCK_STATUSES,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_NET_DAYS]: {
        type: 'input',
        name: 'titles:net-days',
        // validate: validateDefaultInvoiceNetDays,
        validation: G.yupIntegerNotRequiredLessOrEqual(0, 10000),
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_NET_DAYS,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_MARK_AS_READY]: {
        type: 'switcher',
        name: 'titles:mark-as-ready',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_MARK_AS_READY,
      },
      [GC.INVOICE_CLO_INVOICE_NUMBER_USE_CLO_PRIM_REF]: {
        type: 'switcher',
        name: 'titles:use-clo-primary-reference',
        nameForAttribute: GC.INVOICE_CLO_INVOICE_NUMBER_USE_CLO_PRIM_REF,
      },
      [GC.INVOICE_CLO_INVOICE_NUMBER_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:number-autogenerated',
        nameForAttribute: GC.INVOICE_CLO_INVOICE_NUMBER_AUTOGENERATED,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_ADD_TO_CREDIT_ON_CLO_CANCEL_STATUS]: {
        type: 'switcher',
        name: 'titles:add-to-credit-on-order-cancel-status',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_ADD_TO_CREDIT_ON_CLO_CANCEL_STATUS,
      },
      [GC.INVOICE_CLO_INVOICE_NUMBER_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:number-sequence',
        nameForAttribute: GC.INVOICE_CLO_INVOICE_NUMBER_SEQUENCE,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_INTEGRATED_REFERENCE]: {
        type: 'select',
        options: 'cloReferenceTypes',
        name: 'titles:integrated-customer-reference',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_INTEGRATED_REFERENCE,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_AUTO_CREATE_CARRIER_INVOICE_STATUSES]: {
        zIndex: 17,
        type: 'list',
        options: 'carrierInvoiceStatuses',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:auto-create-carrier-invoice-statuses',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_AUTO_CREATE_CARRIER_INVOICE_STATUSES,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_MASS_MAIL_SUBJECT]: {
        type: 'text',
        name: 'titles:mass-mail-subject',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_MASS_MAIL_SUBJECT,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_MASS_MAIL_TEXT]: {
        type: 'text',
        name: 'titles:mass-mail-text',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_MASS_MAIL_TEXT,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_AUTO_CLO_REQUIRED_DOCUMENT_TYPES]: {
        zIndex: 16,
        type: 'list',
        options: 'availableDocumentTypes',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:auto-create-clo-required-doc-types',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_AUTO_CLO_REQUIRED_DOCUMENT_TYPES,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_AUTO_PICKUP_REQUIRED_DOCUMENT_TYPES]: {
        zIndex: 15,
        type: 'list',
        options: 'availableDocumentTypes',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:auto-create-pickup-required-doc-types',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_AUTO_PICKUP_REQUIRED_DOCUMENT_TYPES,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_AUTO_DROP_REQUIRED_DOCUMENT_TYPES]: {
        zIndex: 14,
        type: 'list',
        options: 'availableDocumentTypes',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:auto-create-drop-required-doc-types',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_AUTO_DROP_REQUIRED_DOCUMENT_TYPES,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_COST_ALLOCATION_SKIPPED_STATUSES]: {
        zIndex: 13,
        type: 'list',
        options: 'customerInvoiceStatuses',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:cost-allocation-skipped-statuses',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_COST_ALLOCATION_SKIPPED_STATUSES,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_COMPLETED_STATUS]: {
        type: 'select',
        name: 'titles:default-completed-status',
        options: 'completedCustomerInvoiceStatuses',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_DEFAULT_COMPLETED_STATUS,
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_FACTORING_FEE_TYPE]: {
        type: 'select',
        name: 'titles:factoring-fee-type',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_FACTORING_FEE_TYPE,
        options: [
          {
            value: GC.CHARGE_RATE_UNIT_TYPE_FLAT,
            label: G.getWindowLocale('titles:flat', 'Flat'),
          },
          {
            value: GC.CHARGE_RATE_UNIT_TYPE_PERCENT,
            label: G.getWindowLocale('titles:percent', 'Percent'),
          },
        ],
      },
      [GC.INVOICE_CLO_CUSTOMER_INVOICE_FACTORING_FEE_RATE]: {
        type: 'text',
        name: 'titles:factoring-fee-rate',
        nameForAttribute: GC.INVOICE_CLO_CUSTOMER_INVOICE_FACTORING_FEE_RATE,
        validate: [G.isEmptyOrFloat, G.curriedIsEmptyOrMinMaxNumber(0, 1000)],
      },
    },
  },
  {
    configType: 'custom',
    addPopupTitle: 'titles:add',
    fields: invoiceStatusesFields,
    editPopupTitle: 'titles:edit',
    configName: GC.INVOICE_CI_STATUS,
    customConfigName: GC.INVOICE_CI_STATUS,
    title: 'titles:customer-invoice-statuses',
  },
];

const INVOICE_CARRIER_INVOICES = [
  {
    title: 'titles:carrier-invoices',
    fields: {
      [GC.INVOICE_TEL_CARRIER_INVOICE_AUTO_CREATE_ON]: {
        type: 'select',
        name: 'titles:auto-create-on',
        nameForAttribute: GC.INVOICE_TEL_CARRIER_INVOICE_AUTO_CREATE_ON,
        options: [
          {
            label: G.getWindowLocale('titles:delivery', 'Delivery'),
            value: 'DELIVERY',
          },
          {
            label: G.getWindowLocale('titles:customer-invoice-create', 'Customer Invoice Create'),
            value: 'CUSTOMER_INVOICE_CREATE',
          },
        ],
      },
      [GC.INVOICE_TEL_CARRIER_INVOICE_ADD_DAYS]: {
        type: 'input',
        name: 'titles:add-days',
        // validate: G.isEmptyOrInteger,
        validation: G.yupIntegerNotRequired,
        nameForAttribute: GC.INVOICE_TEL_CARRIER_INVOICE_ADD_DAYS,
      },
      [GC.INVOICE_TEL_CARRIER_INVOICE_DEFAULT_DATE]: {
        type: 'select',
        name: 'titles:default-date',
        nameForAttribute: GC.INVOICE_TEL_CARRIER_INVOICE_DEFAULT_DATE,
        options: [
          { label: G.getWindowLocale('titles:creation', 'Creation'), value: 'CREATION_DATE' },
          { label: G.getWindowLocale('titles:delivery', 'Delivery'), value: 'DELIVERY_DATE' },
        ],
      },
      [GC.INVOICE_TEL_CARRIER_INVOICE_DEFAULT_STATUS]: {
        type: 'select',
        options: {
          from: 'props',
          customConfigNameOnProps: 'carrierInvoiceStatuses',
        },
        name: 'titles:default-status',
        nameForAttribute: GC.INVOICE_TEL_CARRIER_INVOICE_DEFAULT_STATUS,
      },
      [GC.INVOICE_TEL_SECOND_CARRIER_INVOICE_DEFAULT_STATUS]: {
        type: 'select',
        options: {
          from: 'props',
          customConfigNameOnProps: 'carrierInvoiceStatuses',
        },
        name: 'titles:second-invoice-default-status',
        nameForAttribute: GC.INVOICE_TEL_SECOND_CARRIER_INVOICE_DEFAULT_STATUS,
      },
      [GC.INVOICE_TEL_CARRIER_INVOICE_LOCK_STATUSES]: {
        type: 'list',
        options: 'carrierInvoiceStatuses',
        component: MultiselectFieldComponent,
        name: 'titles:lock-invoice-statuses',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.INVOICE_TEL_CARRIER_INVOICE_LOCK_STATUSES,
      },
      [GC.INVOICE_TEL_CARRIER_INVOICE_DEFAULT_NET_DAYS]: {
        type: 'input',
        name: 'titles:net-days',
        // validate: validateDefaultInvoiceNetDays,
        validation: G.yupIntegerNotRequiredLessOrEqual(0, 10000),
        nameForAttribute: GC.INVOICE_TEL_CARRIER_INVOICE_DEFAULT_NET_DAYS,
      },
      [GC.INVOICE_TEL_CARRIER_INVOICE_NUMBER_USE_TEL_PRIM_REF]: {
        type: 'switcher',
        name: 'titles:use-tel-primary-reference',
        nameForAttribute: GC.INVOICE_TEL_CARRIER_INVOICE_NUMBER_USE_TEL_PRIM_REF,
      },
      [GC.INVOICE_TEL_CARRIER_INVOICE_NUMBER_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:number-autogenerated',
        nameForAttribute: GC.INVOICE_TEL_CARRIER_INVOICE_NUMBER_AUTOGENERATED,
      },
      [GC.INVOICE_TEL_CARRIER_INVOICE_NUMBER_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:number-sequence',
        nameForAttribute: GC.INVOICE_TEL_CARRIER_INVOICE_NUMBER_SEQUENCE,
      },
      [GC.INVOICE_TEL_CARRIER_INVOICE_COST_ALLOCATION_SKIPPED_STATUSES]: {
        zIndex: 10,
        type: 'list',
        options: 'carrierInvoiceStatuses',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        name: 'titles:cost-allocation-skipped-statuses',
        nameForAttribute: GC.INVOICE_TEL_CARRIER_INVOICE_COST_ALLOCATION_SKIPPED_STATUSES,
      },
      [GC.INVOICE_TEL_SECOND_CARRIER_INVOICE_DEFAULT_COMPLETED_STATUS]: {
        type: 'select',
        name: 'titles:default-completed-status',
        options: 'completedCarrierInvoiceStatuses',
        nameForAttribute: GC.INVOICE_TEL_SECOND_CARRIER_INVOICE_DEFAULT_COMPLETED_STATUS,
      },
    },
  },
  {
    title: 'titles:service-vendor-invoices',
    fields: {
      [GC.INVOICE_TEL_SERVICE_VENDOR_INVOICE_NUMBER_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:number-autogenerated',
        nameForAttribute: GC.INVOICE_TEL_SERVICE_VENDOR_INVOICE_NUMBER_AUTOGENERATED,
      },
      [GC.INVOICE_TEL_SERVICE_VENDOR_INVOICE_NUMBER_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:number-sequence',
        nameForAttribute: GC.INVOICE_TEL_SERVICE_VENDOR_INVOICE_NUMBER_SEQUENCE,
      },
    },
  },
  {
    configType: 'custom',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    fields: invoiceStatusesFields,
    configName: GC.INVOICE_CARRIER_STATUS,
    title: 'titles:carrier-invoice-statuses',
    customConfigName: GC.INVOICE_CARRIER_STATUS,
  },
];

const INVOICE_GL_CODES = [
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:gl-code',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    configName: GC.INVOICE_GL_CODE,
  },
  {
    configType: 'custom',
    title: 'titles:gl-code-mapping',
    configName: GC.CUSTOM_GL_CODE_MAPPING_CONFIG,
    customConfigName: GC.CUSTOM_GL_CODE_MAPPING_CONFIG,
    additionalTitleComponent: ({ glCodeMappingFilter, setGLCodeMappingFilter }: Object) =>
      <ReactSelectComponent
        value={glCodeMappingFilter}
        handleChange={setGLCodeMappingFilter}
        options={getQBAccountMappingScopeOptions()}
        placeholder={G.getWindowLocale('titles:filter-by-scope', 'Filter By Scope')}
      />,
    fields: [
      {
        name: 'titles:scope',
        nameForAttribute: GC.FIELD_SCOPE,
        customLogic: (_: any, { scope }: Object) => G.toTitleCase(scope),
      },
      {
        tableColumnWith: '300px',
        name: 'titles:invoice-gl-code-type',
        nameForAttribute: GC.FIELD_GL_CODE_TYPE,
        customLogic: (_: any, { glCodeType }: Object) => G.getEnumLocale(glCodeType),
      },
      {
        name: 'titles:order-type',
        nameForAttribute: GC.FIELD_ORDER_TYPE_GUID,
        customLogic: (_: any, { orderTypeGuid }: Object, props: Object) => {
          const { configOptions } = props;

          return R.compose(
            R.pathOr('', [GC.FIELD_LABEL]),
            R.find(R.propEq(orderTypeGuid, GC.FIELD_VALUE)),
            R.pathOr([], ['orderType']),
          )(configOptions);
        },
      },
      {
        name: 'titles:invoice-gl-code',
        nameForAttribute: GC.FIELD_GL_CODE_GUID,
        customLogic: (_: any, { glCodeGuid }: Object, props: Object) => R.compose(
          R.pathOr('', [GC.FIELD_DISPLAYED_VALUE]),
          R.find((item: Object) => R.equals(glCodeGuid, G.getParentGuidOrGuidFromObject(item))),
          R.pathOr([], ['dropdowns', GC.INVOICE_GL_CODE, 'options']),
        )(props),
      },
      {
        name: 'titles:accessorial',
        nameForAttribute: GC.FIELD_ACCESSORIAL_CONFIG_GUID,
        customLogic: (_: any, { assessorialConfigGuid }: Object, props: Object) => R.compose(
          R.pathOr('', [GC.FIELD_DISPLAYED_VALUE]),
          R.find(R.propEq(assessorialConfigGuid, GC.FIELD_ORIGINAL_CONFIG_GUID)),
          R.pathOr([], ['configOptions', 'accessorialList']),
        )(props),
      },
      {
        name: 'titles:currency',
        nameForAttribute: GC.FIELD_CURRENCY,
      },
    ],
  },
];

const EXPENSE_TYPES = [
  {
    configType: 'custom',
    title: 'titles:expense-type',
    configName: GC.CUSTOM_EXPENSE_TYPES_CONFIG,
    customConfigName: GC.CUSTOM_EXPENSE_TYPES_CONFIG,
    fields: [
      {
        name: 'titles:name',
        nameForAttribute: GC.FIELD_NAME,
      },
      {
        name: 'titles:maintenance-type',
        nameForAttribute: GC.FIELD_CONFIGURATION_MAINTENANCE_TYPE,
        customLogic: (_: any, { maintenanceType }: Object) => G.getEnumLocale(maintenanceType),
      },
    ],
  },
];

const ConfigInvoicesGroup = {
  INVOICE_GENERAL,
  INVOICE_QUICK_BOOKS,
  INVOICE_SAGE_INTACCT,
  INVOICE_BUSINESS_CENTRAL,
  INVOICE_FLEET_INVOICES,
  INVOICE_CUSTOMER_INVOICES,
  INVOICE_CARRIER_INVOICES,
  INVOICE_GL_CODES,
  EXPENSE_TYPES,
};

export {
  ConfigInvoicesGroup,
};
