import * as R from 'ramda';
import * as P from 'plow-js';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// feature invoice
import { telInvoiceInitFields } from './settings/tel-fields';
import { cloInvoiceInitFields, serviceVendorInitFields } from './settings/fields-settings';
//////////////////////////////////////////////////

export const getGuidsFromStringOrSelectedList = (data: any, list: Array) => (
  G.ifElse(
    R.is(String, data),
    () => R.of(Array, data),
    () => R.compose(
      R.map((entity: Object) => entity.guid),
      R.filter((entity: Object) => entity.selected),
    )(list),
  )()
);

export const getItemListSuccess = (state: Object, data: Object) => {
  const { itemList, pagination } = state;

  const indexAdditional = G.ifElse(
    R.isNil(itemList),
    0,
    R.length(R.values(itemList)),
  );

  const newItems = data.results.map((item: Object, index: number) => R.mergeRight(
    item,
    {
      selected: false,
      index: R.add(index, indexAdditional),
    },
  ));

  const list = R.mergeRight(itemList, R.indexBy(R.prop('guid'), newItems));
  const newOffset = R.add(pagination.offset, pagination.limit);

  return P.$all(
    P.$set('itemList', list),
    P.$set('pageVisited', true),
    P.$set('pagination.limit', 10),
    P.$set('totalCount', data.totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(data.totalCount, newOffset),
        newOffset,
        data.totalCount,
      ),
    ),
    state,
  );
};

export const selectItem = (state: Object, id: Object) => {
  const { itemList } = state;
  if (R.equals(id, 'all')) {
    const value = R.not(R.all(
      (item: Object) => item.selected,
      R.values(itemList),
    ));
    return P.$set(
      'itemList',
      R.map(
        (item: Object) => R.assoc(
          'selected',
          value,
          item,
        ),
        itemList,
      ),
      state,
    );
  }
  return P.$toggle(`itemList.${id}.selected`, state);
};

export const changeInvoicesSuccess = (state: Object, { data, guids }: Object) => (
  P.$override(
    'itemList',
    R.map(
      (invoice: Object) => {
        const newParams = R.find(R.eqProps(GC.FIELD_GUID, invoice), data);

        const updatedInvoice = R.mergeRight(newParams, {
          [GC.FIELD_SELECTED]: false,
          [GC.GRC.STATUS_DISPLAYED_VALUE]: R.path([GC.FIELD_STATUS, GC.FIELD_DISPLAYED_VALUE], newParams),
        });

        return R.mergeRight(invoice, updatedInvoice);
      },
      R.pick(guids, P.$get('itemList', state)),
    ),
    state,
  )
);

export const readyForBillingSuccess = (state: Object, { data, guids }: Object) => {
  const invoicesList = R.pick(guids, P.$get('itemList', state));
  return P.$override(
    'itemList',
    R.map(
      (invoice: Object) => {
        const newProps = R.find(R.eqProps(GC.FIELD_GUID, invoice), data);
        const newPropsWithStatus = R.assoc(
          GC.GRC.STATUS_DISPLAYED_VALUE,
          R.path([GC.FIELD_STATUS, GC.FIELD_DISPLAYED_VALUE], newProps),
          newProps,
        );
        const newPropsWithSystemStatus = R.assoc(
          GC.FIELD_SYSTEM_STATUS,
          R.path([GC.FIELD_SYSTEM_STATUS], newPropsWithStatus),
          newPropsWithStatus,
        );
        return R.mergeRight(invoice, newPropsWithSystemStatus);
      },
      invoicesList,
    ),
    state,
  );
};

export const getRenderModal = (
  component: Object,
  title: string,
  id: string,
) => ({
  component,
  options: {
    title,
    width: '310px',
    height: 'auto',
    controlButtons: [
      {
        id,
        type: 'button',
        margin: '0 12px 0 0',
        name: G.getWindowLocale('actions:save', 'Save'),
      },
    ],
  },
});

export const getChangeInvoicesTitle = (invoiceGuid: string) => G.getWindowLocale(
  G.ifElse(G.isString(invoiceGuid), 'titles:edit-invoice', 'titles:change-invoices'),
  'Change Invoices',
);

export const setConfigOptionGuidToEntity = (initValues: Object) => (
  G.mapDropdownsObjectInEntity(
    GC.FIELD_CONFIG_GUID,
    [GC.FIELD_INVOICE_STATUS],
    G.mapDropdownsObjectInEntity(
      GC.FIELD_DROPDOWN_OPTION_GUID,
      [GC.FIELD_MODE, GC.FIELD_GL_CODE, GC.FIELD_SERVICE_TYPE],
      initValues,
    ),
  )
);

export const setConfigOptionGuidToEntity2 = (initValues: Object) => (
  G.mapDropdownsObjectInEntity(
    GC.FIELD_CONFIG_GUID,
    [GC.FIELD_INVOICE_STATUS],
    G.mapDropdownsObjectInEntity(
      GC.FIELD_DROPDOWN_OPTION_GUID,
      [GC.FIELD_GL_CODE, GC.FIELD_SERVICE_TYPE],
      initValues,
    ),
  )
);

export const setDefaultInvoiceValues = (props: Object, isTelInvoice: any) => {
  const {
    totalWeight,
    selectedRate,
    initialValues,
    totalDistance,
    defaultNetDays,
    defaultUomFields,
  } = props;

  let initFields = G.ifElse(isTelInvoice, telInvoiceInitFields, cloInvoiceInitFields);

  if (G.isNotNilAndNotEmpty(defaultNetDays)) {
    initFields = R.assoc(GC.FIELD_INVOICE_NET_DAYS, defaultNetDays, telInvoiceInitFields);
  }

  if (G.isNotNilAndNotEmpty(selectedRate)) {
    const fieldsToOmit = G.ifElse(
      isTelInvoice,
      [GC.FIELD_INVOICE_STATUS],
      [],
    );

    const data = R.mergeRight(initFields, setConfigOptionGuidToEntity(selectedRate));

    return R.omit(fieldsToOmit, data);
  }

  if (G.isNotNilAndNotEmpty(initialValues)) {
    const valuesToUse = G.ifElse(
      isTelInvoice,
      initialValues,
      R.assoc(
        GC.FIELD_GL_POSTED_DATE,
        R.pathOr(null, [GC.SYSTEM_OBJECT_ACCOUNTING_INTEGRATION, GC.FIELD_GL_POSTED_DATE], initialValues),
        initialValues,
      ),
    );

    const data = R.mergeRight(initFields, setConfigOptionGuidToEntity(valuesToUse));

    return data;
  }

  return G.setDefaultFieldsToRateInvoiceFormik({
    totalWeight,
    totalDistance,
    defaultUomFields,
    formikInitFields: initFields,
  });
};

export const setDefaultInvoiceValues2 = (props: Object) => {
  const { asyncConfigs, totalWeight, totalDistance, initialValues } = props;

  if (G.isNotNilAndNotEmpty(initialValues)) {
    const data = R.mergeRight(serviceVendorInitFields, setConfigOptionGuidToEntity2(initialValues));

    return data;
  }

  const defaultUomFields = G.getDefaultUomFields(asyncConfigs);
  const defaultCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, asyncConfigs);

  return G.setDefaultFieldsToRateInvoiceFormik2({
    totalWeight,
    totalDistance,
    defaultCurrency,
    defaultUomFields,
    formikInitFields: serviceVendorInitFields,
  });
};

export const getTotalsFromValues = (values: Object) => (
  R.pick(GC.GROUPED_FIELDS.TRIP_TOTALS_ARR, values)
);

const getInvoiceStatusOptions = (statusConfigName: string, props: Object) => ({
  status: G.mapCustomConfigOptionsFromProps(statusConfigName, props, true),
});

export const setOptionsForSelect = (statusConfigName: string, props: Object) => {
  const statusOptions = getInvoiceStatusOptions(statusConfigName, props);
  const configOptions = G.getModeServiceGLCodeFromAsyncConfigsForRateSelect(props);

  return R.mergeRight(statusOptions, configOptions);
};

export const setOptionsForServiceVendorSelect = (statusConfigName: string, props: Object) => {
  const statusOptions = getInvoiceStatusOptions(statusConfigName, props);
  const configOptions = G.getModeGLCodeFromAsyncConfigsForRateSelect(props);
  const serviceType = R.pathOr([], ['vendorServiceTypeOptions'], props);

  return R.assoc('serviceType', serviceType, R.mergeRight(statusOptions, configOptions));
};

export const getArrowIcon = (expanded: boolean) => G.ifElse(
  expanded,
  I.arrowUpSimple,
  I.arrowDownSimple,
)(G.getTheme('colors.light.mainLight'));

export const isValidCharge = (event: Object) => {
  const validExp = /^\d*(\.?\d*)$/;
  const decimal = R.pathOr('', ['1'], event.target.value.split('.'));
  const hasDecimal = G.ifElse(
    decimal.length,
    R.gte(2, decimal.length),
    true,
  );
  return R.and(validExp.test(event.target.value), hasDecimal);
};

const transformReferencesValue = (references: Array, availableTypes: Array) => R.map(
  (reference: Object) => {
    const referenceTypeGuid = G.getPropFromObject(GC.FIELD_REFERENCE_TYPE_GUID, reference);
    const referenceType = R.find(R.propEq(GC.FIELD_GUID, referenceTypeGuid), availableTypes);
    const allowedValues = R.pathOr([], [GC.FIELD_REFERENCE_ALLOWED_VALUES], referenceType);

    if (R.and(G.isNotNilAndNotEmpty(allowedValues), G.notContain(G.getValueFromObject(reference), allowedValues))) {
      return R.assoc(GC.FIELD_VALUE, '', reference);
    }

    return reference;
  },
  references,
);

export const getDataWithTransformedReferences = (data: Object, availableTypes: Array) => {
  const references = R.pathOr([], [GC.FIELD_REFERENCES], data);

  if (G.isNilOrEmpty(references)) return data;

  const availableReferenceTypesGuids = R.pluck(GC.FIELD_GUID, availableTypes);

  const referencesToUse = transformReferencesValue(
    R.filter(
      ({ referenceTypeGuid }: Object) => R.includes(referenceTypeGuid, availableReferenceTypesGuids),
      references,
    ),
    availableTypes,
    );

  return R.assoc(GC.FIELD_REFERENCES, referencesToUse, data);
};

export const invoiceNetDaysCustomBlurHandler = (event: Object, field: Object, props: Object) => {
  const { values, handleBlur, setFieldValue } = props;

  const { payments, invoiceDate } = values;

  const value = G.getEventTargetValue(event);

  if (G.isAllTrue(
    G.isNilOrEmpty(payments),
    G.isNotNilAndNotEmpty(value),
    G.isValidDate(invoiceDate),
  )) {
    const numberValue = G.toNumber(value);

    const newPaymentDueDate = G.addDateTimeWithFormat(invoiceDate, numberValue, 'days', GC.DEFAULT_DATE_FORMAT);

    setFieldValue(GC.FIELD_INVOICE_PAYMENT_DUE_DATE, newPaymentDueDate);
  }

  handleBlur(event);
};

export const invoiceNetDaysCustomChangeHandler = (event: Object, field: Object, props: Object) => {
  const { values, handleChange, setFieldValue } = props;

  const { payments, invoiceDate } = values;

  const value = G.getEventTargetValue(event);

  if (G.isAllTrue(
    G.isNilOrEmpty(payments),
    G.isNotNilAndNotEmpty(value),
    G.isValidDate(invoiceDate),
  )) {
    const numberValue = G.toNumber(value);

    const newPaymentDueDate = G.addDateTimeWithFormat(invoiceDate, numberValue, 'days', GC.DEFAULT_DATE_FORMAT);

    setFieldValue(GC.FIELD_INVOICE_PAYMENT_DUE_DATE, newPaymentDueDate);
  }

  handleChange(event);
};

export const invoiceDateCustomBlurHandler = (event: Object, field: Object, props: Object) => {
  const { values, handleBlur, setFieldValue } = props;

  const { netDays, payments } = values;

  const value = G.getEventTargetValue(event);

  if (G.isAllTrue(
    G.isValidDate(value),
    G.isNilOrEmpty(payments),
    G.isNotNilAndNotEmpty(netDays),
  )) {
    const numberNetDaysValue = G.toNumber(netDays);

    const newPaymentDueDate = G.addDateTimeWithFormat(
      value,
      numberNetDaysValue,
      'days',
      GC.DEFAULT_DATE_FORMAT,
    );

    setFieldValue(GC.FIELD_INVOICE_PAYMENT_DUE_DATE, newPaymentDueDate);
  }

  handleBlur(event);
};

export const invoiceDateCustomChangeHandler2 = ({ value, props }: Object) => {
  const { values, setFieldValue } = props;

  const { netDays, payments } = values;

  if (G.isAllTrue(
    G.isValidDate(value),
    G.isNilOrEmpty(payments),
    G.isNotNilAndNotEmpty(netDays),
  )) {
    const numberNetDaysValue = G.toNumber(netDays);

    const newPaymentDueDate = G.addDateTimeWithFormat(
      value,
      numberNetDaysValue,
      'days',
      GC.DEFAULT_DATE_FORMAT,
    );

    setFieldValue(GC.FIELD_INVOICE_PAYMENT_DUE_DATE, newPaymentDueDate);
  }

  setFieldValue(GC.FIELD_INVOICE_DATE, value);
};
