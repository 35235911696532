import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  STATE_OPTIONS,
  COUNTRY_OPTIONS,
  DEFAULT_WEIGHT_UOM_OPTIONS,
  DEFAULT_DISTANCE_UOM_OPTIONS,
  DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
  DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
} from '../../../../helpers/options';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 220,
};

const inputWrapperStylesForWiderFields = {
  mb: 25,
  width: 300,
};

const calendarInputWrapperStyles = {
  width: '100%',
};

export const generalFieldSettings = [
  {
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:name'],
    fieldName: GC.FIELD_DRIVER_COMPENSATION_NAME,
  },
  {
    inputWrapperStyles,
    label: ['titles:rate-minimum'],
    fieldName: GC.FIELD_CHARGE_MIN_RATE,
  },
  {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    calendarInputWrapperStyles,
    label: ['titles:effective-date'],
    fieldName: GC.FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE,
    maxDate: G.addDateTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    isRequired: true,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles,
    calendarInputWrapperStyles,
    minDate: G.getCurrentDate(),
    label: ['titles:expiration-date'],
    maxDate: G.addDateTime(G.getCurrentDate(), 3, 'years'),
    fieldName: GC.FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE,
  },
];

export const zoneFieldSettings = (prefix: string) => [
  {
    type: 'addressAutocomplete',
    label: ['titles:search-city'],
    fieldName: `${prefix}.searchCity`,
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 13 },
  },
  {
    type: 'multiItems',
    inputWrapperStyles,
    label: ['titles:cities'],
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_CITIES}`,
    placeholder: G.getWindowLocale('titles:enter-city-and-press-enter', 'Enter City and Press Enter'),
  },
  {
    isMulti: true,
    type: 'reactSelect',
    options: STATE_OPTIONS,
    label: ['titles:states'],
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 12 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_STATES}`,
  },
  {
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    options: COUNTRY_OPTIONS,
    label: ['titles:countries'],
    inputWrapperStyles: { ...inputWrapperStyles, zIndex: 12 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_COUNTRIES}`,
  },
  {
    inputWrapperStyles,
    type: 'addressAutocomplete',
    label: ['titles:search-zip'],
    fieldName: `${prefix}.searchZip`,
  },
  {
    isMulti: true,
    type: 'multiItems',
    inputWrapperStyles,
    label: ['titles:zip-codes'],
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES}`,
    placeholder: G.getWindowLocale('titles:enter-zip-and-press-enter', 'Enter Zip and Press Enter'),
  },
  {
    label: ['titles:zip-from'],
    inputWrapperStyles: { width: 220 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM}`,
  },
  {
    label: ['titles:zip-to'],
    inputWrapperStyles: { width: 220 },
    fieldName: `${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO}`,
  },
];

const weightRangeFieldSettings = [
  {
    label: ['titles:min-weight'],
    fieldName: GC.FIELD_MIN_WEIGHT,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
  {
    label: ['titles:max-weight'],
    fieldName: GC.FIELD_MAX_WEIGHT,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
  {
    type: 'select',
    label: ['titles:weight-uom'],
    fieldName: GC.FIELD_WEIGHT_UOM,
    options: DEFAULT_WEIGHT_UOM_OPTIONS,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
];

const quantityRangeFieldSettings = [
  {
    label: ['titles:min-quantity'],
    fieldName: GC.FIELD_MIN_QUANTITY,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
  {
    label: ['titles:max-quantity'],
    fieldName: GC.FIELD_MAX_QUANTITY,
    inputWrapperStyles: { ...inputWrapperStylesForWiderFields, mr: 346 },
  },
];

const volumeRangeFieldSettings = [
  {
    label: ['titles:max-volume'],
    fieldName: GC.FIELD_CARRIER_CONTRACT_MAX_VOLUME,
    inputWrapperStyles: inputWrapperStylesForWiderFields,
  },
  {
    type: 'select',
    label: ['titles:volume-uom'],
    options: DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
    fieldName: GC.FIELD_CARRIER_CONTRACT_VOLUME_UOM,
    inputWrapperStyles: { ...inputWrapperStylesForWiderFields, mr: 346 },
  },
];

const discountRangeFieldSettings = [
  {
    type: 'select',
    label: ['titles:discount-type'],
    inputWrapperStyles: inputWrapperStylesForWiderFields,
    fieldName: `${GC.FIELD_CHARGE_DISCOUNT}.${GC.FIELD_DISCOUNT_TYPE}`,
    options: [
      { label: '', value: '' },
      { value: GC.DISCOUNT_TYPE_FLAT, label: G.getWindowLocale('titles:flat', 'Flat') },
      { value: GC.DISCOUNT_TYPE_VARIABLE, label: G.getWindowLocale('titles:variable', 'Variable') },
    ],
  },
  {
    label: ['titles:discount-rate'],
    fieldName: `${GC.FIELD_CHARGE_DISCOUNT}.${GC.FIELD_RATE}`,
    inputWrapperStyles: {
      ...inputWrapperStylesForWiderFields,
      display: (props: Object) => G.ifElse(
        R.pathEq(GC.DISCOUNT_TYPE_FLAT, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
        'block',
        'none',
      ),
      visibility: (props: Object) => G.ifElse(
        R.pathEq(GC.DISCOUNT_TYPE_FLAT, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
        'visible',
        'hidden',
      ),
    },
  },
  {
    type: 'select',
    label: ['titles:discount-rate-unit'],
    options: DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS,
    fieldName: `${GC.FIELD_CHARGE_DISCOUNT}.${GC.FIELD_RATE_UNIT}`,
    inputWrapperStyles: {
      ...inputWrapperStylesForWiderFields,
      display: (props: Object) => G.ifElse(
        R.pathEq(GC.DISCOUNT_TYPE_FLAT, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
        'block',
        'none',
      ),
      visibility: (props: Object) => G.ifElse(
        R.pathEq(GC.DISCOUNT_TYPE_FLAT, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
        'visible',
        'hidden',
      ),
    },
  },
  {
    type: 'select',
    isRequired: true,
    label: ['titles:variable-type'],
    fieldName: `${GC.FIELD_CHARGE_DISCOUNT}.${GC.FIELD_VARIABLE_TYPE}`,
    inputWrapperStyles: {
      ...inputWrapperStylesForWiderFields,
      display: (props: Object) => G.ifElse(
        R.pathEq(GC.DISCOUNT_TYPE_VARIABLE, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
        'block',
        'none',
      ),
    },
    options: [
      GC.EMPTY_OPTION_OBJECT,
      { value: GC.CHARGE_RATE_TYPE_WEIGHT, label: G.getWindowLocale('titles:weight', 'Weight') },
      { value: GC.CHARGE_RATE_TYPE_DISTANCE, label: G.getWindowLocale('titles:distance', 'Distance') },
      { value: GC.CHARGE_RATE_TYPE_STOP, label: G.getWindowLocale('titles:stop', 'Stop') },
      { value: GC.CHARGE_RATE_TYPE_RATE_TOTAL, label: G.getWindowLocale('titles:rate-total', 'Rate Total') },
    ],
  },
  {
    type: 'select',
    isRequired: true,
    options: 'variableUnitOptions',
    label: ['titles:variable-unit'],
    fieldName: `${GC.FIELD_CHARGE_DISCOUNT}.${GC.FIELD_VARIABLE_UNIT}`,
    inputWrapperStyles: {
      ...inputWrapperStylesForWiderFields,
      display: (props: Object) => G.ifElse(
        R.pathEq(GC.DISCOUNT_TYPE_VARIABLE, ['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_DISCOUNT_TYPE], props),
        'block',
        'none',
      ),
      visibility: (props: Object) => G.ifElse(
        R.includes(
          R.path(['values', GC.FIELD_CHARGE_DISCOUNT, GC.FIELD_VARIABLE_TYPE], props),
          [GC.CHARGE_RATE_TYPE_WEIGHT, GC.CHARGE_RATE_TYPE_DISTANCE],
        ),
        'visible',
        'hidden',
      ),
    },
  },
];

const distanceRangeFieldSettings = [
  {
    label: ['titles:distance-range-from'],
    inputWrapperStyles: inputWrapperStylesForWiderFields,
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM,
  },
  {
    label: ['titles:distance-range-to'],
    inputWrapperStyles: inputWrapperStylesForWiderFields,
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO,
  },
  {
    type: 'select',
    label: ['titles:distance-range-uom'],
    inputWrapperStyles: inputWrapperStylesForWiderFields,
    fieldName: GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM,
    options: G.addEmptyOptionToDropDown(DEFAULT_DISTANCE_UOM_OPTIONS),
  },
];

export const compensationRangeSections = [
  {
    title: 'titles:weight',
    fieldSettings: weightRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), weightRangeFieldSettings),
  },
  {
    title: 'titles:distance',
    fieldSettings: distanceRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), distanceRangeFieldSettings),
  },
  {
    title: 'titles:quantity',
    fieldSettings: quantityRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), quantityRangeFieldSettings),
  },
  {
    title: 'titles:volume',
    fieldSettings: volumeRangeFieldSettings,
    fields: R.map(R.prop('fieldName'), volumeRangeFieldSettings),
  },
  {
    title: 'titles:discount',
    prefix: GC.FIELD_CHARGE_DISCOUNT,
    fieldSettings: discountRangeFieldSettings,
    fields: [GC.FIELD_RATE, GC.FIELD_RATE_UNIT, GC.FIELD_DISCOUNT_TYPE],
  },
];

const zoneSchema = {
  [GC.FIELD_DRIVER_COMPENSATION_COUNTRIES]: G.yupArrayRequired,
  [GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_FROM]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
  [GC.FIELD_DRIVER_COMPENSATION_ZIP_RANGE_TO]: Yup.string()
    .nullable(true)
    .notRequired()
    .max(20, G.getShouldBeFromToCharLocaleTxt(0, 20)),
};

const minMaxNumberValidation = G.yupNumberNotRequired
  .min(0, G.getShouldBeFromToCharLocaleTxt(0, 999999))
  .max(999999, G.getShouldBeFromToCharLocaleTxt(0, 999999));

export const validationSchema = Yup.lazy((values: Object) => {
  const {
    discount,
    minWeight,
    maxWeight,
    distanceRangeTo,
    distanceRangeFrom,
  } = values;

  const { rate, discountType, variableType } = R.or(discount, {});

  let schema = {
    [GC.FIELD_MAX_WEIGHT]: minMaxNumberValidation,
    [GC.FIELD_MIN_WEIGHT]: minMaxNumberValidation,
    [GC.FIELD_EFFECTIVE_DATE]: G.yupStringRequired,
    [GC.FIELD_EXPIRATION_DATE]: G.yupStringRequired,
    [GC.FIELD_MIN_QUANTITY]: minMaxNumberValidation,
    [GC.FIELD_MAX_QUANTITY]: minMaxNumberValidation,
    [GC.FIELD_ORIGIN]: Yup.object().shape(zoneSchema),
    [GC.FIELD_MIN_TEMPERATURE]: minMaxNumberValidation,
    [GC.FIELD_CHARGE_MIN_RATE]: minMaxNumberValidation,
    [GC.FIELD_MAX_TEMPERATURE]: minMaxNumberValidation,
    [GC.FIELD_DESTINATION]: Yup.object().shape(zoneSchema),
    [GC.FIELD_CARRIER_CONTRACT_MAX_VOLUME]: minMaxNumberValidation,
    [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO]: minMaxNumberValidation,
    [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM]: minMaxNumberValidation,
    [GC.FIELD_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt())
      .max(85, G.getShouldBeFromToCharLocaleTxt(0, 85)),
    [GC.FIELD_CHARGE_DISCOUNT]: Yup.object().nullable(true).shape({
      [GC.FIELD_RATE]: minMaxNumberValidation,
      [GC.FIELD_VARIABLE_TYPE]: G.ifElse(
        R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE),
        G.yupStringRequired,
      ),
      [GC.FIELD_RATE_UNIT]: G.ifElse(
        R.and(R.equals(discountType, GC.DISCOUNT_TYPE_FLAT), G.isNotNilAndNotEmpty(rate)),
        G.yupStringRequired,
      ),
      [GC.FIELD_VARIABLE_UNIT]: G.ifElse(
        R.and(
          R.equals(discountType, GC.DISCOUNT_TYPE_VARIABLE),
          R.includes(
            variableType,
            [GC.CHARGE_RATE_TYPE_WEIGHT, GC.CHARGE_RATE_TYPE_DISTANCE],
          ),
        ),
        G.yupStringRequired,
      ),
    }),
  };

  if (G.isOneNotNilOrNotEmpty([minWeight, maxWeight])) {
    schema = R.assoc(GC.FIELD_WEIGHT_UOM, G.yupStringRequired, schema);
  }

  if (G.isOneNotNilOrNotEmpty([distanceRangeTo, distanceRangeFrom])) {
    schema = R.assoc(GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM, G.yupStringRequired, schema);
  }

  return Yup.object().shape(schema);
});

export const defaultValues = {
  [GC.FIELD_NAME]: '',
  [GC.FIELD_WEIGHT_UOM]: '',
  [GC.FIELD_MIN_WEIGHT]: '',
  [GC.FIELD_MAX_WEIGHT]: '',
  [GC.FIELD_MIN_QUANTITY]: '',
  [GC.FIELD_MAX_QUANTITY]: '',
  [GC.FIELD_EFFECTIVE_DATE]: '',
  [GC.FIELD_CHARGE_MIN_RATE]: '',
  [GC.FIELD_EXPIRATION_DATE]: '',
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM]: '',
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO]: '',
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM]: '',
  [GC.FIELD_ORIGIN]: {
    [GC.FIELD_CARRIER_RATE_COUNTRIES]: null,
    [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO]: '',
    [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM]: '',
  },
  [GC.FIELD_DESTINATION]: {
    [GC.FIELD_CARRIER_RATE_COUNTRIES]: null,
    [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO]: '',
    [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM]: '',
  },
};

