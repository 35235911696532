import React from 'react';
import * as R from 'ramda';
import { css } from 'styled-components';
import { DivIcon, polygon } from 'leaflet';
import { Marker, TileLayer } from 'react-leaflet';
import { renderToString } from 'react-dom/server';
import { MapContainer } from 'react-leaflet/MapContainer';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withWindowSize } from '../../../hocs';
// components
import { StopMarker } from '../../../components/map/components/stop-components';
// ui
import { Box, StickedBox, RelativeBox } from '../../../ui';
//////////////////////////////////////////////////

const getMapCenterAndBounds = (locations: Array = []) => {
  if (G.isNilOrEmpty(locations)) return { mapCenter: [38.755157, -98.269035] };

  const bounds = R.map((latLng: Object) => R.values(latLng), locations);

  return {
    bounds,
    center: polygon(bounds).getBounds().getCenter(),
  };
};

const locationsWithContent = (stops: Array) => (
  R.map((stop: Object) => ({
    ...stop,
    markerContent: <StopMarker {...stop} />,
  }), stops)
);

const MarkersWithInfo = ({location}: Object) => (
  <Box transform='translate(0, -91%)'>
    <RelativeBox
      width={36}
      bg='white'
      height={36}
      cursor='pointer'
      border='5px solid'
      borderRadius='50%'
      transition='0.2s transform'
      borderColor={R.or(location.color, G.getTheme('map.markerColor'))}
      transform={`${G.ifElse(location.isOpen, 'scale(1.1)', 'scale(1)')} ${location.transform}`}
      css={css`
        &:hover {
          transform: scale(1.1) ${location.transform};
        }
        &:after {
          top: 26px;
          right: 50%;
          content: '';
          position: absolute;
          transform: translateX(50%);
          border: 13px solid transparent;
          border-top: 18px solid ${location.color || G.getTheme('map.markerColor')};
        }
      `}
    >
      <Box
        width='100%'
        height='100%'
        overflow='hidden'
        borderRadius='50%'
      >
        {location.markerContent}
      </Box>
    </RelativeBox>
  </Box>
);

const Markers = ({ markerLocations }: Array) => (
  markerLocations.map((location: Object, index: number) => {
    const { latLng } = location;

    const markerComponent = <MarkersWithInfo location={location} />;

    const icon = new DivIcon({
      iconSize: [36, 36],
      className: 'route-marker',
      html: renderToString(markerComponent),
    });

    const markerKey = `route-marker-${index}`;

    return <Marker key={markerKey} position={latLng} icon={icon} />;
  })
);

const OtherBlocksHeightSum = 435;

const DetailsMap = (props: Object) => {
  const {
    stops,
    locations,
  } = props;

  const markerLocations = G.makeLocationsWithTransform(locationsWithContent(stops));
  const { bounds, center } = getMapCenterAndBounds(locations);

  return (
    <StickedBox
      left='0'
      p='0 15px'
      width='100%'
    >
      <MapContainer
        zoom={4}
        maxZoom={18}
        bounds={bounds}
        center={center}
        css={css`
          z-index: 1;
          width: 100%;
          height: calc(100vh - ${OtherBlocksHeightSum}px);
        }`}
      >
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Markers markerLocations={markerLocations} />
      </MapContainer>
    </StickedBox>
  );
};

export default withWindowSize(DetailsMap);
