import styled, { css } from 'styled-components';
import { space, fontSize } from 'styled-system';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box, Flex, FlexHovered } from '../../../../ui';
//////////////////////////////////////////////////

export const WrapperCss = css`
  width: 100%;
`;

export const InnerPopover = styled.div`
  padding: 6px;
  display: flex;
  user-select: none;
  align-items: start;
  position: relative;
  border-radius: 4px;
  word-break: break-all;
  flex-direction: column;
  justify-content: space-between;
  background: ${G.getTheme('colors.light.lightGrey')};
  box-shadow:
    0px 5px 5px -3px ${G.getTheme('colors.boxShadowGrey')},
    0px 8px 10px 1px ${G.getTheme('colors.boxShadowGrey')},
    0px 3px 14px 2px ${G.getTheme('colors.boxShadowGrey')};

  &:before {
    content: '';
    left: -6px;
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    top: calc(50% - 14px);
    transform: rotate(45deg);
    background: ${G.getTheme('colors.light.lightGrey')};
  };
`;

export const LinksList = styled.div`
  ${space}
  ${fontSize}

  width: 100%;
  display: flex;
  padding: 6px 0;
  user-select: none;
  align-items: start;
  word-break: break-all;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ color }: Object) => color};
  cursor: ${({ cursor }: Object) => cursor};
  font-weight: ${({ fontWeight }: Object) => fontWeight};

  & > div {
    z-index: 1;
    width: 100%;
    border-radius: 4px;
    padding: ${({ p }: Object) => p || '6px'};
    background-color: ${({ backgroundColor }: Object) => backgroundColor};
    &:hover {
      color: ${({ hoverColor }: Object) => hoverColor};
      cursor: ${({ hoverCursor }: Object) => hoverCursor};
      background-color: ${({ hoverBackgroundColor }: Object) => hoverBackgroundColor};
    }
  }
`;

export const LabelSmall = styled.div`
  display: block;
  font-size: 8px;
  max-width: 50px;
  overflow: hidden;
  line-height: 9px;
  font-style: normal;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ color }: Object) => color || G.getTheme('colors.white')};
`;

export const Divider = styled.div`
  height: 0;
  margin: 12px 8px;
  border: 1.5px solid;
  width: calc(100% - 16px);
  border-color: ${({ borderColor }: Object) => borderColor || G.getTheme('colors.light.navbarButtonHoverBackground')};
`;

export const PinnedScrollbar = styled(Flex)`
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NavbarButton = styled(FlexHovered)`
  position: relative;
  white-space: nowrap;
  background: ${({ active, editPinned }: Object) => (
    G.ifElse(
      editPinned,
      G.getTheme('colors.light.navbarButtonEditBackground'),
      G.ifElse(
        active,
        G.getTheme('colors.light.navbarButtonHoverBackground'),
        'transparent',
      ),
    )
  )};
`;

export const ActiveSign = styled(Box)`
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  position: absolute;
`;

const whiteColor = G.getTheme('colors.white');

export const PinnedItemUI = styled(Box)`
  width: 100%;
  position: relative;

  ${({ isHighlighted }: Object) => isHighlighted && `
    &::before {
      content: '';
      top: -3px;
      width: 5px;
      height: 5px;
      zIndex:9999;
      opacity: 0.5;
      margin-left: -5px;
      position: absolute;
      border-radius: 50%;
      border: 2px solid ${whiteColor};
    }
  `}
`;

export const GhostDivider = styled(Box)`
  ${({ isHighlighted }: Object) => isHighlighted && `
    &::before {
      top: 0;
      content: '';
      width: 100%;
      height: 2px;
      zIndex:9999;
      opacity: 0.5;
      margin-left: 3px;
      position: absolute;
      background-color: ${whiteColor};
    }
  `}
`;

export const DeleteButton = styled(Box)`
  top: 0;
  right: 0px;
  width: 18px;
  height: 18px;
  margin: 3px 0;
  cursor: pointer;
  padding: 0px 3px;
  position: absolute;
  border-radius: 50%;
  background: ${G.getTheme('colors.light.mainLight')};
  border: 1px solid ${G.getTheme('colors.light.darkRed')};
  & svg {
    width: 10px;
    height: 16px;
  }
`;

