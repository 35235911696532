import * as R from 'ramda';
import { createSelector } from 'reselect';
// features
import PC from '../permission/role-permission';
// helpers/constants
import * as GC from '../../constants';
//////////////////////////////////////////////////

const selectAuthStore = (state: Object) => state.auth;
const selectSessionData = (state: Object) => state.auth.sessionData;
const selectLoggedInUserData = (state: Object) => state.auth.loginData;

const makeSelectAuthData = () => createSelector(
  selectAuthStore,
  (auth: Object) => ({ ...auth }),
);

const makeSelectCurrentUserLoggedInStatus = () => createSelector(
  selectAuthStore,
  (auth: Object) => auth.loggedIn,
);

const makeSelectCurrentUserData = () => createSelector(
  selectLoggedInUserData,
  selectSessionData,
  (loginData: Object | null, sessionData: Object | null) => {
    if (sessionData) return sessionData;

    if (loginData) return loginData;

    return null;
  },
);

const makeSelectCurrentUserGuid = () => createSelector(
  selectLoggedInUserData,
  selectSessionData,
  (loginData: Object | null, sessionData: Object | null) => {
    let userGuid = null;

    if (sessionData) {
      userGuid = sessionData.user_guid;
    } else if (loginData) {
      userGuid = loginData.user_guid;
    }

    return userGuid;
  },
);

const makeSelectCurrentUserName = () => createSelector(
  selectLoggedInUserData,
  selectSessionData,
  (loginData: Object | null, sessionData: Object | null) => {
    let userName = '';

    if (sessionData) {
      userName = sessionData.login_id;
    } else if (loginData) {
      userName = loginData.login_id;
    }

    return userName;
  },
);

const makeSelectCurrentUserBranchGuid = () => createSelector(
  selectLoggedInUserData,
  selectSessionData,
  (loginData: Object | null, sessionData: Object | null) => {
    let branchGuid = null;

    if (sessionData) {
      branchGuid = sessionData[GC.FIELD_BRANCH_UNDERSCORE_GUID];
    } else if (loginData) {
      branchGuid = loginData[GC.FIELD_BRANCH_UNDERSCORE_GUID];
    }

    return branchGuid;
  },
);

const makeSelectIsCurrentUserSuperAdmin = () => createSelector(
  selectLoggedInUserData,
  selectSessionData,
  (loginData: Object | null, sessionData: Object | null) => {
    let isSuperAdmin = false;

    if (sessionData) {
      isSuperAdmin = R.includes(PC.ROLE_LIUBANCHYK_SUPER_ADMIN, sessionData.authorities);
    } else if (loginData) {
      isSuperAdmin = R.includes(PC.ROLE_LIUBANCHYK_SUPER_ADMIN, loginData.authorities);
    }

    return isSuperAdmin;
  },
);

export {
  selectAuthStore,
  selectSessionData,
  makeSelectAuthData,
  selectLoggedInUserData,
  makeSelectCurrentUserData,
  makeSelectCurrentUserName,
  makeSelectCurrentUserGuid,
  makeSelectCurrentUserBranchGuid,
  makeSelectIsCurrentUserSuperAdmin,
  makeSelectCurrentUserLoggedInStatus,
};
