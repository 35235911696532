import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

const EmptyList = ({ children }: Object) => (
  <Box
    py={50}
    width='100%'
    fontSize={36}
    textAlign='center'
    color='light.lightGrey'
  >
    {children}
  </Box>
);

const CalendarWrapper = styled(Flex)`
  & .react-datepicker__navigation {
    z-index: 11;
  }
  & input {
    color: ${({ color }: Object) => color || 'unset'};
    border-color: ${({ borderColor }: Object) => borderColor || G.getTheme('forms.inputs.borderColor')};
  }
`;

const TableWrapper = ({ maxH, children }: Object) => (
  <Box
    width='100%'
    overflow='auto'
    maxHeight={R.or(maxH, 500)}
    border={`1px solid ${G.getTheme('tables.rows.borderColor')}`}
  >
    {children}
  </Box>
);

const TableInner = ({ children, minWidth }: Object) => <Box minWidth={minWidth}>{children}</Box>;

const setTableRowBackground = (props: Object) => R.or(
  props.bgColor,
  G.getTheme(G.ifElse(
    props.active,
    G.ifElse(
      props.mainColors,
      'tables.rows.bgColorActive',
      'tables.rows.additionalBgColor',
    ),
    'tables.rows.bgColor',
  )),
);

const setTableBorderBottom = (props: Object) => {
  const { active, mainColors, lightGreyBorderBottom } = props;

  if (G.isTrue(lightGreyBorderBottom)) return G.getTheme('colors.lightGrey');

  return G.getTheme(G.ifElse(
    active,
    G.ifElse(
      mainColors,
      'tables.rows.borderColorActive',
      'tables.rows.additionalBorderColor',
    ),
    ' ',
  ));
};

const TableRow = ({ children, height = 40, ...props }: Object) => (
  <Flex
    {...props}
    minWidth='100%'
    minHeight={height}
    width='fit-content'
    bg={setTableRowBackground(props)}
    borderBottom={`1px solid ${setTableBorderBottom(props)}`}
  >
    {children}
  </Flex>
);

export {
  TableRow,
  EmptyList,
  TableInner,
  TableWrapper,
  CalendarWrapper,
};
