import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// forms
import { Fieldset2 } from '../../forms';
// ui
import { Box, Flex, Form, ActionButton, RelativeFlex } from '../../ui';
// feature auth
import { LegalInfo } from './legal-info';
import {
  StyledLinkTo,
  StyledFormTitle,
  StyledFormSubtitle,
} from './ui';
//////////////////////////////////////////////////

const AuthForm = ({
  props,
  title,
  fields,
  subtitle,
  mbLinkTo,
  widthLogo,
  widthForm,
  textAlign,
  heightLogo,
  pathLinkTo,
  actionLinkTo,
  isSubmitting,
  handleChange,
  handleSubmit,
  maxWidthForm,
  withLegalInfo,
  displayLinkTo,
  fontSizeTitle,
  widthSubmitBtn,
  actionSubmitBtn,
  heightSubmitBtn,
  displaySubtitle,
  borderRadiusBtn,
  puddingSubmitBtn,
  fontSizeSubmitBtn,
  displayInputsBlock,
  splashScreenSetting,
  alignSelfInputsBlock,
  justifySelfInputsBlock,
  justifyContentInputsBlock }: Object,
) => (
  <Flex
    height='100%'
    flexDirection='column'
    justifyContent='center'
    width={R.or(widthForm, '37%')}
  >
    <Box mb={70} textAlign='left'>
      <img
        alt='Logo'
        width={widthLogo}
        height={heightLogo}
        src={R.or(R.path(['logoUrl'], splashScreenSetting), G.getNewLogoLink())}
      />
    </Box>
    <Form
      px={48}
      width='100%'
      position='relative'
      onSubmit={handleSubmit}
      maxWidth={R.or(maxWidthForm, 500)}
    >
      <StyledFormTitle textAlign={textAlign} fontSizeTitle={fontSizeTitle}>
        {title}
      </StyledFormTitle>
      <StyledFormSubtitle textAlign={textAlign} displaySubtitle={displaySubtitle}>
        {subtitle}
      </StyledFormSubtitle>
      <RelativeFlex
        mt={47}
        display={displayInputsBlock}
        alignSelf={alignSelfInputsBlock}
        justifySelf={justifySelfInputsBlock}
        justifyContent={justifyContentInputsBlock}
      >
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={fields}
          flexDirection='column'
          justifyContent='center'
          fieldsGroupMargin='0px'
          handleChange={handleChange}
        />
        <Flex
          mb={mbLinkTo}
          alignItems='center'
          display={displayLinkTo}
          justifyContent='flex-end'
        >
          <StyledLinkTo mt={12} fontSize={12} to={R.or(pathLinkTo, '')}>
            {actionLinkTo}
          </StyledLinkTo>
        </Flex>
      </RelativeFlex>
      <ActionButton
        type='submit'
        fontWeight='bold'
        p={puddingSubmitBtn}
        width={widthSubmitBtn}
        disabled={isSubmitting}
        fontSize={fontSizeSubmitBtn}
        height={R.or(heightSubmitBtn, 42)}
        textColor={G.getTheme('colors.black')}
        borderRadius={R.or(borderRadiusBtn, 4)}
        bgColor={R.pathOr(G.getTheme('colors.lime'), ['buttonBackgroundColor'], splashScreenSetting)}
      >
        {actionSubmitBtn}
      </ActionButton>
    </Form>
    {G.isTrue(withLegalInfo) && <LegalInfo />}
  </Flex>
  );

export default AuthForm;
