import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// features
import { makeSelectAppReleaseByExternalUrl } from '../../../app-release/selectors';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box, Flex, Text } from '../../../../ui';
// feature navbar-menu
import { ReleaseVersionLink } from './ui';
//////////////////////////////////////////////////

const VersionRelease = ({
  iconsColor,
  latestRelease,
}: Object) => (
  <Box color={iconsColor}>
    <Flex
      p='4px 10px'
      justifyContent='space-between'
      background={G.getTheme('colors.light.navbarToggle')}
    >
      <Text fontSize={12}>{G.getWindowLocale('titles:panel-hide', 'Hide Panel')}</Text>
      {
        latestRelease &&
        <ReleaseVersionLink
          target='_blank'
          withHover={true}
          href={latestRelease.url}
        >
          {G.getWindowLocale('titles:version', 'Version')} {latestRelease.version}
        </ReleaseVersionLink>
      }
    </Flex>
  </Box>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  latestRelease: makeSelectAppReleaseByExternalUrl(state),
}));

export default connect(mapStateToProps)(VersionRelease);
