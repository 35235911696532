import * as R from 'ramda';
import React, {
  useMemo,
  Fragment,
  createRef,
  useEffect,
} from 'react';
// components
import { TextComponent } from '../../text';
// features
import {
  setName,
  filterTypesWithMuiTitleInput,
} from '../../table/components/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import {
  Flex,
  IconWrapper,
  RelativeFlex,
} from '../../../ui';
// component virtualized-table
import TitleSort from './title-sort';
import TitleFilter from './title-filter';
import { CheckBoxComponent } from './table-components';
import { StickyRowWrapper, TitleCellWrapper } from '../ui';
//////////////////////////////////////////////////

const RegularTitleCell = ({
  name,
  width,
  fontSize,
  fieldName,
  fieldProps,
  titleSortValue,
  titleFilterValue,
  customTitleComponent,
  handleTableTitleFilter,
}: Object) => {
  return (
    <Fragment>
      {
        G.isFunction(customTitleComponent) &&
        customTitleComponent(fieldName)
      }
      {
        R.or(
          R.and(fieldProps.disableFilter, fieldProps.disableSort),
          G.notContain(fieldProps.type, filterTypesWithMuiTitleInput),
        ) &&
        <Flex
          maxWidth='100%'
          overflow='hidden'
          alignItems='center'
          height='-webkit-fill-available'
        >
          <TextComponent title={name} fontSize={fontSize} withEllipsis={true} color={G.getTheme('colors.textDark')}>
            {name}
          </TextComponent>
        </Flex>
      }
      {
        R.and(
          !(fieldProps.disableFilter && fieldProps.disableSort),
          G.isNotNilAndNotEmpty(fieldProps),
        ) && (
        <RelativeFlex
          width='calc(100% - 25px)'
          height='-webkit-fill-available'
          maxWidth={R.subtract(width, 40)}
        >
          {
            fieldProps.disableFilter ?
              <TextComponent title={name} fontSize={fontSize} withEllipsis={true} color={G.getTheme('colors.textDark')}>
                {name}
              </TextComponent> :
              <TitleFilter
                title={name}
                width='100%'
                fontSize={fontSize}
                fieldProps={fieldProps}
                useNewTitleFilterInputs={true}
                titleFilterValue={titleFilterValue}
                handleTableTitleFilter={handleTableTitleFilter}
              />
          }
          {
            R.not(fieldProps.disableSort) && (
            <TitleSort
              fieldProps={fieldProps}
              titleSortValue={titleSortValue}
              handleTableTitleFilter={handleTableTitleFilter}
            />
          )}
        </RelativeFlex>
      )}
    </Fragment>
  );
};

const InnerTitleCell = ({
  width,
  fontSize,
  fieldName,
  allChecked,
  fieldProps,
  onOptionClick,
  columnSettings,
  titleSortValue,
  titleFilterValue,
  customTitleComponent,
  handleClickResetIcon,
  handleTableTitleFilter,
} : Object) => {
  if (R.equals(fieldName, 'checkboxes')) {
    return (
      <CheckBoxComponent
        id='all'
        name='select all'
        mainColors={true}
        justifyContent='center'
        checked={Boolean(allChecked)}
        onOptionClick={onOptionClick}
      />
    );
  } else if (R.equals(fieldName, 'leftActions')) {
    return '';
  } else if (R.equals(fieldName, 'rightActions')) {
    return (
      <Flex
        p='8px'
        height='100%'
        flexDirection='column'
        justifyContent='center'
        title='Reset table columns width'
      >
        <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickResetIcon(e)}>
          {I.threeDots()}
        </IconWrapper>
      </Flex>
    );
  }

  const name = setName({ name: fieldName }, { columnSettings });

  return (
    <RegularTitleCell
      name={name}
      width={width}
      fontSize={fontSize}
      fieldName={fieldName}
      fieldProps={fieldProps}
      columnSettings={columnSettings}
      titleSortValue={titleSortValue}
      titleFilterValue={titleFilterValue}
      customTitleComponent={customTitleComponent}
      handleTableTitleFilter={handleTableTitleFilter}
    />
  );
};

const HeaderRow = ({
  style,
  columns,
  fontSize,
  allChecked,
  stickyLeft,
  filterProps,
  onOptionClick,
  columnSettings,
  resizeObserver,
  titleSortValues,
  withRightActions,
  titleFilterValues,
  handleClickResetIcon,
  handleTableTitleFilter,
} : Object) => {
  let indent = 0;

  const elementsRefs = useMemo(() => columns.map(() => createRef()), [columns]);

  useEffect(() => {
    if (R.not(resizeObserver)) return;

    columns.forEach((column: Object, columnIndex: number) => {
      const { resizable, fieldName } = column;

      if (!resizable) return;

      const titleRef = elementsRefs[columnIndex];
      const el = titleRef.current;

      if (R.not(el)) return;

      el[GC.FIELD_NAME] = fieldName;
      resizeObserver.observe(el);
    });
  }, [resizeObserver, elementsRefs]);

  const headerTitles = columns.map((column: Object, columnIndex: number) => {
    const {
      name,
      width,
      fieldName,
      resizable,
      defaultWidth,
      customTitleComponent,
    } = column;

    const titleSortValue = R.pathOr({}, [fieldName], titleSortValues);
    const titleFilterValue = R.pathOr({}, [fieldName], titleFilterValues);
    const fieldProps = R.pathOr({}, [fieldName], filterProps);
    const cellWidth = `${width || 100}px`;
    const cellPadding = R.pathOr('4px 8px', ['styles', 'padding'], column);

    let replaceStyle = {};

    if (R.lt(columnIndex, stickyLeft)) {
      replaceStyle = {
        zIndex: 999,
        left: indent,
        right: 'auto',
        position: 'sticky',
      };
    }

    if (withRightActions && R.equals(columnIndex, R.dec(R.length(columns)))) {
      replaceStyle = {
        right: 0,
        zIndex: 999,
        left: 'auto',
        position: 'sticky',
        textAlign: 'right',
        paddingRight: '10px',
      };
    }

    indent += width;

    const cellRef = elementsRefs[columnIndex];

    const innerTitleCellProps = {
      width,
      fontSize,
      fieldName,
      allChecked,
      fieldProps,
      onOptionClick,
      columnSettings,
      titleSortValue,
      titleFilterValue,
      customTitleComponent,
      handleClickResetIcon,
      handleTableTitleFilter,
    };

    return (
      <TitleCellWrapper
        ref={cellRef}
        height='100%'
        p={cellPadding}
        width={cellWidth}
        fontSize={fontSize}
        key={`title-${name}`}
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='center'
        minWidth={R.divide(defaultWidth, 2)}
        maxWidth={R.multiply(defaultWidth, 2)}
        resize={G.ifElse(resizable, 'horizontal', 'none')}
        overflow={G.ifElse(resizable, 'hidden', 'inherit')}
        style={{ ...style, width: cellWidth, ...replaceStyle }}
      >
        <InnerTitleCell {...innerTitleCellProps} />
      </TitleCellWrapper>
    );
  });

  return (
    <StickyRowWrapper style={style}>
      <Flex>
        {headerTitles}
      </Flex>
    </StickyRowWrapper>
  );
};

export default HeaderRow;
