import { space, width } from 'styled-system';
import styled, { keyframes } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Button } from '../../ui';
//////////////////////////////////////////////////

export const FormSection = styled('section')`
  ${space}
  ${width}

  display: flex;
  flex-wrap: wrap;
  position: static;

  z-index: ${({ zIndex }: Object) => zIndex || 'initial'};
  justify-content: ${({ justifyContent }: Object) => justifyContent};
  flex-direction: ${({ flexDirection }: Object) => flexDirection || 'row'};
`;

export const DispatchButton = styled(Button)`
  height: 20px;
  min-width: 65px;
  border-radius: 2px;
  color: ${() => G.getTheme('buttons.dispatchBtn.textColor')};
  background-color: ${() => G.getTheme('buttons.dispatchBtn.bgColor')};
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const PulseButton = styled('div')`
  animation: ${pulse} ease-in-out infinite both;
  animation-duration: ${({ animationDuration }: Object) => animationDuration};

  &:hover {
    animation-duration: 0s;
  }
`;
