// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { getCarrierEdiTransactionTypeOptions } from '../../../helpers/options';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'selectMultiple',
    value: GC.FIELD_STATUS,
    name: G.getWindowLocale('titles:status', 'Status'),
    placeholder: { text: 'Choose', key: 'titles:choose' },
    options: [
      {
        value: GC.EDI_STATUS_TYPE_ERROR,
        label: G.getEnumLocale(GC.EDI_STATUS_TYPE_ERROR),
      },
      {
        value: GC.EDI_STATUS_TYPE_UNASSIGNED,
        label: G.getEnumLocale(GC.EDI_STATUS_TYPE_UNASSIGNED),
      },
      {
        value: GC.EDI_STATUS_TYPE_PENDING,
        label: G.getEnumLocale(GC.EDI_STATUS_TYPE_PENDING),
      },
      {
        value: GC.EDI_STATUS_TYPE_ACCEPTED,
        label: G.getEnumLocale(GC.EDI_STATUS_TYPE_ACCEPTED),
      },
      {
        value: GC.EDI_STATUS_TYPE_DECLINED,
        label: G.getEnumLocale(GC.EDI_STATUS_TYPE_DECLINED),
      },
      {
        value: GC.EDI_STATUS_TYPE_RETRYING,
        label: G.getEnumLocale(GC.EDI_STATUS_TYPE_RETRYING),
      },
    ],
  },
  {
    type: 'boolean',
    value: GC.FIELD_EDI_PROCESSED,
    name: G.getWindowLocale('titles:processed', 'Processed'),
  },
  {
    type: 'string',
    value: GC.FIELD_EDI_SENDER_ID,
    name: G.getWindowLocale('titles:sender-id', 'Sender ID'),
  },
  {
    type: 'string',
    value: GC.FIELD_EDI_EXTERNAL_ID,
    name: G.getWindowLocale('titles:external-id', 'External ID'),
  },
  {
    type: 'date',
    value: GC.FIELD_EDI_RECEIVED_DATE,
    name: G.getWindowLocale('titles:received-date', 'Received Date'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_EDI_TRANSACTION_TYPE,
    options: getCarrierEdiTransactionTypeOptions(),
    placeholder: { text: 'Choose', key: 'titles:choose' },
    name: G.getWindowLocale('titles:transaction-type', 'Transaction Type'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_DIRECTION,
    name: G.getWindowLocale('titles:direction', 'Direction'),
    options: [
      {
        value: GC.EDI_DIRECTION_TYPE_INBOUND,
        label: G.getEnumLocale(GC.EDI_DIRECTION_TYPE_INBOUND),
      },
      {
        value: GC.EDI_DIRECTION_TYPE_OUTBOUND,
        label: G.getEnumLocale(GC.EDI_DIRECTION_TYPE_OUTBOUND),
      },
    ],
  },
  {
    type: 'date',
    value: GC.FIELD_EDI_TRANSACTION_DATE,
    name: G.getWindowLocale('titles:transaction-date', 'Transaction Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_EDI_ORIGINAL_FILE_NAME,
    name: G.getWindowLocale('titles:file-name', 'File Name'),
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:clo', 'CLO'),
    value: GC.GRC.EDI_CLO_PRIMARY_REFERENCE_VALUE,
  },
  {
    type: 'string',
    value: GC.GRC.EDI_CLO_TRANSACTION_ACTION,
    name: G.getWindowLocale('titles:transaction-action', 'Transaction Action'),
  },
  {
    type: 'string',
    value: GC.GRC.EDI_CREATED_CLO_PRIMARY_REFERENCE_VALUE,
    name: G.getWindowLocale('titles:created-order', 'Created Order'),
  },
  {
    type: 'string',
    value: GC.GRC.EDI_CREATED_CLO_STATUS,
    name: `${G.getWindowLocale('titles:created-order', 'Created Order')}: ${
      G.getWindowLocale('titles:status', 'Status')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_EXPIRATION_DATE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:expiration-date', 'Expiration Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_LOCATION_NAME,
    name: G.getWindowLocale('titles:bill-to', 'Bill To'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_LOCATION_TYPE_DISPLAYED,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:type', 'Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_COUNTRY,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_STATE,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_CITY,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_ADDRESS1,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_ZIP,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_PAYMENT_TERM,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:payment-terms', 'Payment Terms')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_CONTACT_NAME,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:contact-name', 'titles:contact-name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_PHONE,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:phone', 'titles:phone')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_BILL_TO_COMMENTS,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:comments', 'titles:comments')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_FIRST_EVENT_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:clo-first-event', 'Order First Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:clo-first-event', 'Order First Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:clo-first-event', 'Order First Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:clo-first-event', 'Order First Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:clo-first-event', 'Order First Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_LAST_EVENT_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:clo-last-event', 'Order Last Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:clo-last-event', 'Order Last Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:clo-last-event', 'Order Last Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:clo-last-event', 'Order Last Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:clo-last-event', 'Order Last Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
];
