import * as R from 'ramda';
import { put, call } from 'redux-saga/effects';
// common
import { showRequestStatusModal } from '../../../common/actions';
// features
import {
  getItemListRequest as getCarrierInvoices,
  resetListAndPagination as resetCarrierInvoiceListAndPagination,
} from '../../invoice/carrier/actions';
import {
  getItemListRequest as getCustomerInvoices,
  resetListAndPagination as resetCustomerInvoiceListAndPagination,
} from '../../invoice/customer/actions';
import {
  getItemListRequest as getServiceVendorInvoices,
  resetListAndPagination as resetServiceVendorInvoiceListAndPagination,
} from '../../invoice/service-vendor/actions';
import {
  getItemListRequest as getVendorInvoices,
  resetListAndPagination as resetVendorInvoiceListAndPagination,
} from '../../invoice/vendor/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature sockets-v2
import * as A from '../actions';
import * as C from '../constants';
//////////////////////////////////////////////////

const messageTypes = R.prop('genericMessageType', C);

function* handleGenericMessageReceived(payload: Object) {
  const { data, socketDataType } = payload;

  switch (socketDataType) {
    case messageTypes.chatMessage:
      yield put(A.socketTelChatMessageReceived(payload));
      yield put(A.socketCarrierPortalReceived(payload));
      break;
    case messageTypes.loadStatus:
      yield put(A.socketTelLoadStatusReceived(data));
      break;
    case messageTypes.rateStatus:
      yield put(A.socketTelRateStatusReceived(data));
      break;
    case messageTypes.eventStatus:
      yield put(A.socketTelEventStatusReceived(payload));
      break;
    case messageTypes.statusMessage:
      yield put(A.socketTelStatusMessageReceived(payload));
      break;
    case messageTypes.document:
      yield put(A.socketTelDocumentReceived(payload));
      break;
    case messageTypes.costAllocation:
      yield put(A.socketTelCostAllocationsReceived(payload));
      break;
    case messageTypes.rate:
      yield put(A.socketTelRateReceived(payload));
      break;
    case messageTypes.cloStatusToTel:
      yield put(A.socketCloStatusToTelReceived(payload));
      break;
    case messageTypes.driverCardsTel:
      yield put(A.socketDriverCardsTelReceived(payload));
      break;
    case messageTypes.availableDriversNote:
      yield put(A.socketAvailableDriversNoteReceived(payload));
      break;
    case messageTypes.availableDriversComments:
      yield put(A.socketAvailableDriversCommentsReceived(payload));
      break;
    case messageTypes.availableDriversStatus:
      yield put(A.socketAvailableDriversStatusReceived(payload));
      break;
    case messageTypes.availableDriversReservation:
      yield put(A.socketAvailableDriversReservationReceived(payload));
      break;
    case messageTypes.availableDriversLastKnownLocation:
      yield put(A.socketAvailableDriversLocationReceived(payload));
      break;
    case messageTypes.generatedDocumentUrl:
      yield put(A.socketUserDocumentGeneratedReceivedSuccess(data));

      if (G.isString(data)) {
        yield call(G.showToastrMessage, 'info', data);
      } else {
        const url = R.path([GC.FIELD_DOCUMENT_URL], data);
        const fileName = R.path([GC.FIELD_DOCUMENT_DOCUMENT_NAME], data);

        if (R.isNil(window.amousSocketUserDocumentGeneratedReceived)) {
          window.amousSocketUserDocumentGeneratedReceived = [];
        }

        window.amousSocketUserDocumentGeneratedReceived.push({ url, fileName });
      }
      break;
    case messageTypes.ifta:
      const message = G.getWindowLocale('titles:ifta-report-competed', 'IFTA Report Completed');

      yield call(G.showToastrMessage, 'success', message);
      break;
    case messageTypes.ediExportResult:
    case messageTypes.exportToAccounting:
      const { status, totalCount, successCount } = data;

      const errors = R.compose(
        R.values,
        R.mapObjIndexed((messageArray: array, key: string) => ({ key, messageArray })),
        R.pathOr({}, ['errors']),
      )(data);

      const pageType = R.path(['type'], payload);
      const currentPage = R.path(['location', 'pathname'], window);

      if (R.and(R.equals(pageType, 'CUSTOMER'), R.equals(currentPage, GC.ROUTE_PATH_CUSTOMER_INVOICES_LIST))) {
        yield put(resetCustomerInvoiceListAndPagination());
        yield put(getCustomerInvoices(true));
      } else if (R.and(R.equals(pageType, 'CARRIER'), R.equals(currentPage, GC.ROUTE_PATH_CARRIER_INVOICES_LIST))) {
        yield put(resetCarrierInvoiceListAndPagination());
        yield put(getCarrierInvoices(true));
      } else if (R.and(
        R.equals(pageType, 'SERVICE_VENDOR'),
        R.equals(currentPage, GC.ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST),
      )) {
        yield put(resetServiceVendorInvoiceListAndPagination());
        yield put(getServiceVendorInvoices(true));
      } else if (R.and(
        R.equals(pageType, 'FLEET_VENDOR'),
        R.equals(currentPage, GC.ROUTE_PATH_VENDOR_INVOICES_LIST),
      )) {
        yield put(resetVendorInvoiceListAndPagination());
        yield put(getVendorInvoices(true));
      }

      const exportResultTypeLocaleArr = G.ifElse(
        R.equals(socketDataType, messageTypes.ediExportResult),
        ['titles:edi', 'EDI'],
        ['titles:accounting', 'Accounting'],
      );

      const title = `${G.getWindowLocale(...exportResultTypeLocaleArr)} ${
        G.getWindowLocale('titles:export-result', 'Export Result')}`;

      yield put(showRequestStatusModal({
        title,
        status,
        errors,
        totalCount,
        successCount,
      }));
      break;
    case messageTypes.frontError:
    case messageTypes.generalError:
    case messageTypes.cloCreateError:
    case messageTypes.macroPointError:
    case messageTypes.masterInvoiceExportError:
    case messageTypes.carrierLoadBoardSyncError:
      console.log('//////////////////// SOCKET_FRONT_ERROR', socketDataType, payload, '////////////////////');
      break;
    default:
  }
}

export default handleGenericMessageReceived;
