import * as R from 'ramda';
import React, {
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
// components
import {
  ActionButton,
  CheckBoxComponent,
} from '../../table/components/table-components';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex } from '../../../ui';
// component virtualized-table
import {
  RowWrapper,
  CellWrapper,
  EditableCellWrapper,
} from '../ui';
import VirtualizedListContext from './context';
//////////////////////////////////////////////////

const InnerCell = ({
  value,
  columns,
  rowData,
  fontSize,
  cellWidth,
  fieldName,
  columnIndex,
  onOptionClick,
  actionButtons,
  customComponent,
  withRightActions,
}: Object) => {
  if (R.equals(fieldName, 'checkboxes')) {
    const guid = G.getGuidFromObject(rowData);

    return (
      <CheckBoxComponent
        id={guid}
        name={guid}
        item={rowData}
        mainColors={true}
        justifyContent='center'
        onOptionClick={onOptionClick}
        checked={Boolean(rowData.selected)}
      />
    );
  } else if (R.equals(fieldName, 'leftActions')) {
    return (
      <Flex height='100%' width='100%'>
        {
        G.isNotNilAndNotEmpty(actionButtons) &&
          actionButtons.map((actionData: Object, i: number) => (
            <ActionButton key={i} item={rowData} actionData={actionData} />
          ))
        }
      </Flex>
    );
  }

  if (withRightActions && R.equals(columnIndex, R.dec(R.length(columns)))) {
    return (
      <Flex
        p='8px'
        height='100%'
        flexDirection='column'
        justifyContent='center'
        title='Reset table columns width'
      />
    );
  }

  if (customComponent) {
    return (
      <Flex height='100%' width='100%' fontSize={fontSize}>
        {customComponent({ data: rowData, width: cellWidth })}
      </Flex>
    );
  }

  return (
    <Flex height='100%' width='100%' fontSize={fontSize}>
      {value || '-'}
    </Flex>
  );
};

const EditableCell = memo(({
  index,
  cellProps,
  replaceStyle,
  withBoxShadow,
  setEditedValue,
}: Object) => {
  const inputRef = useRef();

  const [editing, setEditing] = useState(false);

  const { value, columnIndex, fieldName } = cellProps;

  const [cellValue, setCellValue] = useState(value);

  const onEndEditingHandle = useCallback(() => {
    const newValue = G.ifElse(
      R.equals(typeof value, 'number'),
      +cellValue,
      cellValue,
    );

    setEditing(false);

    inputRef.current = undefined;

    if (isNaN(newValue)) return;

    if (G.notEquals(value, newValue)) setEditedValue([index, columnIndex, fieldName], newValue);
  }, [cellValue]);

  const onEditHandle = useCallback(() => {
    setEditing(true);
  }, []);

  const onCellValueChange = useCallback((e: Object) => {
    const target = e.currentTarget;

    setCellValue(target.value);
  }, []);

  useEffect(() => () => {
    // Fix for scroll when editing
    if (R.equals(typeof inputRef.current, 'function')) {
      inputRef.current();
    }
  }, []);

  useEffect(() => {
    if (editing) {
      inputRef.current = onEndEditingHandle;
    } else {
      inputRef.current = undefined;
    }
  }, [editing, onEndEditingHandle]);

  if (editing) {
    return (
      <EditableCellWrapper
        p='4px 8px'
        style={{ ...replaceStyle }}
        withBoxShadow={withBoxShadow}
      >
        <input
          autoFocus={true}
          defaultValue={cellValue}
          onBlur={onEndEditingHandle}
          onChange={onCellValueChange}
        />
      </EditableCellWrapper>
    );
  }

  return (
    <CellWrapper
      p='4px 8px'
      cursor='pointer'
      onClick={onEditHandle}
      style={{ ...replaceStyle }}
      withBoxShadow={withBoxShadow}
    >
      <InnerCell {...cellProps} />
    </CellWrapper>
  );
});

const RowCells = memo(({
  index,
  columns,
  fontSize,
  listData,
  stickyLeft,
  actionButtons,
  onOptionClick,
  setEditedValue,
  withRightActions,
} : Object) => {
  let indent = 0;

  const handleClickRowActionsIcon = () => {};

  return columns.map((column: Object, columnIndex: number) => {
    const {
      name,
      width,
      styles,
      editable,
      fieldName,
      customComponent,
    } = column;

    const rowData = listData[R.dec(index)];
    const value = rowData[fieldName];
    const cellWidth = `${width || 100}px`;

    let replaceStyle = {
      left: indent,
      width: cellWidth,
      minWidth: cellWidth,
    };

    if (R.lt(columnIndex, stickyLeft)) {
      replaceStyle = {
        ...replaceStyle,
        zIndex: 998,
        position: 'sticky',
        background: 'inherit',
      };
    }

    if (withRightActions && R.equals(columnIndex, R.dec(R.length(columns)))) {
      replaceStyle = {
        ...replaceStyle,
        right: 0,
        zIndex: 998,
        left: 'auto',
        position: 'sticky',
        textAlign: 'right',
        paddingRight: '10px',
        background: 'inherit',
      };
    }

    indent += width;

    const withBoxShadow = R.equals(columnIndex, R.dec(stickyLeft));

    const cellProps = {
      value,
      columns,
      rowData,
      fontSize,
      cellWidth,
      fieldName,
      columnIndex,
      onOptionClick,
      actionButtons,
      customComponent,
      withRightActions,
      handleClickRowActionsIcon,
    };

    if (editable) {
      return (
        <EditableCell
          key={name}
          name={name}
          index={index}
          cellProps={cellProps}
          withBoxShadow={withBoxShadow}
          setEditedValue={setEditedValue}
          replaceStyle={{ ...replaceStyle }}
        />
      );
    }

    return (
      <CellWrapper
        key={name}
        style={{ ...replaceStyle }}
        withBoxShadow={withBoxShadow}
        p={R.propOr('4px 8px', 'padding', styles)}
      >
        <InnerCell {...cellProps} />
      </CellWrapper>
    );
  });
});

export const Row = memo(({ index, style }: Object) => (
  <VirtualizedListContext.Consumer>
    {(props: Object) => {
      const { listData, rowTopMargin } = props;

      const rowData = listData[R.dec(index)];

      const textDecoration = G.ifElse(
        G.isTrue(rowData.cancelled),
        'line-through',
        'none',
      );

      return (
        <RowWrapper
          highlighted={rowData.highlighted}
          style={{ ...style, textDecoration, marginTop: rowTopMargin }}
        >
          <Flex
            height='100%'
            position='relative'
            background='inherit'
          >
            <RowCells index={index} style={style} {...props} />
          </Flex>
        </RowWrapper>
      );
    }}
  </VirtualizedListContext.Consumer>
));
