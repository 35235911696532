import React from 'react';
//
import XlsxViewer from './xlsx-viewer';
import DocxViewer from './docx-viewer';
import ImageViewer from './image-viewer';
//////////////////////////////////////////////////

const FileViewer = ({ blob, fileType, filePath, onError }: Object) => {
  switch (fileType) {
    case 'csv':
    case 'xls':
    case 'xlsx':
      return <XlsxViewer blob={blob} filePath={filePath} onError={onError} />;
    case 'docx':
      return <DocxViewer blob={blob} filePath={filePath} />;
    case 'png':
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'jpeg':
      return <ImageViewer filePath={filePath} />;
    default:
      return <p><b>{`.${fileType}`}</b> is not supported.</p>;
  }
};

export default FileViewer;
