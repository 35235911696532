import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// features
import { socketUserDocumentGeneratedReceivedSuccess } from '../sockets-v2/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature clo
import * as A from './actions';
import * as H from './helpers';
import { ADD_MASTER_INVOICE } from './constants';
//////////////////////////////////////////////////

const initialState = {
  totalCount: 0,
  loading: false,
  itemList: null,
  pagination: {
    limit: 20,
    offset: 0,
  },
  filterParams: {},
  usedReport: null,
  titleSortValues: {},
  reportPending: false,
  updatedInvoice: null,
  tableTitleFilters: {},
  availableReports: null,
  recallDocumentsGuid: null,
  currentBranchConfigs: null,
  availableFactoringTypes: {},
};

const setInitialState = () => initialState;

const setReportPending = (state: Object) => (
  P.$set('reportPending', true, state)
);

const setReports = (state: Object, data: Array) => (
  P.$set('availableReports', data, state)
);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('usedReport', data),
    P.$set('titleSortValues', {}),
    P.$set('reportPending', false),
    P.$set('tableTitleFilters', {}),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setFilterProps = (state: Object, data: Array) => (
  P.$set('filterProps', data, state)
);

const cleanQuickFilter = (state: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('filterParams', {}),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setQuickFilterParams = (state: Object, data: Object) => (
  P.$set('filterParams', data, state)
);

const getConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$set('currentBranchConfigs', data, state)
);

const setListLoading = (state: Object, data: boolean) => (
  P.$set('loading', data, state)
);

const resetListAndPagination = (state: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const getItemListSuccess = (state: Object, data: Object) => {
  const { itemList, pagination } = state;
  const newOffset = R.add(pagination.offset, pagination.limit);
  const indexAdditional = G.ifElse(
    R.isNil(itemList),
    0,
    R.length(R.values(itemList)),
  );
  const newMasterInvoices = data.results.map((p: Object, i: number) => R.mergeRight(
    p,
    {
      selected: false,
      expanded: false,
      index: R.add(i, indexAdditional),
    },
  ));
  const newList = R.mergeRight(itemList, R.indexBy(R.prop('guid'), newMasterInvoices));
  return P.$all(
    P.$set('itemList', newList),
    P.$set('pagination.limit', 10),
    P.$set('totalCount', data.totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(data.totalCount, newOffset),
        newOffset,
        data.totalCount,
      ),
    ),
    state,
  );
};

const selectItem = (state: Object, data: string) => {
  if (R.equals(data, 'all')) {
    const { itemList } = state;
    const value = R.not(R.all(
      (item: Object) => item.selected,
      R.values(itemList),
    ));
    return P.$set(
      'itemList',
      R.map(
        (item: Object) => R.assoc('selected', value, item),
        itemList,
      ),
      state,
    );
  }
  return P.$toggle(`itemList.${data}.selected`, state);
};

const toggleMasterInvoiceDetails = (state: Object, data: string) => (
  P.$toggle(`itemList.${data}.expanded`, state)
);

const setMasterInvoiceDetailsLoading = (state: Object, data: string) => (
  P.$set(`itemList.${data}.detailsLoading`, true, state)
);

const getMasterInvoiceDetailsSuccess = (state: Object, data: Object) => (
  P.$set(
    `itemList.${data.guid}`,
    R.mergeRight(
      R.path(['itemList', data.guid], state),
      {
        detailsError: false,
        detailsLoading: false,
        details: data.details,
      },
    ),
    state,
  )
);

const getMasterInvoiceDetailsError = (state: Object, data: string) => (
  P.$all(
    P.$set(`itemList.${data}.detailsError`, true),
    P.$set(`itemList.${data}.detailsLoading`, false),
    state,
  )
);

const collapseAndResetMasterInvoiceInvoices = (state: Object, data: string) => (
  P.$set(
    `itemList.${data.guid}`,
    R.mergeRight(
      R.path(['itemList', data.guid], state),
      {
        ...data,
        details: null,
        expanded: false,
      },
    ),
    state,
  )
);

const removeMasterInvoiceInvoiceFromUI = (state: Object, data: Object) => {
  const guid = R.path(['masterInvoice', 'guid'], data);
  const item = R.path(['itemList', guid], state);

  let masterInvoice = R.mergeRight(item, data.masterInvoice);

  if (G.isNotNil(item.details)) {
    masterInvoice = R.assoc(
      'details',
      R.filter(
        (invoice: Object) => G.notEquals(invoice.guid, data.invoiceGuid),
        item.details,
      ),
      masterInvoice,
    );
  }

  return P.$set(`itemList.${guid}`, masterInvoice, state);
};

const removeMasterInvoiceFromUI = (state: Object, data: string) => (
  P.$all(
    P.$drop(`itemList.${data}`),
    P.$set('totalCount', R.dec(state.totalCount)),
    state,
  )
);

const updateInvoiceSuccess = (state: Object, data: string) => {
  const { invoice, updateFrom } = data;

  const mappedInvoice = G.mapDropdownsObjectInEntity(
    GC.FIELD_DISPLAYED_VALUE,
    [GC.FIELD_INVOICE_STATUS, GC.FIELD_MODE],
    invoice,
  );

  if (R.equals(updateFrom, ADD_MASTER_INVOICE)) {
    return P.$set('updatedInvoice', mappedInvoice, state);
  }

  const masterInvoiceGuid = R.prop(GC.FIELD_INVOICE_MASTER_INVOICE_GUID, invoice);
  const invoiceGuid = R.prop(GC.FIELD_GUID, invoice);
  const grandTotal = R.path(['mastersTotals', 'grandTotal'], invoice);
  const invoicesTotal = R.path(['mastersTotals', 'invoicesTotal'], invoice);
  const currentDetails = R.path(['itemList', masterInvoiceGuid, 'details'], state);

  let newMasterInvoice = {
    ...R.path(['itemList', masterInvoiceGuid], state),
    grandTotal,
    invoicesTotal,
  };

  if (G.isNotNil(currentDetails)) {
    const details = R.compose(
      R.map((item: Object) => {
        if (R.equals(G.getGuidFromObject(item), invoiceGuid)) {
          return R.mergeRight(
            mappedInvoice,
            {
              documents: item.documents,
              cloPrimaryReferenceValue: item.cloPrimaryReferenceValue,
              ...H.getInvoiceChargesFromResponse(mappedInvoice.charges),
            },
          );
        }

        return item;
      }),
      R.path(['itemList', masterInvoiceGuid, 'details']),
    )(state);

    newMasterInvoice = {
      ...newMasterInvoice,
      details,
    };
  }


  return P.$set(`itemList.${masterInvoiceGuid}`, newMasterInvoice, state);
};

const getAvailableFactoringTypesSuccess = (state: Object, data: Object) => (
  P.$set('availableFactoringTypes', data, state)
);

const onSocketUserDocumentGeneratedReceivedSuccess = (state: Object) => (
  P.$set('recallDocumentsGuid', G.generateGuid(), state)
);

const getAvailableCustomExportTypesSuccess = (state: Object, data: Object) => (
  P.$set('availableCustomExportTypes', data, state)
);

export default createReducer({
  [A.selectItem]: selectItem,
  [A.setReports]: setReports,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setFilterProps]: setFilterProps,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: G.setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: G.setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.updateInvoiceSuccess]: updateInvoiceSuccess,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.getConfigsByNamesSuccess]: getConfigsByNamesSuccess,
  [A.removeMasterInvoiceFromUI]: removeMasterInvoiceFromUI,
  [A.toggleMasterInvoiceDetails]: toggleMasterInvoiceDetails,
  [A.getMasterInvoiceDetailsError]: getMasterInvoiceDetailsError,
  [A.setMasterInvoiceDetailsLoading]: setMasterInvoiceDetailsLoading,
  [A.getMasterInvoiceDetailsSuccess]: getMasterInvoiceDetailsSuccess,
  [A.removeMasterInvoiceInvoiceFromUI]: removeMasterInvoiceInvoiceFromUI,
  [A.getAvailableFactoringTypesSuccess]: getAvailableFactoringTypesSuccess,
  [A.getAvailableCustomExportTypesSuccess]: getAvailableCustomExportTypesSuccess,
  [A.collapseAndResetMasterInvoiceInvoices]: collapseAndResetMasterInvoiceInvoices,
  //
  [socketUserDocumentGeneratedReceivedSuccess]: onSocketUserDocumentGeneratedReceivedSuccess,
}, initialState);
