import * as R from 'ramda';
import React from 'react';
import { compose, withHandlers } from 'react-recompose';
import { Field, FieldArray, getIn } from 'formik';
// components
import { TextComponent } from '../../../components/text';
// features
import PC from '../../permission/role-permission';
// forms
import { Checkbox, SelectWrapper, renderOptions } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import withChargeComments from '../../../hocs/with-charge-comments';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  StickedBox,
  ChargeInput as Input,
  ChargeInputSelect as InputSelect,
} from '../../../ui';
// feature master-invoice
import { calculateChargesTotal } from '../helpers';
import {
  chargeInitFields,
  getMasterInvoiceChargeFieldset,
} from '../settings/master-invoice-with-charges-settings';
//////////////////////////////////////////////////

export const ChargesSectionHeader = (props: Object) => (
  <StickedBox
    top='0'
    p='10px 0'
    zIndex={11}
    display='block'
    bg={G.getTheme('modal.bgColor')}
  >
    <Flex>
      <TextComponent
        p='2px 10px'
        fontSize={14}
        fontWeight={700}
        borderRadius='3px'
        display='inline-block'
        bg={G.getTheme('colors.dark.lightGrey')}
        color={G.getTheme('colors.light.black')}
      >
        {G.getWindowLocale('titles:additional-charges', 'Additional Charges')}
      </TextComponent>
      <Box
        mx='5px'
        cursor='pointer'
        title='Add new charge'
        onClick={() => props.push(chargeInitFields)}
      >
        {I.plusRound()}
      </Box>
      <TextComponent
        ml={25}
        fontSize={12}
        fontWeight={700}
        display='inline-block'
        color={G.getTheme('colors.light.black')}
      >
        {G.getWindowLocale('titles:discount', 'Discount')}
      </TextComponent>
    </Flex>
  </StickedBox>
);

export const ChargesSectionFooter = (props: Object) => {
  const {
    currency,
    grandTotal,
    invoicesTotal,
    discountTotal,
    additionalCharges,
  } = props;

  return (
    <StickedBox
      bottom='0'
      p='10px 0'
      zIndex={11}
      display='block'
      bg={G.getTheme('modal.bgColor')}
    >
      <Flex justifyContent='flex-end'>
        {
          G.isNotNaN(discountTotal) && G.isNotNilAndNotEmpty(discountTotal) &&
          <TextComponent
            p='2px 10px'
            fontSize={14}
            fontWeight={700}
            borderRadius='3px'
            display='inline-block'
            color={G.getTheme('colors.white')}
            bg={G.getTheme('colors.light.mainRed')}
          >
            {
              `${G.getWindowLocale('titles:discount', 'Discount')}: ${currency} ${G.toFixed(discountTotal)}`
            }
          </TextComponent>
        }
        {
          G.isNotNaN(additionalCharges) && G.isNotNilAndNotEmpty(additionalCharges) &&
          <TextComponent
            ml={15}
            p='2px 10px'
            fontSize={14}
            fontWeight={700}
            borderRadius='3px'
            display='inline-block'
            color={G.getTheme('colors.white')}
            bg={G.getTheme('colors.light.green')}
          >
            {
              `${G.getWindowLocale('titles:additional-charges', 'Additional Charges')}: ${
                currency} ${G.toFixed(additionalCharges)}`
            }
          </TextComponent>
        }
        {
          G.isNotNaN(invoicesTotal) && G.isNotNilAndNotEmpty(invoicesTotal) &&
          <TextComponent
            ml={15}
            p='2px 10px'
            fontSize={14}
            fontWeight={700}
            borderRadius='3px'
            display='inline-block'
            color={G.getTheme('colors.white')}
            bg={G.getTheme('colors.light.blueGrey')}
          >
            {
              `${G.getWindowLocale('titles:total-clo-charges', 'Total Order Charges')}: ${
                currency} ${G.toFixed(invoicesTotal)}`
            }
          </TextComponent>
        }
        {
          G.isNotNaN(grandTotal) && G.isNotNilAndNotEmpty(grandTotal) &&
          <TextComponent
            ml={15}
            p='2px 10px'
            fontSize={14}
            fontWeight={700}
            borderRadius='3px'
            display='inline-block'
            color={G.getTheme('colors.white')}
            bg={G.getTheme('colors.light.blue')}
          >
            {
              `${G.getWindowLocale('titles:gross-total', 'Gross Total')}: ${currency} ${G.toFixed(grandTotal)}`
            }
          </TextComponent>
        }
      </Flex>
    </StickedBox>
  );
};

export const getOptionsFromAccessorials = R.compose(
  R.prepend(GC.EMPTY_OPTION_NULLABLE_OBJECT),
  R.map((item: Object) => ({
    value: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
    label: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
  })),
);

export const setFieldOptions = (
  { options, fieldName }: Object,
  chargeRateType: string,
  accessorialsConfigs: Array,
  glCodeOptions: Array,
) => {
  if (R.equals(fieldName, GC.FIELD_CHARGE_RATE_NAME)) {
    return getOptionsFromAccessorials(accessorialsConfigs);
  } else if (R.equals(fieldName, GC.FIELD_GL_CODE)) {
    return glCodeOptions;
  }

  return options;
};

export const accessorialFieldsToOmit = [
  GC.FIELD_GUID,
  GC.FIELD_VERSION,
  GC.FIELD_CREATED_BY,
  GC.FIELD_CREATED_DATE,
  GC.FIELD_BRANCH_GUID,
  GC.FIELD_LAST_MODIFIED_BY,
  GC.FIELD_ACCESSORIAL_COPY,
  GC.FIELD_LAST_MODIFIED_DATE,
  GC.FIELD_ACCESSORIAL_SERVICE,
  GC.FIELD_ACCESSORIAL_PARENT_GUID,
  GC.FIELD_ACCESSORIAL_FUEL_RELATED,
  GC.FIELD_ACCESSORIAL_TEL_INVOICE_GL,
  GC.FIELD_ACCESSORIAL_CLO_INVOICE_GL,
  GC.FIELD_ACCESSORIAL_ORIGINAL_CONFIG_GUID,
];

export const getChargeFields = (
  fieldValue: string,
  accessorials: Array,
  initCharge: Object,
) => R.compose(
  R.mergeRight(initCharge),
  R.omit(accessorialFieldsToOmit),
  G.renameKeys({ [GC.FIELD_ACCESSORIAL_ORIGINAL_CONFIG_GUID]: GC.FIELD_ACCESSORIAL_CONFIG_GUID }),
  R.find(R.propEq(fieldValue, GC.FIELD_ACCESSORIAL_DISPLAYED_VALUE)),
)(accessorials);

export const getChargeFieldName = R.compose(
  R.last(),
  R.split('.'),
);

export const isRateOrQuantityChargeField = (fieldName: string) => R.or(
  R.equals(GC.FIELD_CHARGE_RATE, fieldName),
  R.equals(GC.FIELD_CHARGE_QUANTITY, fieldName),
);

export const recalculateChargesOnChangeAccessorial = ({
  value,
  values,
  chargeIndex,
  accessorialsConfigs,
}: Object) => R.update(
  chargeIndex,
  getChargeFields(value, accessorialsConfigs, chargeInitFields),
  R.pathOr([], [GC.FIELD_MASTER_INVOICE_CHARGES], values),
);

export const recalculateChargesOnChangeRateOrQuantity = ({
  value,
  values,
  chargeIndex,
  chargeFieldName,
}: Object) => {
  const charges = R.pathOr([], [GC.FIELD_MASTER_INVOICE_CHARGES], values);
  const currentChargeWithValue = R.assoc(chargeFieldName, value, R.nth(chargeIndex, charges));
  const currentChargeWithTotal = R.assoc(
    GC.FIELD_CHARGE_TOTAL,
    R.multiply(
      R.propOr(1, GC.FIELD_CHARGE_RATE, currentChargeWithValue),
      R.propOr(1, GC.FIELD_CHARGE_QUANTITY, currentChargeWithValue),
    ),
    currentChargeWithValue,
  );

  return R.update(chargeIndex, currentChargeWithTotal, charges);
};

export const addCommentsToPayrollCharges = ({
  value,
  values,
  chargeIndex,
}: Object) => {
  const charges = R.pathOr([], [GC.FIELD_PAYROLL_CHARGES], values);
  const currentChargeWithComments = R.assoc(GC.FIELD_CHARGE_COMMENTS, value, R.nth(chargeIndex, charges));

  return R.update(chargeIndex, currentChargeWithComments, charges);
};

export const enhanceChargeFields = compose(
  withHandlers({
    handleChangeInput: (props: Object) => (inputData: Object) => {
      const { name } = inputData.field;
      const { target: { value } } = inputData.event;
      const { chargeIndex, accessorialsConfigs, form: { values, handleChange, setFieldValue } } = props;

      const chargeFieldName = getChargeFieldName(name);

      if (R.equals(chargeFieldName, GC.FIELD_CHARGE_RATE_NAME)) {
        return setFieldValue(
          GC.FIELD_MASTER_INVOICE_CHARGES,
          recalculateChargesOnChangeAccessorial({ value, values, chargeIndex, accessorialsConfigs }),
        );
      } else if (isRateOrQuantityChargeField(chargeFieldName)) {
        return setFieldValue(
          GC.FIELD_MASTER_INVOICE_CHARGES,
          recalculateChargesOnChangeRateOrQuantity({ value, values, chargeIndex, chargeFieldName }),
        );
      }

      handleChange(inputData.event);
    },
    handleAddChargeComment: (props: Object) => (charge: Object) => {
      const { chargeIndex, comments } = charge;
      const { form: { values, setFieldValue } } = props;

      setFieldValue(
        GC.FIELD_PAYROLL_CHARGES,
        addCommentsToPayrollCharges({ value: comments, values, chargeIndex }),
      );
    },
  }),
);

export const checkHasFieldError = (name: string, errors: Object, touched: Object) => {
  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  return G.ifElse(
    R.and(G.isNotNilAndNotEmpty(error), G.isTrue(touch)),
    true,
    false,
  );
};

export const renderField = (fieldSettings: Object, props: Object) => {
  if (R.equals(fieldSettings.type, 'component')) {
    return <fieldSettings.component {...props} {...fieldSettings} />;
  }

  if (R.equals(fieldSettings.type, 'select')) {
    const chargeRateType = R.path(
      ['form', 'values', GC.FIELD_MASTER_INVOICE_CHARGES, props.chargeIndex, GC.FIELD_CHARGE_RATE_TYPE],
      props,
    );
    const options = setFieldOptions(
      fieldSettings,
      chargeRateType,
      R.or(props.accessorialsConfigs, []),
      props.glCodeOptions,
    );

    return (
      <SelectWrapper afterTop={13} afterRight={15} width={R.or(fieldSettings.fieldWidth, 'auto')}>
        <Field
          name={`${GC.FIELD_MASTER_INVOICE_CHARGES}.${props.chargeIndex}.${fieldSettings.fieldName}`}
        >
          {({ field }: Object) => (
            <InputSelect
              {...field}
              pl={10}
              pr={10}
              disabled={fieldSettings.disabled}
              placeholder={fieldSettings.placeholder}
              width={R.or(fieldSettings.fieldWidth, 'auto')}
              onChange={(event: Object) => props.handleChangeInput({ event, field })}
              hasError={checkHasFieldError(field.name, props.form.errors, props.form.touched)}
            >
              {renderOptions(options)}
            </InputSelect>
          )}
        </Field>
      </SelectWrapper>
    );
  } else if (R.equals(fieldSettings.type, 'checkbox')) {
    return (
      <Field
        name={`${GC.FIELD_MASTER_INVOICE_CHARGES}.${props.chargeIndex}.${fieldSettings.fieldName}`}
      >
        {({ field }: Object) => (
          <Checkbox
            {...field}
            type='checkbox'
            checked={Boolean(field.value)}
            onChange={(event: Object) => props.handleChangeInput({ event, field })}
            hasError={checkHasFieldError(field.name, props.form.errors, props.form.touched)}
          />
        )}
      </Field>
    );
  }

  return (
    <Field
      name={`${GC.FIELD_MASTER_INVOICE_CHARGES}.${props.chargeIndex}.${fieldSettings.fieldName}`}
    >
      {({ field }: Object) => (
        <Input
          {...field}
          pl={10}
          pr={10}
          placeholder={fieldSettings.placeholder}
          width={R.or(fieldSettings.fieldWidth, 'auto')}
          onChange={(event: Object) => props.handleChangeInput({ event, field })}
          hasError={checkHasFieldError(field.name, props.form.errors, props.form.touched)}
        />
      )}
    </Field>
  );
};

const getPayrollChargeFieldFromValues = (props: Object, fieldName: string) => R.path(
  ['form', 'values', GC.FIELD_PAYROLL_CHARGES, props.chargeIndex, fieldName],
  props,
);

export const ChargeFields = enhanceChargeFields(withChargeComments('master')((props: Object) => (
  <Flex flexWrap='wrap' justifyContent='space-between'>
    {
      getMasterInvoiceChargeFieldset(props.glDisabled).map((fieldSettings: Object, i: number) => (
        <Box
          key={i}
          my='5px'
          mr='5px'
          display='flex'
          alignItems='center'
          width={R.or(fieldSettings.wrapperBoxWidth, 'auto')}
        >
          {
            R.equals(fieldSettings.fieldName, GC.FIELD_CHARGE_RATE_NAME)
            && (
              <Flex
                mx='5px'
                display='flex'
                cursor='pointer'
                alignItems='center'
                title='Add new charge'
                onClick={() => props.remove(props.chargeIndex)}
              >
                {I.trash()}
              </Flex>
            )
          }
          {
            R.equals(fieldSettings.fieldName, GC.FIELD_CHARGE_RATE_NAME)
            && (
              <Flex
                mr={10}
                ml='5px'
                display='flex'
                cursor='pointer'
                alignItems='center'
                title={R.or(
                  getPayrollChargeFieldFromValues(props, GC.FIELD_CHARGE_COMMENTS),
                  G.getWindowLocale('actions.add-comments', 'Add Comments'),
                )}
                onClick={() => props.handleOpenChargeComment(
                  props.chargeIndex,
                  getPayrollChargeFieldFromValues(props, GC.FIELD_CHARGE_COMMENTS),
                )}
              >
                {I.comment()}
              </Flex>
            )
          }
          {renderField(fieldSettings, props)}
        </Box>
      ))
    }
  </Flex>
)));

export const ChargesSection = (props: Object) => {
  const { authorities, asyncConfigs, accessorialsConfigs, handleOpenChargeComment } = props;

  const pathToCharges = ['values', GC.FIELD_MASTER_INVOICE_CHARGES];
  const currency = G.getCurrencySymbol(R.pathOr(
    GC.DEFAULT_UI_CURRENCY,
    ['values', GC.FIELD_CURRENCY],
    props,
  ));
  const invoicesTotal = R.pathOr(0, ['values', GC.FIELD_MASTER_INVOICE_INVOICES_TOTAL], props);
  const chargesTotal = calculateChargesTotal(R.path(pathToCharges, props));
  const discountTotal = G.calculateMasterInvoiceDiscount(R.path(pathToCharges, props));
  const additionalCharges = R.add(chargesTotal, discountTotal);
  const grandTotal = R.subtract(R.add(invoicesTotal, additionalCharges), discountTotal);
  const glCodeOptions = G.addEmptyOptionToDropDown(
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.INVOICE_GL_CODE,
    ),
    G.getWindowLocale('titles:gl-code', 'GL Code'),
  );
  const glDisabled = G.notContain(PC.GL_CODE_WRITE, authorities);

  return (
    <Box
      p='0 10px'
      overflow='auto'
      borderTop='1px solid'
      maxHeight='calc(95vh - 450px)'
      borderColor={G.getTheme('colors.light.grey')}
    >
      <FieldArray
        name={GC.FIELD_MASTER_INVOICE_CHARGES}
        render={(formikArrayProps: Object) => (
          <Box>
            <ChargesSectionHeader {...props} push={formikArrayProps.push} />
            <Box>
              {
                R.gt(R.length(R.pathOr([], pathToCharges, props)), 0)
                && R.path(pathToCharges, props).map((charge: string, i: string) => (
                  <ChargeFields
                    {...formikArrayProps}
                    key={i}
                    chargeIndex={i}
                    glDisabled={glDisabled}
                    glCodeOptions={glCodeOptions}
                    accessorialsConfigs={accessorialsConfigs}
                    handleOpenChargeComment={handleOpenChargeComment}
                  />
                ))
              }
            </Box>
            <ChargesSectionFooter
              currency={currency}
              grandTotal={grandTotal}
              discountTotal={discountTotal}
              invoicesTotal={invoicesTotal}
              additionalCharges={additionalCharges}
            />
          </Box>
        )}
      />
    </Box>
  );
};
