import * as R from 'ramda';
import * as P from 'plow-js';
// constants
import * as GC from '../constants';
// helpers
import { toNumber } from './calc';
import { isBetween } from './date';
import { getItemFromWindow } from './window';
import { getPropFromObject, getGuidFromObject } from './getter';
import { getItemFromLocalStorage, setItemToLocalStorage } from './storage';
import { getWindowLocale, getWindowLocaleArr, getWindowLocaleFromStringOrArray } from './locale';
import {
  isTrue,
  ifElse,
  isArray,
  notEquals,
  getOrElse,
  isAnyTrue,
  isFunction,
  isNotEmpty,
  mapIndexed,
  notContain,
  isNilOrEmpty,
  isAnyNilOrEmpty,
  isNotNilAndNotEmpty,
} from './helpers';
//////////////////////////////////////////////////

const getDefaultReportModal = (component: any, maxWidth: any = 1470) => ({
  component,
  options: {
    maxWidth,
    version: 2,
    width: 'calc(100vw - 20px)',
    height: 'calc(100vh - 60px)',
  },
});

const isFilterNumberRange = ({ dataType, operation }: Object) => R.and(
  R.equals(dataType, 'number'),
  R.equals(operation, 'range'),
);

const isFilterStringRange = ({ dataType, operation }: Object) => R.and(
  R.equals(dataType, 'string'),
  R.equals(operation, 'range'),
);

const isFilterValuesBoolean = ({ dataType, operation }: Object) => R.and(
  R.equals(dataType, 'boolean'),
  R.or(R.equals(operation, 'equal'), (R.equals(operation, 'not_equal'))),
);

const setOptionLabelFromNameOrValue = (options: Array) => R.map(
  (option: Object) => {
    const { name, label, value } = option;

    if (isNotNilAndNotEmpty(label)) return option;

    return R.assoc('label', R.or(name, value), option);
  },
  R.or(options, []),
);

const getReportColumnPropTextFromData = (propName: string, data: Object) => {
  let text = R.pathOr('', [propName], data);

  if (isArray(text)) {
    text = R.join(', ', text);
  }

  return text;
};

const localeKeyToReportFieldMap = R.compose(
  R.indexBy(R.prop('locale')),
  R.values,
  R.mapObjIndexed((item: Object, key: string) => {
    const { name } = item;
    const locale = R.trim(getWindowLocaleArr(name));

    return { key, locale };
  }),
);

const prependFieldToTableReportFields = (report: Object, field: Object) => {
  const fields = getPropFromObject('fields', report);

  if (isNilOrEmpty(fields)) return report;

  const newFields = R.compose(
    R.map((item: Object) => R.assoc('sequence', R.inc(item.sequence), item)),
    R.prepend(field),
  )(fields);

  return R.assoc('fields', newFields, report);
};

const addSystemFieldsToTableReport = (report: Object, systemFields: Array) => {
  if (isNilOrEmpty(report)) return report;

  if (isNilOrEmpty(systemFields)) return report;

  const newFields = systemFields.map((name: string, index: number) => ({ name, sequence: index }));

  return R.assoc('systemFields', newFields, report);
};

const defaultFilterFields = {
  to: null,
  next: null,
  last: null,
  from: null,
  guid: null,
  groupId: null,
  timeUnit: null,
  dataType: null,
  operation: null,
  stringValue: null,
  stringRange: null,
  numberRange: null,
  numberValue: null,
  booleanValue: null,
  propertyName: null,
  dateRelative: false,
  referenceName: null,
  referenceValue: null,
};

const createReportFilterFromTitleSearch = (data: Object) => {
  const { value, groupId, fieldProps } = data;
  const { type, collection, filterFunction } = fieldProps;

  let filter;
  const propertyName = R.prop(GC.FIELD_VALUE, fieldProps);

  if (R.equals(type, 'string')) {
    filter = {
      ...defaultFilterFields,
      groupId,
      propertyName,
      dataType: type,
      filterFunction,
      stringValue: value,
      operation: 'contain',
      collection: R.or(collection, false),
    };
  }

  if (R.equals(type, 'number')) {
    filter = {
      ...defaultFilterFields,
      groupId,
      propertyName,
      dataType: type,
      filterFunction,
      operation: 'equal',
      numberValue: value,
      collection: R.or(collection, false),
    };
  }

  if (R.equals(type, 'date')) {
    filter = {
      ...defaultFilterFields,
      groupId,
      propertyName,
      dataType: type,
      filterFunction,
      operation: 'date-range',
      to: `${value} 11:59 PM`,
      from: `${value} 12:00 AM`,
      collection: R.or(collection, false),
    };
  }

  if (R.equals(type, 'boolean')) {
    filter = {
      ...defaultFilterFields,
      groupId,
      propertyName,
      dataType: type,
      filterFunction,
      operation: 'equal',
      booleanValue: value,
      collection: R.or(collection, false),
    };
  }

  if (R.equals(type, 'reference')) {
    filter = {
      ...defaultFilterFields,
      groupId,
      dataType: type,
      filterFunction,
      collection: false,
      operation: 'contain',
      referenceValue: value,
      propertyName: 'references',
      referenceName: propertyName,
    };
  }

  if (R.equals(type, 'selectMultiple')) {
    filter = {
      ...defaultFilterFields,
      groupId,
      propertyName,
      dataType: type,
      filterFunction,
      operation: 'in',
      stringValue: value,
      collection: R.or(collection, false),
      selectMultipleValue: R.of(Array, { value }),
    };
  }

  return { filter, isEmptyValue: isNilOrEmpty(value) };
};

const handleTableTitleFilter = ({
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  resetListAndPagination,
}: Object) => (data: Object) => {
  const { isSorting } = data;

  if (isSorting) {
    setTableTitleSort(data.sortData);
  } else {
    const filter = createReportFilterFromTitleSearch(data);

    setTableTitleFilter(filter);
  }

  resetListAndPagination();
  getItemListRequest(true);
};

const handleTableTitleFilter2 = (data: Object, A: Object, dispatch: Function) => {
  const { isSorting } = data;

  dispatch(A.resetListAndPagination());

  if (isSorting) {
    dispatch(A.setTableTitleSort(data.sortData));
  } else {
    const filter = createReportFilterFromTitleSearch(data);

    dispatch(A.setTableTitleFilter(filter));
  }
};

const getTableTitleSortValues = (titleSortValues: Object, data: Object) => {
  const { name } = data;

  let sortValues;

  if (R.pathEq(R.prop(GC.FIELD_ORDER, data), [name, GC.FIELD_ORDER], titleSortValues)) {
    sortValues = R.compose(
      R.indexBy(R.prop(GC.FIELD_NAME)),
      mapIndexed((entity: Object, i: number) => R.assoc('sequence', R.inc(i), entity)),
      R.sortBy(R.prop('sequence')),
      R.values,
      R.dissoc(name),
    )(titleSortValues);
  } else {
    let sequence;

    const exist = R.path([name], titleSortValues);

    if (isNotNilAndNotEmpty(exist)) {
      sequence = R.prop('sequence', exist);
    } else {
      sequence = R.compose(
        R.inc,
        R.length,
        R.values,
      )(titleSortValues);
    }

    sortValues = R.assoc(name, R.assoc('sequence', sequence, data), titleSortValues);
  }

  return sortValues;
};

const setTableTitleSort = (state: Object, data: Object) => {
  const { titleSortValues } = state;
  const sortValues = getTableTitleSortValues(titleSortValues, data);

  return P.$set('titleSortValues', sortValues, state);
};

const getTableTitleFilterValues = (tableTitleFilters: Object, data: Object) => {
  const { filter, isEmptyValue } = data;

  const propToUse = ifElse(
    R.propEq('references', 'propertyName', filter),
    GC.FIELD_REFERENCE_NAME,
    'propertyName',
  );

  return ifElse(
    isEmptyValue,
    R.dissoc(R.prop(propToUse, filter), tableTitleFilters),
    R.assoc(R.prop(propToUse, filter), filter, tableTitleFilters),
  );
};

const setTableTitleFilter = (state: Object, data: Object) => {
  const { tableTitleFilters } = state;

  const filterValues = getTableTitleFilterValues(tableTitleFilters, data);

  return P.$set('tableTitleFilters', filterValues, state);
};

const omitReportFields = (report: Object, fieldNames: Array = []) => R.assoc(
  'fields',
  R.compose(
    R.sortBy(R.prop('sequence')),
    R.values,
    R.omit(fieldNames),
    R.indexBy(R.prop(GC.FIELD_NAME)),
    R.prop('fields'),
  )(report),
  report,
);

const getAssessorialName = (name: string) => {
  const assessorials = R.indexBy(R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID), R.or(getItemFromWindow('assessorialList'), []));
  const splitted = R.split('.', name);
  const guid = splitted[R.dec(splitted.length)];

  return R.pathOr('', [guid, GC.FIELD_DISPLAYED_VALUE], assessorials);
};

const getReferenceTitleName = (name: string) => {
  let prefix = null;

  if (R.includes(`${GC.REFERENCE_FIELD_NAME_CLO}.`, name)) {
    prefix = GC.REFERENCE_FIELD_NAME_CLO;
  } else if (R.includes(`${GC.REFERENCE_FIELD_NAME_TEL}.`, name)) {
    prefix = GC.REFERENCE_FIELD_NAME_TEL;
  } else if (R.includes(GC.REFERENCE_FIELD_NAME_CLOS, name)) {
    prefix = GC.REFERENCE_FIELD_NAME_CLOS;
  } else if (R.includes(GC.REFERENCE_FIELD_NAME_TELS, name)) {
    prefix = GC.REFERENCE_FIELD_NAME_TELS;
  } else if (R.includes(GC.REFERENCE_FIELD_NAME_LOAD, name)) {
    prefix = GC.REFERENCE_FIELD_NAME_LOAD;
  }

  if (R.isNil(prefix)) return `Ref: ${name}`;

  return `Ref (${getWindowLocale(...GC.referenceFieldNameLocaleMap[prefix])}): ${R.last(R.split('.', name))}`;
};

const getFieldDisplayName = (field: Object, fieldSettings: Object) => {
  const { name, reference } = field;

  if (R.includes('Assessorial', name)) return `CHRG: ${getAssessorialName(name)}`;

  if (isTrue(reference)) return getReferenceTitleName(name);

  return R.pathOr(name, [name, GC.FIELD_NAME], fieldSettings);
};

const addDisplayNameToReportFields = (fields: Array, fieldSettings: Array) => {
  const indexedFieldSettings = R.indexBy(R.prop(GC.FIELD_VALUE), fieldSettings);

  return R.map(
    (field: Object) => ({
      ...field,
      displayName: getFieldDisplayName(field, indexedFieldSettings),
    }),
    fields,
  );
};

const getReferencesForReport = (refList: Array, prefix: string) => R.map(
  (ref: Object) => R.mergeRight(ref, {
    name: `${prefix}.${R.prop(GC.FIELD_NAME, ref)}`,
    prefix: getWindowLocale(...GC.referenceFieldNameLocaleMap[prefix]),
  }),
  refList,
);

const getRefListByReportType = (refTypesByScopeName: Array, type: string) => {
  const map = {
    [GC.CLO_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_CLO], refTypesByScopeName),
    [GC.TEL_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_TEL], refTypesByScopeName),
    [GC.USER_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_USER], refTypesByScopeName),
    [GC.ITEM_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_ITEM], refTypesByScopeName),
    [GC.TEL_EVENT_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_TEL], refTypesByScopeName),
    [GC.ROUTE_CLO_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_CLO], refTypesByScopeName),
    [GC.ROUTE_TEL_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_TEL], refTypesByScopeName),
    [GC.PIVOT_CLO_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_CLO], refTypesByScopeName),
    [GC.PIVOT_TEL_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_TEL], refTypesByScopeName),
    [GC.BRANCH_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_BRANCH], refTypesByScopeName),
    [GC.CARRIER_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_CARRIER], refTypesByScopeName),
    [GC.FLEET_TRUCK_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_FLEET_TRUCK], refTypesByScopeName),
    [GC.CUSTOMER_PORTAL_CLO_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_CLO], refTypesByScopeName),
    [GC.FLEET_DRIVER_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_FLEET_DRIVER], refTypesByScopeName),
    [GC.FLEET_VENDOR_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_FLEET_VENDOR], refTypesByScopeName),
    [GC.LOCATION_TEMPLATE_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_LOCATION], refTypesByScopeName),
    [GC.FLEET_TRAILER_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_FLEET_TRAILER], refTypesByScopeName),
    [GC.CARRIER_INVOICE_REPORT]: () => R.pathOr([], [GC.REF_SCOPE_NAME_CARRIER_INVOICE], refTypesByScopeName),
    [GC.CUSTOMER_INVOICE_REPORT]: () => R.concat(
      R.pathOr([], [GC.REF_SCOPE_NAME_CUSTOMER_INVOICE], refTypesByScopeName),
      getReferencesForReport(
        R.pathOr([], [GC.REF_SCOPE_NAME_CLO], refTypesByScopeName),
        GC.REFERENCE_FIELD_NAME_CLO,
      ),
    ),
    [GC.PIVOT_CUSTOMER_INVOICE_REPORT]: () => R.concat(
      R.pathOr([], [GC.REF_SCOPE_NAME_CUSTOMER_INVOICE], refTypesByScopeName),
      getReferencesForReport(
        R.pathOr([], [GC.REF_SCOPE_NAME_CLO], refTypesByScopeName),
        GC.REFERENCE_FIELD_NAME_CLO,
      ),
    ),
    [GC.DRIVER_PAYROLL_INVOICE_REPORT]: () => R.concat(
      R.pathOr([], [GC.REF_SCOPE_NAME_FLEET_INVOICE], refTypesByScopeName),
      getReferencesForReport(
        R.pathOr([], [GC.REF_SCOPE_NAME_TEL], refTypesByScopeName),
        GC.REFERENCE_FIELD_NAME_TEL,
      ),
    ),
    [GC.VENDOR_INVOICE_REPORT]: () => R.concat(
      R.pathOr([], [GC.REF_SCOPE_NAME_FLEET_INVOICE], refTypesByScopeName),
      getReferencesForReport(
        R.pathOr([], [GC.REF_SCOPE_NAME_TEL], refTypesByScopeName),
        GC.REFERENCE_FIELD_NAME_TEL,
      ),
    ),
    [GC.CLO_EVENT_REPORT]: () => R.concat(
      R.pathOr([], [GC.REF_SCOPE_NAME_LOAD_EVENT], refTypesByScopeName),
      getReferencesForReport(
        R.pathOr([], [GC.REF_SCOPE_NAME_CLO], refTypesByScopeName),
        GC.REFERENCE_FIELD_NAME_CLO,
      ),
    ),
    [GC.ROUTE_REPORT]: () => R.concat(
      getReferencesForReport(
        R.pathOr([], [GC.REF_SCOPE_NAME_TEL], refTypesByScopeName),
        GC.REFERENCE_FIELD_NAME_TELS,
      ),
      getReferencesForReport(
        R.pathOr([], [GC.REF_SCOPE_NAME_CLO], refTypesByScopeName),
        GC.REFERENCE_FIELD_NAME_CLOS,
      ),
    ),
    [GC.ROUTE_BY_LOAD_REPORT]: () => getReferencesForReport(
      R.compose(
        R.values,
        R.indexBy(R.prop(GC.FIELD_GUID)),
        R.concat(R.pathOr([], [GC.REF_SCOPE_NAME_TEL], refTypesByScopeName)),
      )(R.pathOr([], [GC.REF_SCOPE_NAME_CLO], refTypesByScopeName)),
      GC.REFERENCE_FIELD_NAME_LOAD,
    ),
    [GC.PIVOT_ROUTE_BY_LOAD_REPORT]: () => getReferencesForReport(
      R.compose(
        R.values,
        R.indexBy(R.prop(GC.FIELD_GUID)),
        R.concat(R.pathOr([], [GC.REF_SCOPE_NAME_TEL], refTypesByScopeName)),
      )(R.pathOr([], [GC.REF_SCOPE_NAME_CLO], refTypesByScopeName)),
      GC.REFERENCE_FIELD_NAME_LOAD,
    ),
  };

  return R.pathOr(() => [], [type], map)();
};

const hasPinnedReports = (reports: Array) => isAnyTrue(
  ...R.map((report: Object) => getPropFromObject(GC.FIELD_PINNED_REPORT, report), R.or(reports, [])),
);

const hasSearchCriteria = (report: Object, filter: Object) => R.or(
  isNotNilAndNotEmpty(filter),
  isNotNilAndNotEmpty(R.path(['searchCriteria'], report)),
);

const hasPinnedReportsAndSearchCriteria = (reports: Array, report: Object, filter: Object) => (
  R.and(
    hasPinnedReports((reports)),
    hasSearchCriteria(report, filter),
  )
);

// helper for define checked elements on list
const setSelectedElement = (selectedList: Array, list: Array, event: Object) => {
  let selected = [];

  if (R.and(R.equals(event.target.name, 'all'), event.target.checked)) {
    selected = R.concat(list, []);
  } else if (R.and(R.equals(event.target.name, 'all'), R.not(event.target.checked))) {
    selected = [];
  } else if (R.not(R.equals(event.target.name, 'all'))) {
    selected = ifElse(
      R.includes(event.target.name, selectedList),
      R.filter((entGuid: string) => (R.not(R.equals(entGuid, event.target.name))), selectedList),
      R.concat(selectedList, [event.target.name]),
    );
  }

  return selected;
};

// helper for added searchCriteria if using list filter
const getReferenceNameWithoutPrefix = (referenceName: string = '', referenceFieldName: string) => {
  if (R.isEmpty(referenceName)) return '';

  const referencePrefix = `Ref (${getWindowLocale(...GC.referenceFieldNameLocaleMap[referenceFieldName])}): `;

  if (R.includes(referencePrefix, referenceName)) return R.slice(referencePrefix.length, Infinity, referenceName);

  return referenceName;
};

const transformReferenceOrderFields = R.map(
  (data: Object) => {
    const { reference, referenceFieldName } = data;

    if (R.and(isTrue(reference), isNotNilAndNotEmpty(referenceFieldName))) {
      const value = getReferenceNameWithoutPrefix(R.prop(GC.FIELD_NAME, data), referenceFieldName);

      return R.assoc(GC.FIELD_NAME, value, data);
    }

    return data;
  },
);

const transformReferences = R.map(
  (data: Object) => {
    const { propertyName, referenceName, referenceFieldName } = data;

    if (R.and(R.equals(propertyName, GC.FIELD_REFERENCES), isNotNilAndNotEmpty(referenceFieldName))) {
      const value = getReferenceNameWithoutPrefix(referenceName, referenceFieldName);

      return R.assoc(GC.FIELD_REFERENCE_NAME, value, data);
    }

    return data;
  },
);

const setSearchCriteria = ({ filterParams, reqBody }: Object) => ifElse(
  isNotNilAndNotEmpty(filterParams),
  () => R.assoc(
    'searchCriteria',
    transformReferences(R.concat(reqBody.searchCriteria, R.of(Array, filterParams))),
    reqBody,
  ),
  () => reqBody,
)();

// helper for compiling all frozen column
const getFixedColumn = (report: Object, settings: Object) => {
  let freezedColumn = [];

  if (isNotEmpty(report)) {
    freezedColumn = R.filter((field: Object) => (
      R.and(notEquals(field.name, 'guid'), R.equals(field.freezed, true))
    ), report.fields);

    const renderFreezedColumn = freezedColumn.map((column: Object) => ({
      customLogic: R.equals(column[GC.FIELD_REFERENCE], true)
        || R.equals(column.name, 'roles.name')
        || R.pathOr(false, [column.name, 'customLogic'], settings),
      label: getOrElse(settings[column.name], 'label', null),
      sequence: column.sequence,
      nameForAttribute: column.name,
      name: ifElse(
        isNotEmpty(column.name),
        getOrElse(settings[column.name], 'name', `Ref: ${column.name}`),
        '',
      ),
      width: getOrElse(settings[column.name], 'width', 300),
    }));

    return R.sort((a: Object, b: Object) => (
      a.sequence - b.sequence
    ), renderFreezedColumn);
  }
  return freezedColumn;
};

const getCustomLogic = (column: Object, columnSettings: Object) => {
  const customLogic = R.path([column.name, 'customLogic'], columnSettings);

  if (R.is(Function, customLogic)) {
    return customLogic;
  }

  return R.equals(column[GC.FIELD_REFERENCE], true)
  || R.equals(column.name, 'roles.name')
  || R.pathOr(false, [column.name, 'customLogic'], columnSettings);
};

// helper for compiling all free column
const getBasicColumn = (report: Object, settings: Object) => {
  let basicColumn = [];

  if (isNotEmpty(report)) {
    basicColumn = R.filter((field: Object) => (
      R.and(
        notEquals(field.name, 'guid'),
        notEquals(field.freezed, true),
      )
    ), report.fields);

    const renderBasicColumn = basicColumn.map((column: Object) => ({
      customLogic: getCustomLogic(column, settings),
      label: getOrElse(settings[column.name], 'label', null),
      sequence: column.sequence,
      nameForAttribute: column.name,
      name: ifElse(
        isNotEmpty(column.name),
        getOrElse(settings[column.name], 'name', `Ref: ${column.name}`),
        '',
      ),
      width: getOrElse(settings[column.name], 'width', 300),
    }));

    return R.sort((a: Object, b: Object) => (
      a.sequence - b.sequence
    ), renderBasicColumn);
  }

  return basicColumn;
};

// helper for setting filter by entity params
const setFilterByParams = (
  refList: Array = [],
  entityParams: Array = [],
  options: Object = {},
) => {
  const reportType = R.propOr('', 'reportType', options);
  const assessorialList = R.propOr([], 'assessorialList', options);
  const disableRefFilter = R.propOr(false, 'disableRefFilter', options);

  const refs = R.map(({ name, prefix }: Object) => {
    const prefixValue = ifElse(isNotNilAndNotEmpty(prefix), `Ref (${prefix})`, 'Ref');

    return {
      value: name,
      type: GC.FIELD_REFERENCE,
      disableFilter: disableRefFilter,
      name: `${prefixValue}: ${R.last(R.split('.', name))}`,
    };
  })(refList);

  const assessorialPrefixMap = {
    [GC.TEL_REPORT]: 'rate.sumByAssessorial.',
    [GC.CLO_REPORT]: 'rate.sumByAssessorial.',
    [GC.PIVOT_TEL_REPORT]: 'rate.sumByAssessorial.',
    [GC.PIVOT_CLO_REPORT]: 'rate.sumByAssessorial.',
    [GC.PAYROLL_REPORT]: 'invoices.sumByAssessorial.',
    [GC.CUSTOMER_INVOICE_REPORT]: 'sumByAssessorial.',
    [GC.PIVOT_CUSTOMER_INVOICE_REPORT]: 'sumByAssessorial.',
    [GC.VENDOR_PAYROLL_REPORT]: 'invoices.sumByAssessorial.',
    [GC.PIVOT_DRIVER_PAYROLL_REPORT]: 'invoices.sumByAssessorial.',
    [GC.PIVOT_VENDOR_PAYROLL_REPORT]: 'invoices.sumByAssessorial.',
  };

  const assessorialPrefix = R.pathOr('', [reportType], assessorialPrefixMap);

  const assessorials = R.map(
    ({ displayedValue, originalConfigGuid }: Object) => ({
      type: 'assessorial',
      disableFilter: true,
      name: `CHRG: ${displayedValue}`,
      value: `${assessorialPrefix}${originalConfigGuid}`,
    }),
    assessorialList,
  );

  const payrollAssessorialPrefix = 'charges.sumByAssessorial.';

  const isPayrollReport = R.includes(reportType, [
    GC.PAYROLL_REPORT,
    GC.VENDOR_PAYROLL_REPORT,
    GC.PIVOT_DRIVER_PAYROLL_REPORT,
    GC.PIVOT_VENDOR_PAYROLL_REPORT,
  ]);

  if (R.not(isPayrollReport)) return [...entityParams, ...refs, ...assessorials];

  const payrollAssessorials = R.map(
    ({ displayedValue, originalConfigGuid }: Object) => ({
      type: 'assessorial',
      disableFilter: true,
      name: `CHRG(Payroll): ${displayedValue}`,
      value: `${payrollAssessorialPrefix}${originalConfigGuid}`,
    }),
    assessorialList,
  );

  return [...entityParams, ...refs, ...assessorials, ...payrollAssessorials];
};

// helper for adding required field for reporting request
const addRequiredFields = (entity: Array, requiredFields: Array) => {
  let fields = entity.slice();

  requiredFields.forEach((requiredField: string) => {
    const exist = R.find(
      R.propEq(requiredField, GC.FIELD_NAME),
      entity,
    );

    if (R.isNil(exist)) {
      fields = R.append(
        {
          freezed: false,
          reference: false,
          name: requiredField,
          sequence: (fields.length + 1),
        },
        fields,
      );
    }
  });

  return fields;
};

const getReportSortedBySeqFreez = (report: Object) => (
  R.assoc(
    'fields',
    R.sortBy(
      (field: Object) => (
        R.not(R.prop('freezed', field))
      ),
      R.sortBy(
        R.prop('sequence'),
        report.fields,
      ),
    ),
    report,
  )
);

const getReportsSortedBySeqFreez = (reports: Array) => (
  ifElse(
    isNilOrEmpty(reports),
    () => reports,
    () => R.map(
      getReportSortedBySeqFreez,
      reports,
    ),
  )()
);

const selectAll = (list: Array, select: boolean) => (
  R.map((item: Object) => (
    P.$set(
      'selected',
      select,
      item,
    )
  ), list)
);

const selectItem = (list: Array, id: string) => (
  ifElse(
    R.equals(id, 'all'),
    selectAll(list, R.not(R.all((item: Object) => isTrue(item.selected))(list))),
    R.map((item: Object) => (
      P.$set(
        'selected',
        ifElse(
          R.equals(R.prop('guid', item), id),
          R.not(R.prop('selected', item)),
          R.prop('selected', item),
        ),
        item,
      )
    ), list),
  )
);

const isAllChecked = (itemList: Array) => (
  ifElse(
    R.or(R.not(itemList), R.not(R.path(['length'], itemList))),
    () => false,
    () => R.all((item: Object) => isTrue(item.selected))(itemList),
  )()
);

const findDefaultReport = (reports: Array) => (
  R.or(
    reports.find((report: Object) => isTrue(report.defaultReport)),
    R.prop(0, reports),
  )
);

const getGuidsFromStringOrSelectedList = (data: any, list: Array) => (
  ifElse(
    R.is(String, data),
    () => R.of(Array, data),
    () => R.compose(
      R.values,
      R.map((entity: Object) => entity.guid),
      R.filter((entity: Object) => entity.selected),
    )(list),
  )()
);

const getSelectedItemGuids = (list: Array) => R.compose(
  R.map(getGuidFromObject),
  R.filter(R.prop('selected')),
)(list);

const getTableSettingsWithMaxHeightByConditions = (props: Object) => {
  const { reportList, filterParams, selectedReport, tableSettings } = props;

  const hasPinned = hasPinnedReports(reportList);
  const hasFilters = hasSearchCriteria(selectedReport, filterParams);

  let maxHeight = 'calc(100vh - 125px)';

  if (R.and(hasPinned, hasFilters)) {
    maxHeight = 'calc(100vh - 190px)';
  } else if (R.or(hasPinned, hasFilters)) {
    maxHeight = 'calc(100vh - 160px)';
  }

  return R.assoc('maxHeight', maxHeight, tableSettings);
};

const getFilterPropsFromColumnSettings = (columnSettings: Object) => R.compose(
  R.indexBy(R.prop(GC.FIELD_VALUE)),
  R.values,
  R.mapObjIndexed(({ name, filter = { type: 'string' } }: Object, value: string) => ({
    ...filter,
    value,
    // TODO: check where used name and this locale approach
    name: getWindowLocaleFromStringOrArray(name, ': '),
    // [GC.FIELD_NAME]: ifElse(
    //   isArray(name),
    //   `${getWindowLocale(name[0])}: ${getWindowLocale(name[1])}`,
    //   getWindowLocale(name),
    // ),
  })),
)(columnSettings);

const getReportFieldsFromColumnSettings = (columnSettings: Object) => R.compose(
  mapIndexed(({ name, collection }: Object, index: number) => ({ name, collection, sequence: index })),
  R.values,
  R.mapObjIndexed(({ collection }: Object, value: string) => ({
    collection,
    name: value,
  })),
)(columnSettings);

const getOnlyReportFieldsFromColumnSettings = (columnSettings: Array = []) => Object.keys(columnSettings)
  .map((name: string) => ({ name }));

const getReportFromColumnSettings = (columnSettings: Array = [], additionProps: any = {}) => ({
  ...additionProps,
  fields: getOnlyReportFieldsFromColumnSettings(columnSettings),
});

const getAddressPointFieldsByType = (prefix: string) => {
  const localePrefix = ifElse(
    R.equals(prefix, GC.FIELD_ORIGIN),
    'titles:origin',
    'titles:destination',
  );

  const getLocaleArr = (key: string) => [localePrefix, key];

  return {
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_CITIES}`]: {
      width: 300,
      collection: true,
      name: getLocaleArr('titles:cities'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_STATES}`]: {
      width: 300,
      collection: true,
      name: getLocaleArr('titles:states'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_COUNTRIES}`]: {
      width: 300,
      collection: true,
      name: getLocaleArr('titles:countries'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES}`]: {
      width: 300,
      collection: true,
      name: getLocaleArr('titles:zip-codes'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO}`]: {
      name: getLocaleArr('titles:zip-to'),
    },
    [`${prefix}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM}`]: {
      name: getLocaleArr('titles:zip-from'),
    },
  };
};

const getSearchCriteriaByGuidAndPropertyName = (guid: string, propertyName: string) => R.of(Array, {
  propertyName,
  stringValue: guid,
  dataType: 'string',
  operation: 'equal',
});

const getSearchCriteriaByGuid = (guid: string) => getSearchCriteriaByGuidAndPropertyName(guid, GC.FIELD_GUID);

const getAmousUiReportsFromLocalStorage = () => getItemFromLocalStorage('amousUiReports');

const getUiReportFieldsFromLocalStorage = (reportName: string) =>
  R.pathOr([], [reportName], getAmousUiReportsFromLocalStorage());

const setUiReportFieldsToLocalStorage = (reportName: string, fields: Array) => setItemToLocalStorage(
  'amousUiReports',
  R.assoc(reportName, fields, R.or(getAmousUiReportsFromLocalStorage(), {})),
);

const filterOperationsMap = {
  in: ({ itemValue, filterValue }: Object) => R.equals(itemValue, filterValue),
  equal: ({ itemValue, filterValue }: Object) => R.equals(itemValue, filterValue),
  contain: ({ itemValue, filterValue }: Object) => R.includes(R.toLower(filterValue), R.toLower(R.or(itemValue, ''))),
  [GC.FIELD_DATE_RANGE]: ({ itemValue, filterValue }: Object) => {
    const { to, from } = filterValue;

    return isBetween(itemValue, from, to);
  },
};

const getFilterValue = (filter: Object) => {
  const { to, from, dataType, stringValue, numberValue, booleanValue } = filter;

  switch (dataType) {
    case 'string':
    case 'selectMultiple':
      return stringValue;
    case 'number':
      return isNotNilAndNotEmpty(numberValue) ? toNumber(numberValue) : numberValue;
    case 'date':
      return { to, from };
    case 'boolean':
      return booleanValue;
    default:
      return null;
  }
};

const getItemListFilteredByUiTableTitleFilters = (
  report: Object,
  itemList: Array,
  uiTableTitleFilters: Object,
) => {
  if (isAnyNilOrEmpty([itemList, uiTableTitleFilters])) return itemList;

  const filtersKeys = R.keys(uiTableTitleFilters);
  const reportFieldNames = R.pluck(GC.FIELD_NAME, R.pathOr([], ['fields'], report));

  return R.filter(
    (item: Object) => R.all((filterKey: string) => {
      if (notContain(filterKey, reportFieldNames)) return true;

      const itemValue = getPropFromObject(filterKey, item);
      const filter = getPropFromObject(filterKey, uiTableTitleFilters);
      const filterFunction = R.propOr(null, 'filterFunction', filter);
      const filterValue = getFilterValue(filter);

      if (isFunction(filterFunction)) {
        return filterFunction(filterValue, item);
      }

      const filterOperation = getPropFromObject(
        getPropFromObject(GC.FIELD_OPERATION, filter),
        filterOperationsMap,
      );

      return filterOperation ? filterOperation({ itemValue, filterValue }) : true;
    }, filtersKeys),
    itemList,
  );
};

const getItemListSortedByUITableSorting = (sortValues: Object, filterProps: Object, itemList: Array) => {
  const sortingList = R.sortBy(R.prop('sequence'), R.values(sortValues));

  const sortingCriteria = R.map((sortOption: Object) => {
    const { name, order } = sortOption;

    const filterParams = R.prop(name, filterProps);

    if (R.not(filterParams)) return () => {};

    const { type } = filterParams;

    const orderFn = ifElse(R.equals(order, 'DESC'), R.descend, R.ascend);

    return orderFn((arg: Object) => {
      const value = R.propOr('', name, arg);

      switch (type) {
        case 'date':
          if (R.equals(name, GC.FIELD_LOAD_BOARD_LAST_MODIFIED_DATE)) {
            return Math.abs(R.subtract(new Date(), new Date(value)));
          }

          return new Date(value).getTime();
        case 'number':
          return +(value || 0);
        default:
          return value;
      }
    });
  }, sortingList);

  return R.sortWith(sortingCriteria, itemList);
};

export {
  selectAll,
  selectItem,
  isAllChecked,
  getFixedColumn,
  getBasicColumn,
  omitReportFields,
  hasPinnedReports,
  setTableTitleSort,
  setSearchCriteria,
  addRequiredFields,
  hasSearchCriteria,
  setFilterByParams,
  findDefaultReport,
  setSelectedElement,
  getAssessorialName,
  isFilterNumberRange,
  isFilterStringRange,
  setTableTitleFilter,
  getSelectedItemGuids,
  getReferenceTitleName,
  getDefaultReportModal,
  isFilterValuesBoolean,
  handleTableTitleFilter,
  getRefListByReportType,
  getSearchCriteriaByGuid,
  getTableTitleSortValues,
  handleTableTitleFilter2,
  getReportSortedBySeqFreez,
  getTableTitleFilterValues,
  localeKeyToReportFieldMap,
  getReportsSortedBySeqFreez,
  getReportFromColumnSettings,
  getAddressPointFieldsByType,
  addDisplayNameToReportFields,
  addSystemFieldsToTableReport,
  setOptionLabelFromNameOrValue,
  transformReferenceOrderFields,
  setUiReportFieldsToLocalStorage,
  getReportColumnPropTextFromData,
  prependFieldToTableReportFields,
  getGuidsFromStringOrSelectedList,
  getFilterPropsFromColumnSettings,
  getItemListSortedByUITableSorting,
  getUiReportFieldsFromLocalStorage,
  hasPinnedReportsAndSearchCriteria,
  createReportFilterFromTitleSearch,
  getReportFieldsFromColumnSettings,
  getOnlyReportFieldsFromColumnSettings,
  getSearchCriteriaByGuidAndPropertyName,
  getItemListFilteredByUiTableTitleFilters,
  getTableSettingsWithMaxHeightByConditions,
};
