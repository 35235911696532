import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const mainStopFields = [
  {
    type: 'select',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 0,
      flexBasis: 200,
    },
    fieldName: GC.FIELD_LOCATION_TYPE,
    options: {
      configName: GC.TEMPLATES_LOCATION_TYPE,
    },
    label: G.getWindowLocale('titles:type', 'Type'),
  },
  {
    type: 'asyncSearch',
    inputWrapperStyles: {
      zIndex: 15,
      flexGrow: 0,
      flexBasis: 250,
    },
    fieldName: GC.FIELD_TEMPLATE_ID,
    label: G.getWindowLocale('titles:search-by-template-id-name', 'Search by Template Id/Name'),
  },
];

const stopAddressFields1 = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: {
      mr: 20,
      mb: 25,
      flexGrow: 1,
      flexBasis: 180,
    },
    fieldName: GC.FIELD_LOCATION_NAME,
    label: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    inputWrapperStyles: {
      mr: 20,
      mb: 25,
      zIndex: 14,
      flexGrow: 1,
      flexBasis: 180,
    },
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
    label: G.getWindowLocale('titles:address1', 'Address1'),
  },
  {
    type: 'text',
    inputWrapperStyles: {
      mb: 25,
      flexGrow: 1,
      flexBasis: 180,
    },
    fieldName: GC.FIELD_ADDRESS_2,
    label: G.getWindowLocale('titles:address2', 'Address2'),
  },
];

const stopAddressFields2 = [
  {
    isRequired: true,
    omitAddress1: true,
    inputWrapperStyles: {
      mr: 20,
      mb: 25,
      zIndex: 14,
      flexGrow: 1,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_CITY,
    type: 'addressAutocomplete',
    label: G.getWindowLocale('titles:city', 'City'),
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: {
      mr: 20,
      mb: 25,
      flexGrow: 1,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_STATE,
    label: G.getWindowLocale('titles:state', 'State'),
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles: {
      mr: 20,
      mb: 25,
      flexGrow: 1,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_ZIP,
    label: G.getWindowLocale('titles:postal-code', 'Postal Code'),
  },
  {
    isRequired: true,
    type: 'countrySelect',
    inputWrapperStyles: {
      mb: 25,
      flexGrow: 1,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_COUNTRY,
    label: G.getWindowLocale('titles:country', 'Country'),
  },
];

const stopAddressFields3 = [
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 0,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_LATITUDE,
    label: G.getWindowLocale('titles:latitude', 'Latitude'),
  },
  {
    type: 'text',
    disabled: true,
    isRequired: true,
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 0,
      flexBasis: 120,
    },
    fieldName: GC.FIELD_LONGITUDE,
    label: G.getWindowLocale('titles:longitude', 'Longitude'),
  },
  {
    type: 'textarea',
    inputStyles: {
      height: 36,
    },
    inputWrapperStyles: {
      flexGrow: 1,
      flexBasis: 200,
    },
    fieldName: GC.FIELD_COMMENTS,
    label: G.getWindowLocale('titles:comments', 'Comments'),
  },
];

export const addressStopFields = [
  ...stopAddressFields1,
  ...stopAddressFields2,
  ...stopAddressFields3,
];

export const earlyCalendarDateTimeDisplayFunc = (props: Object) => {
  const { branchConfigs } = props;

  const conf = G.getConfigValueFromStoreOrWindow(GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME, branchConfigs);

  return G.ifElse(
    G.isFalse(conf),
    'initial',
    'none',
  );
};

export const earlyCalendarDateAndTimeDisplayFunc = (props: Object) => {
  const { branchConfigs } = props;

  const conf = G.getConfigValueFromStoreOrWindow(GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME, branchConfigs);

  return G.ifElse(
    G.isTrue(conf),
    'initial',
    'none',
  );
};

const lateCalendarDateTimeDisplayFunc = (props: Object) => {
  const { values, fromPage, branchConfigs } = props;

  const conf = G.getConfigValueFromStoreOrWindow(GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME, branchConfigs);

  return G.ifElse(
    R.and(
      R.or(
        R.pathEq(true, [GC.FIELD_LOAD_FCFS], values),
        R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]),
      ),
      G.isFalse(conf),
    ),
    'initial',
    'none',
  );
};

const lateCalendarDateAndTimeDisplayFunc = (props: Object) => {
  const { values, fromPage, branchConfigs } = props;

  const conf = G.getConfigValueFromStoreOrWindow(GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME, branchConfigs);

  return G.ifElse(
    R.and(
      R.or(
        R.pathEq(true, [GC.FIELD_LOAD_FCFS], values),
        R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]),
      ),
      G.isTrue(conf),
    ),
    'initial',
    'none',
  );
};

export const getCustomChangeHandler2 = (timeField: any, dateField: string) => ({ value, props }: Object) => {
  const { values, fromPage, setValues, setFieldValue } = props;

  const timePath = G.ifElse(G.isArray(timeField), timeField, R.of(Array, timeField));
  const eventTime = R.path(timePath, values);

  if (G.isValidDate(value)) {
    const validDate = G.createLocalDateTimeFromInstanceOrISOString(value, GC.DEFAULT_DATE_FORMAT);
    const eventDateTime = `${validDate} ${eventTime}`;

    if (G.isValidDate(eventDateTime)) {
      const isFromPlanner = R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]);

      if (R.and(isFromPlanner, R.equals(dateField, GC.FIELD_LOAD_EVENT_EARLY_DATE))) {
        return setValues(R.mergeRight(values, {
          [GC.FIELD_LOAD_EVENT_LATE_DATE]: eventDateTime,
          [GC.FIELD_LOAD_EVENT_EARLY_DATE]: eventDateTime,
        }));
      }

      return setFieldValue(dateField, eventDateTime);
    }
  }

  setFieldValue(
    dateField,
    G.createLocalDateTimeFromInstanceOrISOString(value, GC.DEFAULT_DATE_FORMAT),
  );
};

export const getDateCustomBlurHandler = (timeField: any, dateField: string) => (
  event: Object,
  field: Object,
  props: Object,
) => {
  const { values, fromPage, setValues, setFieldValue } = props;

  const value = event.target.value;
  const timePath = G.ifElse(G.isArray(timeField), timeField, R.of(Array, timeField));
  const eventTime = R.path(timePath, values);

  if (G.isValidDate(value)) {
    const validDate = G.createLocalDateTimeFromInstanceOrISOString(value, GC.DEFAULT_DATE_FORMAT);
    const eventDateTime = G.createLocalDateTimeFromInstanceOrISOString(
      `${validDate} ${eventTime}`,
      GC.DEFAULT_DATE_TIME_FORMAT,
    );
    const isFromPlanner = R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]);

    if (G.isValidDate(eventDateTime)) {
      if (R.and(isFromPlanner, R.equals(dateField, GC.FIELD_LOAD_EVENT_EARLY_DATE))) {
        return setValues(R.mergeRight(values, {
          [GC.FIELD_LOAD_EVENT_LATE_DATE]: eventDateTime,
          [GC.FIELD_LOAD_EVENT_EARLY_DATE]: eventDateTime,
        }));
      }

      setFieldValue(dateField, eventDateTime);
    }
  }
};

const getDateTimeCustomBlurHandler = (dateField: string) => (
  event: Object,
  field: Object,
  props: Object,
) => {
  const { values, fromPage, setValues, setFieldValue } = props;

  const value = event.target.value;

  if (G.isValidDate(value)) {
    const validDateTime = G.createLocalDateTimeFromInstanceOrISOString(value, GC.DEFAULT_DATE_TIME_FORMAT);
    const isFromPlanner = R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]);

    if (G.isValidDate(validDateTime)) {
      if (R.and(isFromPlanner, R.equals(dateField, GC.FIELD_LOAD_EVENT_EARLY_DATE))) {
        return setValues(R.mergeRight(values, {
          [GC.FIELD_LOAD_EVENT_LATE_DATE]: validDateTime,
          [GC.FIELD_LOAD_EVENT_EARLY_DATE]: validDateTime,
        }));
      }

      setFieldValue(dateField, validDateTime);
    }
  }
};

export const getTimeCustomBlurHandler = (dateField: any) => (event: Object, field: Object, props: Object) => {
  const { values, fromPage, setValues, setFieldValue } = props;

  const value = event.target.value;
  const datePath = G.ifElse(G.isArray(dateField), dateField, R.of(Array, dateField));
  const eventDate = R.path(datePath, values);

  if (G.isValidDate(eventDate)) {
    const validDate = G.createLocalDateTimeFromInstanceOrISOString(eventDate, GC.DEFAULT_DATE_FORMAT);
    const eventDateTime = G.createLocalDateTimeFromInstanceOrISOString(
      `${validDate} ${value}`,
      GC.DEFAULT_DATE_TIME_FORMAT,
    );
    if (G.isValidDate(eventDateTime)) {
      let dateFieldToSet = dateField;

      if (G.isArray(dateField)) dateFieldToSet = R.join('.', dateField);

      const isFromPlanner = R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]);

      if (R.and(isFromPlanner, R.equals(dateField, GC.FIELD_LOAD_EVENT_EARLY_DATE))) {
        setValues(R.mergeRight(values, {
          [GC.FIELD_LOAD_EVENT_LATE_TIME]: value,
          [GC.FIELD_LOAD_EVENT_LATE_DATE]: eventDateTime,
          [GC.FIELD_LOAD_EVENT_EARLY_DATE]: eventDateTime,
        }));
      } else {
        setFieldValue(dateFieldToSet, eventDateTime);
      }
    }
  }
};

export const pickupInfoStopFields = [
  {
    width: 100,
    type: 'text',
    inputWrapperStyles: {
      mr: 10,
      flexGrow: 1,
      flexBasis: 100,
    },
    fieldName: GC.FIELD_STOP_NUMBER,
    label: G.getWindowLocale('titles:pickup-number', 'Pickup Number'),
  },
  {
    type: 'checkbox',
    inputWrapperStyles: {
      mb: 0,
      mr: 10,
      flexGrow: 0,
      flexBasis: 60,
    },
    fieldName: GC.FIELD_LOAD_FCFS,
    label: G.getWindowLocale('titles:fcfs', 'FCFS'),
  },
  {
    isRequired: true,
    timeIntervals: 1,
    type: 'datePicker',
    timeSelection: true,
    shouldCustomBlur: true,
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 0,
      flexBasis: 180,
      display: earlyCalendarDateTimeDisplayFunc,
    },
    name: 'titles:early-date',
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_DATE,
    placeholder: GC.DEFAULT_DATE_TIME_PLACEHOLDER,
    label: G.getWindowLocale('titles:pickup-window-from', 'Pickup Window From'),
    customBlurHandler: getDateTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    timeIntervals: 1,
    type: 'datePicker',
    timeSelection: true,
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 0,
      flexBasis: 180,
      display: lateCalendarDateTimeDisplayFunc,
    },
    name: 'titles:window-to',
    fieldName: GC.FIELD_LOAD_EVENT_LATE_DATE,
    placeholder: GC.DEFAULT_DATE_TIME_PLACEHOLDER,
    label: G.getWindowLocale('titles:pickup-window-to', 'Pickup Window To'),
  },
  {
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 10,
      flexGrow: 0,
      flexBasis: 120,
      display: earlyCalendarDateAndTimeDisplayFunc,
    },
    shouldCustomBlur: true,
    shouldCustomChange: true,
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_DATE,
    label: G.getWindowLocale('titles:pickup-window-from', 'Pickup Window From'),
    customBlurHandler: getDateCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_TIME, GC.FIELD_LOAD_EVENT_EARLY_DATE),
    customChangeHandler2: getCustomChangeHandler2(GC.FIELD_LOAD_EVENT_EARLY_TIME, GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    isRequired: true,
    type: 'timeInput',
    shouldCustomBlur: true,
    inputWrapperStyles: {
      mr: 10,
      flexGrow: 0,
      flexBasis: 100,
      display: earlyCalendarDateAndTimeDisplayFunc,
    },
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_TIME,
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    label: G.getWindowLocale('titles:time', 'Time'),
    customBlurHandler: getTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 10,
      flexGrow: 0,
      flexBasis: 110,
      display: lateCalendarDateAndTimeDisplayFunc,
    },
    shouldCustomBlur: true,
    shouldCustomChange: true,
    fieldName: GC.FIELD_LOAD_EVENT_LATE_DATE,
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    label: G.getWindowLocale('titles:pickup-window-to', 'Pickup Window To'),
    customBlurHandler: getDateCustomBlurHandler(GC.FIELD_LOAD_EVENT_LATE_TIME, GC.FIELD_LOAD_EVENT_LATE_DATE),
    customChangeHandler2: getCustomChangeHandler2(GC.FIELD_LOAD_EVENT_LATE_TIME, GC.FIELD_LOAD_EVENT_LATE_DATE),
  },
  {
    isRequired: true,
    type: 'timeInput',
    shouldCustomBlur: true,
    inputWrapperStyles: {
      mr: 0,
      zIndex: 13,
      flexGrow: 0,
      flexBasis: 100,
      display: lateCalendarDateAndTimeDisplayFunc,
    },
    fieldName: GC.FIELD_LOAD_EVENT_LATE_TIME,
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    label: G.getWindowLocale('titles:time', 'Time'),
    customBlurHandler: getTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_LATE_DATE),
  },
];

export const dropInfoStopFields = [
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 10,
      flexGrow: 1,
      flexBasis: 100,
    },
    fieldName: GC.FIELD_STOP_NUMBER,
    label: G.getWindowLocale('titles:drop-number', 'Drop Number'),
  },
  {
    type: 'checkbox',
    inputWrapperStyles: {
      mb: 0,
      mr: 10,
      flexGrow: 0,
      flexBasis: 60,
    },
    fieldName: GC.FIELD_LOAD_FCFS,
    label: G.getWindowLocale('titles:fcfs', 'FCFS'),
  },
  {
    isRequired: true,
    timeIntervals: 1,
    isClearable: true,
    type: 'datePicker',
    timeSelection: true,
    shouldCustomBlur: true,
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 0,
      flexBasis: 180,
      display: earlyCalendarDateTimeDisplayFunc,
    },
    name: 'titles:early-date',
    placeholder: GC.DEFAULT_DATE_TIME_FORMAT,
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_DATE,
    label: G.getWindowLocale('titles:drop-window-from', 'Drop Window From'),
    customBlurHandler: getDateTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    timeIntervals: 1,
    isClearable: true,
    type: 'datePicker',
    timeSelection: true,
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 0,
      flexBasis: 180,
      display: lateCalendarDateTimeDisplayFunc,
    },
    name: 'titles:window-to',
    placeholder: GC.DEFAULT_DATE_TIME_FORMAT,
    fieldName: GC.FIELD_LOAD_EVENT_LATE_DATE,
    label: G.getWindowLocale('titles:drop-window-to', 'Drop Window To'),
  },
  {
    width: 120,
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 10,
      flexGrow: 0,
      flexBasis: 120,
      display: earlyCalendarDateAndTimeDisplayFunc,
    },
    shouldCustomBlur: true,
    shouldCustomChange: true,
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_DATE,
    label: G.getWindowLocale('titles:drop-window-from', 'Drop Window From'),
    customBlurHandler: getDateCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_TIME, GC.FIELD_LOAD_EVENT_EARLY_DATE),
    customChangeHandler2: getCustomChangeHandler2(GC.FIELD_LOAD_EVENT_EARLY_TIME, GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    isRequired: true,
    type: 'timeInput',
    shouldCustomBlur: true,
    inputWrapperStyles: {
      mr: 10,
      flexGrow: 0,
      flexBasis: 100,
      display: earlyCalendarDateAndTimeDisplayFunc,
    },
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_TIME,
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    label: G.getWindowLocale('titles:time', 'Time'),
    customBlurHandler: getTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 10,
      flexGrow: 0,
      flexBasis: 110,
      display: lateCalendarDateAndTimeDisplayFunc,
    },
    shouldCustomBlur: true,
    shouldCustomChange: true,
    fieldName: GC.FIELD_LOAD_EVENT_LATE_DATE,
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    label: G.getWindowLocale('titles:drop-window-to', 'Drop Window To'),
    customBlurHandler: getDateCustomBlurHandler(GC.FIELD_LOAD_EVENT_LATE_TIME, GC.FIELD_LOAD_EVENT_LATE_DATE),
    customChangeHandler2: getCustomChangeHandler2(GC.FIELD_LOAD_EVENT_LATE_TIME, GC.FIELD_LOAD_EVENT_LATE_DATE),
  },
  {
    isRequired: true,
    type: 'timeInput',
    inputWrapperStyles: {
      mr: 0,
      zIndex: 13,
      flexGrow: 0,
      flexBasis: 100,
      display: lateCalendarDateAndTimeDisplayFunc,
    },
    shouldCustomBlur: true,
    fieldName: GC.FIELD_LOAD_EVENT_LATE_TIME,
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    label: G.getWindowLocale('titles:time', 'Time'),
    customBlurHandler: getTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_LATE_DATE),
  },
];

const apptsLateTimeVisibilityFunc = (props: Object) => {
  const { branchConfigs } = props;

  return G.ifElse(
    G.isTrue(G.getConfigValueFromStore(GC.CLO_UI_SINGLE_APPOINTMENT_TIME, branchConfigs)),
    'hidden',
    'visible',
  );
};

const apptEarlyTimeLabelFunc = (props: Object) => {
  const { branchConfigs } = props;

  return G.ifElse(
    G.isTrue(G.getConfigValueFromStore(GC.CLO_UI_SINGLE_APPOINTMENT_TIME, branchConfigs)),
    G.getWindowLocale('titles:appt-time', 'Appt Time'),
    G.getWindowLocale('titles:early-time', 'Early Time'),
  );
};

export const apptsStopFields = [
  {
    inputWrapperStyles: {
      mb: 0,
      mr: 20,
      width: 80,
    },
    type: 'checkbox',
    fieldName: GC.FIELD_LOAD_APPOINTMENT_REQUIRED,
    label: G.getWindowLocale('titles:required', 'Required'),
  },
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 20,
      flexGrow: 1,
      flexBasis: 100,
    },
    fieldName: GC.FIELD_LOAD_APPOINTMENT_NUMBER,
    label: G.getWindowLocale('titles:appt-number', 'Appt Number'),
  },
  {
    width: 120,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 20,
      width: 120,
    },
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    fieldName: GC.FIELD_LOAD_APPOINTMENT_DATE,
    label: G.getWindowLocale('titles:appt-date', 'Appt Date'),
  },
  {
    width: 120,
    type: 'timeInput',
    inputWrapperStyles: {
      mr: 20,
      width: 110,
    },
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    fieldName: GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
    label: (props: Object) => apptEarlyTimeLabelFunc(props),
  },
  {
    width: 110,
    type: 'timeInput',
    inputWrapperStyles: {
      mr: 20,
      width: 110,
      visibility: apptsLateTimeVisibilityFunc,
    },
    placeholder: GC.DEFAULT_TIME_PM_PLACEHOLDER,
    fieldName: GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
    label: G.getWindowLocale('titles:late-time', 'Late Time'),
  },
];

export const trailersStopFields = [
  {
    inputWrapperStyles: {
      mr: 20,
      zIndex: 21,
      width: 300,
    },
    isMulti: true,
    type: 'reactSelect',
    shouldValidate: true,
    closeMenuOnSelect: false,
    options: 'trailersInServiceOptions',
    fieldName: GC.FIELD_STOP_PICKED_UP_TRAILER_GUIDS,
    label: G.getWindowLocale('titles:picked-up', 'Picked Up'),
  },
  {
    inputWrapperStyles: {
      mr: 20,
      zIndex: 21,
      width: 300,
    },
    isMulti: true,
    type: 'reactSelect',
    shouldValidate: true,
    closeMenuOnSelect: false,
    options: 'trailersInServiceOptions',
    fieldName: GC.FIELD_STOP_DROPPED_TRAILER_GUIDS,
    label: G.getWindowLocale('titles:dropped', 'Dropped'),
  },
];

export const getDefaultContactFields = () => ({
  [GC.FIELD_FAX]: null,
  [GC.FIELD_EMAIL]: null,
  [GC.FIELD_PHONE]: null,
  [GC.FIELD_ID]: G.genShortId(),
  [GC.FIELD_CONTACT_NAME]: null,
  [GC.FIELD_PHONE_EXTENSION]: null,
});

export const contactFieldSettings = [
  {
    type: 'text',
    inputWrapperStyles: {
      mr: 20,
      width: 240,
    },
    fieldName: GC.FIELD_CONTACT_NAME,
    label: G.getWindowLocale('titles:contact-name', 'Contact Name'),
  },
  {
    type: 'phoneNumber',
    inputWrapperStyles: {
      mr: 20,
      width: 200,
    },
    fieldName: GC.FIELD_PHONE,
    label: G.getWindowLocale('titles:phone', 'Phone'),
  },
  {
    width: 100,
    type: 'number',
    inputWrapperStyles: {
      mr: 20,
      width: 100,
    },
    fieldName: GC.FIELD_PHONE_EXTENSION,
    label: G.getWindowLocale('titles:phone-extension', 'Phone Extension'),
  },
  {
    width: 240,
    type: 'text',
    fieldName: GC.FIELD_EMAIL,
    label: G.getWindowLocale('titles:email', 'Email'),
    inputWrapperStyles: {
      mr: 20,
      mt: 30,
      width: 240,
    },
  },
  {
    width: 200,
    type: 'text',
    fieldName: GC.FIELD_FAX,
    label: G.getWindowLocale('titles:fax-number', 'Fax Number'),
    inputWrapperStyles: {
      mr: 20,
      mt: 30,
      width: 200,
    },
  },
];
