import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const store = R.path(['claimManagement', 'claimDetails']);

const makeSelectGeneralDetails = () => createSelector(
  store,
  R.prop('generalDetails'),
);

const makeSelectDropdownOptionsByDropdownNames = createSelector(
  [
    R.path(['claimManagement', 'claimDetails', 'configGroup', 'dropdownOptions']),
    (_: Object, dropdownNames: Array) => dropdownNames,
  ],
  (dropdownOptions: Object, dropdownNames: Array = []) => {
    if (G.isNilOrEmpty(dropdownNames)) return dropdownOptions;

    const dropdownOptionsByNames = R.pickAll(R.split(',', dropdownNames), R.or(dropdownOptions, {}));

    return dropdownOptionsByNames;
  },
);

const makeSelectClaimGuid = () => createSelector(
  store,
  R.path(['generalDetails', GC.FIELD_GUID]),
);

const makeSelectClaimGuidAndClaimBranchGuid = () => createSelector(
  [
    R.path(['claimManagement', 'claimDetails', 'generalDetails', GC.FIELD_GUID]),
    R.path(['claimManagement', 'claimDetails', 'generalDetails', GC.BRANCH_GUID]),
  ],
  (claimGuid: string, claimBranchGuid: string) => ({ claimGuid, claimBranchGuid }),
);

const makeSelectHeaderInfo = () => createSelector(
  R.path(['claimManagement', 'claimDetails', 'generalDetails']),
  ({ type, status, reason, subStatus, claimDate, claimNumber }: Object) => ({
    claimDate,
    claimNumber,
    claimType: G.getDisplayedValueFromObject(type),
    claimReason: G.getDisplayedValueFromObject(reason),
    claimStatus: G.getDisplayedValueFromObject(status),
    claimSubStatus: G.getDisplayedValueFromObject(subStatus),
  }),
);

const makeSelectClo = () => createSelector(
  R.path(['claimManagement', 'claimDetails', 'generalDetails', GC.FIELD_CLO]),
  R.clone,
);

const makeSelectTel = () => createSelector(
  R.path(['claimManagement', 'claimDetails', 'generalDetails', GC.FIELD_TEL]),
  R.clone,
);

const makeSelectDocuments = () => createSelector(
  store,
  R.prop('documents'),
);

const makeSelectNotes = () => createSelector(
  store,
  R.prop('notes'),
);

const makeSelectPinnedNote = () => createSelector(
  R.pathOr([], ['claimManagement', 'claimDetails', 'notes']),
  R.find(R.propEq(true, 'pinned')),
);

const makeSelectRelatedTels = () => createSelector(
  store,
  R.prop('relatedTels'),
);

const makeSelectCloItems = () => createSelector(
  store,
  R.prop('cloItems'),
);

const makeSelectDamagedItemsByCloItemGuid = createSelector(
  [
    R.path(['claimManagement', 'claimDetails', 'groupedByCloItemGuidDamagedItems']),
    (_: any, cloItemGuid: string) => cloItemGuid,
  ],
  (grouped: Object, guid: string) => R.prop(guid, grouped),
);

const makeSelectAccident = () => createSelector(
  store,
  R.prop('accident'),
);

const makeSelectInvolvedPartyList = () => createSelector(
  store,
  R.prop('involvedPartyList'),
);

const makeSelectAttorneyList = () => createSelector(
  store,
  R.prop('attorneyList'),
);

export {
  makeSelectClo,
  makeSelectTel,
  makeSelectNotes,
  makeSelectAccident,
  makeSelectCloItems,
  makeSelectDocuments,
  makeSelectClaimGuid,
  makeSelectPinnedNote,
  makeSelectHeaderInfo,
  makeSelectRelatedTels,
  makeSelectAttorneyList,
  makeSelectGeneralDetails,
  makeSelectInvolvedPartyList,
  makeSelectDamagedItemsByCloItemGuid,
  makeSelectClaimGuidAndClaimBranchGuid,
  makeSelectDropdownOptionsByDropdownNames,
};
