import React from 'react';
import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
// components
import { setName } from '../table/components/helpers';
import { FormFooter2, TextComponent } from '../../components';
// forms
import { Checkbox } from '../../forms/formik/fieldset2/ui';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, scrollableContainerCss4px } from '../../ui';
//////////////////////////////////////////////////

const Field = ({ name, label, checked, disabled, handleChangeCheckbox }: Object) => (
  <Flex
    my='5px'
    height={45}
    width='100%'
    p='10px 20px'
    borderRadius='4px'
    alignItems='center'
    justifyContent='space-between'
    backgroundColor={G.getTheme('colors.white')}
    boxShadow={`0 2px 5px 0 ${G.getTheme('colors.boxShadowGrey')}`}
  >
    <Flex>
      <Box>{I.dnd(G.getTheme('colors.dark.blue'), 20, 20)}</Box>
      <TextComponent
        mx={10}
        title={label}
        fontSize={16}
        maxWidth={340}
        withEllipsis={true}
      >
        {label}
      </TextComponent>
    </Flex>
    {
      R.not(disabled) &&
      <Checkbox
        name={name}
        type='checkbox'
        checked={checked}
        onChange={(e: Object) => handleChangeCheckbox(e, name)}
      />
    }
  </Flex>
);

const enhance = compose(
    withState('reportFieldList', 'setReportFieldList', ({ uiReportFields, defaultReportFields }: Object) => {
      if (G.isNilOrEmpty(uiReportFields)) {
        return R.map((name: string) => ({ name, [GC.FIELD_CHECKED]: true }), defaultReportFields);
      }

      return R.compose(
        R.map((name: string) => ({ name, [GC.FIELD_CHECKED]: R.includes(name, uiReportFields) })),
        R.uniq,
        R.concat(uiReportFields),
      )(defaultReportFields);
    }),
  withHandlers({
    handleChangeCheckbox: (props: Object) => ({ target }: Object, fieldName: string) => {
      const { reportFieldList, setReportFieldList } = props;

      const fieldIndex = R.findIndex(R.propEq(fieldName, GC.FIELD_NAME), reportFieldList);

      const fieldsUpdated = R.assocPath(
        [fieldIndex, GC.FIELD_CHECKED],
        G.getPropFromObject(GC.FIELD_CHECKED, target),
        reportFieldList,
      );

      setReportFieldList(fieldsUpdated);
    },
    handleSaveFields: (props: Object) => () => {
      const { closeModal, reportName, reportFieldList, setUiReportFields } = props;

      const reportFields = R.compose(
        R.map(R.prop(GC.FIELD_NAME)),
        R.filter(R.prop(GC.FIELD_CHECKED)),
      )(reportFieldList);

      setUiReportFields(reportFields);

      G.setUiReportFieldsToLocalStorage(reportName, reportFields);

      closeModal();
    },
    onDragEnd: (props: Object) => ({ source, destination }: Object) => {
      const { reportFieldList, setReportFieldList } = props;

      const startIndex = G.getPropFromObject('index', source);
      const endIndex = G.getPropFromObject('index', destination);

      if (R.and(destination, G.notEquals(startIndex, endIndex))) {
        const item = R.path([startIndex], reportFieldList);

        const newList = R.compose(
          R.insert(endIndex, item),
          R.remove(startIndex, 1),
        )(reportFieldList);

        setReportFieldList(newList);
      }
    },
  }),
  pure,
);

const EditViewForm = enhance((props: Object) => {
  const {
    onDragEnd,
    columnSettings,
    reportFieldList,
    handleSaveFields,
    disableFieldHandler,
    handleChangeCheckbox,
  } = props;

  return (
    <Flex height='100%' flexDirection='column' justifyContent='space-between'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='uiReportFields'>
          {(provided: Object) => (
            <Box
              {...provided.droppableProps}
              p={15}
              mb={30}
              width='100%'
              overflow='auto'
              ref={provided.innerRef}
              css={scrollableContainerCss4px}
            >
              {
                reportFieldList.map((field: Object, index: number) => {
                  const { name } = field;

                  const disabled = G.isFunction(disableFieldHandler) ? disableFieldHandler(name) : false;

                  const label = setName(field, { columnSettings });

                  return (
                    <Draggable key={name} index={index} draggableId={name}>
                      {(provided: Object) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <Field
                            {...field}
                            label={label}
                            disabled={disabled}
                            handleChangeCheckbox={handleChangeCheckbox}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })
              }
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <FormFooter2
        submitAction={handleSaveFields}
        submitBtnText={G.getWindowLocale('actions:save', 'Save')}
        boxStyles={{ p: 15, bottom: '0', position: 'sticky', bg: G.getTheme('colors.white') }}
      />
    </Flex>
  );
});

export default EditViewForm;
