import shave from 'shave';
import { createRoot } from 'react-dom/client';
import React, { useEffect, useRef } from 'react';
//////////////////////////////////////////////////

const jsShaveCharClassName = 'js-shave-char';
const showLessTextClassName = 'js-show-less-text';

const TextMoreLess = ({
  text,
  className,
  lessHeight,
  rootProps = {},
  showLessElement,
  showMoreElement,
  collapsed = true,
  onClick = () => {},
  showMoreText = '...',
}: Object) => {
  const { style } = rootProps;

  const rootRef = useRef(null);

  useEffect(() => {
    const setDOM = () => {
      if (!rootRef.current) return;

      if (collapsed) {
        shave(rootRef.current, lessHeight, { spaces: false, classname: className, character: showMoreText });

        const shaveChar = rootRef.current.querySelector(`.${jsShaveCharClassName}`);

        if (shaveChar && showMoreElement) {
          const root = createRoot(shaveChar);

          root.render(showMoreElement);
          shaveChar.__reactRoot = root;
        }
      } else {
        const hasShowLess = !!rootRef.current.querySelector(`.${showLessTextClassName}`);

        if (rootRef.current.offsetHeight > lessHeight && showLessElement && !hasShowLess) {
          const collapse = document.createElement('span');

          collapse.setAttribute('class', showLessTextClassName);

          const root = createRoot(collapse);

          root.render(showLessElement);
          rootRef.current.appendChild(collapse);
          collapse.__reactRoot = root;
        }
      }
    };

    const resetDOM = () => {
      if (!rootRef.current) return;

      const shaveChar = rootRef.current.querySelector(`.${jsShaveCharClassName}`);

      if (shaveChar && shaveChar.__reactRoot) {
        setTimeout(() => { // Delay Unmounting Until Rendering Completes (Need to avoid console error)
          shaveChar.__reactRoot.unmount();
        }, 0);
      }

      const collapse = rootRef.current.querySelector(`.${showLessTextClassName}`);

      if (collapse && collapse.__reactRoot) {
        rootRef.current.removeChild(collapse);

        setTimeout(() => { // Delay Unmounting Until Rendering Completes (Need to avoid console error)
          collapse.__reactRoot.unmount();
        }, 0);
      }

      rootRef.current.innerHTML = text;
    };

    window.addEventListener('resize', setDOM);

    setDOM();

    return () => {
      resetDOM();

      window.removeEventListener('resize', setDOM);
    };
  }, [text, lessHeight, collapsed, className, showMoreText]);

  return (
    <div ref={rootRef} style={style} className={className} onClick={onClick}>
      {text}
    </div>
  );
};

export default TextMoreLess;
