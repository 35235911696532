import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  Text,
  Span,
  SelectWrapper,
  SelectComponent,
} from '../../../ui';
// component charge
import NormalizedCurrency from '../normalized-currency';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const whiteBlack = G.getTheme('colors.black');
const darkGreyColor = G.getTheme('colors.darkGrey');
const darkBlueColor = G.getTheme('colors.dark.blue');
const whiteGreyColor = G.getTheme('colors.whiteGrey');
const lightDarkGreyColor = G.getTheme('colors.light.darkGrey');

const FieldSelect = (props: Object) => {
  const { name, width, options, onChangeSelect } = props;

  return (
    <SelectWrapper afterTop={13} afterRight={15}>
      <SelectComponent
        pr={25}
        name={name}
        width={R.or(width, 232)}
        onChange={onChangeSelect}
        value={R.or(props.value, '')}
        disabled={R.or(props.disabled, false)}
      >
        {
          options.map(({ value, label, disabled }: Object, index: number) => (
            <option
              key={index}
              value={value}
              disabled={disabled}
            >
              {label}
            </option>
          ))
        }
      </SelectComponent>
    </SelectWrapper>
  );
};

const AccessorialsSelect = (props: Object) => {
  const { name, width, disabled, onChangeSelect } = props;

  let options = R.pathOr([], ['options'], props);

  const accessorial = R.pathOr('', [GC.FIELD_VALUE], props);

  const isNotExistedAccessorial = R.compose(
    R.isNil,
    R.find(R.propEq(accessorial, GC.FIELD_VALUE)),
  )(options);

  if (G.isTrue(isNotExistedAccessorial)) {
    options = R.insert(
      1,
      { value: accessorial, label: accessorial },
      options,
    );
  }

  return (
    <SelectWrapper
      afterTop={13}
      afterRight={15}
      display='inline-block'
      width={R.or(width, 232)}
    >
      <SelectComponent
        pr={25}
        name={name}
        width={R.or(width, 232)}
        onChange={onChangeSelect}
        value={R.or(props.value, '')}
        disabled={R.or(disabled, false)}
      >
        {
          options.map(({ value, label, disabled }: Object, index: number) => (
            <option
              key={index}
              value={value}
              disabled={disabled}
            >
              {label}
            </option>
          ))
        }
      </SelectComponent>
    </SelectWrapper>
  );
};

const getPayLocale = (currencySymbol: string, chargesTotalPay: number, activeDriver: string) => {
  let totalPayLocale = ['titles:total-driver-pay', 'Total Driver Pay'];

  if (G.isNilOrEmpty(activeDriver)) totalPayLocale = ['titles:total-carrier-pay', 'Total Carrier Pay'];

  if (R.equals(activeDriver, GC.FIELD_VENDOR)) totalPayLocale = ['titles:total-vendor-pay', 'Total Vendor Pay'];

  return (
    <Box
      p='5px'
      mr={15}
      borderRadius='5px'
      bg='titleDarkBlue'
      color={whiteColor}
    >
      {`${G.getWindowLocale(...totalPayLocale)}: ${currencySymbol} ${chargesTotalPay}`}
    </Box>
  );
};

const getTotalLocale = (isInvoice: boolean) => G.getWindowLocale(
  ...G.ifElse(isInvoice, ['titles:total', 'total'], ['titles:total-expense', 'Total Expense']),
);

const AdvanceSubtotal = (props: Object) => {
  const { charges, currency } = props;

  if (G.isNilOrEmpty(charges)) return null;

  const advanceCharges = R.filter((item: Object) => G.getPropFromObject(GC.FIELD_ADVANCE_PAYMENT, item), charges);

  if (G.isNilOrEmpty(advanceCharges)) return null;

  const subtotal = G.NaNToZero(G.mathRoundNumber(G.calcChargesArrayTotal(advanceCharges, { currency })));
  const text = `- ${G.getCurrencySymbol(currency)} ${subtotal}`;

  return (
    <Flex pr={25}>
      <Text
        fontSize='14px'
        fontWeight='bold'
        color={darkGreyColor}
        textTransform='uppercase'
      >
        {`${G.getWindowLocale('titles:advance-subtotal', 'Advance Subtotal')}:`}
      </Text>
      <TextComponent
        ml={10}
        title={text}
        fontSize={14}
        fontWeight='bold'
        color={whiteBlack}
        withEllipsis={true}
        display='inline-block'
      >
        {text}
      </TextComponent>
    </Flex>
  );
};

const getSubtotalOptions = (props: Object) => {
  const { currency, isVendorRate, isDriverRate, isCarrierRate, isAdditionalCharges } = props;

  if (R.and(G.isTrue(isDriverRate), G.isTrue(isAdditionalCharges))) {
    return {
      rateCurrency: currency, omitAdvancePayment: true,
    };
  }

  if (R.and(G.isTrue(isVendorRate), G.isTrue(isAdditionalCharges))) {
    return {
      rateCurrency: currency, omitAdvancePayment: true,
    };
  }

  if (R.and(G.isTrue(isCarrierRate), G.isTrue(isAdditionalCharges))) {
    return {
      rateCurrency: currency, omitAdvancePayment: true,
    };
  }

  return { rateCurrency: currency };
};

const ChargesArrayHeader = (props: Object) => {
  const {
    title,
    expanded,
    chargeType,
    setExpanded,
    arrayHelpers,
    handleAddCharge,
  } = props;

  const toggleIconName = G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple');

  return (
    <Flex
      p='5px'
      my={10}
      fontWeight='bold'
      bg={whiteGreyColor}
      color={darkBlueColor}
      justifyContent='center'
      alignItems='flex-start'
      textTransform='uppercase'
    >
      <Box mr={15} ml='auto'>{title}</Box>
      <Box
        cursor='pointer'
        onClick={() => handleAddCharge({ chargeType, arrayHelpers })}
      >
        {I.plusRound(darkBlueColor)}
      </Box>
      <Box
        px='6px'
        ml='auto'
        cursor='pointer'
        onClick={() => setExpanded(R.not(expanded))}
      >
        {I[toggleIconName](darkBlueColor)}
      </Box>
    </Flex>
  );
};

const ChargesArrayFooter = (props: Object) => {
  const { charges, currency } = props;

  const subtotalOptions = getSubtotalOptions(props);
  const subtotal = G.NaNToZero(G.mathRoundNumber(G.calcChargesArrayTotal(charges, subtotalOptions)));
  const text = `${G.getCurrencySymbol(currency)} ${subtotal}`;

  return (
    <Flex px={10} justifyContent='end'>
      {
        G.isNotEmpty(charges) &&
        <Flex>
          <AdvanceSubtotal {...props} />
          <Text
            fontSize='14px'
            fontWeight='bold'
            color={darkGreyColor}
            textTransform='uppercase'
          >
            {`${G.getWindowLocale('titles:subtotal', 'Subtotal')}:`}
          </Text>
          <TextComponent
            ml={10}
            title={text}
            fontSize={14}
            fontWeight='bold'
            color={whiteBlack}
            withEllipsis={true}
            display='inline-block'
          >
            {text}
          </TextComponent>
        </Flex>
      }
    </Flex>
  );
};

const ExchangeTotal = (props: Object) => {
  const { values, isEditMode } = props;

  const normalizedTotal = G.getPropFromObject('normalizedTotal', values);

  if (R.and(isEditMode, G.isNotNilAndNotEmpty(normalizedTotal))) {
    const { total, currency, exchangeDate } = normalizedTotal;

    const currencySymbol = G.getCurrencySymbol(currency);

    return (
      <Box
        p='5px'
        mr={15}
        borderRadius='5px'
        bg='titleDarkBlue'
        color={whiteColor}
      >
        {`${G.getWindowLocale('titles:normalized-total', 'Normalized Total')}:
          ${currencySymbol} ${total} (${exchangeDate})`}
      </Box>
    );
  }

  return null;
};

const ChargesTotal = (props: Object) => {
  const {
    values,
    charges,
    isInvoice,
    isEditMode,
    activeDriver,
    normalizedTotal,
  } = props;

  const isNotInvoice = R.not(isInvoice);

  const currency = R.pathOr('', [GC.FIELD_CURRENCY], values);
  const currencySymbol = G.getCurrencySymbolFromRate(values);

  let chargesTotal = G.getCalcTelRateChargesTotal(charges, currency);

  const chargesTotalPay = G.getCalcTelRateChargesTotalPay(charges, currency);

  if (G.isTrue(isInvoice)) chargesTotal = G.getCalcTelInvoiceChargesTotal(charges, currency);

  return (
    <Flex
      p='5px'
      mt={10}
      bg={whiteGreyColor}
      justifyContent='center'
      borderBottom='1px solid'
      borderColor={lightDarkGreyColor}
    >
      <ExchangeTotal values={values} isEditMode={isEditMode} />
      {isNotInvoice && getPayLocale(currencySymbol, chargesTotalPay, activeDriver)}
      <Box
        p='5px'
        mr={15}
        borderRadius='5px'
        bg='titleDarkBlue'
        color={whiteColor}
      >
        {`${getTotalLocale(isInvoice)}: ${currencySymbol} ${chargesTotal}`}
      </Box>
      <NormalizedCurrency
        p='0px'
        total={chargesTotal}
        sourceCurrency={currency}
        normalizedTotal={normalizedTotal}
        title={G.getWindowLocale('titles:current-normalized-total', 'Current Normalized Total')}
      />
    </Flex>
  );
};

const OrderChargesTotal = (props: Object) => {
  const {
    values,
    charges,
    isEditMode,
    normalizedTotal,
  } = props;

  const currency = R.pathOr('', [GC.FIELD_CURRENCY], values);
  const currencySymbol = G.getCurrencySymbolFromRate(values);
  const totalPay = G.getCalcRateChargesTotal(charges, currency);

  return (
    <Flex
      p='5px'
      mt={10}
      bg={whiteGreyColor}
      justifyContent='center'
      borderBottom='1px solid'
      borderColor={lightDarkGreyColor}
    >
      <ExchangeTotal values={values} isEditMode={isEditMode} />
      <Box
        p='5px'
        borderRadius='5px'
        bg='titleDarkBlue'
        color={whiteColor}
      >
        {`${G.getWindowLocale('titles:total-pay', 'Total Pay')}: ${currencySymbol} ${totalPay}`}
      </Box>
      <NormalizedCurrency
        total={totalPay}
        sourceCurrency={currency}
        normalizedTotal={normalizedTotal}
        title={G.getWindowLocale('titles:current-normalized-total', 'Current Normalized Total')}
      />
    </Flex>
  );
};

const ChargeComment = ({ charge, handleOpenChargeComment }: Object) => (
  <span
    onClick={() => handleOpenChargeComment(charge)}
    title={G.getWindowLocale('actions:add-comments', 'Add Comments')}
  >
    {I.comment2()}
  </span>
);

const FuelIndex = ({ charge }: Object) => {
  const fuelIndexInfo = G.getPropFromObject('fuelIndexInfo', charge);

  if (G.isNilOrEmpty(fuelIndexInfo)) return null;

  const { rate, price, district, priceDate } = fuelIndexInfo;

  const text = `
    ${G.getWindowLocale('titles:fuel-price', 'Fuel Price')}: ${price},
    ${G.getWindowLocale('titles:date', 'Date')}: ${priceDate},
    ${G.getWindowLocale('titles:district', 'District')}: ${district},
    ${G.getWindowLocale('titles:rate', 'Rate')}: ${rate}
  `;

  return (
    <Flex pl='5px'>
      <span>{I.average(darkBlueColor, 13, 17)}</span>
      <TextComponent
        px='5px'
        title={text}
        fontSize={12}
        maxWidth={200}
        display='block'
        withEllipsis={true}
        color={darkBlueColor}
      >
        {text}
      </TextComponent>
    </Flex>
  );
};

const ChargeTotal = (props: Object) => {
  const { charge, currency } = props;

  const deduction = G.getPropFromObject(GC.FIELD_DEDUCTION, charge);
  const advance = G.getPropFromObject(GC.FIELD_ADVANCE_PAYMENT, charge);
  const total = G.NaNToZero(G.mathRoundNumber(G.getTotalFromCharge(charge)));
  const currencySymbol = G.getCurrencySymbol(R.pathOr(currency, [GC.FIELD_CHARGE_CURRENCY], charge));
  const text = G.ifElse(
    R.or(G.isTrue(advance), G.isTrue(deduction)),
    `${currencySymbol} -${total}`,
    `${currencySymbol} ${total}`,
  );

  return (
    <Flex>
      <Text
        fontSize='14px'
        fontWeight='bold'
        color={darkGreyColor}
        textTransform='uppercase'
      >
        {`${G.getWindowLocale('titles:total', 'Total')}:`}
      </Text>
      <TextComponent
        ml='10px'
        fontSize='14px'
        fontWeight='bold'
        color={whiteBlack}
        withEllipsis={true}
        display='inline-block'
        title={text}
      >
        {text}
      </TextComponent>
    </Flex>
  );
};

const getChargeCommentText = (charge: Object) => R.pathOr(
  G.getWindowLocale('actions:add-comments', 'Add Comments'),
  [GC.FIELD_CHARGE_COMMENTS],
  charge,
);

const getTaxableText = (charge: Object) => G.ifElse(
  G.isTrue(R.path([GC.FIELD_CHARGE_NON_TAXABLE], charge)),
  G.getWindowLocale('titles:not-taxable', 'Not Taxable'),
  G.getWindowLocale('titles:taxable', 'Taxable'),
);

const getExpenseText = (charge: Object) => G.ifElse(
  G.isTrue(R.path([GC.FIELD_CHARGE_INTERNAL_EXPENSE], charge)),
  G.getWindowLocale('titles:internal-expense', 'Internal Expense'),
  G.getWindowLocale('titles:not-internal-expense', 'Not Internal Expense'),
);

const ChargeInfo = (props: Object) => {
  const {
    charge,
    currency,
    loadType,
    chargesCount,
    showAdjustments,
    handleRemoveCharge,
    handleOpenChargeComment,
    handleOpenLinehaulAdjustments,
  } = props;

  const { deduction, advancePayment, internalExpense, onlyIncludeToInvoice } = charge;

  const commentText = getChargeCommentText(charge);
  const canRemoveCharge = R.or(R.not(G.isChargeTypeMain(charge)), R.gt(chargesCount, 1));

  return (
    <Flex p={10}>
      <Flex flexGrow={1}>
        {
          canRemoveCharge &&
          <Span
            mr={15}
            cursor='pointer'
            onClick={() => handleRemoveCharge(charge)}
            title={G.getWindowLocale('titles:remove-charge', 'Remove Charge')}
          >
            {I.trash(darkBlueColor)}
          </Span>
        }
        <Flex cursor='pointer'>
          <ChargeComment
            charge={charge}
            handleOpenChargeComment={handleOpenChargeComment}
          />
          <TextComponent
            px='5px'
            maxWidth={250}
            fontSize='12px'
            display='block'
            withEllipsis={true}
            title={commentText}
            color={darkBlueColor}
            onClick={() => handleOpenChargeComment(charge)}
          >
            {commentText}
          </TextComponent>
        </Flex>
        <FuelIndex charge={charge} />
        {
          showAdjustments &&
          <Flex mx={10} cursor='pointer'>
            <TextComponent
              px='5px'
              maxWidth={250}
              fontSize='12px'
              display='block'
              color={darkBlueColor}
              onClick={() => handleOpenLinehaulAdjustments(charge)}
            >
              {G.getWindowLocale('titles:adjustments', 'Adjustments')}
            </TextComponent>
          </Flex>
        }
        {
          R.not(G.isChargeTypeMain(charge)) &&
          <Flex mx={10}>
            {G.convertDataTypeToUiField(R.not(R.path([GC.FIELD_CHARGE_NON_TAXABLE], charge)))}
            <Text
              px='5px'
              fontSize='12px'
              color={darkBlueColor}
            >
              {getTaxableText(charge)}
            </Text>
          </Flex>
        }
        {
          internalExpense && R.not(G.isLoadTypeClo(loadType)) &&
          <Flex>
            {G.convertDataTypeToUiField(internalExpense)}
            <Text
              px='5px'
              fontSize='12px'
              color={darkBlueColor}
            >
              {getExpenseText(charge)}
            </Text>
          </Flex>
        }
        {
          advancePayment &&
          <Flex mx={G.ifElse(G.isTrue(internalExpense), 10, '0px')}>
            {G.convertDataTypeToUiField(advancePayment)}
            <Text
              px='5px'
              fontSize='12px'
              color={darkBlueColor}
            >
              {G.getWindowLocale('titles:advance-payment', 'Advance Payment')}
            </Text>
          </Flex>
        }
        <Flex>
          {G.convertDataTypeToUiField2(deduction)}
          <Text
            px='5px'
            fontSize='12px'
            color={darkBlueColor}
          >
            {G.getWindowLocale('titles:deduction', 'Deduction')}
          </Text>
        </Flex>
        {
          onlyIncludeToInvoice &&
          <Flex ml={10}>
            {G.convertDataTypeToUiField2(onlyIncludeToInvoice)}
            <Text
              px='5px'
              fontSize='12px'
              color={darkBlueColor}
            >
              {G.getWindowLocale('titles:include-to-invoice', 'Include To Invoice')}
            </Text>
          </Flex>
        }
      </Flex>
      <ChargeTotal charge={charge} currency={currency} />
    </Flex>
  );
};

export {
  ChargeInfo,
  FieldSelect,
  ChargesTotal,
  OrderChargesTotal,
  AccessorialsSelect,
  ChargesArrayHeader,
  ChargesArrayFooter,
};
