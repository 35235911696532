import * as R from 'ramda';
import { createSelector } from 'reselect';
// feature fleet-map
import {
  getFilteredByTypeLocations,
  getFilteredByUnitIdTrailers,
  getFilteredByLoadedTrailers,
  getFilteredByIdleTimeTrailers,
  getFilteredByEnterpriseFleetsEntity,
} from './helpers';
//////////////////////////////////////////////////

const selectFleetMapStore = (state: Object) => state.fleetMap;

const makeSelectFleets = () => createSelector(
  selectFleetMapStore,
  ({ fleets }: Object) => fleets,
);

const makeSelectFilteredFleets = () => createSelector(
  selectFleetMapStore,
  ({
    fleets,
    idleTimeFilter,
    enterpriseFilter,
    trailerLoadedFilter,
    trailerUnitIdFilter,
  }: Object) => {
    const getFilteredEntities = getFilteredByEnterpriseFleetsEntity(R.pluck('value', enterpriseFilter), fleets);

    return {
      ...fleets,
      trucks: getFilteredEntities('trucks'),
      drivers: getFilteredEntities('drivers'),
      trailers: R.compose(
        getFilteredByIdleTimeTrailers(idleTimeFilter),
        getFilteredByUnitIdTrailers(trailerUnitIdFilter),
        getFilteredByLoadedTrailers(trailerLoadedFilter),
      )(getFilteredEntities('trailers')),
    };
  },
);

const makeSelectGeoFencingLocation = () => createSelector(
  selectFleetMapStore,
  ({ geoFencingLocation, geoFencingTypeFilter }: Object) =>
    getFilteredByTypeLocations(geoFencingTypeFilter, geoFencingLocation),
);

const makeSelectConfigs = () => createSelector(
  selectFleetMapStore,
  ({ configs }: Object) => configs,
);

const makeSelectTrailerLoadedFilter = () => createSelector(
  selectFleetMapStore,
  ({ trailerLoadedFilter }: Object) => trailerLoadedFilter,
);

const makeSelectTrailerUnitIdFilter = () => createSelector(
  selectFleetMapStore,
  ({ trailerUnitIdFilter }: Object) => trailerUnitIdFilter,
);

export {
  makeSelectFleets,
  makeSelectConfigs,
  makeSelectFilteredFleets,
  makeSelectGeoFencingLocation,
  makeSelectTrailerLoadedFilter,
  makeSelectTrailerUnitIdFilter,
};
