import { read, utils } from 'xlsx';
import React, { useId, useState, useEffect, Fragment } from 'react';
// components
import { TextComponent } from '../text';
import { LocalLoader } from '../local-loader';
import VirtualizedTable from '../virtualized-table';
// file-viewer
import { useFetching } from './use-fetching';
//////////////////////////////////////////////////

const tableSettings = {
  tableRowHeight: 32,
  tableTopIndent: 95,
  titleRowHeight: 42,
  withResizableColumns: true,
};

const TableCell = ({ text }: Object) => (
  <TextComponent
    title={text}
    maxWidth='90%'
    display='block'
    withEllipsis={true}
  >
    {text}
  </TextComponent>
);

const XlsxViewer = ({ filePath, onError, tableHeight = 500 }: Object) => {
  const [sheets, setSheets] = useState([]);
  const [sheetNames, setSheetNames] = useState([]);
  const [currentSheetIndex, setCurrentSheetIndex] = useState(0);

  const id = useId();

  const { data, error, isLoading } = useFetching(filePath, onError);

  useEffect(() => {
    if (data) {
      try {
        const dataArr = new Uint8Array(data);
        const binaryString = Array.from(dataArr, (byte: string) => String.fromCharCode(byte)).join('');
        const workbook = read(binaryString, { type: 'binary' });

        const parsedSheets = Object.keys(workbook.Sheets).map((name: string) =>
          utils.sheet_to_json(workbook.Sheets[name], { raw: false, defval: '' }),
        );

        setSheets(parsedSheets);
        setSheetNames(Object.keys(workbook.Sheets));
      } catch (error) {
        console.error('Error parsing workbook:', error);
      }
    }
  }, [data]);

  if (isLoading) {
    return <LocalLoader localLoaderOpened={isLoading} />;
  }

  if (error) {
    return <b>Unable to preview file</b>;
  }

  const rows = sheets[currentSheetIndex];

  if (rows?.length > 0) {
    const columns = Object.keys(rows[0]);

    const report = {
      type: 'xlsxViewer',
      guid: `${currentSheetIndex}-${id}`,
      fields: columns.map((name: string, sequence: number) => ({ name, sequence })),
    };

    const columnSettings = columns.reduce((acc: Object, item: string) => {
      acc[item] = {
        width: 200,
        name: item,
        useCustomName: true,
        customComponent: ({ data = {} }: Object) => <TableCell text={data[item]} />,
      };

      return acc;
    }, {});

    return (
      <Fragment>
        <div style={{ display: 'flex' }}>
          {
            sheetNames.map((item: string, index: number) => (
              <button key={index} onClick={() => setCurrentSheetIndex(index)}>{item}</button>
            ))
          }
        </div>
        <VirtualizedTable
          {...tableSettings}
          report={report}
          listData={rows}
          withLazyLoading={false}
          tableHeight={tableHeight}
          columnSettings={columnSettings}
        />
      </Fragment>
    );
  }

  return null;
};

export default XlsxViewer;
