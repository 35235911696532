import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const getGroupedByDate = R.compose(
  R.map(([key, value]: Array) => ({ title: key, list: value })),
  R.toPairs,
  R.groupBy((obj: Object) =>
    G.createLocalDateTimeFromInstanceOrISOString(obj.date, 'MMMM d, yyyy'),
  ),
);

const getBorderStyles = (arr: Array, index: number) =>
  G.ifElse(
    R.equals(index, arr.length - 1),
    'none',
    `1px solid ${G.getTheme('colors.lightGrey')}`,
  );

export {
  getBorderStyles,
  getGroupedByDate,
};
