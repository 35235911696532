import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { StyledLink, ReactSelect } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature carrier-portal
import { TableCell } from '../components/table-cells';
import { setFormGroupFilterByFilterName } from '../actions';
import { makeSelectFormGroupFilterByFilterName } from '../selectors';
//////////////////////////////////////////////////

const DocumentsFilter = ({ dispatch, documentTypeOptions }: Object) => {
  const documentsFilter = useSelector((state: Object) => makeSelectFormGroupFilterByFilterName(state, 'document'));

  return (
    <ReactSelect
      height={30}
      value={documentsFilter}
      options={documentTypeOptions}
      onChange={(option: Object) => dispatch(setFormGroupFilterByFilterName({
        filterName: 'document',
        filterValue: R.pathOr(null, [GC.FIELD_VALUE], option),
      }))}
      additionalStyles={{
        container: (baseStyles: Object) => ({
          ...baseStyles,
          width: 230,
          margin: '5px 10px',
        }),
        menu: (baseStyles: Object) => ({
          ...baseStyles,
          zIndex: 11,
          color: G.getTheme('colors.black'),
        }),
      }}
    />
  );
};

const fields = {
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:document-type',
    options: GC.CARRIER_DOCUMENT_TYPE,
    additionalInputWrapperStyles: { width: 400 },
    customComponent: (row: Object) => (
      <TableCell text={R.path(['data', GC.FIELD_DOCUMENT_DOCUMENT_TYPE, GC.FIELD_DISPLAYED_VALUE], row)} />
    ),
  },
  [GC.FIELD_DOCUMENT_FILE_NAME]: {
    width: 300,
    name: 'titles:document-name',
    customComponent: ({ data }: Object) => {
      const url = G.getPropFromObject(GC.FIELD_DOCUMENT_URL, data);
      const documentFileName = G.getPropFromObject(GC.FIELD_DOCUMENT_FILE_NAME, data);
      const title = R.or(documentFileName, url);

      return (
        <TextComponent title={title} display='block' maxWidth='90%' withEllipsis={true}>
          {
            G.isNotNilAndNotEmpty(url) &&
            <StyledLink
              target='_blank'
              textDecoration='underline'
              href={G.makeURLString(url)}
              color={G.getTheme('colors.light.blue')}
            >
              {url}
            </StyledLink>
          }
          {G.isNotNilAndNotEmpty(documentFileName) && documentFileName}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_DOCUMENT_DESCRIPTION]: {
    width: 200,
    type: 'textarea',
    name: 'titles:description',
    additionalInputWrapperStyles: { width: 400 },
  },
  [GC.FIELD_DOCUMENT_SIGNED_DATE]: {
    width: 150,
    type: 'datePicker',
    name: 'titles:signed-date',
    maxDate: (props: Object) => {
      const expirationDate = R.path(['values', GC.FIELD_DOCUMENT_EXPIRATION_DATE], props);

      return G.isValidDate(expirationDate) ? G.getNewDate(expirationDate) : null;
    },
  },
  [GC.FIELD_DOCUMENT_EXPIRATION_DATE]: {
    width: 150,
    type: 'datePicker',
    name: 'titles:expired-date',
    minDate: (props: Object) => {
      const signedDate = R.path(['values', GC.FIELD_DOCUMENT_SIGNED_DATE], props);

      return G.isValidDate(signedDate) ? G.getNewDate(signedDate) : null;
    },
  },
  [GC.FIELD_DOCUMENT_URL]: {
    type: 'text',
    name: 'titles:url',
    fieldName: GC.FIELD_DOCUMENT_URL,
    additionalInputWrapperStyles: {
      width: 400,
      display: (props: Object) => {
        const { file, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(R.or(G.isNotNil(file), G.isNotNil(documentFilename)), 'none', 'block');
      },
    },
  },
  [GC.FIELD_DOCUMENT_UPLOAD]: {
    type: 'file',
    name: 'titles:document-template',
    additionalInputWrapperStyles: {
      width: 400,
      display: (props: Object) => {
        const { url, version, documentFilename } = R.pathOr({}, ['values'], props);

        return G.ifElse(
          R.or(G.isNotNilAndNotEmpty(url), R.and(G.isNotNil(version), R.isNil(documentFilename))),
          'none',
          'block',
        );
      },
    },
  },
};

const inputWrapperStyles = {
  mb: 25,
  width: 190,
};

const fieldsWrapperStyles = {
  width: 400,
};

const validationSchema = Yup.lazy(({ url, file }: Object) => Yup.object().shape({
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.yupStringRequired,
  [GC.FIELD_DOCUMENT_DESCRIPTION]: G.yupStringNotRequired,
  [GC.FIELD_DOCUMENT_URL]: G.ifElse(
    G.isAllNilOrEmpty([url, file]),
    G.yupStringRequired,
    Yup.string().nullable(true).url(G.getShouldBeUrlLocaleTxt()),
  ),
  [GC.FIELD_DOCUMENT_UPLOAD]: G.ifElse(
    G.isAllNilOrEmpty([url, file]),
    G.yupStringRequired,
    G.yupStringNotRequired,
  ),
}));

export const documentSettings = {
  fields,
  validationSchema,
  inputWrapperStyles,
  fieldsWrapperStyles,
  groupName: 'document',
  omitFormFields: [GC.FIELD_DOCUMENT_FILE_NAME],
  itemTitleArr: ['titles:document', 'Document'],
  primaryObjectGuidKey: GC.FIELD_PRIMARY_OBJECT_GUID,
  additionalTableSettings: { checkBoxCellWidth: 100 },
  formGroupTitleArr: ['titles:documents', 'Documents'],
  omitColumns: [GC.FIELD_DOCUMENT_URL, GC.FIELD_DOCUMENT_UPLOAD],
  actionsPicker: ['edit', 'remove', 'downloadDocument', 'previewDocument'],
  endpoints: {
    list: 'carrierDocList',
    createOrUpdate: 'carrierDoc',
    remove: 'getCarrierDocEndpoint',
  },
  downloadOptions: {
    endpointName: 'carrierDownloadDoc',
    requestParamsPicker: [GC.FIELD_DOCUMENT_FILE_NAME],
    primaryObjectGuidKey: GC.FIELD_PRIMARY_OBJECT_GUID,
  },
  // components
  AdditionalFormGroupTitleComponent: ({ dispatch, configGroup }: Object) => (
    <DocumentsFilter
      dispatch={dispatch}
      documentTypeOptions={R.pathOr([], ['dropdownOptions', GC.CARRIER_DOCUMENT_TYPE], configGroup)}
    />
  ),
  // helpers
  makeOptionsForSelect: ({ configGroup }: Object) => ({
    [GC.CARRIER_DOCUMENT_TYPE]: R.path(['dropdownOptions', GC.CARRIER_DOCUMENT_TYPE], configGroup),
  }),
  makeInitialValues: (initialValues: Object) => {
    const { documentType, documentFilename } = initialValues;

    return {
      ...initialValues,
      [GC.FIELD_DOCUMENT_UPLOAD]: documentFilename,
      [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.getDropdownOptionGuidFromObject(documentType),
    };
  },
  customCreateOrUpdateHandler: (props: Object, additionalOptions: Object) => {
    const { values, handleCreateOrUpdateEntityRequest } = props;

    handleCreateOrUpdateEntityRequest({
      method: 'post',
      additionalOptions,
      groupName: 'document',
      values: G.makeDataForDocument(values),
      endpoint: G.ifElse(
        G.isNotNilAndNotEmpty(G.getGuidFromObject(values)),
        endpointsMap.carrierDocUpdate,
        endpointsMap.carrierDoc,
      ),
    });
  },
};
