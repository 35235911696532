import * as R from 'ramda';
import { createSelector } from 'reselect';
import { searchResultsColumnSettings } from './settings/result-settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// features
import * as H from './helpers';
//////////////////////////////////////////////////

const store = (state: Object) => state.loadBoard;

const makeSelectDataLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectInitialPageDataLoaded = () => createSelector(
  store,
  ({ initialPageDataLoaded }: Object) => initialPageDataLoaded,
);

const makeSelectAuthorizedLoadBoards = () => createSelector(
  store,
  ({ authorizedLoadBoards }: Object) => authorizedLoadBoards,
);

const makeSelectShipmentList = () => createSelector(
  store,
  ({
    shipments,
    cancelledShown,
    titleSortValues,
    tableTitleFilters,
    filteredBySearchFilter,
  }: Object) => {
    let filtered = shipments;

    if (R.not(cancelledShown)) {
      filtered = R.filter(
        ({ cancelled }: Object) => R.not(cancelled),
        shipments,
      );
    }

    if (G.isNotEmpty(filteredBySearchFilter)) {
      filtered = R.filter(
        ({ searchFilterGuid }: Object) => R.includes(searchFilterGuid, filteredBySearchFilter),
        filtered,
      );
    }

    filtered = H.getFilteredShipments(
      tableTitleFilters,
      R.take(
        GC.SHIPMENT_LIMIT,
        filtered,
      ),
    );

    return H.getSortedShipments(titleSortValues, filtered);
  },
);

// TODO: check next in post tel
const makeSelectRequiredMinMaxPrice = () => createSelector(
  store,
  ({ requiredMinMaxPrice }: Object) => requiredMinMaxPrice,
);

const makeSelectSearchFilters = () => createSelector(
  store,
  ({ searchFilters }: Object) => R.values(searchFilters),
);

const makeSelectCompanyFilterList = () => createSelector(
  store,
  ({ companyFilters }: Object) => companyFilters,
);

const makeSelectUsedReport = () => createSelector(
  store,
  ({ usedReport }: Object) => {
    const report = R.clone(usedReport);

    const { fields } = report;

    const validFields = R.filter(
      R.compose((name: string) => searchResultsColumnSettings[name], R.prop('name')),
      fields,
    );

    report.fields = validFields;

    return report;
  },
);

const makeSelectAvailableReports = () => createSelector(
  store,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectPinnedReportsArePresent = () => createSelector(
  store,
  ({ availableReports }: Object) => R.any(R.propEq(true, 'pinnedReport'), availableReports),
);

const makeSelectCancelledShown = () => createSelector(
  store,
  ({ cancelledShown }: Object) => cancelledShown,
);

const makeSelectAutodialConfigs = () => createSelector(
  store,
  ({ autodialOn, autodialAppName, autodialActionOn }: Object) => ({ autodialOn, autodialAppName, autodialActionOn }),
);

const makeSelectIndexedSearchFilterList = () => createSelector(
  store,
  ({ searchFilters }: Object) => searchFilters,
);

const makeSelectSearchResultsByFilterId = () => createSelector(
  store,
  ({ searchResultsByFilterId }: Object) => searchResultsByFilterId,
);

const excludeTruckerTools = (config: Array) => R.filter(
  G.notPropEq(GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS, GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE),
  config,
);

const makeSelectLoadBoardConfigList = createSelector(
  R.path(['config', 'integration']),
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.prop('loadBoardConfigList'),
  ),
);

const makeSelectAvailableLoadBoards = () => createSelector(
  [store, makeSelectLoadBoardConfigList],
  ({ authorizedLoadBoards = [] }: Object, loadBoardConfigs: Array) => R.filter(
    R.both(
      R.propEq(GC.LB_STATUS_ACTIVE, GC.FIELD_STATUS),
      R.compose(
        R.flip(R.includes)(GC.EXTERNAL_LOAD_BOARDS_TO_SEARCH),
        R.prop(GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE),
      ),
    ),
    R.concat(excludeTruckerTools(loadBoardConfigs), authorizedLoadBoards),
  ),
);

// TODO:
// Check carrier authorized load boards select and source
// Check post tel available load boards and source
const makeSelectPostLoadBoards = () => createSelector(
  [store, makeSelectLoadBoardConfigList],
  ({ authorizedLoadBoards }: Object, loadBoardConfigs: Array) => R.filter(
    R.both(
      R.propEq(GC.LB_STATUS_ACTIVE, GC.FIELD_STATUS),
      R.compose(
        R.flip(R.includes)(GC.EXTERNAL_LOAD_BOARDS_TO_POST),
        R.prop(GC.LOGIN_EXTERNAL_LOAD_BOARD_TYPE),
      ),
    ),
    R.concat(loadBoardConfigs, authorizedLoadBoards),
  ),
);

const makeSelectTemplates = () => createSelector(
  store,
  ({ templates }: Object) => R.values(templates),
);

const makeSelectSavedShipments = () => createSelector(
  store,
  ({ savedShipments }: Object) => savedShipments,
);

const makeSelectBookedShipments = () => createSelector(
  store,
  ({ bookedShipments }: Object) => bookedShipments,
);

const makeSelectCallAlarm = () => createSelector(
  store,
  ({ callAlarm }: Object) => callAlarm,
);

const makeSelectNotifications = () => createSelector(
  store,
  ({ shipments }: Object) => R.filter(R.propEq(true, 'highlighted'), shipments),
);

const makeSelectSearchFilterStates = () => createSelector(
  store,
  ({ searchFiltersStates }: Object) => searchFiltersStates,
);

const makeSelectTitleSortValues = () => createSelector(
  store,
  ({ titleSortValues }: Object) => titleSortValues,
);

const makeSelectTableTitleFilters = () => createSelector(
  store,
  ({ tableTitleFilters }: Object) => tableTitleFilters,
);

const makeSelectFilteredBySearchFilter = () => createSelector(
  store,
  ({ filteredBySearchFilter }: Object) => filteredBySearchFilter,
);

const makeSelectSearchFilterColors = () => createSelector(
  store,
  ({ searchFilterColors }: Object) => searchFilterColors,
);

const makeSelectSearchFiltersCalled = () => createSelector(
  store,
  ({ searchFiltersCalled }: Object) => searchFiltersCalled,
);

export {
  makeSelectCallAlarm,
  makeSelectTemplates,
  makeSelectUsedReport,
  makeSelectDataLoading,
  makeSelectShipmentList,
  makeSelectNotifications,
  makeSelectSearchFilters,
  makeSelectCancelledShown,
  makeSelectPostLoadBoards,
  makeSelectSavedShipments,
  makeSelectBookedShipments,
  makeSelectAutodialConfigs,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectCompanyFilterList,
  makeSelectTableTitleFilters,
  makeSelectSearchFilterStates,
  makeSelectSearchFilterColors,
  makeSelectAvailableLoadBoards,
  makeSelectRequiredMinMaxPrice,
  makeSelectSearchFiltersCalled,
  makeSelectAuthorizedLoadBoards,
  makeSelectInitialPageDataLoaded,
  makeSelectFilteredBySearchFilter,
  makeSelectIndexedSearchFilterList,
  makeSelectPinnedReportsArePresent,
  makeSelectSearchResultsByFilterId,
};
