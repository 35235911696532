import React from 'react';
//////////////////////////////////////////////////

const styles = {
  width: '100%',
  height: 'auto',
  display: 'block',
  margin: '0 auto',
  objectFit: 'contain',
};

const PhotoViewer = ({ filePath }: Object) => (
  <img src={filePath} alt='image_preview' style={styles} />
);

export default PhotoViewer;
