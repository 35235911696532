import * as R from 'ramda';
// constants
import * as GC from '../constants';
import { ENUMS } from '../constants/enums';
// data
import { TIME_ZONES } from '../data/time-zones-mock-data';
import { COUNTRY_REGION_DATA } from '../data/countries-mock-data';
// helpers
import { getEnumLocale, getWindowLocale } from './locale';
//////////////////////////////////////////////////

// TODO: with clear case for naming and export {}

export const BRANCH_TYPE_OPTIONS = [
  { label: getWindowLocale('titles:customer', 'Customer'), value: GC.BRANCH_TYPE_ENUM_CUSTOMER },
  { label: getWindowLocale('titles:division', 'Division'), value: GC.BRANCH_TYPE_ENUM_DIVISION },
];

export const BRANCH_TYPE_MASTER_OPTION = {
  value: GC.BRANCH_TYPE_ENUM_MASTER,
  label: getWindowLocale('titles:master', 'Master'),
};

export const getReferenceScopeOptions = () => [
  {
    value: GC.REFERENCE_SCOPE_TYPE_CLO,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_CLO),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_TEL,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_TEL),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_USER,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_USER),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_ITEM,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_ITEM),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_ROUTE,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_ROUTE),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_BRANCH,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_BRANCH),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_CARRIER,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_CARRIER),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_LOCATION,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_LOCATION),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_LOAD_EVENT,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_LOAD_EVENT),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_FLEET_TRUCK,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_FLEET_TRUCK),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_FLEET_DRIVER,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_FLEET_DRIVER),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_FLEET_VENDOR,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_FLEET_VENDOR),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_FLEET_TRAILER,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_FLEET_TRAILER),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_FLEET_INVOICE,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_FLEET_INVOICE),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_CARRIER_INVOICE,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_CARRIER_INVOICE),
  },
  {
    value: GC.REFERENCE_SCOPE_TYPE_CUSTOMER_INVOICE,
    label: getEnumLocale(GC.REFERENCE_SCOPE_TYPE_CUSTOMER_INVOICE),
  },
];

export const getDriverCardsDefaultGroupByEnumOptions = () => ([
  { label: getEnumLocale(ENUMS.ENUM_DRIVER), value: ENUMS.ENUM_DRIVER },
  { label: getEnumLocale(ENUMS.ENUM_TRUCK), value: ENUMS.ENUM_TRUCK },
]);

export const getDriverCardsDefaultGroupFilterEnumOptions = () => ([
  { label: getEnumLocale(ENUMS.ENUM_NONE), value: ENUMS.ENUM_NONE },
  { label: getEnumLocale(ENUMS.ENUM_DISPATCHER), value: ENUMS.ENUM_DISPATCHER },
  { label: getEnumLocale(ENUMS.ENUM_DISPATCHING_GROUP), value: ENUMS.ENUM_DISPATCHING_GROUP },
  { label: getEnumLocale(ENUMS.ENUM_GROUP_AND_DISPATCHER), value: ENUMS.ENUM_GROUP_AND_DISPATCHER },
]);

export const getDriverCardsDefaultDriverFilterEnumOptions = () => ([
  { label: getEnumLocale(ENUMS.ENUM_NONE), value: ENUMS.ENUM_NONE },
  { label: getEnumLocale(ENUMS.ENUM_CURRENT_USER), value: ENUMS.ENUM_CURRENT_USER },
  { label: getEnumLocale(ENUMS.ENUM_CURRENT_GROUPS), value: ENUMS.ENUM_CURRENT_GROUPS },
]);

export const getCustomerContractUpliftApplyToEnumOptions = () => ([
  { label: getEnumLocale(ENUMS.ENUM_ALL_CHARGES), value: ENUMS.ENUM_ALL_CHARGES },
  { label: getEnumLocale(ENUMS.ENUM_MAIN_CHARGE), value: ENUMS.ENUM_MAIN_CHARGE },
]);

export const getQBAccountMappingScopeOptions = () => [
  {
    value: GC.INVOICE_SCOPE_TYPE_DRIVER,
    label: getEnumLocale(GC.INVOICE_SCOPE_TYPE_DRIVER),
  },
  {
    value: GC.INVOICE_SCOPE_TYPE_CARRIER,
    label: getEnumLocale(GC.INVOICE_SCOPE_TYPE_CARRIER),
  },
  {
    value: GC.INVOICE_SCOPE_TYPE_CUSTOMER,
    label: getEnumLocale(GC.INVOICE_SCOPE_TYPE_CUSTOMER),
  },
  {
    value: GC.INVOICE_SCOPE_TYPE_VENDOR,
    label: getEnumLocale(GC.INVOICE_SCOPE_TYPE_VENDOR),
  },
  {
    value: GC.INVOICE_SCOPE_TYPE_SERVICE_VENDOR,
    label: getEnumLocale(GC.INVOICE_SCOPE_TYPE_SERVICE_VENDOR),
  },
];

export const getMobileAppRequiredTrailerConfigEnumOptions = () => ([
  { label: getEnumLocale(ENUMS.ENUM_NONE), value: ENUMS.ENUM_NONE },
  { label: getEnumLocale(ENUMS.ENUM_DROP_ONLY), value: ENUMS.ENUM_DROP_ONLY },
  { label: getEnumLocale(ENUMS.ENUM_PICKUP_ONLY), value: ENUMS.ENUM_PICKUP_ONLY },
  { label: getEnumLocale(ENUMS.ENUM_DROP_AND_PICKUP), value: ENUMS.ENUM_DROP_AND_PICKUP },
]);

// TODO: check and implement UI for BOTH
export const getUiOrderEntryConfigEnumOptions = () => ([
  // { label: getEnumLocale(ENUM_BOTH), value: ENUM_BOTH },
  { label: getEnumLocale(ENUMS.ENUM_QUICK), value: ENUMS.ENUM_QUICK },
  { label: getEnumLocale(ENUMS.ENUM_STANDARD), value: ENUMS.ENUM_STANDARD },
]);

export const getMobileAppHideDocumentBtnsEnumOptions = () => ([
  { label: getEnumLocale(ENUMS.ENUM_NONE), value: ENUMS.ENUM_NONE },
  { label: getEnumLocale(ENUMS.ENUM_SCAN), value: ENUMS.ENUM_SCAN },
  { label: getEnumLocale(ENUMS.ENUM_PHOTO), value: ENUMS.ENUM_PHOTO },
  { label: getEnumLocale(ENUMS.ENUM_CAMERA), value: ENUMS.ENUM_CAMERA },
  { label: getEnumLocale(ENUMS.ENUM_DOCUMENT), value: ENUMS.ENUM_DOCUMENT },
]);

export const getRaleBillingEnumOptions = () => ([
  { label: getEnumLocale(ENUMS.ENUM_PENDING), value: ENUMS.ENUM_PENDING },
  { label: getEnumLocale(ENUMS.ENUM_COMPLETED), value: ENUMS.ENUM_COMPLETED },
]);

export const getTrailerManageFromEnumOptions = () => ([
  { label: getEnumLocale(ENUMS.ENUM_EVENTS), value: ENUMS.ENUM_EVENTS },
  { label: getEnumLocale(ENUMS.ENUM_TEL_RATE), value: ENUMS.ENUM_TEL_RATE },
]);

const sageAccountValues = [
  GC.INVOICE_SCOPE_TYPE_CUSTOMER,
  GC.INVOICE_SCOPE_TYPE_CARRIER,
  GC.INVOICE_SCOPE_TYPE_VENDOR,
  GC.INVOICE_SCOPE_TYPE_SERVICE_VENDOR,
];

export const getSageAccountMappingScopeOptions = () => R.map(
  (value: string) => ({
    value,
    label: getEnumLocale(value),
  }),
  sageAccountValues,
);

export const DEFAULT_ROUTE_TYPE_OPTIONS = [
  { label: getWindowLocale('titles:clo', 'CLO'), value: 'clo' },
  { label: getWindowLocale('titles:tel', 'TEL'), value: 'tel' },
];

export const DRIVER_TYPE_OPTIONS = {
  [GC.DRIVER_TYPE_EMPLOYEE]: {
    [GC.FIELD_VALUE]: GC.DRIVER_TYPE_EMPLOYEE,
    [GC.FIELD_LABEL]: getWindowLocale('titles:employee', 'Employee'),
  },
  [GC.DRIVER_TYPE_OWNER_OPERATOR]: {
    [GC.FIELD_VALUE]: GC.DRIVER_TYPE_OWNER_OPERATOR,
    [GC.FIELD_LABEL]: getWindowLocale('titles:owner-operator', 'Owner Operator'),
  },
  [GC.DRIVER_TYPE_CONTRACTOR]: {
    [GC.FIELD_VALUE]: GC.DRIVER_TYPE_CONTRACTOR,
    [GC.FIELD_LABEL]: getWindowLocale('titles:contractor', 'Contractor'),
  },
};

export const PAYMENT_TERM_OPTIONS = [
  { label: getWindowLocale('titles:prepaid', 'Prepaid'), value: GC.PAYMENT_TERM_OPTION_PREPAID },
  { label: getWindowLocale('titles:collect', 'Collect'), value: GC.PAYMENT_TERM_OPTION_COLLECT },
  { label: getWindowLocale('titles:quick-pay', 'Quick-Pay'), value: GC.PAYMENT_TERM_OPTION_QUICK_PAY },
  { label: getWindowLocale('titles:direct-pay', 'Direct-Pay'), value: GC.PAYMENT_TERM_OPTION_DIRECT_PAY },
  { label: getWindowLocale('titles:third-party', 'Third Party'), value: GC.PAYMENT_TERM_OPTION_THIRD_PARTY },
];

export const LOCATION_TEMPLATE_BRANCH_FILTER_TYPES = [
  { label: getWindowLocale('titles:tree', 'Tree'), value: GC.BRANCH_FILTER_TYPE_TREE },
  { label: getWindowLocale('titles:full-tree', 'Full Tree'), value: GC.BRANCH_FILTER_TYPE_FULL_TREE },
  { label: getWindowLocale('titles:parent-and-current', 'Parent And Current'), value: GC.BRANCH_FILTER_TYPE_PARENT_AND_CURRENT },
];

export const CLO_ROUTE_DEFAULT_ORGANIZE_BY = [
  { label: 'No Grouping', value: GC.ORGANIZE_BY_NO_GROUPING },
  { label: 'Route', value: GC.ORGANIZE_BY_ROUTE },
  { label: 'Truck', value: GC.ORGANIZE_BY_TRUCK },
  { label: 'Branch', value: GC.ORGANIZE_BY_BRANCH },
  { label: 'Bill To', value: GC.ORGANIZE_BY_BILL_TO },
  { label: 'Last Stop Date', value: GC.ORGANIZE_BY_LAST_EVENT_DATE },
  { label: 'First Stop Date', value: GC.ORGANIZE_BY_FIRST_EVENT_DATE },
  { label: 'Status', value: GC.ORGANIZE_BY_STATUS },
  { label: 'Mode', value: GC.ORGANIZE_BY_CLO_TRANSPORTATION_MODE },
];

export const DEFAULT_LOADBOARD_DIALING_APP_OPTIONS = [
  { label: 'Skype', value: GC.DIAL_SERVICE_SKYPE },
  { label: 'Default', value: GC.DIAL_SERVICE_DEFAULT },
  { label: 'Facetime', value: GC.DIAL_SERVICE_FACETIME },
];

export const DEFAULT_REPLY_TO = [
  {
    value: ENUMS.ENUM_USER,
    label: getEnumLocale(ENUMS.ENUM_USER),
  },
  {
    value: ENUMS.ENUM_BRANCH,
    label: getEnumLocale(ENUMS.ENUM_BRANCH),
  },
];

export const MAIL_SENDING_REPLY_TO_OPTIONS = [
  {
    value: ENUMS.ENUM_ACCOUNTING,
    label: getEnumLocale(ENUMS.ENUM_ACCOUNTING),
  },
  {
    value: ENUMS.ENUM_USER,
    label: getEnumLocale(ENUMS.ENUM_USER),
  },
  {
    value: ENUMS.ENUM_BRANCH,
    label: getEnumLocale(ENUMS.ENUM_BRANCH),
  },
];

export const TEL_ROUTE_DEFAULT_ORGANIZE_BY = [
  { label: 'No Grouping', value: GC.ORGANIZE_BY_NO_GROUPING },
  { label: 'Route', value: GC.ORGANIZE_BY_ROUTE },
  { label: 'Truck', value: GC.ORGANIZE_BY_TRUCK },
  { label: 'Branch', value: GC.ORGANIZE_BY_BRANCH },
  { label: 'Driver/Carrier', value: GC.ORGANIZE_BY_DRIVER },
  { label: 'Carrier/Driver', value: GC.ORGANIZE_BY_CARRIER },
  { label: 'Last Stop Date', value: GC.ORGANIZE_BY_LAST_EVENT_DATE },
  { label: 'First Stop Date', value: GC.ORGANIZE_BY_FIRST_EVENT_DATE },
  { label: 'Dispatched By', value: GC.ORGANIZE_BY_DISPATCHED_BY },
  { label: 'Status', value: GC.ORGANIZE_BY_STATUS },
  { label: 'Mode', value: GC.ORGANIZE_BY_TEL_TRANSPORTATION_MODE },
];

export const DEFAULT_DISTANCE_UOM_OPTIONS = [
  { label: GC.UOM_MILE_LABEL, value: GC.UOM_MILE },
  { label: GC.UOM_KILOMETER_LABEL, value: GC.UOM_KILOMETER },
];

// TODO: check all common cases for rate type and unit

export const DEFAULT_WEIGHT_UOM_OPTIONS = [
  { label: '', value: '' },
  { label: 'Pounds', value: GC.UOM_POUND },
  { label: 'Kilograms', value: GC.UOM_KILOGRAM },
  { label: 'Short Tons', value: GC.UOM_TON_US },
  { label: 'Metric Tons', value: GC.UOM_METRIC_TON },
];

export const DEFAULT_ITEM_WEIGHT_UOM_OPTIONS = DEFAULT_WEIGHT_UOM_OPTIONS;

export const DEFAULT_AWARDED_LINE_WEIGHT_UOM_OPTIONS = DEFAULT_WEIGHT_UOM_OPTIONS;

export const ITEM_TYPE_OPTIONS = [
  { label: '', value: '' },
  { value: GC.ITEM_TYPE_GENERAL, label: getWindowLocale('titles:general', 'General') },
  { value: GC.ITEM_TYPE_VEHICLE, label: getWindowLocale('titles:vehicle', 'Vehicle') },
];

export const DEFAULT_WEIGHT_RATE_UNIT_OPTIONS = [
  { label: '', value: '' },
  { label: 'Pounds', value: GC.UOM_POUND },
  { label: 'Kilograms', value: GC.UOM_KILOGRAM },
  { label: 'Pounds100', value: GC.UOM_POUND_100 },
  { label: 'Kg100', value: GC.UOM_KILOGRAM_100 },
  { label: 'Short Tons', value: GC.UOM_TON_US },
  { label: 'Metric Tons', value: GC.UOM_METRIC_TON },
];

export const DEFAULT_TIME_RATE_UNIT_OPTIONS = [
  { label: '', value: '' },
  { label: 'Per Hour', value: GC.CHARGE_RATE_UNIT_TYPE_HOUR },
  { label: 'Per Day', value: GC.CHARGE_RATE_UNIT_TYPE_DAY },
];

export const DEFAULT_TIME_RATE_UNIT_OPTIONS_2 = [
  { label: '', value: '' },
  { label: 'Per Hour', value: GC.CHARGE_RATE_UNIT_TYPE_HOUR },
  { label: 'Per Week', value: GC.CHARGE_RATE_UNIT_TYPE_WEEK },
];

export const DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS = [
  GC.EMPTY_OPTION_OBJECT,
  { label: getWindowLocale('titles:flat', 'Flat'), value: GC.CHARGE_RATE_TYPE_FLAT },
  { label: getWindowLocale('titles:distance', 'Distance'), value: GC.CHARGE_RATE_TYPE_DISTANCE },
  { label: getWindowLocale('titles:deadhead-distance', 'Deadhead Distance'), value: GC.CHARGE_RATE_TYPE_DEADHEAD_DISTANCE },
  { label: getWindowLocale('titles:volume', 'Volume'), value: GC.CHARGE_RATE_TYPE_VOLUME },
  { label: getWindowLocale('titles:quantity', 'Quantity'), value: GC.CHARGE_RATE_TYPE_QUANTITY },
  { label: getWindowLocale('titles:weight', 'Weight'), value: GC.CHARGE_RATE_TYPE_WEIGHT },
  { label: getWindowLocale('titles:stop', 'Stop'), value: GC.CHARGE_RATE_TYPE_STOP },
  { label: getWindowLocale('titles:time', 'Time'), value: GC.CHARGE_RATE_TYPE_TIME },
  { label: getWindowLocale('titles:line-haul', 'Line Haul'), value: GC.CHARGE_RATE_TYPE_LINE_HAUL },
  { label: getWindowLocale('titles:taxable-total', 'Taxable Total'), value: GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL },
];

export const DEFAULT_CARRIER_ASSESSORIAL_RATE_TYPE_OPTIONS = [
  { label: '', value: '' },
  { label: 'Flat', value: GC.CHARGE_RATE_TYPE_FLAT },
  { label: 'Distance', value: GC.CHARGE_RATE_TYPE_DISTANCE },
  { label: 'Weight', value: GC.CHARGE_RATE_TYPE_WEIGHT },
  { label: 'Volume', value: GC.CHARGE_RATE_TYPE_VOLUME },
  { label: 'Quantity', value: GC.CHARGE_RATE_TYPE_QUANTITY },
  { label: 'Time', value: GC.CHARGE_RATE_TYPE_TIME },
  { label: 'Stop', value: GC.CHARGE_RATE_TYPE_STOP },
  { label: 'Line Haul', value: GC.CHARGE_RATE_TYPE_LINE_HAUL },
  { label: 'Taxable Total', value: GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL },
];

export const DEFAULT_VENDOR_ACCESSORIAL_RATE_TYPE_OPTIONS = [
  ...DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
  { label: 'Customer Rate', value: GC.CHARGE_RATE_TYPE_CUSTOMER_RATE },
  { label: 'Customer Line Haul', value: GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL },
];

export const DEFAULT_CARRIER_RATE_TYPE_OPTIONS = [
  { label: '', value: '' },
  { label: 'Flat', value: GC.CHARGE_RATE_TYPE_FLAT },
  { label: 'Distance', value: GC.CHARGE_RATE_TYPE_DISTANCE },
  { label: 'Weight', value: GC.CHARGE_RATE_TYPE_WEIGHT },
  { label: 'Volume', value: GC.CHARGE_RATE_TYPE_VOLUME },
  { label: 'Quantity', value: GC.CHARGE_RATE_TYPE_QUANTITY },
  { label: 'Time', value: GC.CHARGE_RATE_TYPE_TIME },
];

export const DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS = [
  { label: '', value: '' },
  { label: 'Flat', value: GC.CHARGE_RATE_TYPE_FLAT },
  { label: 'Distance', value: GC.CHARGE_RATE_TYPE_DISTANCE },
  { label: 'Volume', value: GC.CHARGE_RATE_TYPE_VOLUME },
  { label: 'Quantity', value: GC.CHARGE_RATE_TYPE_QUANTITY },
  { label: 'Weight', value: GC.CHARGE_RATE_TYPE_WEIGHT },
  { label: 'Time', value: GC.CHARGE_RATE_TYPE_TIME },
  { label: 'Customer Rate', value: GC.CHARGE_RATE_TYPE_CUSTOMER_RATE },
  { label: 'Customer Line Haul', value: GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL },
];

export const DEFAULT_CARRIER_COMPENSATION_RATE_TYPE_OPTIONS = DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS;

export const DEFAULT_PAYROLL_COMPENSATION_RATE_TYPE_OPTIONS = [
  { label: '', value: '' },
  { label: 'Time', value: GC.CHARGE_RATE_TYPE_TIME },
  { label: 'Distance', value: GC.CHARGE_RATE_TYPE_DISTANCE },
];

export const DEFAULT_DISTANCE_RATE_UNIT_OPTIONS = [
  { label: '', value: '' },
  { label: 'Miles', value: GC.UOM_MILE },
  { label: 'Kilometers', value: GC.UOM_KILOMETER },
];

export const DEFAULT_DEADHEAD_DISTANCE_RATE_UNIT_OPTIONS = [
  { label: '', value: '' },
  { label: 'Miles', value: GC.UOM_MILE },
  { label: 'Kilometers', value: GC.UOM_KILOMETER },
];

export const DEFAULT_CUSTOMER_RATE_RATE_UNIT_OPTIONS = [
  { label: '', value: '' },
  { label: 'Flat', value: GC.CHARGE_RATE_UNIT_TYPE_FLAT },
  { label: 'Percent', value: GC.CHARGE_RATE_UNIT_TYPE_PERCENT },
];

export const DEFAULT_LINE_HAUL_RATE_UNIT_OPTIONS = [
  { label: '', value: '' },
  { label: 'Percent', value: GC.CHARGE_RATE_UNIT_TYPE_PERCENT },
];

export const DEFAULT_TAXABLE_TOTAL_RATE_UNIT_OPTIONS = [
  { label: '', value: '' },
  { label: 'Percent', value: GC.CHARGE_RATE_UNIT_TYPE_PERCENT },
];

export const DEFAULT_DISCOUNT_RATE_UNIT_OPTIONS = [
  { label: '', value: '' },
  { label: 'Flat', value: GC.CHARGE_RATE_UNIT_TYPE_FLAT },
  { label: 'Percent', value: GC.CHARGE_RATE_UNIT_TYPE_PERCENT },
];

export const DEFAULT_DISCOUNT_VARIABLE_TYPE_OPTIONS = [
  { label: '', value: '' },
  { value: GC.CHARGE_RATE_TYPE_WEIGHT, label: getWindowLocale('titles:weight', 'Weight') },
  { value: GC.CHARGE_RATE_TYPE_DISTANCE, label: getWindowLocale('titles:distance', 'Distance') },
  { value: GC.CHARGE_RATE_TYPE_STOP, label: getWindowLocale('titles:stop', 'Stop') },
  { value: GC.CHARGE_RATE_TYPE_RATE_TOTAL, label: getWindowLocale('titles:rate-total', 'Rate Total') },
];

export const DEFAULT_VOLUME_RATE_UNIT_OPTIONS = [
  { label: '', value: '' },
  { label: 'Cubic Foot', value: GC.UOM_CUBIC_FT },
  { label: 'Cubic Metre', value: GC.UOM_CUBIC_M },
  { label: 'Gallon Liquid', value: GC.UOM_GALLON_LIQUID },
];

export const FUEL_UOM_OPTIONS = [
  { label: 'Gallon Liquid', value: GC.UOM_GALLON_LIQUID },
  { label: 'Liters', value: GC.UOM_LITER },
];

export const FLAT_PERCENT_RATE_UNIT_OPTIONS = [
  { label: getWindowLocale('titles:flat', 'Flat'), value: GC.CHARGE_RATE_UNIT_TYPE_FLAT },
  { label: getWindowLocale('titles:percent', 'Percent'), value: GC.CHARGE_RATE_UNIT_TYPE_PERCENT },
];

export const DEFAULT_QUANTITY_RATE_UNIT_OPTIONS = [
  { label: '', value: '' },
  { label: getWindowLocale('titles:bags', 'Bags'), value: GC.ITEM_QUANTITY_UNIT_BAG },
  { label: getWindowLocale('titles:bales', 'Bales'), value: GC.ITEM_QUANTITY_UNIT_BALES },
  { label: getWindowLocale('titles:bin', 'Bin'), value: GC.ITEM_QUANTITY_UNIT_BIN },
  { label: getWindowLocale('titles:box', 'Box'), value: GC.ITEM_QUANTITY_UNIT_BOX },
  { label: getWindowLocale('titles:bundles', 'Bundles'), value: GC.ITEM_QUANTITY_UNIT_BUNDLES },
  { label: getWindowLocale('titles:cases', 'Cases'), value: GC.ITEM_QUANTITY_UNIT_CASES },
  { label: getWindowLocale('titles:cartons', 'Cartons'), value: GC.ITEM_QUANTITY_UNIT_CARTONS },
  { label: getWindowLocale('titles:crates', 'Crates'), value: GC.ITEM_QUANTITY_UNIT_CRATES },
  { label: getWindowLocale('titles:container', 'Container'), value: GC.ITEM_QUANTITY_UNIT_CONTAINER },
  { label: getWindowLocale('titles:drums', 'Drums'), value: GC.ITEM_QUANTITY_UNIT_DRUMS },
  { label: getWindowLocale('titles:gallon', 'Gallon'), value: GC.ITEM_QUANTITY_UNIT_GALLON },
  { label: getWindowLocale('titles:gaylord', 'Gaylord'), value: GC.ITEM_QUANTITY_UNIT_GAYLORD },
  { label: getWindowLocale('titles:pallet', 'Pallet'), value: GC.ITEM_QUANTITY_UNIT_PALLET },
  { label: getWindowLocale('titles:pail', 'Pail'), value: GC.ITEM_QUANTITY_UNIT_PAIL },
  { label: getWindowLocale('titles:pieces', 'Pieces'), value: GC.ITEM_QUANTITY_UNIT_PIECES },
  { label: getWindowLocale('titles:racks', 'Racks'), value: GC.ITEM_QUANTITY_UNIT_RACK },
  { label: getWindowLocale('titles:rolls', 'Rolls'), value: GC.ITEM_QUANTITY_UNIT_ROLL },
  { label: getWindowLocale('titles:sack', 'Sack'), value: GC.ITEM_QUANTITY_UNIT_SACK },
  { label: getWindowLocale('titles:skids', 'Skids'), value: GC.ITEM_QUANTITY_UNIT_SKIDS },
  { label: getWindowLocale('titles:tote', 'Tote'), value: GC.ITEM_QUANTITY_UNIT_TOTE },
  { label: getWindowLocale('titles:truckload', 'Truckload'), value: GC.ITEM_QUANTITY_TYPE_TRUCK_LOAD },
];

// ITEM
export const ITEM_FREIGHT_CLASS_OPTIONS_STANDARD = [
  { label: '', value: '' },
  { label: '050', value: 'FIFTY' },
  { label: '055', value: 'FIFTY_FIVE' },
  { label: '060', value: 'SIXTY' },
  { label: '065', value: 'SIXTY_FIVE' },
  { label: '070', value: 'SEVENTY' },
  { label: '077.5', value: 'SEVENTY_SEVEN_POINT_FIVE' },
  { label: '085', value: 'EIGHTY_FIVE' },
  { label: '092.5', value: 'NINETY_TWO_POINT_FIVE' },
  { label: '100', value: 'ONE_HUNDRED' },
  { label: '110', value: 'ONE_HUNDRED_TEN' },
  { label: '125', value: 'ONE_HUNDRED_TWENTY_FIVE' },
  { label: '150', value: 'ONE_HUNDRED_FIFTY' },
  { label: '175', value: 'ONE_HUNDRED_SEVENTY_FIVE' },
  { label: '200', value: 'TWO_HUNDRED' },
  { label: '250', value: 'TWO_HUNDRED_FIFTY' },
  { label: '300', value: 'THREE_HUNDRED' },
  { label: '400', value: 'FOUR_HUNDRED' },
  { label: '500', value: 'FIVE_HUNDRED' },
  { label: 'No Freight Class', value: 'NO_FREIGHT_CLASS' },
];

export const ITEM_FREIGHT_CLASS_OPTIONS_FAK = [
  { label: '', value: '' },
  { label: '060', value: 'SIXTY' },
  { label: '065', value: 'SIXTY_FIVE' },
  { label: '070', value: 'SEVENTY' },
  { label: '085', value: 'EIGHTY_FIVE' },
  { label: '092.5', value: 'NINETY_TWO_POINT_FIVE' },
  { label: '100', value: 'ONE_HUNDRED' },
  { label: '125', value: 'ONE_HUNDRED_TWENTY_FIVE' },
  { label: '175', value: 'ONE_HUNDRED_SEVENTY_FIVE' },
  { label: '250', value: 'TWO_HUNDRED_FIFTY' },
  { label: '300', value: 'THREE_HUNDRED' },
  { label: '400', value: 'FOUR_HUNDRED' },
  { label: 'No Freight Class', value: 'NO_FREIGHT_CLASS' },
];

export const getFreightClassOptions = () => {
  // const config = getAmousConfigByNameFromWindow(GC.CLO_ITEM_FREIGHT_CLASS_CALCULATOR);

  const config = window.getAmousConfigByNameFromWindow(GC.CLO_ITEM_FREIGHT_CLASS_CALCULATOR);

  if (R.equals(config, GC.FREIGHT_CLASS_CALCULATOR_FAK)) return ITEM_FREIGHT_CLASS_OPTIONS_FAK;

  return ITEM_FREIGHT_CLASS_OPTIONS_STANDARD;
};

export const ITEM_DIMENSIONS_OPTIONS = [
  { label: '', value: '' },
  { label: getWindowLocale('titles:feet', 'Feet'), value: GC.UOM_FOOT },
  { label: getWindowLocale('titles:inches', 'Inches'), value: GC.UOM_INCH },
  { label: getWindowLocale('titles:meters', 'Meters'), value: GC.UOM_METER },
  { label: getWindowLocale('titles:centimeters', 'Centimeters'), value: GC.UOM_CENTIMETER },
];

export const ITEM_ODOMETER_UOM_OPTIONS = [
  { label: '', value: '' },
  { value: GC.UOM_MILE, label: getWindowLocale('titles:mile', 'Mile') },
  { value: GC.UOM_METER, label: getWindowLocale('titles:meter', 'Meter') },
  { value: GC.UOM_KILOMETER, label: getWindowLocale('titles:kilometer', 'Kilometer') },
];

export const ITEM_TEMPERATURE_OPTIONS = [
  { label: '', value: '' },
  { label: 'Celsius', value: GC.UOM_CELSIUS },
  { label: 'Fahrenheit', value: GC.UOM_FAHRENHEIT },
];

export const ITEM_COMMODITY_OPTIONS = [
  '',
  'Agriculture',
  'Alcohol',
  'Armchair',
  'Automotive',
  'Bricks',
  'COA',
  'Chemicals',
  'Desk',
  'Electronics',
  'Food',
  'General',
  'Household Goods',
  'Machinery',
  'Manufactured Products',
  'Metals',
  'Other',
  'Petroleum Products',
  'Pharmaceutical',
  'Plastics',
  'Range',
  'Textiles',
  'Wood Products',
];

export const ITEM_TEMPERATURE_TYPE_OPTIONS = [
  { label: '', value: '' },
  { label: getWindowLocale('titles:hot', 'Hot'), value: GC.ITEM_TEMPERATURE_TYPE_HOT },
  { label: getWindowLocale('titles:dry', 'Dry'), value: GC.ITEM_TEMPERATURE_TYPE_DRY },
  { label: getWindowLocale('titles:frozen', 'Frozen'), value: GC.ITEM_TEMPERATURE_TYPE_FROZEN },
  { label: getWindowLocale('titles:chilled', 'Chilled'), value: GC.ITEM_TEMPERATURE_TYPE_CHILLED },
];

export const TEMPERATURE_SENSOR_OPTIONS = [
  { label: '', value: '' },
  {
    value: ENUMS.ENUM_YES,
    label: getEnumLocale(ENUMS.ENUM_YES),
  },
  {
    value: ENUMS.ENUM_NO,
    label: getEnumLocale(ENUMS.ENUM_NO),
  },
  {
    value: ENUMS.ENUM_UNKNOWN,
    label: getEnumLocale(ENUMS.ENUM_UNKNOWN),
  },
];

export const ITEM_VOLUME_OPTIONS = DEFAULT_VOLUME_RATE_UNIT_OPTIONS;

// TODO: check names for rate type and unit

export const DEFAULT_RATE_UNIT_GROUP_OPTIONS = {
  [GC.CHARGE_RATE_TYPE_TIME]: DEFAULT_TIME_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_VOLUME]: DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_WEIGHT]: DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_QUANTITY]: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_DISTANCE]: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_CUSTOMER_RATE]: DEFAULT_CUSTOMER_RATE_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL]: DEFAULT_CUSTOMER_RATE_RATE_UNIT_OPTIONS,
};

export const DEFAULT_CONTRACT_RATE_UNIT_OPTIONS = [
  ...DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  ...DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
  ...DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  ...DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  ...DEFAULT_CUSTOMER_RATE_RATE_UNIT_OPTIONS,
  ...DEFAULT_TIME_RATE_UNIT_OPTIONS,
];

export const DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_OPTIONS = [
  ...DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  ...DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
  ...DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  ...DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  ...DEFAULT_TIME_RATE_UNIT_OPTIONS,
  ...DEFAULT_DEADHEAD_DISTANCE_RATE_UNIT_OPTIONS,
  ...DEFAULT_LINE_HAUL_RATE_UNIT_OPTIONS,
  ...DEFAULT_TAXABLE_TOTAL_RATE_UNIT_OPTIONS,
];

export const DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_GROUP_OPTIONS = {
  [GC.CHARGE_RATE_TYPE_TIME]: DEFAULT_TIME_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_WEIGHT]: DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_VOLUME]: DEFAULT_VOLUME_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_QUANTITY]: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_DISTANCE]: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_LINE_HAUL]: DEFAULT_LINE_HAUL_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL]: DEFAULT_TAXABLE_TOTAL_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_DEADHEAD_DISTANCE]: DEFAULT_DEADHEAD_DISTANCE_RATE_UNIT_OPTIONS,
};

export const DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS = {
  ...DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_GROUP_OPTIONS,
  [GC.CHARGE_RATE_TYPE_CUSTOMER_RATE]: DEFAULT_LINE_HAUL_RATE_UNIT_OPTIONS,
  [GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL]: DEFAULT_LINE_HAUL_RATE_UNIT_OPTIONS,
};

export const DRIVER_COMPENSATION_RATE_TYPE_CONTAIN = [
  GC.CHARGE_RATE_TYPE_DISTANCE,
  GC.CHARGE_RATE_TYPE_WEIGHT,
  GC.CHARGE_RATE_TYPE_VOLUME,
  GC.CHARGE_RATE_TYPE_QUANTITY,
  GC.CHARGE_RATE_TYPE_CUSTOMER_RATE,
  GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL,
  GC.CHARGE_RATE_TYPE_TIME,
];

export const ACCESSORIALS_RATE_TYPE_CONTAIN = [
  GC.CHARGE_RATE_TYPE_DISTANCE,
  GC.CHARGE_RATE_TYPE_DEADHEAD_DISTANCE,
  GC.CHARGE_RATE_TYPE_VOLUME,
  GC.CHARGE_RATE_TYPE_QUANTITY,
  GC.CHARGE_RATE_TYPE_WEIGHT,
  GC.CHARGE_RATE_TYPE_LINE_HAUL,
  GC.CHARGE_RATE_TYPE_TIME,
  GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL,
];

export const USER_ROLE_TYPES = [
  '',
  GC.USER_ROLE_TYPE_DRIVER,
  GC.USER_ROLE_TYPE_GENERAL,
  GC.USER_ROLE_TYPE_CARRIER,
  GC.USER_ROLE_TYPE_CUSTOMER,
];

export const COUNTRY_OPTIONS = COUNTRY_REGION_DATA.map(
  (countrySetting: Array) => ({ value: R.head(countrySetting), label: R.head(countrySetting) }),
);

export const statesMap = R.indexBy(R.prop('name'), COUNTRY_REGION_DATA.reduce((prev: Array, countrySetting: Array) => {
  const stateString = R.last(countrySetting);
  const stateData = R.split('|', stateString);
  const formatedStates = stateData.map(R.split('~'));
  const stateOptions = formatedStates.map((state: string) => ({ name: R.head(state), shortName: R.last(state) }));

  return R.concat(prev, stateOptions);
}, []));

export const STATE_OPTIONS = R.map(
  (state: string) => ({ [GC.FIELD_VALUE]: state, [GC.FIELD_LABEL]: state }),
  R.keys(statesMap),
);

export const TIME_ZONE_OPTIONS = TIME_ZONES;

export const countriesMap = R.indexBy(R.prop('name'), COUNTRY_REGION_DATA.map(
  (countrySetting: Array) => ({ name: R.head(countrySetting), shortName: countrySetting[1] }),
));

export const getDateTimeFormatOptions = () => [
  {
    value: GC.DATE_TIME_FORMAT_US,
    label: getWindowLocale('titles:us-date-time-format', 'US (MM/DD/YYYY hh:mm am)'),
  },
  {
    value: GC.DATE_TIME_FORMAT_US_24H,
    label: getWindowLocale('titles:us-date-time-format-24', 'US Military (MM/DD/YYYY hh:mm)'),
  },
];

export const getDistanceCalculatorConfigIntegrationTypes = () => [
  { label: getWindowLocale('titles:amazon', 'Amazon'), value: GC.DISTANCE_CALCULATION_TYPE_AMAZON },
  { label: getWindowLocale('titles:google', 'Google'), value: GC.DISTANCE_CALCULATION_TYPE_GOOGLE },
  { label: getWindowLocale('titles:pc-miler', 'PC Miler'), value: GC.DISTANCE_CALCULATION_TYPE_PC_MILER },
  { label: getWindowLocale('titles:pro-miles', 'Pro Miles'), value: GC.DISTANCE_CALCULATION_TYPE_PRO_MILES },
  { label: getWindowLocale('titles:tom-tom', 'TomTom'), value: GC.DISTANCE_CALCULATION_TYPE_TOM_TOM },
  { label: getWindowLocale('titles:rand-mcnally', 'Rand McNally'), value: GC.DISTANCE_CALCULATION_TYPE_RAND_MCNALLY },
];

export const getDistanceCalculatorConfigCalculateByOptions = () => [
  GC.EMPTY_OPTION_OBJECT,
  { label: getWindowLocale('titles:geo-coordinates', 'Geo Coordinates'), value: 'GEO_COORDINATES' },
  { label: getWindowLocale('titles:city-and-state', 'City And State'), value: 'CITY_AND_STATE' },
];

// carrier integration
export const getCarrierRateIntegrationOptions = () => [
  GC.EMPTY_OPTION_OBJECT,
  {
    label: 'AAA Cooper',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_AAA_COOPER,
  },
  {
    label: 'A Duie Pyle',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_A_DUIE_PYLE,
  },
  {
    label: 'ArcBest',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_ARC_BEST,
  },
  {
    label: 'Day&Ross',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_DAY_AND_ROSS,
  },
  {
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_DAYTON_FREIGHT,
    label: getWindowLocale('titles:dayton-freight', 'Dayton Freight'),
  },
  {
    label: 'FedEx',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_FEDEX,
  },
  {
    label: 'GLS',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_GLS,
  },
  {
    label: 'Holland',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_HOLLAND,
  },
  {
    label: 'Manitoulin',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_MANITOULIN,
  },
  {
    label: 'New Penn',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_NEW_PENN,
  },
  {
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_ODFL,
    label: GC.CARRIER_RATE_INTEGRATION_TYPE_ODFL,
  },
  {
    label: 'Polaris',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_POLARIS,
  },
  {
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_PRIORITY_ONE,
    label: getWindowLocale('titles:priority-one', 'Priority One'),
  },
  {
    label: 'Reddaway',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_REDDAWAY,
  },
  {
    label: 'RoadRunner',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_ROADRUNNER,
  },
  {
    label: 'SAIA',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_SAIA,
  },
  {
    label: 'SMTL',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_SMTL,
  },
  {
    label: 'Southeastern Freight Lines',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_SEFL,
  },
  {
    label: 'TForce World Wide ',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_T_FORCE_WORLD_WIDE,
  },
  {
    label: 'Unishippers',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_UNISHIPPERS,
  },
  {
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_UPS,
    label: GC.CARRIER_RATE_INTEGRATION_TYPE_UPS,
  },
  {
    label: 'WARD',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_WARD,
  },
  {
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_XPO_LOGISTICS,
    label: getWindowLocale('titles:xpo-logistics', 'XPO Logistics'),
  },
  {
    label: 'YRC',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_YRC,
  },
  {
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_RIST,
    label: getWindowLocale('titles:rist-transportation', 'RIST Transportation'),
  },
];

export const getEDIConfigSenderIdentificationTypes = () => [
  GC.EMPTY_OPTION_OBJECT,
  {
    label: getWindowLocale('titles:sender-id', 'Sender ID'),
    value: GC.EDI_LOADER_SENDER_IDENTIFICATION_TYPE_SENDER_ID,
  },
  {
    value: GC.EDI_LOADER_SENDER_IDENTIFICATION_TYPE_SHIPPER_IDENTIFICATION_CODE,
    label: getWindowLocale('titles:shipper-identification-code', 'Shipper Identification Code'),
  },
  {
    value: GC.EDI_LOADER_SENDER_IDENTIFICATION_TYPE_ACCOUNT_CODE_REFERENCE,
    label: getWindowLocale('titles:account-code-reference', 'Account Code Reference'),
  },
];

export const getEDIConfigLoadIdentificationTypes = () => [
  GC.EMPTY_OPTION_OBJECT,
  {
    value: 'PICKUP_NUMBER',
    label: getWindowLocale('titles:pickup-number', 'Pickup Number'),
  },
  {
    value: 'PRIMARY_REFERENCE_VALUE',
    label: getWindowLocale('titles:primary-reference-value', 'Primary Reference Value'),
  },
  {
    value: 'TRACKING_NUMBER',
    label: getWindowLocale('titles:tracking-number', 'Tracking Number'),
  },
];

// user monitoring integration
export const getUserMonitoringIntegrationOptions = () => [
  GC.EMPTY_OPTION_OBJECT,
  {
    label: 'Smartlook',
    value: GC.USER_MONITORING_APP_INTEGRATION_TYPE_SMARTLOOK,
  },
];

// customer integration config types
export const customerIntegrationConfigTypes = [
  {
    label: 'Four Kites',
    value: GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_FOUR_KITES,
  },
  {
    label: 'CH Robinson',
    value: GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_CH_ROBINSON,
  },
  {
    label: 'Project 44',
    value: GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_PROJECT_44,
  },
  {
    label: 'MacroPoint',
    value: GC.CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_MACRO_POINT,
  },
];

export const gpsIntegrationConfigTypes = [
  {
    label: 'Blue Star',
    value: GC.GPS_INTEGRATION_TYPE_BLUE_STAR,
  },
  {
    label: 'Club ELD',
    value: GC.GPS_INTEGRATION_TYPE_CLUB_ELD,
  },
  {
    label: 'GEOTAB',
    value: GC.GPS_INTEGRATION_TYPE_GEO_TAB,
  },
  {
    label: 'Keep Truckin',
    value: GC.GPS_INTEGRATION_TYPE_KEEP_TRUCKIN,
  },
  {
    label: 'Lucid ELD',
    value: GC.GPS_INTEGRATION_TYPE_LUCID_ELD,
  },
  {
    label: 'Maestral ELD',
    value: GC.GPS_INTEGRATION_TYPE_MAESTRAL_ELD,
  },
  {
    label: 'Master ELD',
    value: GC.GPS_INTEGRATION_TYPE_MASTER_ELD,
  },
  {
    label: 'Omnitracs',
    value: GC.GPS_INTEGRATION_TYPE_OMNITRACS,
  },
  {
    label: 'Samsara',
    value: GC.GPS_INTEGRATION_TYPE_SAMSARA,
  },
  {
    label: 'Sparkle ELD',
    value: GC.GPS_INTEGRATION_TYPE_SPARKLE_ELD,
  },
  {
    label: 'Track Ensure',
    value: GC.GPS_INTEGRATION_TYPE_TRACK_ENSURE,
  },
  {
    label: 'Verizon',
    value: GC.GPS_INTEGRATION_TYPE_VERIZON,
  },
];

// communication notification configurations
export const getCommunicationNotificationObjectTypeOptions = () => [
  {
    label: getWindowLocale('titles:tel', 'TEL'),
    value: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_TEL,
  },
  {
    label: getWindowLocale('titles:clo', 'CLO'),
    value: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_CLO,
  },
  {
    label: getWindowLocale('titles:driver', 'Driver'),
    value: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_DRIVER,
  },
  {
    label: getWindowLocale('titles:carrier', 'Carrier'),
    value: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_CARRIER,
  },
  {
    label: getWindowLocale('titles:truck', 'Truck'),
    value: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_TRUCK,
  },
  {
    label: getWindowLocale('titles:trailer', 'Trailer'),
    value: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_TRAILER,
  },
  {
    label: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_EDI,
    value: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_EDI,
  },
  {
    value: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_IFTA,
    label: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_IFTA,
  },
  {
    label: getWindowLocale('titles:branch', 'Branch'),
    value: GC.BRANCH_UPPERCASE,
  },
  {
    label: getWindowLocale('titles:order-quote', 'Order Quote'),
    value: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJECT_TYPE_ORDER_QUOTE,
  },
];

// communication documents configurations
export const getSpecificDocumentFeaturesOptions = () => [
  {
    value: GC.DOCUMENT_TEMPLATE_SPECIFIC_FEATURE_EVENT_RELATED,
    label: getWindowLocale('titles:event-related', 'Event Related'),
  },
  {
    value: GC.DOCUMENT_TEMPLATE_SPECIFIC_FEATURE_ALLOW_ZERO_RATE,
    label: getWindowLocale('titles:allow-zero-rate', 'Allow Zero Rate'),
  },
  {
    value: GC.DOCUMENT_TEMPLATE_SPECIFIC_FEATURE_VENDOR_PAYROLL_SCIP_RELATED_TRIP_INVOICES,
    label: getWindowLocale(
      'titles:vendor-payroll-skip-related-trip-invoices',
      'Vendor Payroll Skip Related Trip Invoices',
    ),
  },
  {
    value: GC.DOCUMENT_TEMPLATE_SPECIFIC_FEATURE_DAILY_DRIVER_HOURS_ADD_HOS_LOGS,
    label: getWindowLocale(
      'titles:daily-driver-hours-add-hos-logs',
      'Daily Driver Hours add Hos Logs',
    ),
  },
];

// communication documents filter configurations
export const getCommunicationDocumentFiltersOptions = () => [
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_TEL,
    label: getWindowLocale('titles:tel', 'TEL'),
  },
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_CLO,
    label: getWindowLocale('titles:clo', 'CLO'),
  },
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE,
    label: getWindowLocale('titles:customer-invoice', 'Customer Invoice'),
  },
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_IFTA,
    label: getWindowLocale('titles:ifta', 'IFTA'),
  },
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_ROUTE,
    label: getWindowLocale('titles:route', 'Route'),
  },
  {
    label: getWindowLocale('titles:truck', 'Truck'),
    value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRUCK,
  },
  {
    label: getWindowLocale('titles:driver', 'Driver'),
    value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_DRIVER,
  },
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_TRAILER,
    label: getWindowLocale('titles:trailer', 'Trailer'),
  },
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_ORDER_QUOTE,
    label: getWindowLocale('titles:order-quote', 'Order Quote'),
  },
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL,
    label: getWindowLocale('titles:driver-payroll', 'Driver Payroll'),
  },
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_PAYROLL,
    label: getWindowLocale('titles:vendor-payroll', 'Vendor Payroll'),
  },
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_PAYROLL_NEW,
    label: getWindowLocale('titles:vendor-payroll-new', 'Vendor Payroll New'),
  },
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_VENDOR_DRIVER_RATE_LIST,
    label: getWindowLocale(
      'titles:vendor-driver-rate-list',
      'Vendor Driver Rate List',
    ),
  },
  {
    value: GC.DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE,
    label: getWindowLocale(
      'titles:customer-master-invoice',
      'Customer Master Invoice',
    ),
  },
];

// license class
export const LICENSE_CLASS_OPTIONS = [
  GC.EMPTY_OPTION_OBJECT,
  {
    value: 'Class A',
    label: 'Class A',
  },
  {
    value: 'Class B',
    label: 'Class B',
  },
  {
    value: 'Class C',
    label: 'Class C',
  },
  {
    value: 'Class D',
    label: 'Class D',
  },
  {
    value: 'Class E',
    label: 'Class E',
  },
  {
    value: 'Class AM',
    label: 'Class AM',
  },
  {
    value: 'Class AZ',
    label: 'Class AZ',
  },
  {
    value: 'Class F',
    label: 'Class F',
  },
  {
    value: 'Class O',
    label: 'Class O',
  },
  {
    value: 'Class R',
    label: 'Class R',
  },
  {
    value: 'Class 1',
    label: 'Class 1',
  },
  {
    value: 'Class 2',
    label: 'Class 2',
  },
  {
    value: 'Class 3',
    label: 'Class 3',
  },
  {
    value: 'Class 4',
    label: 'Class 4',
  },
  {
    value: 'Class 5',
    label: 'Class 5',
  },
  {
    value: 'Class A Permit',
    label: 'Class A Permit',
  },
];

export const LICENSE_RESTRICTION_OPTIONS = [
  GC.EMPTY_OPTION_OBJECT,
  {
    label: 'Power Brakes',
    value: 'Power Brakes',
  },
  {
    label: 'Wheel Spinner',
    value: 'Wheel Spinner',
  },
  {
    label: 'No Restriction',
    value: 'No Restriction',
  },
  {
    label: 'Power Steering',
    value: 'Power Steering',
  },
  {
    label: 'Mechanical Aid',
    value: 'Mechanical Aid',
  },
  {
    label: 'Outside Mirrors',
    value: 'Outside Mirrors',
  },
  {
    label: 'Prosthetic Device',
    value: 'Prosthetic Device',
  },
  {
    label: 'Temporary Visitor',
    value: 'Temporary Visitor',
  },
  {
    label: 'Corrective Lenses',
    value: 'Corrective Lenses',
  },
  {
    label: 'Full Hand Control',
    value: 'Full Hand Control',
  },
  {
    label: 'Telescopic Lens 4',
    value: 'Telescopic Lens 4',
  },
  {
    label: 'Med Cert Exemption',
    value: 'Med Cert Exemption',
  },
  {
    label: 'Hand-Operated Brake',
    value: 'Hand-Operated Brake',
  },
  {
    label: 'Accel Left of Brake',
    value: 'Accel Left of Brake',
  },
  {
    label: 'Shoulder Harness Use',
    value: 'Shoulder Harness Use',
  },
  {
    label: 'No Limited Access Rds',
    value: 'No Limited Access Rds',
  },
  {
    label: 'Daylight Driving Only',
    value: 'Daylight Driving Only',
  },
  {
    label: 'Limited to Employment',
    value: 'Limited to Employment',
  },
  {
    label: 'Automatic Transmission',
    value: 'Automatic Transmission',
  },
  {
    label: 'Built Up Seat/Ped/Shoe',
    value: 'Built Up Seat/Ped/Shoe',
  },
  {
    label: 'Foot Oper Parking Brake',
    value: 'Foot Oper Parking Brake',
  },
  {
    label: 'No Airbrake Equipped CMV',
    value: 'No Airbrake Equipped CMV',
  },
  {
    label: 'No Class A Passenger VEH',
    value: 'No Class A Passenger VEH',
  },
  {
    label: 'Ignition Interlock Device',
    value: 'Ignition Interlock Device',
  },
  {
    label: 'Limited Use Auto MAX 40 MPH',
    value: 'Limited Use Auto MAX 40 MPH',
  },
  {
    label: 'Problem Driver (Class D Only)',
    value: 'Problem Driver (Class D Only)',
  },
  {
    label: 'No Class A and B Passenger Veh',
    value: 'No Class A and B Passenger Veh',
  },
  {
    label: 'Hearing Aid or Full-View Mirror',
    value: 'Hearing Aid or Full-View Mirror',
  },
  {
    label: 'No Manual Transmission Equipped CMV',
    value: 'No Manual Transmission Equipped CMV',
  },
  {
    label: 'Medical Variance (Commercial Class Only)',
    value: 'Medical Variance (Commercial Class Only)',
  },
  {
    label: 'No Tractor/Trailer CMV (Commercial Class Only)',
    value: 'No Tractor/Trailer CMV (Commercial Class Only)',
  },
  {
    label: 'No Tractor/Trailer CMV/Truck Not Over 26,000 GVWR',
    value: 'No Tractor/Trailer CMV/Truck Not Over 26,000 GVWR',
  },
  {
    label: 'No Vehicle Designed for 15 or More Adult Passengers',
    value: 'No Vehicle Designed for 15 or More Adult Passengers',
  },
  {
    label: 'No Full Airbrake Equipped CMV (Commercial Class Only)',
    value: 'No Full Airbrake Equipped CMV (Commercial Class Only)',
  },
  {
    label: 'CDL Intrastate (No operation outside NYS for commerce)',
    value: 'CDL Intrastate (No operation outside NYS for commerce)',
  },
  {
    label: 'No Passengers in CMV Bus (Commercial Learner Permit Only)',
    value: 'No Passengers in CMV Bus (Commercial Learner Permit Only)',
  },
  {
    label: 'No Cargo in CMV Tank Vehicle (Commercial Learner Permit Only)',
    value: 'No Cargo in CMV Tank Vehicle (Commercial Learner Permit Only)',
  },
];

export const DUTY_STATUS_CODE_OPTIONS = [
  {
    value: GC.DUTY_STATUS_CODE_TYPE_YARD_MOVE,
    label: getWindowLocale('titles:yard-move', 'Yard Move'),
  },
  {
    value: GC.DUTY_STATUS_CODE_TYPE_ON_DUTY,
    label: getWindowLocale('titles:on-duty', 'On Duty'),
  },
  {
    value: GC.DUTY_STATUS_CODE_TYPE_DRIVING,
    label: getWindowLocale('titles:driving', 'Driving'),
  },
  {
    value: GC.DUTY_STATUS_CODE_TYPE_OFF_DUTY,
    label: getWindowLocale('titles:off-duty', 'Off Duty'),
  },
  {
    value: GC.DUTY_STATUS_CODE_TYPE_SLEEPER_BED,
    label: getWindowLocale('titles:sleeper-bed', 'Sleeper Bed'),
  },
  {
    value: GC.DUTY_STATUS_CODE_TYPE_PERSONAL_CONVEYANCE,
    label: getWindowLocale('titles:personal-conveyance', 'Personal Conveyance'),
  },
  {
    value: GC.DUTY_STATUS_CODE_TYPE_UNKNOWN,
    label: getWindowLocale('titles:unknown', 'Unknown'),
  },
  {
    value: GC.DUTY_STATUS_CODE_TYPE_SLEEPER,
    label: getWindowLocale('titles:sleeper', 'Sleeper'),
  },
  {
    value: GC.DUTY_STATUS_CODE_TYPE_WAITING_TIME,
    label: getWindowLocale('titles:waiting-time', 'Waiting Time'),
  },
  {
    value: GC.DUTY_STATUS_CODE_TYPE_INTERMEDIATE,
    label: getWindowLocale('titles:intermediate', 'Intermediate'),
  },
];

export const statusCodeMappingTypeOptions = () => [
  GC.EMPTY_OPTION_OBJECT,
  {
    label: 'AAA Cooper',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_AAA_COOPER,
  },
  {
    label: 'A Duie Pyle',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_A_DUIE_PYLE,
  },
  {
    label: 'ArcBest',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_ARC_BEST,
  },
  {
    label: 'Day&Ross',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_DAY_AND_ROSS,
  },
  {
    label: 'Dayton Freight',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_DAYTON_FREIGHT,
  },
  {
    label: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
  },
  {
    label: 'FedEx',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_FEDEX,
  },
  {
    label: 'FourKites',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_FOUR_KITES,
  },
  {
    label: 'GLS',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_GLS,
  },
  {
    label: 'Holland and Reddaway',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_HOLLAND_AND_REDDAWAY,
  },
  {
    label: 'Macro Point',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_MACRO_POINT,
  },
  {
    label: 'Manitoulin',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_MANITOULIN,
  },
  {
    label: 'New Penn',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_NEW_PENN,
  },
  {
    label: 'ODFL',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_ODFL,
  },
  {
    label: 'Polaris',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_POLARIS,
  },
  {
    label: 'RIST',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_RIST,
  },
  {
    label: 'RoadRunner',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_ROADRUNNER,
  },
  {
    label: 'SAIA',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_SAIA,
  },
  {
    label: 'SMTL',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_SMTL,
  },
  {
    label: 'Southeastern Freight Lines',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_SEFL,
  },
  {
    label: 'Trucker Tools',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_TRUCKER_TOOLS,
  },
  {
    label: 'TForce World Wide',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_T_FORCE_WORLD_WIDE,
  },
  {
    label: 'Unishippers',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_UNISHIPPERS,
  },
  {
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_UPS,
    label: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_UPS,
  },
  {
    label: 'WARD',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_WARD,
  },
  {
    label: 'XPO Logistics',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_XPO_LOGISTICS,
  },
  {
    label: 'YRC',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_YRC,
  },
];

export const statusCodeOutboundMappingTypeOptions = [
  {
    label: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
  },
  {
    label: 'Project 44',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_PROJECT_44,
  },
  {
    label: 'CH Robinson',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_CH_ROBINSON,
  },
  {
    label: 'MacroPoint',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_MACRO_POINT,
  },
];

export const statusReasonCodeMappingTypeOptions = [
  {
    label: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI,
  },
];

export const serviceTypeMappingIntegrationTypeOptions = () => [
  GC.EMPTY_OPTION_OBJECT,
  {
    label: 'AAA Cooper',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_AAA_COOPER,
  },
  {
    label: 'Dayton Freight',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_DAYTON_FREIGHT,
  },
  {
    label: 'FedEx',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_FEDEX,
  },
  {
    label: 'GLS',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_GLS,
  },
  {
    label: 'Holland',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_HOLLAND,
  },
  {
    label: 'Manitoulin',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_MANITOULIN,
  },
  {
    label: 'Reddaway',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_REDDAWAY,
  },
  {
    label: 'SAIA',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_SAIA,
  },
  {
    label: 'Unishippers',
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_UNISHIPPERS,
  },
  {
    label: GC.CARRIER_RATE_INTEGRATION_TYPE_UPS,
    value: GC.CARRIER_RATE_INTEGRATION_TYPE_UPS,
  },
  {
    label: 'WARD',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_WARD,
  },
  {
    label: 'XPO Logistics',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_XPO_LOGISTICS,
  },
  {
    label: 'YRC',
    value: GC.STATUS_CODE_MAPPING_INTEGRATION_TYPE_YRC,
  },
];

export const dayOptions = () => ({
  SUNDAY: { value: 'SUNDAY', label: getEnumLocale('SUNDAY') },
  MONDAY: { value: 'MONDAY', label: getEnumLocale('MONDAY') },
  TUESDAY: { value: 'TUESDAY', label: getEnumLocale('TUESDAY') },
  WEDNESDAY: { value: 'WEDNESDAY', label: getEnumLocale('WEDNESDAY') },
  THURSDAY: { value: 'THURSDAY', label: getEnumLocale('THURSDAY') },
  FRIDAY: { value: 'FRIDAY', label: getEnumLocale('FRIDAY') },
  SATURDAY: { value: 'SATURDAY', label: getEnumLocale('SATURDAY') },
});

export const daySelectOptions = [
  { value: 'SUNDAY', label: getEnumLocale('SUNDAY') },
  { value: 'MONDAY', label: getEnumLocale('MONDAY') },
  { value: 'TUESDAY', label: getEnumLocale('TUESDAY') },
  { value: 'WEDNESDAY', label: getEnumLocale('WEDNESDAY') },
  { value: 'THURSDAY', label: getEnumLocale('THURSDAY') },
  { value: 'FRIDAY', label: getEnumLocale('FRIDAY') },
  { value: 'SATURDAY', label: getEnumLocale('SATURDAY') },
];

export const integrationMailConfigTypeOptions = () => [
  {
    label: 'Outlook',
    value: GC.INTEGRATION_MAIL_CONFIG_TYPE_OUTLOOK,
  },
  {
    label: 'Gmail',
    value: GC.INTEGRATION_MAIL_CONFIG_TYPE_GMAIL,
  },
  {
    value: GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM,
    label: getWindowLocale('titles:custom', 'Custom'),
  },
];

export const documentTypeMappingOptions = () => [
  {
    label: 'HubTran',
    value: GC.DOCUMENT_TYPE_MAPPING_TYPE_HUB_TRAN,
  },
  {
    label: 'SAIA',
    value: GC.DOCUMENT_TYPE_MAPPING_TYPE_SAIA,
  },
];

export const documentHubTypeMappingOptions = () => [
  {
    label: 'HubTran',
    value: GC.DOCUMENT_TYPE_MAPPING_TYPE_HUB_TRAN,
  },
];

export const accountingOptions = () => [
  {
    label: 'Business Central',
    value: GC.ACCOUNTING_INTEGRATION_TYPE_BUSINESS_CENTRAL,
  },
  {
    label: 'Sage Intacct',
    value: GC.ACCOUNTING_INTEGRATION_TYPE_SAGE_INTACCT,
  },
];

export const accountingIntegrationOptions = () => [
  {
    label: 'Business Central',
    value: GC.ACCOUNTING_INTEGRATION_TYPE_BUSINESS_CENTRAL,
  },
  {
    label: 'Sage Intacct',
    value: GC.ACCOUNTING_INTEGRATION_TYPE_SAGE_INTACCT,
  },
  {
    label: 'QuickBooks',
    value: GC.ACCOUNTING_INTEGRATION_TYPE_QUICK_BOOKS,
  },
  // TODO: uncomment when be will be done
  // {
  //   label: 'Hub Tran',
  //   value: GC.ACCOUNTING_INTEGRATION_TYPE_HUB_TRAN,
  // },
];

export const commissionAssignmentOptions = () => [
  GC.EMPTY_OPTION_OBJECT,
  {
    value: GC.COMMISSION_ASSIGNMENT_TYPE_SALE_PERSON,
    label: getWindowLocale('titles:sale-person', 'Sale Person'),
  },
  {
    value: GC.COMMISSION_ASSIGNMENT_TYPE_BROKER_AGENT,
    label: getWindowLocale('titles:broker-agent', 'Broker Agent'),
  },
  {
    value: GC.COMMISSION_ASSIGNMENT_TYPE_ACCOUNT_MANAGER,
    label: getWindowLocale('titles:account-manager', 'Account Manager'),
  },
  {
    value: GC.COMMISSION_ASSIGNMENT_TYPE_SALE_REPRESENTATIVE,
    label: getWindowLocale('titles:sale-representative', 'Sale Representative'),
  },
  {
    value: GC.COMMISSION_ASSIGNMENT_TYPE_CARRIER_REPRESENTATIVE,
    label: getWindowLocale('titles:carrier-representative', 'Carrier Representative'),
  },
];

export const PHONE_COUNTRIES = ['US', 'CA', 'MX', 'DE'];

export const notificationLevelOptions = () => [
  {
    label: getWindowLocale('titles:info', 'Info'),
    value: GC.NOTIFICATION_WARNING_LEVEL_TYPE_INFO,
  },
  {
    value: GC.NOTIFICATION_WARNING_LEVEL_TYPE_WARNING,
    label: getWindowLocale('titles:warning', 'Warning'),
  },
  {
    value: GC.NOTIFICATION_WARNING_LEVEL_TYPE_CRITICAL,
    label: getWindowLocale('titles:critical', 'Critical'),
  },
];

export const getStatusMessageCodeStatusTypeOptions = () => [
  {
    value: GC.STATUS_CODE_STATUS_TYPE_ESTIMATED,
    label: getEnumLocale(GC.STATUS_CODE_STATUS_TYPE_ESTIMATED),
  },
  {
    value: GC.STATUS_CODE_STATUS_TYPE_STOP_CHECKED_IN,
    label: getEnumLocale(GC.STATUS_CODE_STATUS_TYPE_STOP_CHECKED_IN),
  },
  {
    value: GC.STATUS_CODE_STATUS_TYPE_DROP_CHECKED_IN,
    label: getEnumLocale(GC.STATUS_CODE_STATUS_TYPE_DROP_CHECKED_IN),
  },
  {
    value: GC.STATUS_CODE_STATUS_TYPE_PICKUP_CHECKED_IN,
    label: getEnumLocale(GC.STATUS_CODE_STATUS_TYPE_PICKUP_CHECKED_IN),
  },
  {
    value: GC.STATUS_CODE_STATUS_TYPE_DROP_COMPLETED,
    label: getEnumLocale(GC.STATUS_CODE_STATUS_TYPE_DROP_COMPLETED),
  },
  {
    value: GC.STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED,
    label: getEnumLocale(GC.STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED),
  },
];

export const getTransportationModeTypeOptions = () => [
  {
    value: GC.TRANSPORTATION_MODE_TYPE_LTL,
    label: getWindowLocale('titles:ltl', 'LTL'),
  },
  {
    value: GC.TRANSPORTATION_MODE_TYPE_AIR,
    label: getWindowLocale('titles:air', 'Air'),
  },
  {
    value: GC.TRANSPORTATION_MODE_TYPE_RAIL,
    label: getWindowLocale('titles:rail', 'Rail'),
  },
  {
    value: GC.TRANSPORTATION_MODE_TYPE_OCEAN,
    label: getWindowLocale('titles:ocean', 'Ocean'),
  },
  {
    value: GC.TRANSPORTATION_MODE_TYPE_DRAYAGE,
    label: getWindowLocale('titles:drayage', 'Drayage'),
  },
  {
    value: GC.TRANSPORTATION_MODE_TYPE_TRUCK_LOAD,
    label: getWindowLocale('titles:truck-load', 'Truck Load'),
  },
  {
    value: GC.TRANSPORTATION_MODE_TYPE_SMALL_TRUCK,
    label: getWindowLocale('titles:small-truck', 'Small Truck'),
  },
  {
    value: GC.TRANSPORTATION_MODE_TYPE_BOX_SHIPMENTS,
    label: getWindowLocale('titles:box-shipments', 'Box Shipments'),
  },
];

export const getCloGlobalSearchOptions = () => [
  {
    value: GC.GLOBAL_SEARCH_TYPE_ALL,
    label: getWindowLocale('titles:all', 'All'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_ITEM,
    label: getWindowLocale('titles:item', 'Item'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_EVENT,
    label: getWindowLocale('titles:event', 'Event'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_BRANCH,
    label: getWindowLocale('titles:branch', 'Branch'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_REFERENCE,
    label: getWindowLocale('titles:reference', 'Reference'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_BILL_TO,
    label: getWindowLocale('titles:bill-to', 'Bill To'),
  },
];

export const getTelGlobalSearchOptions = () => [
  {
    value: GC.GLOBAL_SEARCH_TYPE_ALL,
    label: getWindowLocale('titles:all', 'All'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_ITEM,
    label: getWindowLocale('titles:item', 'Item'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_EVENT,
    label: getWindowLocale('titles:event', 'Event'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_BRANCH,
    label: getWindowLocale('titles:branch', 'Branch'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_REFERENCE,
    label: getWindowLocale('titles:reference', 'Reference'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_BILL_TO,
    label: getWindowLocale('titles:bill-to', 'Bill To'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_DRIVER,
    label: getWindowLocale('titles:driver', 'Driver'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_TRUCK,
    label: getWindowLocale('titles:truck', 'Truck'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_TRAILER,
    label: getWindowLocale('titles:trailer', 'Trailer'),
  },
  {
    value: GC.GLOBAL_SEARCH_TYPE_CARRIER,
    label: getWindowLocale('titles:carrier', 'Carrier'),
  },
];

export const getOrderQuoteStatusTypeOptions = () => [
  {
    value: GC.ORDER_QUOTE_STATUS_TYPE_QUOTED,
    label: getWindowLocale('titles:quoted', 'Quoted'),
  },
  {
    value: GC.ORDER_QUOTE_STATUS_TYPE_EXPIRED,
    label: getWindowLocale('titles:expired', 'Expired'),
  },
  {
    value: GC.ORDER_QUOTE_STATUS_TYPE_CANCELED,
    label: getWindowLocale('titles:canceled', 'Canceled'),
  },
  {
    value: GC.ORDER_QUOTE_STATUS_TYPE_DECLINED,
    label: getWindowLocale('titles:declined', 'Declined'),
  },
  {
    value: GC.ORDER_QUOTE_STATUS_TYPE_ACCEPTED,
    label: getWindowLocale('titles:accepted', 'Accepted'),
  },
  {
    value: GC.ORDER_QUOTE_STATUS_TYPE_PROCESSED,
    label: getWindowLocale('titles:processed', 'Processed'),
  },
  {
    value: GC.ORDER_QUOTE_STATUS_TYPE_RATE_REQUESTED,
    label: getWindowLocale('titles:rate-requested', 'Rate Requested'),
  },
  {
    value: GC.ORDER_QUOTE_STATUS_TYPE_SENT_TO_CUSTOMER,
    label: getWindowLocale('titles:sent-to-customer', 'Sent to Customer'),
  },
];

export const getCustomerEdiExporterTransactionTypeOptions = () => [
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_INVOICE,
    label: `${getWindowLocale('titles:invoice', 'Invoice')} (210)`,
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_STATUS_MESSAGE,
    label: `${getWindowLocale('titles:status-message', 'Status Message')} (214)`,
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ACCEPT_DECLINE_RESPONSE,
    label: `${getWindowLocale('titles:tender-response', 'Tender Response')} (990)`,
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ACKNOWLEDGEMENT_RESPONSE,
    label: `${getWindowLocale('titles:acknowledgement-response', 'Acknowledgement Response')} (997)`,
  },
];

export const getCarrierEdiExporterTransactionTypeOptions = () => [
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ORDER,
    label: `${getWindowLocale('titles:order', 'Order')} (204)`,
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ACKNOWLEDGEMENT_RESPONSE,
    label: `${getWindowLocale('titles:acknowledgement-response', 'Acknowledgement Response')} (997)`,
  },
];

export const getCarrierEdiTransactionTypeOptions = () => [
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_INVOICE,
    label: getEnumLocale(GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_INVOICE),
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_STATUS_MESSAGE,
    label: getEnumLocale(GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_STATUS_MESSAGE),
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ACKNOWLEDGEMENT_RESPONSE,
    label: getEnumLocale(GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ACKNOWLEDGEMENT_RESPONSE),
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ORDER,
    label: getEnumLocale(GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ORDER),
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ACCEPT_DECLINE_RESPONSE,
    label: getEnumLocale(GC.CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ACCEPT_DECLINE_RESPONSE),
  },
];

export const getSharedAccessorialAutoApplyToTypeOptions = () => [
  {
    label: getWindowLocale('titles:fleet', 'Fleet'),
    value: GC.SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_FLEET,
  },
  {
    label: getWindowLocale('titles:carrier', 'Carrier'),
    value: GC.SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_CARRIER,
  },
  {
    label: getWindowLocale('titles:customer', 'Customer'),
    value: GC.SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_CUSTOMER,
  },
];

export const getCustomerEdiExporterReceiverQualifierTypeOptions = () => [
  {
    label: `${getWindowLocale('titles:general', 'General')} (ZZ)`,
    value: GC.CUSTOMER_EDI_EXPORTER_RECEIVER_QUALIFIER_TYPE_GENERAL,
  },
  {
    label: `${getWindowLocale('titles:scac', 'Scac')} (02)`,
    value: GC.CUSTOMER_EDI_EXPORTER_RECEIVER_QUALIFIER_TYPE_SCAC,
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_RECEIVER_QUALIFIER_TYPE_DUNS_NUMBER,
    label: `${getWindowLocale('titles:duns-number', 'D-U-N-S Number')} (01)`,
  },
  {
    value: GC.CUSTOMER_EDI_EXPORTER_RECEIVER_QUALIFIER_TYPE_DUNS_NUMBER_PLUS_SUFFIX,
    label: `${getWindowLocale('titles:duns-number-plus-suffix', 'D-U-N-S Number + Suffix')} (14)`,
  },
  {
    label: `${getWindowLocale('titles:phone', 'Phone')} (12)`,
    value: GC.CUSTOMER_EDI_EXPORTER_RECEIVER_QUALIFIER_TYPE_PHONE,
  },
];

export const factoringIntegrationTypeOptions = [
  {
    label: 'Triumph',
    value: GC.FACTORING_COMPANY_TYPE_TRIUMPH,
  },
  {
    label: 'Compass',
    value: GC.FACTORING_COMPANY_TYPE_COMPASS,
  },
  {
    label: 'OTR Capital',
    value: GC.FACTORING_COMPANY_TYPE_OTR_CAPITAL,
  },
  {
    label: 'eCapital',
    value: GC.FACTORING_COMPANY_TYPE_E_CAPITAL,
  },
  {
    label: 'RTS Financial',
    value: GC.FACTORING_COMPANY_TYPE_RTS_FINANCIAL,
  },
  {
    label: 'RTS Financial Manual',
    value: GC.FACTORING_COMPANY_TYPE_RTS_FINANCIAL_MANUAL,
  },
];

export const triumphFactoringPaymentTermTypeOptions = [
  {
    label: 'Standard Pay',
    value: GC.FACTORING_PAYMENT_TERM_TYPE_TRIUMPH_STANDARD_PAY,
  },
  {
    label: 'Broker Quick Pay',
    value: GC.FACTORING_PAYMENT_TERM_TYPE_TRIUMPH_BROKER_QUICK_PAY,
  },
  {
    label: 'Carrier Quick Pay',
    value: GC.FACTORING_PAYMENT_TERM_TYPE_TRIUMPH_CARRIER_QUICK_PAY,
  },
];

export const encodingTypeOptions = [
  { label: 'UTF-8', value: GC.ENCODING_TYPE_UTF_8 },
  { label: 'ASCII', value: GC.ENCODING_TYPE_ASCII },
];

export const smtpEncryptionTypeOptions = () => [
  { label: getWindowLocale('titles:none', 'None'), value: GC.SMTP_ENCRYPTION_TYPE_NONE },
  { label: 'SSL', value: GC.SMTP_ENCRYPTION_TYPE_SSL },
  { label: 'StartTLS', value: GC.SMTP_ENCRYPTION_TYPE_STARTTLS },
];

export const availableEdiExportMappers = [
  {
    label: 'Hub Group',
    value: GC.CUSTOM_EDI_EXPORT_MAPPER_TYPE_HUB_GROUP,
  },
];

export const availableEdiLoaderExportMappers = [
  {
    label: 'APL',
    value: GC.CUSTOM_EDI_EXPORT_MAPPER_TYPE_APL_SUFFIX_MAPPER,
  },
  {
    label: 'Descartes',
    value: GC.CUSTOM_EDI_EXPORT_MAPPER_TYPE_DESCARTES_MAPPER,
  },
];

export const serviceVendorTypeOptions = () => [
  {
    value: GC.SERVICE_VENDOR_TYPE_GENERAL,
    label: getEnumLocale(GC.SERVICE_VENDOR_TYPE_GENERAL),
  },
  {
    value: GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE,
    label: getEnumLocale(GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE),
  },
  {
    value: GC.SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE,
    label: getEnumLocale(GC.SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE),
  },
];

export const fleetServiceVendorTypeOptions = () => [
  {
    value: GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE,
    label: getEnumLocale(GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE),
  },
  {
    value: GC.SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE,
    label: getEnumLocale(GC.SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE),
  },
];

export const ediExporterContentFormatOptions = () => [
  {
    value: GC.EDI_EXPORTER_CONTENT_FORMAT_TYPE_SINGLE_LINE,
    label: getEnumLocale(GC.EDI_EXPORTER_CONTENT_FORMAT_TYPE_SINGLE_LINE),
  },
  {
    value: GC.EDI_EXPORTER_CONTENT_FORMAT_TYPE_MULTILINE,
    label: getEnumLocale(GC.EDI_EXPORTER_CONTENT_FORMAT_TYPE_MULTILINE),
  },
];

export const driverOnboardingIntegrationTypeOptions = [
  {
    label: 'Tenstreet',
    value: GC.DRIVER_ONBOARDING_INTEGRATION_TYPE_TENSTREET,
  },
];

export const preferredUsernameFormatOptions = () => [
  {
    value: GC.PREFERRED_USER_NAME_TYPE_EMAIL,
    label: getEnumLocale(GC.PREFERRED_USER_NAME_TYPE_EMAIL),
  },
  {
    value: GC.PREFERRED_USER_NAME_TYPE_FIRST_AND_LAST_NAME,
    label: getEnumLocale(GC.PREFERRED_USER_NAME_TYPE_FIRST_AND_LAST_NAME),
  },
  {
    value: GC.PREFERRED_USER_NAME_TYPE_FIRST_NAME_INITIAL_AND_LAST_NAME,
    label: getEnumLocale(GC.PREFERRED_USER_NAME_TYPE_FIRST_NAME_INITIAL_AND_LAST_NAME),
  },
  {
    value: GC.PREFERRED_USER_NAME_TYPE_FIRST_NAME_AND_LAST_NAME_INITIAL,
    label: getEnumLocale(GC.PREFERRED_USER_NAME_TYPE_FIRST_NAME_AND_LAST_NAME_INITIAL),
  },
];

export const payToTypeOptions = [
  {
    value: GC.PAYMENT_TERM_OPTION_FACTORING_COMPANY,
    label: getWindowLocale('titles:factoring-company', 'Factoring Company'),
  },
  {
    value: GC.PAYMENT_TERM_OPTION_INTERNAL_FACTORING,
    label: getWindowLocale('titles:internal-factoring', 'Internal Factoring'),
  },
  {
    value: GC.PAYMENT_TERM_OPTION_DIRECT_PAY,
    label: getWindowLocale('titles:direct-pay', 'Direct Pay'),
  },
];

export const documentSendingTypeOptions = [
  {
    value: GC.DOCUMENT_SENDING_TYPE_LINK,
    label: getEnumLocale(GC.DOCUMENT_SENDING_TYPE_LINK),
  },
  {
    value: GC.DOCUMENT_SENDING_TYPE_ATTACHMENT,
    label: getEnumLocale(GC.DOCUMENT_SENDING_TYPE_ATTACHMENT),
  },
  {
    value: GC.DOCUMENT_SENDING_TYPE_ATTACHMENT_AND_LINK,
    label: getEnumLocale(GC.DOCUMENT_SENDING_TYPE_ATTACHMENT_AND_LINK),
  },
];

export const documentSendingFromOptions = [
  {
    value: GC.DOCUMENT_SENDING_FROM_PRINT_MASTER_INVOICE,
    label: getEnumLocale(GC.DOCUMENT_SENDING_FROM_PRINT_MASTER_INVOICE),
  },
];

export const vendorPayrollAccessorialPayForUnitTypeOptions = [
  {
    value: GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_NONE,
    label: getEnumLocale(GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_NONE),
  },
  {
    value: GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_EVERY_TRUCK,
    label: getEnumLocale(GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_EVERY_TRUCK),
  },
  {
    value: GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_TRUCKS,
    label: getEnumLocale(GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_TRUCKS),
  },
  {
    value: GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_EVERY_DRIVER,
    label: getEnumLocale(GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_EVERY_DRIVER),
  },
  {
    value: GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS,
    label: getEnumLocale(GC.VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS),
  },
];

export const crossBorderIntegrationTypeOptions = [
  {
    value: GC.CROSS_BORDER_INTEGRATION_TYPE_BORDER_CONNECT,
    label: getWindowLocale('titles:border-connect', 'Border Connect'),
  },
];

export const manifestTypeOptions = [
  {
    value: GC.ACI,
    label: getWindowLocale('titles:aci', 'ACI'),
  },
  {
    value: GC.ACE,
    label: getWindowLocale('titles:ace', 'ACE'),
  },
];

export const priorityTypeOptions = [
  GC.EMPTY_OPTION_OBJECT,
  {
    value: GC.PRIORITY_TYPE_LOW,
    label: getWindowLocale('titles:low', 'Low'),
  },
  {
    value: GC.PRIORITY_TYPE_MEDIUM,
    label: getWindowLocale('titles:medium', 'Medium'),
  },
  {
    value: GC.PRIORITY_TYPE_HIGH,
    label: getWindowLocale('titles:high', 'High'),
  },
];

export const fleetServiceIssueStatusOptions = [
  {
    value: GC.STATUS_CREATED,
    label: getWindowLocale('titles:created', 'Created'),
  },
  {
    value: GC.STATUS_IN_PROGRESS,
    label: getWindowLocale('titles:in-progress', 'In Progress'),
  },
  {
    value: GC.STATUS_RESOLVED,
    label: getWindowLocale('titles:resolved', 'Resolved'),
  },
];

export const workOrderStatusOptions = [
  {
    value: GC.STATUS_OPENED,
    label: getWindowLocale('titles:opened', 'Opened'),
  },
  {
    value: GC.STATUS_IN_PROGRESS,
    label: getWindowLocale('titles:in-progress', 'In Progress'),
  },
  {
    value: GC.STATUS_COMPLETED,
    label: getWindowLocale('titles:completed', 'Completed'),
  },
];

export const idleTimeTypeOptions = [
  {
    value: GC.IDLE_TIME_TYPE_LESS_THEN_A_DAY,
    label: getEnumLocale(GC.IDLE_TIME_TYPE_LESS_THEN_A_DAY),
  },
  {
    value: GC.IDLE_TIME_TYPE_FROM_ONE_TO_THREE_DAYS,
    label: getEnumLocale(GC.IDLE_TIME_TYPE_FROM_ONE_TO_THREE_DAYS),
  },
  {
    value: GC.IDLE_TIME_TYPE_FROM_FOUR_TO_SIX_DAYS,
    label: getEnumLocale(GC.IDLE_TIME_TYPE_FROM_FOUR_TO_SIX_DAYS),
  },
  {
    value: GC.IDLE_TIME_TYPE_FROM_SEVEN_DAYS,
    label: getEnumLocale(GC.IDLE_TIME_TYPE_FROM_SEVEN_DAYS),
  },
];
