import * as R from 'ramda';
import React from 'react';
import { compose, withState, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// forms
import { FormGroupWrapper } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, IconWrapper } from '../../../ui';
// feature reference
import { enhanceReferences } from '../hocs';
//////////////////////////////////////////////////

const enhance = compose(
  enhanceReferences,
  withState('isOpened', 'toggleOpened', true),
  withHandlers({
    onToggleSection: (props: Object) => () => {
      props.toggleOpened(R.not(props.isOpened));
    },
  }),
);

const Header = () => (
  <Flex justifyContent='left'>
    <Box p='10px 20px' width={320}>
      {G.getWindowLocale('titles:value', 'Value')}
    </Box>
    <Box p='10px 20px'>
      {G.getWindowLocale('titles:name', 'Name')}
    </Box>
  </Flex>
);

const renderReferences = (props: Array) => {
  const { references, handleRemoveItem, handleOpenReference } = props;

  const darkBlue = G.getTheme('colors.dark.blue');

  return R.values(references).map((item: Object, i: number) => (
    <Flex key={i} justifyContent='left'>
      <Flex width={320} p='10px 20px'>
        <IconWrapper
          pr={15}
          cursor='pointer'
          onClick={() => handleRemoveItem(item.guid)}
        >
          {I.trash(darkBlue)}
        </IconWrapper>
        <IconWrapper
          pr={15}
          cursor='pointer'
          onClick={() => handleOpenReference(item)}
        >
          {I.pencil(darkBlue)}
        </IconWrapper>
        <TextComponent
          width={200}
          display='block'
          title={item.value}
          withEllipsis={true}
        >
          {item.value}
        </TextComponent>
      </Flex>
      <Box p='10px 20px'>
        <TextComponent
          width={360}
          display='block'
          title={item.value}
          withEllipsis={true}
        >
          {item.name}
        </TextComponent>
      </Box>
    </Flex>
  ));
};

export const ReferencesComponent = (props: Object) => (
  <FormGroupWrapper isOpened={props.isOpened}>
    <FormGroupTitleComponent
      withArrowDown={true}
      isOpened={props.isOpened}
      onClickLabel={props.handleOpenReference}
      onToggleFormGroup={props.onToggleSection}
      endIcon={I.plusRound(G.getTheme('icons.iconColorWhite'))}
      text={G.getWindowLocale('titles:references', 'References')}
    />
    <Box bg='white'>
      <Header />
      {renderReferences(props)}
    </Box>
  </FormGroupWrapper>
);

export default enhance(ReferencesComponent);
