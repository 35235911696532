import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers
import * as G from '../../helpers';
//////////////////////////////////////////////////

const store = ({ availableDriver }: Object) => availableDriver;

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => itemList,
);

const makeSelectTotalCount = () => createSelector(
  store,
  ({ activeList, totalCount, allDrivers }: Object) => G.ifElse(
    R.equals(activeList, 'allDrivers'),
    G.getPropFromObject('totalCount', allDrivers),
    totalCount,
  ),
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading, activeList, allDrivers }: Object) => G.ifElse(
    R.equals(activeList, 'allDrivers'),
    G.getPropFromObject('loading', allDrivers),
    loading,
  ),
);

const makeSelectTruckTypes = () => createSelector(
  store,
  ({ truckTypes }: Object) => truckTypes,
);

const makeSelectSearchRadius = () => createSelector(
  store,
  ({ searchRadius }: Object) => searchRadius,
);

const makeSelectLocationValue = () => createSelector(
  store,
  ({ locationValue }: Object) => locationValue,
);

const makeSelectDriverInitials = () => createSelector(
  store,
  ({ driverInitials }: Object) => driverInitials,
);

const makeSelectSearchDate = () => createSelector(
  store,
  ({ searchDate }: Object) => searchDate,
);

const makeSelectSearchLocation = () => createSelector(
  store,
  ({ searchLocation }: Object) => searchLocation,
);

const makeSelectPageVisited = () => createSelector(
  store,
  ({ pageVisited }: Object) => pageVisited,
);

const makeSelectActiveList = () => createSelector(
  store,
  ({ activeList }: Object) => activeList,
);

const makeSelectUiReportFields = () => createSelector(
  store,
  ({ uiReportFields }: Object) => uiReportFields,
);

const makeSelectShowUnassignedLoads = () => createSelector(
  store,
  ({ showUnassignedLoads }: Object) => showUnassignedLoads,
);

const makeSelectUnassignedLoads = () => createSelector(
  store,
  ({ unassignedLoads }: Object) => unassignedLoads,
);

const makeSelectTeamFilterValue = () => createSelector(
  store,
  ({ teamFilterValue }: Object) => teamFilterValue,
);

const makeSelectColorFilter = () => createSelector(
  store,
  ({ colorFilter }: Object) => colorFilter,
);

// all drivers
const makeSelectAllDriversList = () => createSelector(
  store,
  ({ allDrivers: { itemList } }: Object) => itemList,
);

const makeSelectAllDriversPagination = () => createSelector(
  store,
  ({ allDrivers: { pagination } }: Object) => pagination,
);

export {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectSearchDate,
  makeSelectTruckTypes,
  makeSelectActiveList,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectColorFilter,
  makeSelectSearchRadius,
  makeSelectLocationValue,
  makeSelectUiReportFields,
  makeSelectDriverInitials,
  makeSelectSearchLocation,
  makeSelectUnassignedLoads,
  makeSelectTeamFilterValue,
  makeSelectShowUnassignedLoads,
  // all drivers
  makeSelectAllDriversList,
  makeSelectAllDriversPagination,
};
