import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  compose,
  lifecycle,
  withProps,
  withState,
  withHandlers,
} from 'react-recompose';
// features
import { makeSelectAuthorities } from '../../features/permission/selectors';
// helpers/constants
import * as G from '../../helpers';
// hocs
import { withComponentDidUpdatePropCallback } from '../../hocs';
// ui
import { Box, Flex, AbsoluteBox } from '../../ui';
// component switcher
import { Input } from './ui';
//////////////////////////////////////////////////

const mapOptions = (options: Array, activeIndex: number, value: string) => {
  if (G.isNotNil(value)) {
    return options.map((option: Object) => (
      R.assoc('checked', R.equals(option.value, value), option)
    ));
  }

  const opts = options.map((option: Object, i: number) => (
    R.assoc(
      'checked',
      R.or(R.equals(i, activeIndex), R.equals(option.value, value)),
      // TODO: remove after testing
      // R.or(R.equals(i, R.or(activeIndex, 0)), R.equals(option.value, value)),
      option,
    )
  ));

  return opts;
};

const getActiveButtonOptions = (buttons: Array) => {
  const activeButton = R.find(R.propEq(true, 'checked'), buttons);

  if (G.isNilOrEmpty(activeButton)) return { left: 0, width: 0 };

  const prevWidth = R.compose(
    R.reduce((prev: number, button: Object) => R.add(prev, button.width), 0),
    R.slice(0, R.indexOf(activeButton, buttons)),
  )(buttons);

  return {
    left: prevWidth,
    width: R.prop('width', activeButton),
  };
};

const enhance = compose(
  withProps((props: Object) => {
    let selectedIndex = R.prop('selectedOptionIndex', props);

    let removedIndex = 0;

    const options = R.or(props.options, []).filter((option: Object, i: number) => {
      if (G.checkStringsContainsIfInput(option.permissions, props.authorities)) {
        if (R.equals(props.selectedOptionIndex, i)) selectedIndex = R.subtract(selectedIndex, removedIndex);

        return true;
      }

      if (R.equals(props.selectedOptionIndex, i)) selectedIndex = 0;

      removedIndex = R.inc(removedIndex);

      return false;
    });

    return {
      options,
      selectedOptionIndex: selectedIndex,
    };
  }),
  withState(
    'buttons',
    'setButtons',
    ({ value, options, selectedOptionIndex }: Object) => mapOptions(
      options,
      selectedOptionIndex,
      value,
    ),
  ),
  withState(
    'activeButton',
    'setActiveButton',
    ({ buttons }: Object) => getActiveButtonOptions(buttons),
  ),
  withHandlers({
    handleSelect: (props: Object) => (activeButton: Object) => {
      const { buttons, onSwitch, setButtons, setActiveButton } = props;

      if (activeButton.checked) return;

      const newArr = mapOptions(
        buttons,
        R.indexOf(activeButton, buttons),
      );

      setButtons(newArr);

      const newSelected = getActiveButtonOptions(newArr);

      setActiveButton(newSelected);

      onSwitch(activeButton.value);
    },
    handleChangeSelectedOptionIndex: (props: Object) => () => {
      const { value, options, setButtons, setActiveButton, selectedOptionIndex } = props;

      const buttons = mapOptions(
        options,
        selectedOptionIndex,
        value,
      );

      setButtons(buttons);

      setActiveButton(getActiveButtonOptions(buttons));
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'selectedOptionIndex',
    callbackName: 'handleChangeSelectedOptionIndex',
  }),
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      const { value, options, buttons, setButtons } = this.props;

      if (R.equals(options, prevProps.options)) return;

      const selectedOptionIndex = R.findIndex(R.propEq(true, 'checked'), buttons);

      setButtons(mapOptions(
        options,
        selectedOptionIndex,
        value,
      ));
    },
  }),
);

const InputWithWrapper = (props: Object) => {
  const { data, itemZIndex, handleSelect } = props;

  return (
    <Box
      cursor='pointer'
      textAlign='center'
      width={data.width}
      zIndex={R.or(itemZIndex, 1)}
      onClick={() => handleSelect(data)}
    >
      <Input
        type='radio'
        name='switcher'
        defaultChecked={data.checked}
      />
      {G.getWindowLocale(R.prop('loc', data), data.name)}
    </Box>
  );
};

const getBorderRadius = (version: number) => G.ifElse(R.equals(version, 2), 3, 10);

const switcherBgColorMap = {
  1: 'colors.light.green',
  2: 'colors.dark.blue',
  3: 'colors.dark.blue',
};

const Component = (props: Object) => {
  const { fontSize, textColor, activeButton, handleSelect, version = 1, itemZIndex, switchElementZIndex } = props;

  const borderRadius = getBorderRadius(version);
  const switcherBgColor = G.getTheme(switcherBgColorMap[version]);

  return (
    <Flex
      p='4px 0'
      height={20}
      mb={props.mb}
      mr={props.mr}
      ml={props.ml}
      overflow='hidden'
      width='max-content'
      position='relative'
      borderRadius={borderRadius}
      fontSize={R.or(fontSize, 11)}
      bg={G.getTheme('colors.light.darkGrey')}
      color={R.or(textColor, G.getTheme('colors.light.mainLight'))}
    >
      <AbsoluteBox
        top='0px'
        height={20}
        bg={switcherBgColor}
        left={activeButton.left}
        width={activeButton.width}
        borderRadius={borderRadius}
        zIndex={switchElementZIndex}
        transition='left .2s, width .2s'
      />
      {props.buttons.map((option: Object, i: number) => (
        <InputWithWrapper key={i} data={option} itemZIndex={itemZIndex} handleSelect={handleSelect} />
      ))}
    </Flex>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  authorities: makeSelectAuthorities(state),
}));

export const Switcher = connect(mapStateToProps)(enhance(Component));
