import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dispatch-planner
import * as H from './helpers';
//////////////////////////////////////////////////

const selectBuilderStore = (state: Object) => state.dispatchPlanner;
const selectCurrentBranchConfigs = (state: Object) => state.dispatchPlanner.currentBranchConfigs;
const selectCloList = (state: Object) => state.dispatchPlanner.cloList;
const selectCrossDockCloList = (state: Object) => state.dispatchPlanner.crossDockCloList;

const makeSelectBuilderStore = () => createSelector(
  selectBuilderStore,
  (state: Object) => ({ ...state }),
);

const makeSelectInitRouteGuid = () => createSelector(
  selectBuilderStore,
  ({ initRouteGuid }: Object) => initRouteGuid,
);

const makeSelectOpenedFromPage = () => createSelector(
  selectBuilderStore,
  ({ openedFromPage }: Object) => openedFromPage,
);

const makeSelectEditTemplateRoute = () => createSelector(
  selectBuilderStore,
  ({ editTemplateRoute }: Object) => editTemplateRoute,
);

const makeSelectCurrentRoute = () => createSelector(
  selectBuilderStore,
  ({ currentRoute }: Object) => currentRoute,
);

const makeSelectCurrentRouteGuid = () => createSelector(
  selectBuilderStore,
  ({ currentRoute }: Object) => G.getGuidFromObject(currentRoute),
);

const makeSelectBranchGuidForRequest = () => createSelector(
  selectBuilderStore,
  ({ branchGuidForRequest }: Object) => branchGuidForRequest,
);

const makeSelectCurrentBranchConfigs = () => createSelector(
  selectBuilderStore,
  ({ currentBranchConfigs }: Object) => currentBranchConfigs,
);

const makeSelectCloReport = () => createSelector(
  selectBuilderStore,
  ({ cloReport }: Object) => cloReport,
);

const makeSelectCrossDockCloReport = () => createSelector(
  selectBuilderStore,
  ({ crossDockCloReport }: Object) => crossDockCloReport,
);

const makeSelectTelReport = () => createSelector(
  selectBuilderStore,
  ({ telReport }: Object) => telReport,
);

const makeSelectInboundTelReport = () => createSelector(
  selectBuilderStore,
  ({ inboundTelReport }: Object) => inboundTelReport,
);

const makeSelectOutboundTelReport = () => createSelector(
  selectBuilderStore,
  ({ outboundTelReport }: Object) => outboundTelReport,
);

const makeSelectCloList = () => createSelector(
  selectBuilderStore,
  ({ cloList }: Object) => cloList,
);

const makeSelectCrossDockCloList = () => createSelector(
  selectBuilderStore,
  ({ crossDockCloList }: Object) => crossDockCloList,
);

const makeSelectTelList = () => createSelector(
  selectBuilderStore,
  ({ telList }: Object) => telList,
);

const makeSelectInboundTelList = () => createSelector(
  selectBuilderStore,
  ({ inboundTelList }: Object) => inboundTelList,
);

const makeSelectOutboundTelList = () => createSelector(
  selectBuilderStore,
  ({ outboundTelList }: Object) => outboundTelList,
);

const makeSelectCloListLoading = () => createSelector(
  selectBuilderStore,
  ({ cloListLoading }: Object) => cloListLoading,
);

const makeSelectCrossDockCloListLoading = () => createSelector(
  selectBuilderStore,
  ({ crossDockCloListLoading }: Object) => crossDockCloListLoading,
);

const makeSelectTelListLoading = () => createSelector(
  selectBuilderStore,
  ({ telListLoading }: Object) => telListLoading,
);

const makeSelectInboundTelListLoading = () => createSelector(
  selectBuilderStore,
  ({ inboundTelListLoading }: Object) => inboundTelListLoading,
);

const makeSelectOutboundTelListLoading = () => createSelector(
  selectBuilderStore,
  ({ outboundTelListLoading }: Object) => outboundTelListLoading,
);

const makeSelectCloPagination = () => createSelector(
  selectBuilderStore,
  ({ cloPagination }: Object) => cloPagination,
);

const makeSelectCrossDockCloPagination = () => createSelector(
  selectBuilderStore,
  ({ crossDockCloPagination }: Object) => crossDockCloPagination,
);

const makeSelectTelPagination = () => createSelector(
  selectBuilderStore,
  ({ telPagination }: Object) => telPagination,
);

const makeSelectInboundTelPagination = () => createSelector(
  selectBuilderStore,
  ({ inboundTelPagination }: Object) => inboundTelPagination,
);

const makeSelectOutboundTelPagination = () => createSelector(
  selectBuilderStore,
  ({ outboundTelPagination }: Object) => outboundTelPagination,
);

const makeSelectCloTotal = () => createSelector(
  selectBuilderStore,
  ({ cloTotal }: Object) => cloTotal,
);

const makeSelectCrossDockCloTotal = () => createSelector(
  selectBuilderStore,
  ({ crossDockCloTotal }: Object) => crossDockCloTotal,
);

const makeSelectTelTotal = () => createSelector(
  selectBuilderStore,
  ({ telTotal }: Object) => telTotal,
);

const makeSelectInboundTelTotal = () => createSelector(
  selectBuilderStore,
  ({ inboundTelTotal }: Object) => inboundTelTotal,
);

const makeSelectOutboundTelTotal = () => createSelector(
  selectBuilderStore,
  ({ outboundTelTotal }: Object) => outboundTelTotal,
);

const makeSelectFullScreenView = () => createSelector(
  selectBuilderStore,
  ({ fullScreenView }: Object) => fullScreenView,
);

const makeSelectCloFilterParams = () => createSelector(
  selectBuilderStore,
  ({ cloFilterParams }: Object) => cloFilterParams,
);

const makeSelectCrossDockCloFilterParams = () => createSelector(
  selectBuilderStore,
  ({ crossDockCloFilterParams }: Object) => crossDockCloFilterParams,
);

const makeSelectTelFilterParams = () => createSelector(
  selectBuilderStore,
  ({ telFilterParams }: Object) => telFilterParams,
);

const makeSelectDraftFilterParams = () => createSelector(
  selectBuilderStore,
  ({ draftFilterParams }: Object) => draftFilterParams,
);

const makeSelectCloTableTitleFilters = () => createSelector(
  selectBuilderStore,
  ({ cloTableTitleFilters }: Object) => cloTableTitleFilters,
);

const makeSelectTelTableTitleFilters = () => createSelector(
  selectBuilderStore,
  ({ telTableTitleFilters }: Object) => telTableTitleFilters,
);

const makeSelectInboundTelTableTitleFilters = () => createSelector(
  selectBuilderStore,
  ({ inboundTelTableTitleFilters }: Object) => inboundTelTableTitleFilters,
);

const makeSelectOutboundTelTableTitleFilters = () => createSelector(
  selectBuilderStore,
  ({ outboundTelTableTitleFilters }: Object) => outboundTelTableTitleFilters,
);

const makeSelectCrossDockCloTableTitleFilters = () => createSelector(
  selectBuilderStore,
  ({ crossDockCloTableTitleFilters }: Object) => crossDockCloTableTitleFilters,
);

const makeSelectCloTableTitleSortValues = () => createSelector(
  selectBuilderStore,
  ({ cloTitleSortValues }: Object) => cloTitleSortValues,
);

const makeSelectTelTableTitleSortValues = () => createSelector(
  selectBuilderStore,
  ({ telTitleSortValues }: Object) => telTitleSortValues,
);

const makeSelectInboundTelTableTitleSortValues = () => createSelector(
  selectBuilderStore,
  ({ inboundTelTitleSortValues }: Object) => inboundTelTitleSortValues,
);

const makeSelectOutboundTelTableTitleSortValues = () => createSelector(
  selectBuilderStore,
  ({ outboundTelTitleSortValues }: Object) => outboundTelTitleSortValues,
);

const makeSelectCrossDockCloTableTitleSortValues = () => createSelector(
  selectBuilderStore,
  ({ crossDockCloTitleSortValues }: Object) => crossDockCloTitleSortValues,
);

const makeSelectAvailableCloReports = () => createSelector(
  selectBuilderStore,
  ({ availableCloReports }: Object) => availableCloReports,
);

const makeSelectAvailableTelReports = () => createSelector(
  selectBuilderStore,
  ({ availableTelReports }: Object) => availableTelReports,
);

const makeSelectRouteTelConfigs = () => createSelector(
  selectCurrentBranchConfigs,
  (configs: Object) => G.getRouteTelConfigsForCreateClo(configs),
);

const makeSelectPrimaryReferenceAutogeneratedConfig = () => createSelector(
  selectCurrentBranchConfigs,
  (configs: Object) => G.getConfigValueFromStore(
    GC.TEL_PRIMARY_REFERENCE_AUTOGENERATED,
    configs,
  ),
);

const makeSelectRouteConfigs = () => createSelector(
  selectCurrentBranchConfigs,
  (configs: Object) => {
    const autoRouteName = G.getConfigValueFromStore(
      GC.GENERAL_ROUTE_NAME_AUTOGENERATED,
      configs,
    );
    return { autoRouteName };
  },
);


const makeSelectDraftRouteConfigs = () => createSelector(
  selectCurrentBranchConfigs,
  (configs: Object) => {
    const autoDraftRouteName = G.getConfigValueFromStore(
      GC.GENERAL_DRAFT_ROUTE_NAME_AUTOGENERATED,
      configs,
    );
    const draftRoutePrefix = G.getConfigValueFromStore(
      GC.GENERAL_DRAFT_ROUTE_NAME_PREFIX,
      configs,
    );
    const draftRouteSequence = G.getConfigValueFromStore(
      GC.GENERAL_DRAFT_ROUTE_NAME_SEQUENCE,
      configs,
    );
    return {
      draftRoutePrefix,
      autoDraftRouteName,
      draftRouteSequence,
    };
  },
);

const makeSelectDefaultUomFields = () => createSelector(
  selectCurrentBranchConfigs,
  (configStore: Object) => {
    let defaultValue = {};
    const uomSystem = G.getConfigValueFromStore(
      GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
      configStore,
    );
    if (R.and(G.isNotNil(uomSystem), G.isNotEmpty(uomSystem))) {
      defaultValue = GC.defaultSystemUoms[uomSystem];
    }
    return defaultValue;
  },
);

const makeSelectOptionsForSelect = () => createSelector(
  selectCurrentBranchConfigs,
  (configs: Object) => {
    const serviceType = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      configs,
      GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
      true,
    );
    const transportationMode = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      configs,
      GC.GENERAL_MODE_TRANSPORTATION,
      true,
    );
    return {
      serviceType,
      transportationMode,
    };
  },
);

const makeSelectCloByGuid = (guid: string) => createSelector(
  selectCloList,
  (state: Array = []) => R.find(R.propEq(guid, GC.FIELD_GUID), state),
);

const makeSelectCrossDockCloByGuid = (guid: string) => createSelector(
  selectCrossDockCloList,
  (state: Array = []) => R.find(R.propEq(guid, GC.FIELD_GUID), state),
);

const makeSelectItemsForDrop = () => createSelector(
  selectBuilderStore,
  ({ currentRouteItems }: Object) => R.filter(
    (item: Object) => R.and(
      R.propEq(false, 'selected', item),
      R.not(G.isLoadTypeClo(item)),
    ),
    R.values(currentRouteItems),
  ),
);

const makeSelectItemsForPickup = () => createSelector(
  selectBuilderStore,
  ({ currentRouteItems }: Object) => R.filter(
    (item: Object) => R.and(
      R.propEq(true, 'selected', item),
      R.not(G.isLoadTypeClo(item)),
    ),
    R.values(currentRouteItems),
  ),
);

const makeSelectCurrentRouteItems = () => createSelector(
  selectBuilderStore,
  ({ currentRouteItems }: Object) => currentRouteItems,
);

const makeSelectCurrentRouteContainers = () => createSelector(
  selectBuilderStore,
  ({ currentRouteContainers }: Object) => currentRouteContainers,
);

const makeSelectEventsInfo = () => createSelector(
  selectBuilderStore,
  ({ currentRoute }: Object) => {
    const tels = R.filter(
      (tel: Object) => G.isNotNilAndNotEmpty(tel[GC.FIELD_LOAD_STOPS]),
      R.sortBy(
        R.prop('order'),
        R.values(currentRoute.tels),
      ),
    );
    if (G.isNilOrEmpty(tels)) {
      return {
        firstEvent: null,
        lastEvent: null,
        total: 0,
      };
    }
    return {
      firstEvent: R.head(R.sortBy(
        R.prop('telEventIndex'),
        R.values(R.head(tels).events),
      )),
      lastEvent: R.last(R.sortBy(
        R.prop('telEventIndex'),
        R.values(R.last(tels).events),
      )),
      total: R.reduce(
        (arr: Array = [], tel: Object) => R.concat(arr, R.values(tel[GC.FIELD_LOAD_STOPS])),
        [],
        tels,
      ).length,
    };
  },
);

const makeSelectTotalDistance = () => createSelector(
  selectBuilderStore,
  ({ currentRoute }: Object) => H.getRouteTotalDistance(currentRoute.tels, 'telDistanceToNextStop'),
);

const makeSelectTotalWeight = () => createSelector(
  selectBuilderStore,
  ({ currentRouteItems }: Object) => G.calcItemsTotalWeightWithoutQty(R.values(currentRouteItems)),
);

const makeSelectTotalQuantity = () => createSelector(
  selectBuilderStore,
  ({ currentRouteItems }: Object) => G.calculateTotalQuantity(R.values(currentRouteItems)),
);

const makeSelectBottomTableMode = () => createSelector(
  selectBuilderStore,
  ({ bottomTableMode }: Object) => bottomTableMode,
);

const makeSelectDraftList = () => createSelector(
  selectBuilderStore,
  ({ draftList }: Object) => draftList,
);

const makeSelectDraftPagination = () => createSelector(
  selectBuilderStore,
  ({ draftsPagination }: Object) => draftsPagination,
);

const makeSelectDraftsTotal = () => createSelector(
  selectBuilderStore,
  ({ draftsTotal }: Object) => draftsTotal,
);

const makeSelectDraftListLoading = () => createSelector(
  selectBuilderStore,
  ({ draftListLoading }: Object) => draftListLoading,
);

const makeSelectRouteErrors = () => createSelector(
  selectBuilderStore,
  ({ routeErrors }: Object) => routeErrors,
);

const makeSelectRouteFinancial = () => createSelector(
  selectBuilderStore,
  ({ currentRoute }: Object) => {
    const closTotal = G.toFixed(G.calculateLoadsRatesTotalFromRates(R.values(currentRoute.clos)));
    const telsTotal = G.toFixed(G.calculateTelsRatesTotalFromCharges(R.values(currentRoute.tels)));
    const path = [GC.SYSTEM_OBJECT_RATE, GC.SYSTEM_OBJECT_NORMALIZED_TOTAL, GC.FIELD_CURRENCY];
    const normalizedCurrency = R.compose(
      R.path(path),
      R.find((tel: Object) => G.isNotNil(R.path(path, tel))),
      R.values,
    )(currentRoute.tels);
    const total = G.toFixed(R.subtract(closTotal, telsTotal));

    return {
      total,
      clo: closTotal,
      tel: telsTotal,
      currency: R.or(normalizedCurrency, GC.DEFAULT_UI_CURRENCY),
    };
  },
);

const locationFieldsToPick = [
  GC.FIELD_TEMPLATE_ID,
  GC.FIELD_LOCATION_NAME,
  GC.FIELD_CITY,
  GC.FIELD_STATE,
  GC.FIELD_ADDRESS_1,
  GC.FIELD_ZIP,
];

const makeSelectExistedTerminalOptions = () => createSelector(
  selectBuilderStore,
  ({ currentRoute }: Object) => R.compose(
    R.map((event: Object) => ({
      label: G.concatLocationFields(R.prop('location', event), locationFieldsToPick),
      value: R.prop('stopId', event),
    })),
    R.uniqBy(R.prop('stopId')),
    R.reduce((acc: Array, item: Array) => R.concat(acc, item), []),
    R.map((tel: Object) => R.compose(
      R.uniqBy(R.prop('stopId')),
      R.filter(R.propEq(GC.STOP_TYPE_TERMINAL, 'stopType')),
      R.values(),
      R.prop(GC.FIELD_LOAD_STOPS),
    )(tel)),
    R.values(),
    R.prop('tels'),
  )(currentRoute),
);

const makeSelectSelectedTels = () => createSelector(
  selectBuilderStore,
  ({ selectedTels }: Object) => selectedTels,
);

const makeSelectCrossDockLocationList = () => createSelector(
  selectBuilderStore,
  ({ crossDockLocationList }: Object) => crossDockLocationList,
);

const makeSelectInboundTerminals = () => createSelector(
  selectBuilderStore,
  ({ inboundTerminals }: Object) => inboundTerminals,
);

const makeSelectOutboundTerminals = () => createSelector(
  selectBuilderStore,
  ({ outboundTerminals }: Object) => outboundTerminals,
);

const makeSelectPlannerMode = () => createSelector(
  selectBuilderStore,
  ({ plannerMode }: Object) => plannerMode,
);

const makeSelectRouteChanged = () => createSelector(
  selectBuilderStore,
  ({ currentRoute, currentRouteInitialState }: Object) => G.notEquals(currentRoute, currentRouteInitialState),
);

const makeSelectCurrentRouteInitialState = () => createSelector(
  selectBuilderStore,
  ({ currentRouteInitialState }: Object) => currentRouteInitialState,
);

export {
  makeSelectCloList,
  makeSelectTelList,
  makeSelectCloTotal,
  makeSelectTelTotal,
  makeSelectCloReport,
  makeSelectTelReport,
  makeSelectCloByGuid,
  makeSelectDraftList,
  makeSelectEventsInfo,
  makeSelectTotalWeight,
  makeSelectDraftsTotal,
  makeSelectRouteErrors,
  makeSelectPlannerMode,
  makeSelectRouteConfigs,
  makeSelectBuilderStore,
  makeSelectCurrentRoute,
  makeSelectItemsForDrop,
  makeSelectSelectedTels,
  makeSelectRouteChanged,
  makeSelectTotalDistance,
  makeSelectTotalQuantity,
  makeSelectCloPagination,
  makeSelectTelPagination,
  makeSelectInitRouteGuid,
  makeSelectOpenedFromPage,
  makeSelectItemsForPickup,
  makeSelectCloListLoading,
  makeSelectTelListLoading,
  makeSelectRouteFinancial,
  makeSelectFullScreenView,
  makeSelectInboundTelList,
  makeSelectRouteTelConfigs,
  makeSelectCloFilterParams,
  makeSelectTelFilterParams,
  makeSelectBottomTableMode,
  makeSelectDraftPagination,
  makeSelectOutboundTelList,
  makeSelectInboundTelTotal,
  makeSelectDefaultUomFields,
  makeSelectDraftListLoading,
  makeSelectOptionsForSelect,
  makeSelectInboundTelReport,
  makeSelectOutboundTelTotal,
  makeSelectInboundTerminals,
  makeSelectCurrentRouteGuid,
  makeSelectEditTemplateRoute,
  makeSelectDraftFilterParams,
  makeSelectDraftRouteConfigs,
  makeSelectCurrentRouteItems,
  makeSelectOutboundTelReport,
  makeSelectOutboundTerminals,
  makeSelectAvailableCloReports,
  makeSelectAvailableTelReports,
  makeSelectCloTableTitleFilters,
  makeSelectTelTableTitleFilters,
  makeSelectBranchGuidForRequest,
  makeSelectCurrentBranchConfigs,
  makeSelectInboundTelPagination,
  makeSelectInboundTelListLoading,
  makeSelectCrossDockLocationList,
  makeSelectOutboundTelPagination,
  makeSelectExistedTerminalOptions,
  makeSelectOutboundTelListLoading,
  makeSelectCurrentRouteContainers,
  makeSelectCloTableTitleSortValues,
  makeSelectTelTableTitleSortValues,
  makeSelectCurrentRouteInitialState,
  makeSelectInboundTelTableTitleFilters,
  makeSelectOutboundTelTableTitleFilters,
  makeSelectInboundTelTableTitleSortValues,
  makeSelectOutboundTelTableTitleSortValues,
  makeSelectPrimaryReferenceAutogeneratedConfig,
  // cross dock clos
  makeSelectCrossDockCloList,
  makeSelectCrossDockCloTotal,
  makeSelectCrossDockCloByGuid,
  makeSelectCrossDockCloReport,
  makeSelectCrossDockCloPagination,
  makeSelectCrossDockCloListLoading,
  makeSelectCrossDockCloFilterParams,
  makeSelectCrossDockCloTableTitleFilters,
  makeSelectCrossDockCloTableTitleSortValues,
};
