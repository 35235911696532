import { useState, useEffect } from 'react';
//////////////////////////////////////////////////

export const useFetching = (filePath: string, onError: Function, responseType: string = 'arraybuffer') => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!filePath) {
      setError('No file path provided.');
      setIsLoading(false);

      return;
    }

    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        const response = await fetch(filePath, { signal: abortController.signal });

        if (!response.ok) {
          throw new Error(`Fetch error: ${response.status} ${response.statusText}`);
        }

        const result = responseType === 'arraybuffer' ? await response.arrayBuffer() : await response.text();

        setData(result);
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError(err.message);

          if (onError) onError(err);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => abortController.abort();
  }, [filePath, responseType, onError]);

  return { data, error, isLoading };
};
