import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useCallback } from 'react';
import { createStructuredSelector } from 'reselect';
// components
import { TextComponent } from '../../../components/text';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import { makeSelectBranchesTree } from '../../branch/selectors';
import { makeSelectPinnedBranches } from '../../profile/selectors';
import { makeSelectAppVersionRelease, makeSelectAppReleaseByExternalUrl } from '../../app-release/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { getCurrentMenuItem } from '../../../helpers/menu';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature menus
import SidebarItemComponent from './sidebar-item';
import { toggleSubmenus, addItemToNavRequest } from '../actions';
import {
  makeSelectSidebar,
  makeSelectNavigation,
  makeSelectSubmenusStatus,
} from '../selectors';
import {
  Logo,
  HrDivider,
  LgMenuWrapper,
  ReleaseVersionText,
  ReleaseVersionLink,
  BranchDropDownWrapper,
} from '../ui';
//////////////////////////////////////////////////

const LatestVersionRelease = ({ color, latestRelease }: Object) => (
  <Box p={10} color={color}>
    {
      latestRelease &&
      <ReleaseVersionLink
        target='_blank'
        withHover={true}
        href={latestRelease.url}
      >
        {G.getWindowLocale('titles:version', 'Version')} {latestRelease.version}
      </ReleaseVersionLink>
    }
  </Box>
);


const VersionRelease = ({ appRelease, iconsColor, handleOpenVersionModal }: Object) => {
  if (G.isNilOrEmpty(appRelease)) {
    return (
      <Flex height={32} color={iconsColor} alignItems='center' justifyContent='center'>
        <AuthWrapper notHas={[PC.ROLE_LIUBANCHYK_SUPER_ADMIN]}>
          <ReleaseVersionText withHover={false} colorHover={false}>
            {G.getWindowLocale('titles:version', 'Version')} 1.0.0
          </ReleaseVersionText>
        </AuthWrapper>
        <AuthWrapper has={[PC.ROLE_LIUBANCHYK_SUPER_ADMIN]}>
          <ReleaseVersionText
            withHover={true}
            colorHover={true}
            onClick={handleOpenVersionModal}
          >
            {G.getWindowLocale('titles:version', 'Version')} 1.0.0
          </ReleaseVersionText>
        </AuthWrapper>
      </Flex>
    );
  }

  return (
    <Box color={iconsColor}>
      <ReleaseVersionText
        p={10}
        withHover={true}
        colorHover={true}
        onClick={handleOpenVersionModal}
      >
        {G.getWindowLocale('titles:version', 'Version')} {appRelease}
      </ReleaseVersionText>
    </Box>
  );
};

const getPinnedOptions = (tree: Object, pinnedList: Array) => {
  let branches = [];
  const collect = (branch: Object) => {
    if (G.notEquals(pinnedList.indexOf(branch.guid), -1)) {
      branches = R.append({ guid: branch.guid, [GC.FIELD_BRANCH_NAME]: branch[GC.FIELD_BRANCH_NAME] }, branches);
    }

    if (G.isNotNilAndNotEmpty(branch.children)) {
      R.forEach((child: Object) => collect(child), branch.children);
    }
  };
  collect(tree);

  return branches;
};

const entOptions = (props: Object) => {
  const {
    branches,
    branchTree,
    pinnedBranches,
    handleOpenEntTree,
    handleSwitchBranch,
  } = props;

  const pinnedOptions = getPinnedOptions(branchTree, pinnedBranches);

  return R.compose(
    R.prepend({
      label: 'wordWide',
      text: branches[GC.FIELD_BRANCH_NAME],
      action: () => handleSwitchBranch(branches.guid),
    }),
    R.append({
      label: 'globalSearch',
      action: handleOpenEntTree,
      text: G.getWindowLocale('titles:browse-all', 'Browse all...'),
    }),
    R.map((branch: Object) => ({
      label: 'company',
      text: branch[GC.FIELD_BRANCH_NAME],
      action: () => handleSwitchBranch(branch.guid),
    })),
  )(pinnedOptions);
};

const BranchDropDown = (props: Object) => (
  <BranchDropDownWrapper color={props.iconsColor}>
    {
      entOptions(R.omit(['iconsColor'], props)).map((option: Object, i: number) => (
        <Flex key={i} onClick={option.action}>
          {
            G.isNotNilAndNotEmpty(option.label)
            && <Box mx='4px'>{I[option.label](props.iconsColor)}</Box>
          }
          <TextComponent
            display='block'
            maxWidth='100%'
            title={option.text}
            withEllipsis={true}
          >
            {option.text}
          </TextComponent>
        </Flex>
      ))
    }
  </BranchDropDownWrapper>
);

export const SidebarComponent = (props: Object) => {
  const {
    sidebar,
    styling,
    branches,
    openModal,
    appRelease,
    navigation,
    iconsColor,
    branchTree,
    isMenuOpen,
    latestRelease,
    subMenusStatus,
    pinnedBranches,
    toggleSubmenus,
    handleOpenEntTree,
    handleSwitchBranch,
    handleClickNavItem,
    addItemToNavRequest,
  } = props;

  const handleClickPlusIcon = useCallback((item: Object) => addItemToNavRequest(item), []);

  const sidebarIconColor = R.pathOr(G.getTheme('colors.light.darkRed'), ['textColor'], styling);

  const branchDropDownProps = {
    branches,
    branchTree,
    pinnedBranches,
    handleOpenEntTree,
    handleSwitchBranch,
    iconsColor: sidebarIconColor,
  };

  const subItemsProps = {
    styling,
    navigation,
    subMenusStatus,
    toggleSubmenus,
    handleClickNavItem,
    handleClickPlusIcon,
    navItems: navigation,
    iconsColor: sidebarIconColor,
    currentItem: getCurrentMenuItem(),
  };

  return (
    <LgMenuWrapper isMenuOpen={isMenuOpen} bg={styling.backgroundColor}>
      <Flex p={15} justifyContent='center' bg={R.or(styling.logoBgColor, 'transparent')}>
        <Logo
          alt='logo'
          maxHeight='70px'
          src={R.or(styling.logoUrl, G.getNewLogoLink())}
        />
      </Flex>
      <AuthWrapper has={[PC.BRANCH_WRITE]}>
        <BranchDropDown {...branchDropDownProps} />
      </AuthWrapper>
      <HrDivider m='8px' />
      <Box overflowY='auto' height='calc(100vh - 250px)'>
        {
          G.isNotEmpty(sidebar)
          && sidebar.map((item: Object, i: number) => <SidebarItemComponent {...subItemsProps} key={i} item={item} />)
        }
      </Box>
      {/* TODO: Remove after testing */}
      {/* <VersionRelease {...appReleaseProps} /> */}
      <LatestVersionRelease color={iconsColor} latestRelease={latestRelease} />
    </LgMenuWrapper>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  sidebar: makeSelectSidebar(state),
  navigation: makeSelectNavigation(state),
  branchTree: makeSelectBranchesTree(state),
  appRelease: makeSelectAppVersionRelease(state),
  subMenusStatus: makeSelectSubmenusStatus(state),
  pinnedBranches: makeSelectPinnedBranches(state),
  latestRelease: makeSelectAppReleaseByExternalUrl(state),
}));

export default connect(mapStateToProps, {
  toggleSubmenus,
  addItemToNavRequest,
})(SidebarComponent);
