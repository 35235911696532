import * as R from 'ramda';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
// helpers/constants
import * as GC from '../../constants';
//////////////////////////////////////////////////

const selectRolesStore = (state: Object) => state.roles;

const makeSelectTotalCount = () => createSelector(
  selectRolesStore,
  (state: Object) => R.path([state.activeList], state.totalCount),
);

const makeSelectUsedReport = () => createSelector(
  selectRolesStore,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectRoleList = () => createSelector(
  selectRolesStore,
  ({ roleList }: Object) => roleList,
);

const makeSelectRoleAvailableList = () => createSelector(
  selectRolesStore,
  ({ roleAvailableList }: Object) => roleAvailableList,
);

const makeSelectDriverRoleAvailableList = () => createSelector(
  selectRolesStore,
  R.compose(
    R.filter(R.propEq(GC.USER_ROLE_TYPE_DRIVER, GC.FIELD_TYPE)),
    R.pathOr([], ['roleAvailableList'])
  ),
);

const makeSelectPermissionsGroupsList = () => createSelector(
  selectRolesStore,
  ({ permissionsGroupsList }: Object) => permissionsGroupsList,
);

const makeSelectRolePermissionsGroupsList = () => createSelector(
  selectRolesStore,
  ({ editRoleUserPermissionGroupList }: Object) => editRoleUserPermissionGroupList,
);

const makeSelectSelectedPermissionsGroupUnnormalized = () => createSelector(
  selectRolesStore,
  ({ permissionsGroupsListUnnormalized }: Object) => permissionsGroupsListUnnormalized,
);

const makeSelectEditRoleInitialValues = () => createSelector(
  selectRolesStore,
  ({ editRoleInitialValues }: Object) => editRoleInitialValues,
);

const makeSelectPermissionsList = () => createSelector(
  selectRolesStore,
  ({ permissionsList }: Object) => permissionsList,
);
const makeSelectRoleType = () => createSelector(
  selectRolesStore,
  ({ roleType }: Object) => roleType,
);

const makeSelectRoleSelectedDocumentTypes = () => createSelector(
  selectRolesStore,
  ({ roleSelectedDocumentTypes }: Object) => roleSelectedDocumentTypes,
);

const makeSelectRoleListLoading = () => createSelector(
  selectRolesStore,
  ({ loading }: Object) => loading,
);

const makeSelectActiveList = () => createSelector(
  selectRolesStore,
  (state: Object) => state.activeList,
);

const makeSelectRoleListChoosed = () => createSelector(
  selectRolesStore,
  (state: Object) => R.path([state.activeList], state),
);

const makeSelectRoleByGuid = createCachedSelector(
  selectRolesStore,
  (state: Object, guid: string) => guid,
  (rolesState: Object, guid: string) => R.path(['roleList', guid], rolesState),
)(
  (state: Object, guid: string) => guid,
);

const makeSelectDefaultPermissions = () => createSelector(
  selectRolesStore,
  ({ defaultPermissions }: Object) => defaultPermissions,
);

const makeSelectPermissions = () => createSelector(
  selectRolesStore,
  ({ permissions }: Object) => permissions,
);


export {
  selectRolesStore,
  makeSelectRoleList,
  makeSelectRoleType,
  makeSelectUsedReport,
  makeSelectRoleByGuid,
  makeSelectTotalCount,
  makeSelectActiveList,
  makeSelectPermissions,
  makeSelectRoleListChoosed,
  makeSelectRoleListLoading,
  makeSelectPermissionsList,
  makeSelectRoleAvailableList,
  makeSelectDefaultPermissions,
  makeSelectPermissionsGroupsList,
  makeSelectEditRoleInitialValues,
  makeSelectDriverRoleAvailableList,
  makeSelectRoleSelectedDocumentTypes,
  makeSelectRolePermissionsGroupsList,
  makeSelectSelectedPermissionsGroupUnnormalized,
};
