import styled, { css } from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const TableHeadWrapper = styled.thead`
  top: -1px;
  position: sticky;
`;

export const requiredClass = css`
  &::after {
    content: '*';
    color: ${G.getTheme('colors.light.mainRed')};
  }
`;

export const TableHeaderCellWrapper = styled.th`
  width: 130px;
  padding: 0 5px;
  border: 1px solid;
  border-color: ${() => G.getTheme('colors.lightGrey')};
  background-color: ${() => G.getTheme('colors.whiteSmoke')};
`;

export const TableCellWrapper = styled.td`
  height: 22px !important;
  transition: none !important;
  &.calculated {
    width: 130px !important;
    color: ${() => G.getTheme('colors.white')} !important;
    background: ${() => G.getTheme('colors.lightGreen')} !important;
    & > input {
      color: ${() => G.getTheme('colors.white')};
    }
  }
  & > span {
    margin: 0 auto;
    width: 130px !important;
    padding: 0 5px !important;
    font-size: 14px !important;
    overflow: hidden !important;
    text-align: center !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    border: 2px solid transparent !important;
  }
  &.error {
    width: 130px !important;
    color: ${() => G.getTheme('colors.white')} !important;
    background: ${() => G.getTheme('colors.dark.mainRed')} !important;
    & > input {
      color: ${() => G.getTheme('colors.white')};
    }
  }
  & > input {
    outline: none;
    width: 130px !important;
    height: 100% !important;
    padding: 0 5px !important;
    font-size: 14px !important;
    text-align: center !important;
    border: 1px double ${() => G.getTheme('colors.curiosBlue')} !important;
  }
`;
