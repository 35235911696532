import React from 'react';
import * as R from 'ramda';
import { Field } from 'redux-form';
import MaskedInput from 'react-text-mask';
import styled, { css } from 'styled-components';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { CountryDropdown } from 'react-country-region-selector';
import { compose, withState, lifecycle, withHandlers } from 'react-recompose';
import {
  left,
  space,
  width,
  zIndex,
  height,
  system,
  bottom,
  overflow,
  fontSize,
  minWidth,
  minHeight,
  borderRadius,
} from 'styled-system';
// components
import LabelComponent from '../components/label';
// forms
import { Switcher } from '../components/switcher';
import { DatePickerMui } from '../components/datepicker-mui';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
import { PHONE_COUNTRIES } from '../helpers/options';
// icons
import * as I from '../svgs';
// ui
import { Box, RelativeBox, ReactSelect, PlacesAutocomplete } from '../ui';
// forms
import MultiEmailInput from './formik/email';
import { renderBorderColor } from './helpers';
import AfterAction from './components/after-actions';
import { Toggle, DatePicker, CalendarFormGroup, CalendarMuiFormGroup } from './restyled';
//////////////////////////////////////////////////

const isInfoPresent = (info: any) => G.isAllTrue(
  G.isNotNilAndNotEmpty(info),
  G.notEquals(info, GC.THREE_DOTS_STRING),
);

export const Form = styled.form`
  ${space}
  ${width}
  ${height}
  ${overflow}
  ${minHeight}

  display: flex;
  position: relative;

  z-index: ${({ zIndex }: Object) => zIndex || 12};
  align-items: ${({ alignItems }: Object) => alignItems};
  flex-direction: ${({ direction }: Object) => direction || 'column'};
`;

Form.defaultProps = {
  minHeight: 'calc(100% - 60px)',
};

export const PureStyledForm = styled.form`
  ${space}
  ${width}
  ${height}
  ${overflow}
  ${minWidth}
  ${minHeight}
`;

export const LeftLabelsWrapper = styled.aside`
  ${left}
  ${width}

  z-index: -1;
  min-height: 100%;
  position: absolute;
  transition: left .2s linear;
  background-color: ${() => G.getTheme('forms.labelsWrapper.bgColor')};
`;

LeftLabelsWrapper.defaultProps = {
  width: 320,
  left: '1px',
};

export const FormGroupWrapper = styled.section`
  ${space}

  width: 100%;

  z-index: ${({ zi }: Object) => zi};
  display: ${({ display }: Object) => display};
  position: ${({ position }: Object) => position || 'static'};
  flex-direction: ${({ flexDirection }: Object) => flexDirection};
  height: ${({ isOpened }: Object) => G.ifElse(
    R.and(R.equals(isOpened, false), G.isNotNil(isOpened)), '32px', 'auto')
  };
  overflow: ${({ isOpened }: Object) => G.ifElse(
    R.and(R.equals(isOpened, false), G.isNotNil(isOpened)), 'hidden', 'visible')
  };
`;

export const FormGroup = styled.div`
  ${space}
  ${width}

  z-index: ${({ zI }: Object) => zI || 'unset'};
  position: ${({ position }: Object) => position};
  display: ${({ display }: Object) => display || 'flex'};
  align-items: ${({ align }: Object) => align || 'center'};
  justify-content: ${({ jc }: Object) => jc || 'baseline'};
  min-height: ${({ minHeight }: Object) => minHeight || '30px'};
  flex-direction: ${({ direction }: Object) => direction || 'column'};

  ${({ additionClass }: Object) => additionClass}
`;

export const FormGroupTitle = styled.div`
  ${space}

  height: 30px;
  line-height: 30px;
  padding-left: 15px;

  background-color: ${() => G.getTheme('colors.mainBlue')};
  color: ${() => G.getTheme('forms.group.title.textColor')};
`;

export const FormGroupTitleWrapper = styled.div`
  position: relative;

  z-index: ${({ zIndex }: Object) => zIndex};

  & .arrow {
    right: 16px;
    top: 11px;
    position: absolute;
    transform: ${({isOpened}: Object) => isOpened && 'rotate(180deg)'};
  }
`;

export const TabFormWrapper = styled.div`
  background: linear-gradient(
    to right,
    ${() => G.getTheme('labelsWrapper.bgColor')} 320px,
    ${() => G.getTheme('pages.layOutBgColor')} 0%
  );
  & > form > section {
    z-index: unset;
  }
`;

export const InputWrapper = styled.span`
  ${space}
  ${width}

  display: flex;
  position: relative;
  align-items: center;
`;

export const Input = styled.input`
  ${space}
  ${width}
  ${height}
  ${fontSize}

  z-index: 2;
  cursor: text;
  outline: none;

  padding: ${({ p }: Object) => p || '0 0 0 15px'};
  height: ${({ height }: Object) => height || '30px'};
  text-align: ${({ p }: Object) => R.equals(p, '0') && 'center'};
  line-height: ${({ lineHeight }: Object) => lineHeight || '30px'};
  border-radius: ${({ borderRadius }: Object) => borderRadius || '2px'};
  border: ${(props: Object) => G.ifElse(props.withoutBorder, 'none', '1px solid')};
  border-color: ${(props: Object) => G.ifElse(
    G.isNotNilAndNotEmpty(props.borderColor),
    props.borderColor,
    renderBorderColor(props),
  )};
  background-color: ${({ bgColor, disabled }: Object) => (
    bgColor ||
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};

  &:focus {
    box-shadow: ${({ withoutBorder }: Object) => R.not(withoutBorder) && '0 0 5px 0 rgba(206, 40, 40, 0.5)'};
    box-shadow: ${({ version, withoutBorder }: Object) => (
      R.not(withoutBorder) &&
      `0 0 5px 0 ${G.getThemeByCond(G.isThemeSecondVersion(version), 'colors.boxShadowBlue', 'colors.boxShadowRed')}`
    )};
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }

  & + .MuiInputAdornment-root {
    min-width: 31px;
    margin-left: 2px;
  }
`;

export const Textarea = styled.textarea`
  ${space}
  ${width}
  ${height}

  cursor: text;
  resize: none;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 20px;

  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  border-radius: ${({ borderRadius }: Object) => borderRadius || '1px'};
  background-color: ${({ disabled }: Object) =>
    G.getThemeByCond(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor')};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

Textarea.defaultProps = {
  height: 72,
  width: 540,
};

// demo 2
const afterPosition = system({
  afterTop: {
    property: 'top',
    transform: (value: any) => G.ifElse(G.isNumber(value), `${value}px`, value),
  },
  afterRight: {
    property: 'right',
    transform: (value: any) => G.ifElse(G.isNumber(value), `${value}px`, value),
  },
});

export const SelectWrapper = styled.div`
  ${width}

  position: relative;

  &:after {
    width: 6px;
    height: 6px;
    content: '';
    z-index: 10;
    position: absolute;
    border: solid black;
    pointer-events: none;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg) translate(0, -60%);

    ${afterPosition}
  }
`;

SelectWrapper.defaultProps = {
  afterTop: 18,
  afterRight: 25,
};

export const SelectComponent = styled.select`
  ${space}
  ${width}
  ${fontSize}
  ${borderRadius}

  z-index: 0;
  height: 30px;
  outline: none;
  appearance: none;
  line-height: 30px;
  position: relative;

  background-color: ${() => G.getTheme('forms.inputs.bgColor')};
  border: 1px solid ${(props: Object) => renderBorderColor(props)};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

SelectComponent.defaultProps = {
  pl: 15,
  pr: 25,
  borderRadius: '2px',
};

const Separator = styled.span`
  right: -13px;
  position: absolute;
`;

const Option = styled.option``;

const ReadOnly = styled.div`
  ${space}

  padding: 0 5px;
  font-weight: bold;

  color: ${() => G.getTheme('forms.readOnlyColor')};
`;

export const Checkbox = styled.input`
  ${space}

  z-index: 0;
  position: relative;
  margin-bottom: ${({ mb }: Object) => mb};

  &:focus {
    outline: none;
  }

  &:before {
    left: 0;
    top: 50%;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 2px;
    position: absolute;
    transform: translate(0, -50%);

    background-color: ${() => G.getTheme('forms.checkboxes.bgColor')};
    border: 1px solid ${({ borderColor }: Object) => G.getTheme(G.ifElse(
      G.isNotNil(borderColor),
      borderColor,
      'forms.checkboxes.borderColor',
    ))};
  }

  &:checked:before {
    border: 1px solid ${({ borderColorChecked }: Object) => G.getTheme(G.ifElse(
      G.isNotNil(borderColorChecked),
      borderColorChecked,
      'forms.checkboxes.borderColorChecked',
    ))};
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 2px;
    position: absolute;
    background-color: ${() => G.getTheme('forms.checkboxes.bgColorChecked')};
  }

  &:checked:after {
    border: 1px solid ${({ textColorChecked }: Object) => G.getTheme(G.ifElse(
      G.isNotNil(textColorChecked),
      textColorChecked,
      'forms.checkboxes.textColorChecked',
    ))};
    left: 0;
    top: 50%;
    width: 3px;
    height: 8px;
    content: '';
    position: absolute;
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg) translate(0, -90%);
  }
`;

export const Label = styled.label`
  ${space}
  ${width}
  ${fontSize}

  display: flex;

  cursor: ${({ cursor }: Object) => cursor};
  min-width: ${({ minWidth }: Object) => minWidth};
  font-weight: ${({ fontWeight }: Object) => fontWeight};
  flex-direction: ${({ flexDirection }: Object) => flexDirection};
  color: ${({ color }: Object) => color || G.getTheme('forms.inputs.labelColor')};

  &.required::after {
    content: '*';
    color: ${() => G.getTheme('colors.light.mainRed')};
  }

  ${({ fieldClass }: Object) => G.ifElse(G.isObject(fieldClass), fieldClass)}
`;

const CalendarIconLabel = styled(Label)`
  &.clear-label-styles {
    padding-left: 0px;
    width: 20px;
  }
`;

export const Error = styled.span`
  ${space}
  ${fontSize}

  top: ${({ errorTop }: Object) => errorTop};
  left: ${({ errorLeft }: Object) => errorLeft};
  z-index: ${({ zIndex }: Object) => zIndex || 'unset'};
  display: ${({ errorDisplay }: Object) => errorDisplay};
  cursor: ${({ cursor }: Object) => R.or(cursor, 'auto')};
  position: ${({ errorPosition }: Object) => errorPosition};
  color: ${() => G.getTheme('forms.inputs.errorTextColor')};
  overflow: ${({ errorOverflow }: Object) => errorOverflow};
  text-align: ${({ errorTextAlign }: Object) => errorTextAlign};
  white-space: ${({ errorWhiteSpace }: Object) => errorWhiteSpace};
  width: ${({ errorWidth }: Object) => errorWidth || 'max-content'};
  text-overflow: ${({ errorTextOverflow }: Object) => errorTextOverflow};

  ${({ errorClass }: Object) => errorClass}
`;

export const ErrorsWrapper = styled.span`
  ${space}
  ${fontSize}

  display: flex;
  padding: 10px 20px;
  flex-direction: column;

  color: ${() => G.getTheme('forms.inputs.errorTextColor')};

  & span + span {
    margin: 5px 0 0;
  }
`;

const InfoWrapper = styled.div`
  ${space}
  ${bottom}
  ${zIndex}

  position: relative;
  align-items: center;
  display: inline-flex;

  & .info-icon {
    ${width}
    ${height}
    ${minWidth}

    cursor: help;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-radius: 50%;
    color: ${() => G.getTheme('colors.mainBlue')};
    border: 1px solid ${() => G.getTheme('colors.mainBlue')};
    &:hover {
      color: ${() => G.getTheme('info.hoverIconColor')};
      background-color: ${() => G.getTheme('colors.mainBlue')};
    }
  }
  & .info-text {
    left: 16px;
    padding: 5px;
    display: none;
    margin: 0 5px;
    font-size: 12px;
    max-width: 400px;
    position: absolute;
    width: max-content;
    border-radius: 2px;
    word-break: break-word;
    background-color: ${() => G.getTheme('info.bgColor')};
    box-shadow: 0 0 2px 2px ${() => G.getTheme('colors.mainBlue')};
  }
  & .info-icon:hover + .info-text {
    display: inline-flex;
  }
`;

InfoWrapper.defaultProps = {
  width: 16,
  zIndex: 11,
  height: 16,
  minWidth: 16,
};

export const MultiSelectWrapper = styled.div`
  ${space}
  ${width}

  z-index: ${({ zIndex }: Object) => zIndex || '10'};
  & > div.Select {
    & .Select-control {
      height: 30px;
      border-radius: 2px;
      border-color: ${(props: Object) => renderBorderColor(props)};
      & .Select-placeholder {
        line-height: 30px;
      }
      & .Select-value {
        width: 100%;
        line-height: 30px;
        & .Select-value-label {
          width: calc(100% - 20px);
        }
      }
    }
    &.is-focused:not(.is-open) .Select-control {
      box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
      border-color: ${() => G.getTheme('forms.inputs.borderColor')};
    }
  }
  & .Select-input {
    height: 28px;
    padding: 0px;
    & input {
      height: 28px;
      padding: 0px;
    }
  }
`;

const LeftText = styled.section`
  margin-right: 5px;
  color: ${({ isChecked }: Object) =>
    isChecked &&
    (G.getTheme('toggle.textUnchecked') || G.getTheme('toggle.rightTextChecked'))
  };
`;

const RightText = styled.section`
  margin-left: 5px;
  color: ${({ isChecked }: Object) =>
    isChecked &&
    (G.getTheme('toggle.leftTextChecked') || G.getTheme('toggle.textUnchecked'))
  };
`;

export const ToggleWrapper = styled.section`
  ${space}
  ${width}

  display: flex;
`;

const CountrySelectWrapper = styled.section`
  ${width}
  ${space}

  height: 30px;
  display: flex;
  position: relative;

  z-index: ${({ zi }: Object) => zi || 'unset'};

  & > select {
    z-index: 0;
    width: 100%;
    height: 30px;
    outline: none;
    appearance: none;
    line-height: 30px;
    position: relative;
    border-radius: 2px;
    padding-left: 15px;
    padding-right: 25px;
    background-color: ${() => G.getTheme('forms.inputs.bgColor')};
    border: 1px solid ${(props: Object) => renderBorderColor(props)};

    &:focus {
      box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
    }
  }

  &:after {
    top: 15px;
    width: 6px;
    content: '';
    height: 6px;
    right: 15px;
    position: absolute;
    border: solid black;
    pointer-events: none;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg) translate(0, -60%);
  }
`;

const CustomFieldWrapper = styled.div`
  ${width}
  ${space}

  & .Select-value {
    width: calc(100% - 8px);
  }

  & .Select-multi-value-wrapper {
    width: 100%;
  }

  & .Select--multi .Select-value-label {
    word-break: break-word;
    width: calc(100% - 20px);
  }
`;

export const Info = (props: Object) => {
  const { text } = props;

  return (
    <InfoWrapper {...props}>
      <Box cursor='help' className='info-icon'>i</Box>
      {text && <Box className='info-text'>{text}</Box>}
    </InfoWrapper>
  );
};

const SubValueInfo = styled.div`
  padding: 3px;
  font-size: 12px;
  margin-right: 15px;
  border-radius: 2px;
  background-color: ${() => G.getTheme('config.configGroupItem.inheritedValueBgColor')};
`;

const UploadWrapper = styled.label`
  ${width}
  ${space}

  cursor: pointer;
  position: relative;
  text-align: center;
  word-wrap: break-word;
  padding: 6px 40px 6px 15px;

  border: ${(props: Object) => `1px dashed ${renderBorderColor(props)}`};
  color: ${({ disabled }: Object) => (
    G.ifElse(
      disabled,
      G.getTheme('buttons.disabled.textColor'),
      G.getTheme('buttons.uploadBtn.textColor'),
    )
  )};

  & input[type=file] {
    display: none;
  }
`;

export const CleanWrapper = styled.div`
  top: 0px;
  right: 15px;
  width: 23px;
  z-index: 14;
  height: 30px;
  padding: 7px 5px;
  position: absolute;
`;

export const renderOptions = (options: Array) => {
  if (R.isNil(options)) return;

  if (R.not(Array.isArray(options))) {
    const defaultOptions = [<Option key={1} value='' />];
    R.forEachObjIndexed((opt: Object) => (
      defaultOptions.push(<Option key={opt.guid} value={opt.guid}>{opt.displayValue}</Option>)
    ), options);

    return defaultOptions;
  }

  return options.map((option: string, index: number) => {
    if (R.is(String, option)) {
      return <Option key={index} value={option}>{option}</Option>;
    }

    return (
      <Option key={index} disabled={option.disabled} value={R.or(option.value, option.guid)}>
        {R.or(option.name, option.label)}
      </Option>
    );
  });
};

const renderFirstSelectOption = (config: Object) => (
  <Option disabled={config.disabled} value={R.or(config.value, '')}>
    {R.or(config.text, '')}
  </Option>
);

const getSwitcherIndex = (value: any) => {
  if (G.isTrue(value)) return 0;

  if (G.isFalse(value)) return 1;

  return 2;
};

const getSwitcherValue = (value: any) => {
  if (G.isBoolean(value)) return value;

  return R.or(value, null);
};

const renderMultiswitchGroup = ({
  info,
  input,
  label,
  margin,
  change,
  options,
  display,
  direction,
  fieldClass,
  labelMargin,
  labelFontSize,
  labelPosition,
  additionClass,
  customSwitchFunction,
}: Object) => (
  <FormGroup
    display={display}
    additionClass={additionClass}
    direction={R.or(direction, 'column')}
  >
    {
      G.isNotNil(labelPosition) &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        // demo 2.1
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
      </Label>
    }
    <InputWrapper m={margin} width={width}>
      <Switcher
        options={options}
        value={getSwitcherValue(input.value)}
        onSwitch={(value: string) => {
          if (G.isFunction(customSwitchFunction)) return customSwitchFunction(value);

          change(input.name, value);
        }}
        selectedOptionIndex={R.or(getSwitcherIndex(input.value), 0)}
      />
      {isInfoPresent(info) && <Info text={info} />}
    </InputWrapper>
  </FormGroup>
);

const renderDisplay = (type: string, display: string) => (
  G.ifElse(
    R.equals(type, 'hidden'),
    'none',
    R.or(display, 'flex'),
  )
);

const enhance = compose(
  lifecycle({
    componentDidUpdate() {
      const { meta: { touched, error }, withError, onHasError, onClearError } = this.props;
      if (R.and(touched, error)) onHasError(error);
      if (R.and(withError, R.isNil(error))) onClearError();
    },
  }),
);

const renderInputInMultipleGroup = enhance(({
  type,
  info,
  width,
  input,
  margin,
  padding,
  fontSize,
  separator,
  inputWidth,
  placeholder,
  withSeparator,
  meta: { touched, error },
}: Object) => (
  <InputWrapper m={margin} width={width}>
    <Input
      {...input}
      type={type}
      p={padding}
      error={error}
      touched={touched}
      width={inputWidth}
      fontSize={fontSize}
      placeholder={placeholder}
    />
    {withSeparator && <Separator>{separator}</Separator>}
    {isInfoPresent(info) && <Info text={info} />}
  </InputWrapper>
));

const renderSelectInMultipleGroup = enhance(({
  info,
  input,
  margin,
  padding,
  options,
  fontSize,
  inputWidth,
  meta: { touched, error },
}: Object) => (
  <span>
    <SelectWrapper>
      <SelectComponent
        {...input}
        m={margin}
        p={padding}
        error={error}
        touched={touched}
        width={inputWidth}
        fontSize={fontSize}
      >
        {renderOptions(options)}
      </SelectComponent>
    </SelectWrapper>
    {isInfoPresent(info) && <Info text={info} />}
  </span>
));

export const renderInputGroup = ({
  jc,
  type,
  info,
  align,
  input,
  width,
  label,
  margin,
  padding,
  display,
  handler,
  version,
  fontSize,
  position,
  endLabel,
  errorTop,
  disabled,
  direction,
  errorLeft,
  lineHeight,
  fieldClass,
  errorClass,
  errorMargin,
  placeholder,
  afterAction,
  labelMargin,
  autoComplete,
  groupPadding,
  errorDisplay,
  errorFontSize,
  additionClass,
  errorPosition,
  labelFontSize,
  labelPosition,
  afterActionText,
  onClickEndLabel,
  afterActionHasPerm,
  additionalLabelComponent,
  meta: { touched, error },
}: Object) => (
  <FormGroup
    jc={jc}
    align={align}
    p={groupPadding}
    position={position}
    additionClass={additionClass}
    direction={R.or(direction, 'column')}
    display={renderDisplay(type, display)}
  >
    {
      G.isNotNil(labelPosition) &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
        {
          G.isNotNil(endLabel) &&
          <LabelComponent typedName='dark' endAction={onClickEndLabel} endLabel={endLabel} />
        }
        {G.isNotNilAndNotEmpty(additionalLabelComponent) && additionalLabelComponent}
      </Label>
    }
    <Input
      {...input}
      m={margin}
      type={type}
      p={padding}
      error={error}
      width={width}
      version={version}
      touched={touched}
      fontSize={fontSize}
      lineHeight={lineHeight}
      placeholder={placeholder}
      disabled={R.or(disabled, false)}
      autoComplete={R.or(autoComplete, '')}
      onChange={R.or(handler, input.onChange)}
      value={G.ifElse(G.isObject(input.value), '', input.value)}
    />
    {
      afterAction &&
      <AfterAction
        afterAction={afterAction}
        afterActionText={afterActionText}
        afterActionHasPerm={afterActionHasPerm}
      />
    }
    {isInfoPresent(info) && <Info text={info} />}
    {
      R.and(touched, error) &&
      <Error
        m={errorMargin}
        errorTop={errorTop}
        errorLeft={errorLeft}
        errorClass={errorClass}
        fontSize={errorFontSize}
        errorDisplay={errorDisplay}
        errorPosition={errorPosition}
      >
        {error}
      </Error>
    }
  </FormGroup>
);

const getTitle = (value: string, options: Array) => {
  if (R.or(G.isNilOrEmpty(value), G.isNilOrEmpty(options))) return '';

  const sel = R.find((option: Object) => R.equals(value, option.value), options);

  return R.pathOr('', ['label'], sel);
};

const getOptionValue = (value: string) => {
  if (G.isObject(value)) {
    const valueToUse = R.or(
      R.path([GC.FIELD_DROPDOWN_OPTION_GUID], value),
      R.path([GC.FIELD_GUID], value),
    );

    if (G.isNotNilAndNotEmpty(valueToUse)) return valueToUse;
  }

  return value;
};

export const renderSelectGroup = ({
  jc,
  info,
  label,
  input,
  width,
  align,
  margin,
  padding,
  options,
  display,
  position,
  afterTop,
  disabled,
  fontSize,
  errorTop,
  direction,
  errorLeft,
  afterRight,
  fieldClass,
  errorClass,
  labelMargin,
  errorMargin,
  groupPadding,
  labelFontSize,
  labelPosition,
  errorFontSize,
  additionClass,
  errorPosition,
  firstOptionConfig,
  additionalLabelComponent,
  meta: { touched, error },
}: Object) => (
  <FormGroup
    jc={jc}
    align={align}
    p={groupPadding}
    position={position}
    additionClass={additionClass}
    display={R.or(display, 'flex')}
    direction={R.or(direction, 'column')}
  >
    {
      G.isNotNil(labelPosition) &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
        {G.isNotNilAndNotEmpty(additionalLabelComponent) && additionalLabelComponent}
      </Label>
    }
    <SelectWrapper afterTop={afterTop} afterRight={afterRight}>
      <SelectComponent
        {...input}
        m={margin}
        p={padding}
        width={width}
        error={error}
        touched={touched}
        disabled={disabled}
        fontSize={fontSize}
        value={getOptionValue(input.value)}
        title={getTitle(input.value, options)}
      >
        {firstOptionConfig && renderFirstSelectOption(firstOptionConfig)}
        {renderOptions(options)}
      </SelectComponent>
    </SelectWrapper>
    {isInfoPresent(info) && <Info text={info} />}
    {
      R.and(touched, error) &&
      <Error
        m={errorMargin}
        errorTop={errorTop}
        errorLeft={errorLeft}
        errorClass={errorClass}
        fontSize={errorFontSize}
        errorPosition={errorPosition}
      >
        {error}
      </Error>
    }
  </FormGroup>
);

const renderTextareaGroup = ({
  jc,
  type,
  info,
  label,
  input,
  align,
  width,
  margin,
  display,
  padding,
  fontSize,
  endLabel,
  direction,
  fieldClass,
  errorClass,
  placeholder,
  labelMargin,
  errorMargin,
  groupPadding,
  labelFontSize,
  labelPosition,
  errorFontSize,
  additionClass,
  onClickEndLabel,
  meta: { touched, error },
}: Object) => (
  <FormGroup
    jc={jc}
    align={align}
    p={groupPadding}
    display={display}
    additionClass={additionClass}
    direction={R.or(direction, 'column')}
  >
    {
      G.isNotNil(labelPosition) &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
        {
          G.isNotNil(endLabel) &&
          <LabelComponent endAction={onClickEndLabel} endLabel={endLabel} typedName='dark' />
        }
      </Label>
    }
    <Textarea
      {...input}
      m={margin}
      type={type}
      p={padding}
      width={width}
      error={error}
      touched={touched}
      fontSize={fontSize}
      placeholder={placeholder}
    />
    {isInfoPresent(info) && <Info text={info} />}
    {
      R.and(touched, error) &&
      <Error errorClass={errorClass} fontSize={errorFontSize} m={errorMargin}>
        {error}
      </Error>
    }
  </FormGroup>
);

const renderCheckboxGroup = ({
  type,
  info,
  width,
  input,
  label,
  margin,
  checked,
  direction,
  fieldClass,
  labelMargin,
  labelFontSize,
  labelPosition,
  additionClass,
}: Object) => (
  <FormGroup
    width={width}
    align='center'
    additionClass={additionClass}
    direction={R.or(direction, 'column')}
  >
    {
      R.equals(labelPosition, 'left') &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
      </Label>
    }
    {
      R.and(R.equals(labelPosition, 'left'), isInfoPresent(info)) &&
      <Info text={info} />
    }
    <Checkbox m={margin} checked={Boolean(checked)} {...input} type={type} value={Boolean(input.value)} />
    {
      R.or(R.equals(labelPosition, 'right'), R.not(labelPosition)) &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
      </Label>
    }
    {
      R.and(R.or(R.equals(labelPosition, 'right'), R.not(labelPosition)), isInfoPresent(info)) &&
      <Info text={info} />
    }
  </FormGroup>
);

export const LabelIcon = styled.img`
  ${space}
`;

const renderReadOnlyGroup = ({
  info,
  label,
  margin,
  readData,
  direction,
  labelIcon,
  labelMargin,
  labelFontSize,
  additionClass,
  labelPosition,
  labelIconAction,
  labelIconPadding,
  additionalLabelComponent,
}: Object) => {
  const getReadData = (readData: any) => {
    if (G.isObject(readData)) return G.getDisplayedValueFromObject(readData);

    return R.or(readData, '-');
  };

  return (
    <FormGroup
      align='center'
      additionClass={additionClass}
      direction={R.or(direction, 'column')}
    >
      {
        R.and(R.equals(labelPosition, 'left'), G.isNotNilAndNotEmpty(labelIcon)) &&
        <LabelIcon
          alt={labelIcon}
          p={labelIconPadding}
          onClick={labelIconAction}
          src={G.composeImgLink('light', labelIcon)}
        />
      }
      {
        R.equals(labelPosition, 'left') &&
        <Label m={labelMargin} fontSize={labelFontSize}>
          {label}
          {
            G.isNotNilAndNotEmpty(additionalLabelComponent) &&
            additionalLabelComponent
          }
        </Label>
      }
      {
        R.and(G.notEquals(labelPosition, 'left'), isInfoPresent(info)) &&
        <Info text={info} />
      }
      <ReadOnly m={margin}>{getReadData(readData)}</ReadOnly>
      {
        R.or(R.equals(labelPosition, 'right'), R.not(labelPosition)) &&
        <Label m={labelMargin} fontSize={labelFontSize}>{label}</Label>
      }
      {
        R.and(R.or(G.notEquals(labelPosition, 'right'), R.not(labelPosition)), isInfoPresent(info)) &&
        <Info text={info} />
      }
    </FormGroup>
  );
};

const renderToggleGroup = ({
  jc,
  info,
  label,
  input,
  width,
  margin,
  checked,
  handler,
  disabled,
  leftText,
  minHeight,
  direction,
  labelIcon,
  rightText,
  labelMargin,
  subValueInfo,
  groupPadding,
  additionClass,
  labelFontSize,
  labelPosition,
  labelIconAction,
  labelIconPadding,
}: Object) => (
  <FormGroup
    jc={jc}
    align='center'
    p={groupPadding}
    minHeight={minHeight}
    additionClass={additionClass}
    direction={R.or(direction, 'column')}
  >
    {
      labelPosition === 'left' && labelIcon &&
      <LabelIcon
        alt={labelIcon}
        p={labelIconPadding}
        onClick={labelIconAction}
        src={G.composeImgLink('light', labelIcon)}
      />
    }
    {
      labelPosition === 'left'
      && <Label m={labelMargin} minWidth='max-content' fontSize={labelFontSize}>{label}</Label>
    }
    {labelPosition !== 'left' && isInfoPresent(info) && <Info text={info} />}
    <ToggleWrapper m={margin} width={width}>
      {leftText && <LeftText isChecked={input.value}>{leftText}</LeftText>}
      <Toggle
        {...input}
        icons={false}
        disabled={disabled}
        value={Boolean(input.value)}
        onChange={R.or(handler, input.onChange)}
        checked={Boolean(G.ifElse(G.isNotNil(checked), checked, input.value))}
      />
      {rightText && <RightText isChecked={input.value}>{rightText}</RightText>}
    </ToggleWrapper>
    {
      (labelPosition === 'right' || !labelPosition) &&
      <Label fontSize={labelFontSize} m={labelMargin}>{label}</Label>
    }
    {
      (labelPosition !== 'right' || !labelPosition) && subValueInfo &&
      <SubValueInfo>{subValueInfo}</SubValueInfo>
    }
    {(labelPosition !== 'right' || !labelPosition) && isInfoPresent(info) && <Info text={info} />}
  </FormGroup>
);

const renderCalendarGroup = ({
  jc,
  info,
  input,
  label,
  width,
  align,
  margin,
  maxDate,
  minDate,
  handler,
  direction,
  fieldClass,
  errorClass,
  placeholder,
  errorMargin,
  labelMargin,
  isClearable,
  groupPadding,
  labelPosition,
  additionClass,
  errorFontSize,
  labelFontSize,
  timeSelection,
  timeIntervals,
  withoutCalendarLabel,
  meta: { touched, error, active },
}: Object) => {
  const timeFormat = G.ifElse(timeSelection, GC.DEFAULT_DATE_TIME_FORMAT, GC.DEFAULT_DATE_FORMAT);
  const selected = G.isNotNilAndNotEmpty(input.value) ? new Date(input.value) : null;

  const setMaxDate = () => {
    if (G.isNotNilAndNotEmpty(maxDate)) {
      return maxDate;
    }

    return null;
  };
  const setMinDate = () => {
    if (G.isNotNilAndNotEmpty(minDate)) {
      return minDate;
    }

    return null;
  };

  return (
    <CalendarFormGroup
      jc={jc}
      width={width}
      active={active}
      p={groupPadding}
      additionClass={additionClass}
      timeSelection={timeSelection}
      align={R.or(align, 'center')}
      direction={R.or(direction, 'column')}
    >
      {
        R.equals(labelPosition, 'left') &&
        <Label
          m={labelMargin}
          fieldClass={fieldClass}
          fontSize={labelFontSize}
          className={G.ifElse(G.isString(fieldClass), fieldClass)}
        >
          {label}
        </Label>
      }
      {
        R.and(G.notEquals(labelPosition, 'left'), isInfoPresent(info)) &&
        <Info text={info} />
      }
      <DatePicker
        {...R.dissoc('value', input)}
        m={margin}
        width={width}
        error={error}
        margin={margin}
        id={input.name}
        touched={touched}
        autoComplete='off'
        selected={selected}
        todayButton='Today'
        maxDate={setMaxDate()}
        minDate={setMinDate()}
        portalId='root-portal'
        showPopperArrow={false}
        dateFormat={timeFormat}
        showYearDropdown={true}
        showMonthDropdown={true}
        showTimeSelect={timeSelection}
        isClearable={R.or(isClearable, false)}
        timeIntervals={R.or(timeIntervals, 15)}
        placeholderText={R.or(placeholder, '')}
        onChange={R.or(handler, input.onChange)}
      />
      {
        R.and(R.equals(labelPosition, 'right'), R.not(labelPosition)) &&
        <Label
          m={labelMargin}
          fieldClass={fieldClass}
          fontSize={labelFontSize}
          className={G.ifElse(G.isString(fieldClass), fieldClass)}
        >
          {label}
        </Label>
      }
      {
        G.notEquals(withoutCalendarLabel, true) &&
        <CalendarIconLabel className='clear-label-styles' htmlFor={input.name}>
          <LabelComponent frontIcon={I.calendar(G.getTheme('icons.iconColor'), 16, 15)} />
        </CalendarIconLabel>
      }
      {
        R.and(R.or(G.notEquals(labelPosition, 'right'), R.not(labelPosition)), isInfoPresent(info)) &&
        <Info text={info} />
      }
      {
        R.and(touched, error) &&
        <Error errorClass={errorClass} fontSize={errorFontSize} m={errorMargin}>{error}</Error>
      }
    </CalendarFormGroup>
  );
};

const renderDatePickerMui = ({
  jc,
  input,
  width,
  align,
  label,
  margin,
  maxDate,
  minDate,
  handler,
  direction,
  fieldClass,
  errorClass,
  errorMargin,
  placeholder,
  isClearable,
  labelMargin,
  groupPadding,
  disablePortal,
  errorFontSize,
  additionClass,
  labelPosition,
  labelFontSize,
  timeSelection,
  timeIntervals,
  withCalendarIcon,
  meta: { touched, error, active },
}: Object) => {
  const selected = G.isNotNilAndNotEmpty(input.value) ? new Date(input.value) : null;

  const setMaxDate = () => {
    if (G.isNotNilAndNotEmpty(maxDate)) {
      return maxDate;
    }

    return G.addDateTime(new Date(), 100, 'years');
  };

  const setMinDate = () => {
    if (G.isNotNilAndNotEmpty(minDate)) {
      return minDate;
    }

    return G.subtractDateTime(new Date(), 100, 'years');
  };

  const handleChange = (value: Object) => {
    const handleChange = R.or(handler, input.onChange);
    const newValue = G.ifElse(
      R.or(G.isNilOrEmpty(value), R.not(G.isValidDate(value))),
      null,
      G.createLocalDateTimeFromInstanceOrISOString(value, G.getDateTimeFormat(timeSelection)),
    );

    handleChange(newValue);
  };

  return (
    <CalendarMuiFormGroup
      jc={jc}
      active={active}
      p={groupPadding}
      additionClass={additionClass}
      align={R.or(align, 'center')}
      direction={R.or(direction, 'column')}
    >
      {
        R.equals(labelPosition, 'left') &&
        <Label
          m={labelMargin}
          fieldClass={fieldClass}
          fontSize={labelFontSize}
          className={G.ifElse(G.isString(fieldClass), fieldClass)}
        >
          {label}
        </Label>
      }
      <DatePickerMui
        m={margin}
        width={width}
        value={selected}
        maxDate={setMaxDate()}
        minDate={setMinDate()}
        onChange={handleChange}
        placeholder={placeholder}
        isClearable={isClearable}
        minutesStep={timeIntervals}
        disablePortal={disablePortal}
        withCalendarIcon={withCalendarIcon}
        inputSettings={{
          ...R.omit(['onChange', 'value'], input),
          error,
          touched,
          boxShadow: 'unset',
          id: `${input.name}`,
        }}
      />
      {
        R.and(R.equals(labelPosition, 'right'), R.not(labelPosition)) &&
        <Label
          m={labelMargin}
          fieldClass={fieldClass}
          fontSize={labelFontSize}
          className={G.ifElse(G.isString(fieldClass), fieldClass)}
        >
          {label}
        </Label>
      }
      {
        R.and(touched, error) &&
        <Error errorClass={errorClass} fontSize={errorFontSize} m={errorMargin}>{error}</Error>
      }
    </CalendarMuiFormGroup>
  );
};

// TODO: remove after testing
const withRerenderAutocomplete = compose(
  withState('renderFirstTime', 'setRenderFirstTime', true),
  withState('showAutocomplete', 'setShowAutocomplete', true),
  withHandlers({
    handlesSetShowAutocomplete: (props: Object) => () => {
      if (props.renderFirstTime) {
        props.setRenderFirstTime(false);
        props.setShowAutocomplete(false);
        setTimeout(() => {
          props.setShowAutocomplete(true);
        }, 0);
      }
    },
  }),
);

const renderAddressAutocompleteGroup = ({
  jc,
  zI,
  info,
  input,
  label,
  width,
  align,
  zIndex,
  margin,
  errorTop,
  endLabel,
  position,
  errorLeft,
  direction,
  errorClass,
  fieldClass,
  labelMargin,
  errorMargin,
  placeholder,
  groupPadding,
  errorPosition,
  additionClass,
  errorFontSize,
  labelFontSize,
  onChangeAddress,
  onClickEndLabel,
  onSelectAddress,
  renderFirstTime,
  useMenuPortalTarget,
  meta: { touched, error },
}: Object) => (
  <FormGroup
    jc={jc}
    zI={zIndex}
    align={align}
    p={groupPadding}
    position={position}
    additionClass={additionClass}
    direction={R.or(direction, 'column')}
  >
    {

      label &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
        {
          G.isNotNil(endLabel) &&
          <LabelComponent endAction={onClickEndLabel} endLabel={endLabel} typedName='dark' />
        }
      </Label>
    }
    <RelativeBox
      m={margin}
      width={width}
      zIndex={R.or(zI, zIndex)}
    >
      <PlacesAutocomplete
        placeholder={placeholder}
        handleBlur={input.onBlur}
        value={R.or(input.value, '')}
        handleSelect={onSelectAddress}
        autoFocus={R.not(renderFirstTime)}
        useMenuPortalTarget={useMenuPortalTarget}
        setFieldValue={(fieldName: any, value: string) => onChangeAddress(value)}
        additionalStyles={{
          control: (baseStyles: Object, props: Object) => ({
            ...baseStyles,
            minHeight: 30,
            borderRadius: 2,
            height: 'max-content',
            borderColor: renderBorderColor({ error, touched }),
            boxShadow: G.ifElse(G.isTrue(props.isFocused), '0 0 5px 0 rgba(206, 40, 40, 0.5)'),
            ':hover': {
              borderColor: renderBorderColor({ error, touched }),
            },
          }),
        }}
      />
    </RelativeBox>
    {isInfoPresent(info) && <Info text={info} />}
    {
      R.and(touched, error) &&
      <Error
        m={errorMargin}
        errorTop={errorTop}
        errorLeft={errorLeft}
        errorClass={errorClass}
        fontSize={errorFontSize}
        errorPosition={errorPosition}
      >
        {error}
      </Error>
    }
  </FormGroup>
);

// TODO: remove after testing
const renderFieldAutocompleteGroup = withRerenderAutocomplete(({
  info,
  input,
  width,
  label,
  zIndex,
  margin,
  endLabel,
  direction,
  fieldClass,
  errorMargin,
  placeholder,
  labelMargin,
  errorFontSize,
  additionClass,
  labelFontSize,
  renderFirstTime,
  onClickEndLabel,
  onSelectSearchValue,
  onChangeSearchField,
  meta: { touched, error },
}: Object) => (
  <FormGroup
    additionClass={additionClass}
    direction={R.or(direction, 'column')}
  >
    {
      label &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
        {
          G.isNotNil(endLabel) &&
          <LabelComponent endAction={onClickEndLabel} endLabel={endLabel} typedName='dark' />
        }
      </Label>
    }
    <RelativeBox
      m={margin}
      width={width}
      zIndex={zIndex}
    >
      <PlacesAutocomplete
        placeholder={placeholder}
        handleBlur={input.onBlur}
        value={R.or(input.value, '')}
        handleSelect={onSelectSearchValue}
        autoFocus={R.not(renderFirstTime)}
        setFieldValue={(fieldName: any, value: string) => onChangeSearchField(value)}
        additionalStyles={{
          control: (baseStyles: Object, props: Object) => ({
            ...baseStyles,
            minHeight: 30,
            borderRadius: 2,
            height: 'max-content',
            borderColor: renderBorderColor({ error, touched }),
            boxShadow: G.ifElse(G.isTrue(props.isFocused), '0 0 5px 0 rgba(206, 40, 40, 0.5)'),
            ':hover': {
              borderColor: renderBorderColor({ error, touched }),
            },
          }),
        }}
      />
    </RelativeBox>
    {isInfoPresent(info) && <Info text={info} />}
    {
      R.and(touched, error) &&
      <Error errorClass={fieldClass} fontSize={errorFontSize} m={errorMargin}>
        {error}
      </Error>
    }
  </FormGroup>
));

const renderCountrySelectGroup = ({
  jc,
  info,
  input,
  align,
  label,
  width,
  zIndex,
  margin,
  endLabel,
  direction,
  fieldClass,
  errorClass,
  errorMargin,
  labelMargin,
  groupPadding,
  additionClass,
  errorFontSize,
  labelFontSize,
  onClickEndLabel,
  meta: { touched, error },
}: Object) => (
  <FormGroup
    jc={jc}
    p={groupPadding}
    additionClass={additionClass}
    align={R.or(align, 'center')}
    direction={R.or(direction, 'column')}
  >
    {
      label &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
        {
          G.isNotNil(endLabel) &&
          <LabelComponent endAction={onClickEndLabel} endLabel={endLabel} typedName='dark' />
        }
      </Label>
    }
    <CountrySelectWrapper
      m={margin}
      zi={zIndex}
      width={width}
      error={error}
      touched={touched}
    >
      <CountryDropdown {...input} />
    </CountrySelectWrapper>
    {isInfoPresent(info) && <Info text={info} />}
    {
      R.and(touched, error) &&
      <Error errorClass={errorClass} fontSize={errorFontSize} m={errorMargin}>{error}</Error>
    }
  </FormGroup>
);

const renderMaskedTimeInput = ({
  jc,
  info,
  align,
  input,
  label,
  width,
  margin,
  direction,
  labelIcon,
  fieldClass,
  labelMargin,
  groupPadding,
  additionClass,
  labelFontSize,
  labelPosition,
  labelIconAction,
  labelIconPadding,
}: Object) => {
  const value = G.setCorrectTimeInitialValue(G.getValueFromObject(input));

  const { mask, pipe, placeholder } = R.path([GC.DATE_TIME_FORMAT_US, 'time'], GC.dateTimeFormatMap);

  return (
    <FormGroup
      jc={jc}
      p={groupPadding}
      additionClass={additionClass}
      align={R.or(align, 'center')}
      direction={R.or(direction, 'column')}
    >
      {
        R.and(R.equals(labelPosition, 'left'), labelIcon) &&
        <LabelIcon
          alt={labelIcon}
          p={labelIconPadding}
          onClick={labelIconAction}
          src={G.composeImgLink('light', labelIcon)}
        />
      }
      {
        R.equals(labelPosition, 'left') &&
        <Label
          m={labelMargin}
          fieldClass={fieldClass}
          fontSize={labelFontSize}
          className={G.ifElse(G.isString(fieldClass), fieldClass)}
        >
          {label}
        </Label>
      }
      {
        R.not(R.equals(labelPosition, 'left')) && isInfoPresent(info) && <Info text={info} />
      }
      <InputWrapper m={margin} width={width}>
        <MaskedInput
          {...input}
          pipe={pipe}
          mask={mask}
          value={value}
          render={(ref: Object, props: Object) => (
            <Input {...props} ref={ref} width='100%' placeholder={placeholder} />
          )}
        />
      </InputWrapper>
      {
        R.or(R.equals(labelPosition, 'right'), R.isNil(labelPosition)) &&
        <Label
          m={labelMargin}
          fieldClass={fieldClass}
          fontSize={labelFontSize}
          className={G.ifElse(G.isString(fieldClass), fieldClass)}
        >
          {label}
        </Label>
      }
      {
        R.not(R.equals(labelPosition, 'right')) && isInfoPresent(info) && <Info text={info} />
      }
    </FormGroup>
  );
};

export const renderCustomFieldGroup = ({
  info,
  name,
  width,
  label,
  margin,
  isMulti,
  options,
  validate,
  component,
  direction,
  labelIcon,
  fieldClass,
  labelMargin,
  labelPosition,
  labelFontSize,
  additionClass,
  labelIconAction,
  labelIconPadding,
  closeMenuOnSelect,
}: Object) => (
  <FormGroup
    align='center'
    additionClass={additionClass}
    direction={R.or(direction, 'column')}
  >
    {
      R.and(R.equals(labelPosition, 'left'), labelIcon) &&
      <LabelIcon
        alt={labelIcon}
        p={labelIconPadding}
        onClick={labelIconAction}
        src={G.composeImgLink('light', labelIcon)}
      />
    }
    {
      R.equals(labelPosition, 'left') &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
      </Label>
    }
    {
      R.not(R.equals(labelPosition, 'left')) && isInfoPresent(info) && <Info text={info} />
    }
    <CustomFieldWrapper m={margin} width={width}>
      <Field
        name={name}
        isMulti={isMulti}
        options={options}
        validate={validate}
        component={component}
        closeMenuOnSelect={closeMenuOnSelect}
      />
    </CustomFieldWrapper>
    {
      R.or(R.equals(labelPosition, 'right'), R.isNil(labelPosition)) &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
      </Label>
    }
    {
      R.not(R.equals(labelPosition, 'right')) && isInfoPresent(info) && <Info text={info} />
    }
  </FormGroup>
);

const renderUploadFileGroup = ({
  jc,
  type,
  input,
  width,
  align,
  margin,
  disabled,
  direction,
  errorClass,
  errorMargin,
  placeholder,
  cleanUpload,
  groupPadding,
  errorFontSize,
  additionClass,
  documentFilename,
  selectFileAction,
  meta: { touched, error },
}: Object) => {
  const text = G.ifElse(G.isNotNil(input.value), input.value, R.or(documentFilename, placeholder));

  return (
    <FormGroup
      jc={jc}
      p={groupPadding}
      additionClass={additionClass}
      align={R.or(align, 'center')}
      direction={R.or(direction, 'column')}
    >
      <UploadWrapper
        m={margin}
        error={error}
        width={width}
        touched={touched}
        disabled={disabled}
      >
        {text}
        <input
          {...input}
          value=''
          type={type}
          disabled={disabled}
          onChange={selectFileAction}
        />
        {
          R.and(G.isNotNil(input.value), G.isNotEmpty(input.value)) &&
          <CleanWrapper
            onClick={(e: Object) => {
              e.preventDefault();
              cleanUpload();
            }}
          >
            {I.trash('#930000')}
          </CleanWrapper>
        }
      </UploadWrapper>
      {
        R.and(touched, error) &&
        <Error errorClass={errorClass} fontSize={errorFontSize} m={errorMargin}>{error}</Error>
      }
    </FormGroup>
  );
};

const renderMultiSelectGroup = ({
  jc,
  info,
  align,
  input,
  width,
  label,
  zIndex,
  margin,
  display,
  options,
  direction,
  fieldClass,
  errorClass,
  labelMargin,
  errorMargin,
  placeholder,
  groupPadding,
  selectAction,
  labelPosition,
  labelFontSize,
  errorFontSize,
  additionClass,
  selectedValues,
  meta: { error, touched },
}: Object) => (
  <FormGroup
    jc={jc}
    align={align}
    p={groupPadding}
    display={display || 'flex'}
    additionClass={additionClass}
    direction={direction || 'column'}
  >
    {
      G.isNotNil(labelPosition) &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
      </Label>
    }
    <RelativeBox
      m={margin}
      width={width}
      zIndex={zIndex}
    >
      <ReactSelect
        height={30}
        isMulti={true}
        name={input.name}
        options={options}
        isClearable={false}
        onChange={selectAction}
        placeholder={placeholder}
        value={R.or(selectedValues, input.value)}
      />
    </RelativeBox>
    {isInfoPresent(info) && <Info text={info} />}
    {
      touched
      && error
      && <Error errorClass={errorClass} fontSize={errorFontSize} m={errorMargin}>{error}</Error>
    }
  </FormGroup>
);

const enhanceEmail = compose(
  withState('emailError', 'setEmailError', null),
  withState('emailTouched', 'setEmailTouched', false),
);

export const renderMultiEmail = enhanceEmail(({
  jc,
  align,
  width,
  label,
  input,
  margin,
  display,
  maxWidth,
  direction,
  emailError,
  fieldClass,
  errorClass,
  labelMargin,
  errorMargin,
  groupPadding,
  selectAction,
  emailTouched,
  labelPosition,
  labelFontSize,
  errorFontSize,
  additionClass,
  setEmailError,
  setEmailTouched,
  shouldOverrideEmails,
}: Object) => (
  <FormGroup
    jc={jc}
    align={align}
    p={groupPadding}
    additionClass={additionClass}
    display={R.or(display, 'flex')}
    direction={R.or(direction, 'column')}
  >
    {
      G.isNotNil(labelPosition) &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
      </Label>
    }
    <Box m={margin}>
      <MultiEmailInput
        width={width}
        value={input.value}
        maxWidth={maxWidth}
        setFieldValue={selectAction}
        shouldOverrideEmails={shouldOverrideEmails}
        setFieldTouched={() => setEmailTouched(true)}
        setFieldError={(id: any, error: string) => setEmailError(error)}
      />
    </Box>
    {
      emailTouched
      && emailError
      && <Error errorClass={errorClass} fontSize={errorFontSize} m={errorMargin}>{emailError}</Error>
    }
  </FormGroup>
));

const PhoneNumberInputWrapper = styled.div`
  ${space}
  ${width}

  & .PhoneInput > input {
    height: 30px;
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: calc(100% - 30px);

    border-color: ${(props: Object) => renderBorderColor(props)};

    &:focus {
      border-color: ${({ version }: Object) => (
        G.getThemeByCond(G.isThemeSecondVersion(version), 'colors.boxShadowBlue', 'colors.boxShadowRed')
      )};
    }
  }

  & .react-phone-number-input__input--style {
    height: 30px;
    width: calc(100% - 30px);
    border-color: ${(props: Object) => renderBorderColor(props)};

    &:focus {
      border-color: ${({ version }: Object) => (
        G.getThemeByCond(G.isThemeSecondVersion(version), 'colors.boxShadowBlue', 'colors.boxShadowRed')
      )};
    }
  }
`;

export const renderPhoneNumberInputGroup = ({
  jc,
  type,
  info,
  align,
  input,
  width,
  label,
  margin,
  display,
  position,
  endLabel,
  errorTop,
  direction,
  errorLeft,
  fieldClass,
  errorClass,
  errorMargin,
  afterAction,
  labelMargin,
  groupPadding,
  errorDisplay,
  errorFontSize,
  additionClass,
  errorPosition,
  labelFontSize,
  labelPosition,
  afterActionText,
  onClickEndLabel,
  afterActionHasPerm,
  meta: { touched, error },
}: Object) => (
  <FormGroup
    jc={jc}
    align={align}
    p={groupPadding}
    position={position}
    additionClass={additionClass}
    direction={R.or(direction, 'column')}
    display={renderDisplay(type, display)}
  >
    {
      G.isNotNil(labelPosition) &&
      <Label
        m={labelMargin}
        fieldClass={fieldClass}
        fontSize={labelFontSize}
        className={G.ifElse(G.isString(fieldClass), fieldClass)}
      >
        {label}
        {
          G.isNotNil(endLabel) &&
          <LabelComponent typedName='dark' endAction={onClickEndLabel} endLabel={endLabel} />
        }
      </Label>
    }
    <PhoneNumberInputWrapper
      m={margin}
      error={error}
      width={width}
      touched={touched}
    >
      <PhoneInput
        {...input}
        country='US'
        flags={flags}
        countries={PHONE_COUNTRIES}
        onChange={(value: string) => input.onChange(R.or(value, ''))}
        placeholder={G.getWindowLocale('titles:enter-phone-number', 'Enter phone number')}
      />
    </PhoneNumberInputWrapper>
    {
      afterAction &&
      <AfterAction
        afterAction={afterAction}
        afterActionText={afterActionText}
        afterActionHasPerm={afterActionHasPerm}
      />
    }
    {isInfoPresent(info) && <Info text={info} />}
    {
      R.and(touched, error) &&
      <Error
        m={errorMargin}
        errorTop={errorTop}
        errorLeft={errorLeft}
        errorClass={errorClass}
        fontSize={errorFontSize}
        errorDisplay={errorDisplay}
        errorPosition={errorPosition}
      >
        {error}
      </Error>
    }
  </FormGroup>
);

const renderTypeRenderMap = {
  text: renderInputGroup,
  select: renderSelectGroup,
  toggle: renderToggleGroup,
  file: renderUploadFileGroup,
  time: renderMaskedTimeInput,
  multiEmail: renderMultiEmail,
  textarea: renderTextareaGroup,
  checkbox: renderCheckboxGroup,
  calendar: renderCalendarGroup,
  datePicker: renderDatePickerMui,
  'read-only': renderReadOnlyGroup,
  multiswitch: renderMultiswitchGroup,
  multiselect: renderMultiSelectGroup,
  countrySelect: renderCountrySelectGroup,
  phoneNumber: renderPhoneNumberInputGroup,
  textmultiple: renderInputInMultipleGroup,
  selectmultiple: renderSelectInMultipleGroup,
  // TODO: remove after testing
  // fieldAutocomplete: renderFieldAutocompleteGroup,
  addressAutocomplete: renderAddressAutocompleteGroup,
};

export const renderFormGroup = (type: string) => {
  const configUseMuiCalendar = G.getAmousConfigByNameFromWindow(GC.UI_USE_MUI_CALENDAR);
  let typeToUse = type;

  if (R.and(R.equals(type, 'datePicker'), G.isFalse(configUseMuiCalendar))) {
    typeToUse = 'calendar';
  }

  const render = renderTypeRenderMap[typeToUse];

  if (G.isNotNilAndNotEmpty(render)) {
    return render;
  }

  return renderInputGroup;
};

const inputGroup = css`
  & label {
    width: 320px;
    padding-left: 15px;
  }
  &:first-of-type {
    margin-top: 5px;
  }
`;

export const styles = {
  inputGroup,
};
