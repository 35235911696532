import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// common
import { showRequestStatusModal } from '../../common/actions';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getAllAvailableRefTypesByScopeRequest } from '../reference/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import routesMap from '../../utilities/routes';
import endpointsMap from '../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../utilities/http';
// feature service-vendor
import * as A from './actions';
import * as S from './selectors';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectServiceVendorGuid,
  makeSelectServiceVendorBranchGuid,
} from './selectors';
//////////////////////////////////////////////////

// report
function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.path(['defaultReport'], reportParams),
      )) {
      return yield put(A.setListLoading(false));
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const systemFields = [
      { name: GC.FIELD_BRANCH_GUID, freezed: false, sequence: 100, reference: false },
    ];

    const { limit, offset } = pagination;

    const reqBody = {
      limit,
      offset,
      orderFields,
      systemFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.serviceVendorList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
      yield put(getAllAvailableRefTypesByScopeRequest(GC.SERVICE_VENDOR_REPORT));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (error) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetItemListSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { fields, fileType, orderFields, searchCriteria } = payload;

    const filterParams = yield select(makeSelectFilterParams());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const reqBody = {
      fields,
      orderFields,
      currentEnterprise,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const params = { format: fileType };

    const data = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const options = {
      data,
      params,
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequest, 'post', endpointsMap.serviceVendorExportReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');

    yield put(closeLoader());
  }
}

export function* getServiceVendorRequest({ guid }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.getServiceVendorByGuid(guid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getServiceVendorSuccess(data));
      yield put(A.getServiceVendorConfigsRequest(G.getBranchGuidFromObject(data)));
    } else {
      yield call(G.handleFailResponse, res, 'getServiceVendorRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getServiceVendorRequest exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.SERVICE_VENDOR_REPORT,
  A,
  handleGetItemListSaga,
  { S, createUpdateReportSuccessCallback },
);
// report

// service vendor
function* createServiceVendorRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.serviceVendor, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.goToRoute, routesMap.editServiceVendor(G.getGuidFromObject(data)));
    } else {
      yield call(G.handleFailResponse, res, 'createServiceVendorRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createServiceVendorRequest exception');
  }
}

function* handleDeleteItemSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { data: payload };

    const endpoint = endpointsMap.serviceVendorMassDelete;

    const res = yield call(sendRequest, 'delete', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.deleteItemSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteItemSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteItemSaga exception');
  }
}

export function* updateServiceVendorRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { values, updateAndClose, shouldCloseModal } = payload;

    const options = { data: values };

    const res = yield call(sendRequest, 'put', endpointsMap.serviceVendor, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (updateAndClose) {
        G.historyGoBack();
      } else {
        yield put(A.updateServiceVendorSuccess(data));

        if (shouldCloseModal) {
          yield put(closeModal());
        }
      }
    } else {
      yield call(G.handleFailResponse, res, 'updateServiceVendorRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateServiceVendorRequest exception');
  }
}

function* sendListToIntegrationRequest({ payload }: Object) {
  try {
    const { guids, integrationType } = payload;

    yield put(openLoader());

    const options = {
      data: guids,
      params: { integrationType },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.sendServiceVendorListToIntegration, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { totalCount, successCount, sentToDivisionNames } = data;

      yield put(A.sendListToIntegrationSuccess(sentToDivisionNames));
      yield put(A.resetListAndPagination());

      yield call(handleGetItemListSaga, { payload: { openLoader: true }});

      yield put(closeModal());

      const errors = R.compose(
        R.values,
        R.mapObjIndexed((messageArray: array, key: string) => ({ key, messageArray })),
        R.pathOr({}, ['errors']),
      )(data);

      const title = `${G.getWindowLocale('titles:accounting', 'Accounting')} ${
        G.getWindowLocale('titles:export-result', 'Export Result')}`;

      yield put(showRequestStatusModal({
        title,
        errors,
        totalCount,
        successCount,
        status: G.getPropFromObject(GC.FIELD_STATUS, data),
      }));
    } else {
      yield call(G.handleFailResponse, res, 'sendListToIntegrationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'sendListToIntegrationRequest exception');
  }
}

function* getServiceVendorIntegrationListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.FIELD_SERVICE_VENDOR_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.serviceVendorIntegrationInfoList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getServiceVendorIntegrationListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getServiceVendorIntegrationListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'getServiceVendorIntegrationListRequest exception');
  }
}

function* getServiceVendorIntegrationAuditListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.FIELD_SERVICE_VENDOR_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.serviceVendorIntegrationAuditList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getServiceVendorIntegrationAuditListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getServiceVendorIntegrationAuditListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'getServiceVendorIntegrationAuditListRequest exception');
  }
}

function* getServiceVendorDocListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.FIELD_PRIMARY_OBJECT_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.serviceVendorDocList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getServiceVendorDocListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getServiceVendorDocListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'getServiceVendorDocListRequest exception');
  }
}

function* createOrUpdateServiceVendorDocRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const isUpdate = G.isNotNilAndNotEmpty(G.getGuidFromObject(payload));

    const endpoint = G.ifElse(isUpdate, endpointsMap.serviceVendorDocUpdate, endpointsMap.serviceVendorDoc);

    const options = { data: G.makeDataForDocument(payload) };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(closeModal());
      yield put(A.createOrUpdateServiceVendorDocSuccess({ data, isUpdate }));
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateServiceVendorDocRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateServiceVendorDocRequest exception');
  }
}

function* deleteServiceVendorDocRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'delete', endpointsMap.getCurrentServiceVendorDocEndpoint(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:remove');

      const documentList = yield select(S.makeSelectDocumentList());

      const index = R.findIndex(R.propEq(payload, GC.FIELD_GUID), documentList);

      yield put(A.deleteServiceVendorDocSuccess(index));
    } else {
      yield call(G.handleFailResponse, res, 'deleteServiceVendorDocRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'deleteServiceVendorDocRequest exception');
  }
}

function* downloadServiceVendorDocRequest({ payload }: Object) {
  try {
    const options = {
      resType: 'arraybuffer',
      params: R.pick([GC.FIELD_FILE_NAME, GC.FIELD_PRIMARY_OBJECT_GUID], payload),
    };

    const res = yield call(
      sendRequestWithQSParamsSerializer, 'get', endpointsMap.serviceVendorDocDownloadFile, options,
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isTrue(G.getPropFromObject('isPreview', payload))) {
        G.openFileInWindowFromArrayBufferResponse(res);
      } else {
        G.saveFileFromResponse(res, G.getPropFromObject(GC.FIELD_FILE_NAME, payload));
      }
    } else {
      yield call(G.handleFailResponse, G.convertArrayBufferFailResponse(res), 'downloadServiceVendorDocRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'downloadServiceVendorDocRequest exception');
  }
}
// service vendor

// configs
function* getServiceVendorConfigsRequest({ payload }: Object) {
  try {
    const names = R.join(',', [GC.SERVICE_VENDOR_DOCUMENT_TYPE, GC.SERVICE_VENDOR_VENDOR_SERVICE_TYPE]);

    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getServiceVendorConfigsSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getPasswordConfigsForUsersSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getPasswordConfigsForUsersSaga exception');
  }
}
// configs

// users
function* getUserGeneralListByServiceVendorBranchGuidRequest() {
  try {
    const branchGuid = yield select(makeSelectServiceVendorBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.userListGeneralChildrenAndCurrent,
      successAction: A.getUserGeneralListByServiceVendorBranchGuidSuccess,
      parentSagaName: 'getUserGeneralListByServiceVendorBranchGuidRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getUserGeneralListByServiceVendorBranchGuidRequest exception');
  }
}

// geofencing zone
function* getGeoFencingZoneListRequest({ payload }: Object) {
  try {
    const options = {
      params: { [GC.FIELD_SERVICE_VENDOR_GUID]: payload },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      parentSagaName: 'getGeoFencingZoneListRequest',
      endpoint: endpointsMap.serviceVendorRepairZoneList,
      successAction: A.receivedGeoFencingZoneListSuccess,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getGeoFencingZoneListRequest exception');
  }
}

function* createGeoFencingZoneRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { version, zoneAddressPoints } = payload;

    const serviceVendorGuid = yield select(makeSelectServiceVendorGuid());

    const method = G.ifElse(
      G.isNilOrEmpty(version),
      'post',
      'put',
    );

    const options = {
      data: {
        ...R.dissoc('zoneAddressPoints', payload),
        serviceVendorGuid,
        addressPoints: zoneAddressPoints,
      },
    };

    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:200-201',
      parentSagaName: 'createGeoFencingZoneRequest',
      endpoint: endpointsMap.serviceVendorRepairZone,
      successAction: A.receivedGeoFencingZoneSuccess,
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createGeoFencingZoneRequest exception');
  }
}

function* deleteGeoFencingZoneRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.serviceVendorRepairZoneByGuid(payload);

    yield call(crudSaga, {
      payload,
      endpoint,
      method: 'delete',
      shouldCloseModal: true,
      showSuccessMessage: true,
      successMessage: 'messages:success:204',
      successAction: A.deleteGeoFencingZoneSuccess,
      parentSagaName: 'deleteGeoFencingZoneRequest',
    });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'deleteGeoFencingZoneRequest exception');
  }
}

// visit page
function* visitServiceVendorListPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_SERVICE_VENDOR_LIST_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport());
    } else {
      yield put(A.setInitialState());
    }

    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

export function* visitEditServiceVendorPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_SERVICE_VENDOR_EDIT_PAGE);

    const guid = G.getGuidFromObject(payload);

    yield put(A.cleanQuickFilter());

    yield call(getServiceVendorRequest, payload);

    yield put(A.getServiceVendorDocListRequest(guid));
    yield put(A.getServiceVendorIntegrationListRequest(guid));
    yield put(A.getServiceVendorIntegrationAuditListRequest(guid));

    yield call(getGeoFencingZoneListRequest, { payload: guid });
    yield call(getUserGeneralListByServiceVendorBranchGuidRequest);

    break;
  }
}
// visit page

function* serviceVendorWatcherSaga() {
  // visit page
  yield takeLatest(GC.VISIT_SERVICE_VENDOR_LIST_PAGE, visitServiceVendorListPage);
  yield takeLatest(GC.VISIT_SERVICE_VENDOR_EDIT_PAGE, visitEditServiceVendorPage);
  // report
  yield takeLatest(A.deleteItemRequest, handleDeleteItemSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.getAvailableReportsRequest, handleAvailableReportsRequest);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  // service vendor
  yield takeLatest(A.createServiceVendorRequest, createServiceVendorRequest);
  yield takeLatest(A.updateServiceVendorRequest, updateServiceVendorRequest);
  yield takeLatest(A.sendListToIntegrationRequest, sendListToIntegrationRequest);
  yield takeLatest(A.deleteServiceVendorDocRequest, deleteServiceVendorDocRequest);
  yield takeLatest(A.getServiceVendorDocListRequest, getServiceVendorDocListRequest);
  yield takeLatest(A.downloadServiceVendorDocRequest, downloadServiceVendorDocRequest);
  yield takeLatest(A.createOrUpdateServiceVendorDocRequest, createOrUpdateServiceVendorDocRequest);
  yield takeLatest(A.getServiceVendorIntegrationListRequest, getServiceVendorIntegrationListRequest);
  yield takeLatest(A.getServiceVendorIntegrationAuditListRequest, getServiceVendorIntegrationAuditListRequest);
  // configs
  yield takeLatest(A.getServiceVendorConfigsRequest, getServiceVendorConfigsRequest);
  // geofencing zone
  yield takeLatest(A.deleteGeoFencingZoneRequest, deleteGeoFencingZoneRequest);
  yield takeLatest(A.createGeoFencingZoneRequest, createGeoFencingZoneRequest);
}

export default serviceVendorWatcherSaga;
