import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const setTruckGuid = createAction('setTruckGuid');
const setDriverGuid = createAction('setDriverGuid');
const getTruckListSuccess = createAction('getTruckListSuccess');
const updateTollChargeSuccess = createAction('updateTollChargeSuccess');
const assignDriverTruckRequest = createAction('assignDriverTruckRequest');
const receivedDriverListSuccess = createAction('receivedDriverListSuccess');
const visitTollChargesOnFleetPage = createAction('visitTollChargesOnFleetPage');
const updateTruckTollChargeRequest = createAction('updateTruckTollChargeRequest');
const updateDriverTollChargeRequest = createAction('updateDriverTollChargeRequest');
const getDriverPayrollNumberListByGuidsRequest = createAction('getDriverPayrollNumberListByGuidsRequest');
const getVendorPayrollNumberListByGuidsRequest = createAction('getVendorPayrollNumberListByGuidsRequest');
const receivedDriverPayrollNumberListByGuidsSuccess = createAction('receivedDriverPayrollNumberListByGuidsSuccess');
const receivedVendorPayrollNumberListByGuidsSuccess = createAction('receivedVendorPayrollNumberListByGuidsSuccess');

export {
  // report
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  setTruckGuid,
  setDriverGuid,
  getTruckListSuccess,
  updateTollChargeSuccess,
  assignDriverTruckRequest,
  receivedDriverListSuccess,
  visitTollChargesOnFleetPage,
  updateTruckTollChargeRequest,
  updateDriverTollChargeRequest,
  getDriverPayrollNumberListByGuidsRequest,
  getVendorPayrollNumberListByGuidsRequest,
  receivedDriverPayrollNumberListByGuidsSuccess,
  receivedVendorPayrollNumberListByGuidsSuccess,
};
